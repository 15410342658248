import axios from 'axios';
import rateLimit from 'axios-rate-limit';

export const axiosInstance = () => (
  rateLimit(
    axios.create({ baseURL: process.env.REACT_APP_API_URL }),
    { maxRequests: 5, perMilliseconds: 1000, maxRPS: 5 },
  )
);

export const instance = axiosInstance();

export const axiosInstanceWithAuth = (instance) => {
  const { defaults } = instance;
  return { ...instance, defaults };
};

export default () => axiosInstanceWithAuth(instance);
