import * as React from 'react';

function BoxWorkscheduleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={666.5}
      height={291}
      viewBox="0 0 666.5 291"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix_WI_a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#835bdb" />
          <stop offset={1} stopColor="#c282fd" />
        </linearGradient>
        <style>{'.prefix_WI_c{fill:#ca9ffd}.prefix_WI_d{fill:#fff}'}</style>
      </defs>
      <g transform="translate(-9.5 -728.179)">
        <rect
          width={188}
          height={188}
          rx={94}
          transform="translate(488 728.179)"
          opacity={0.078}
          fill="#9751eb"
        />
        <g opacity={0.522}>
          <g transform="translate(9.857 950.982)">
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 .198)"
            />
            <g transform="translate(87.983)">
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
          <g transform="translate(9.857 986.982)">
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 .198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 28.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 19.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 10.198)"
            />
            <circle
              className="prefix_WI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 .198)"
            />
            <g transform="translate(87.983)">
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                className="prefix_WI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
        </g>
        <g transform="translate(-305 700.179)">
          <rect
            className="prefix_WI_d"
            width={499}
            height={216}
            rx={20}
            transform="translate(430 69)"
          />
          <path
            fill="none"
            stroke="#677ea0"
            opacity={0.304}
            d="M464.5 122.5l428 1"
          />
          <path
            d="M892.167 94.006l-1.105-1.106-4.421 4.421-4.421-4.421-1.106 1.106 4.421 4.421-4.421 4.421 1.105 1.105 4.421-4.421 4.421 4.421 1.105-1.105-4.42-4.421z"
            fill="#9751eb"
          />
          <text
            transform="translate(459 105)"
            fontFamily="Roboto-Regular,Roboto"
            fontSize={20}
            opacity={0.798}
            fill="#9751eb"
          >
            <tspan x={0} y={0}>
              Gerar grid
            </tspan>
          </text>
          <text
            transform="translate(477 154)"
            fontSize={16}
            opacity={0.957}
            fill="#243e64"
            fontFamily="Roboto-Regular,Roboto"
          >
            <tspan x={25.441} y={15}>
              {'O template foi salvo. Deseja gerar o grid do per\xEDodo?'}
            </tspan>
          </text>
          <g opacity={0.896}>
            <g transform="translate(704 204)">
              <rect width={130} height={37} rx={16} fill="url(#prefix_WI_a)" />
              <text
                transform="translate(53.889 22.8)"
                fontFamily="Roboto-Bold,Roboto"
                fontWeight={700}
                fontSize={15}
                fill="#fff"
              >
                <tspan x={-33.644} y={0}>
                  Gerar grid
                </tspan>
              </text>
              <path
                className="prefix_WI_d"
                d="M109.384 17.596l-8.736-5.166a1.185 1.185 0 00-1.8 1.026v10.329a1.191 1.191 0 001.8 1.025l8.738-5.164a1.19 1.19 0 000-2.051z"
              />
            </g>
            <g transform="translate(518.13 204.001)">
              <rect
                width={169}
                height={40}
                rx={20}
                transform="translate(-.13)"
                fill="#8b8b8b"
                opacity={0.118}
              />
              <path
                d="M26.333 16.566h-1.844v1.844h1.844zm3.689 0h-1.844v1.844h1.844zm3.689 0h-1.844v1.844h1.844zm1.844-6.456h-.922V8.266h-1.844v1.844h-7.378V8.266h-1.844v1.844h-.923a1.836 1.836 0 00-1.835 1.845L20.8 24.866a1.844 1.844 0 001.844 1.844h12.912a1.85 1.85 0 001.844-1.844V11.955a1.85 1.85 0 00-1.844-1.845zm0 14.756H22.644V14.722h12.912z"
                fill="#243e64"
              />
              <text
                transform="translate(90.87 25)"
                fontSize={15}
                fill="#243e64"
                fontFamily="Roboto-Regular,Roboto"
              >
                <tspan x={-32.23} y={0}>
                  01 - 31 jul
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BoxWorkscheduleIcon;
