import * as React from 'react';

function BoxCsvIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={473.917}
      height={453.048}
      viewBox="0 0 473.917 453.048"
      {...props}
    >
      <defs>
        <filter
          id="prefix_CI_a"
          x={0}
          y={97.206}
          width={471.376}
          height={355.842}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="b" />
          <feFlood floodOpacity={0.102} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_CI_c"
          x={12.249}
          y={0}
          width={183.973}
          height={183.973}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={20} result="d" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="d" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_CI_e"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#009c8f" />
          <stop offset={1} stopColor="#00ccc3" />
        </linearGradient>
        <style>{'.prefix_CI_b{fill:#fff}.prefix_CI_i{fill:#677ea0}'}</style>
      </defs>
      <g transform="translate(-90.506 -1499.976)">
        <circle
          cx={88.321}
          cy={88.321}
          r={88.321}
          transform="translate(387.781 1755.103)"
          fill="#f1e7fc"
          opacity={0.452}
        />
        <g transform="translate(150.506 1637.182)">
          <g transform="translate(-60 -137.21)" filter="url(#prefix_CI_a)">
            <rect
              className="prefix_CI_b"
              width={351.376}
              height={235.842}
              rx={15}
              transform="translate(60 137.21)"
            />
          </g>
          <path
            d="M23.368 22.916h304.641a10 10 0 0110 10v15.78H13.368v-15.78a10 10 0 0110-10z"
            fill="#dee6f2"
          />
          <g
            transform="translate(13.368 22.916)"
            opacity={0.247}
            stroke="#677ea0"
            fill="none"
          >
            <rect width={325.596} height={197.649} rx={10} stroke="none" />
            <rect x={0.5} y={0.5} width={324.596} height={196.649} rx={9.5} />
          </g>
          <circle
            cx={2.864}
            cy={2.864}
            r={2.864}
            transform="translate(24.825 32.942)"
            fill="#ff5548"
          />
          <circle
            cx={2.864}
            cy={2.864}
            r={2.864}
            transform="translate(32.464 32.942)"
            fill="#ffc056"
          />
          <circle
            cx={2.864}
            cy={2.864}
            r={2.864}
            transform="translate(41.057 32.942)"
            fill="#08af5d"
          />
          <g opacity={0.3}>
            <path
              className="prefix_CI_i"
              d="M94.194 100.009h118.49v3.105H94.194zM94.194 109.323h53.812v3.105H94.194zM153.698 109.323h90.549v3.105h-90.549zM94.194 118.636h118.49v3.105H94.194zM94.194 129.502h155.227v3.105H94.194zM94.194 140.368h33.115v3.105H94.194zM131.449 140.368h72.439v3.105h-72.439zM208.027 140.368h49.155v3.105h-49.155z"
            />
          </g>
        </g>
        <path
          d="M244.722 1587.238h141.462"
          strokeDasharray={5}
          stroke="#677ea0"
          fill="none"
        />
        <path
          d="M437.728 1593.457v-.165a.054.054 0 00-.055-.055c0-.055-.055-.055-.055-.11l-.055-.051-9.355-6.824a.8.8 0 00-.385-.11h-6.77a.782.782 0 00-.33.11v-5.562l-.055-.055c0-.055-.055-.055-.055-.11l-.055-.055-4.4-3.192v-6.439a8.243 8.243 0 014.788-7.484 7.961 7.961 0 014.237 6.989v9.741a.578.578 0 00.275.5l4.675 3.407a.8.8 0 00.385.11h6.659a.625.625 0 00.605-.66v-13.153a13.787 13.787 0 00-13.812-13.813h-6.824a13.822 13.822 0 00-13.813 13.813v9.631a.578.578 0 00.275.5l9.3 6.769a.8.8 0 00.385.11h6.77a.782.782 0 00.33-.11v5.283a.578.578 0 00.275.5l4.4 3.192v5.393a6.815 6.815 0 01-6.6 6.824 6.917 6.917 0 01-2.421-5.228v-9.916a.82.82 0 00-.22-.5l-4.678-3.357a.8.8 0 00-.385-.11h-6.771a.644.644 0 00-.605.44v10.021a15.013 15.013 0 006 11.942l4.513 3.3a14.829 14.829 0 0023.827-11.83v-9.575c.055-.055.055-.11 0-.11m-10.126-6.054l7.594 5.5h-4.622l-7.594-5.5zm-17.06-9.19h4.623l2.917 2.146h-4.623zm4.237-7.374v6.109h-5.5v-6.6a7.915 7.915 0 017.87-7.925 9.561 9.561 0 012.146.275 9.627 9.627 0 00-4.513 8.145m9.135-13.153a12.542 12.542 0 0112.545 12.543v12.6h-5.448v-12.545a13.772 13.772 0 00-8.09-12.547h.991zm-19.316 12.6a12.566 12.566 0 0112.49-12.549h.165a12.5 12.5 0 0112.492 12.547v12l-3.415-2.534v-9.41a9.166 9.166 0 10-18.326-.5v7.649a.578.578 0 00.275.5l4.4 3.192v4.182l-8.09-5.778zm14.856 15.792h-5.5v-4.512h5.5zm6.824 15.519v-5.726a.578.578 0 00-.275-.5l-4.4-3.192v-4.175l8.09 5.833v9.355a6.87 6.87 0 01-6.827 6.879 7.151 7.151 0 01-2.806-.6 8.06 8.06 0 006.219-7.87m-15.74-11.01l2.917 2.146h-4.626l-2.913-2.146zm-5.943 9.245v-8.64l3.412 2.475v9.576a14.836 14.836 0 002.036 7.484 13.843 13.843 0 01-5.448-10.9m31.919 3.357a13.6 13.6 0 01-13.649 13.598 13.8 13.8 0 01-7.98-2.587l-.275-.22a13.824 13.824 0 01-5.393-10.786v-9.245h5.5v9.245a8.119 8.119 0 0016.234 0v-9.022h5.5z"
          opacity={0.594}
          fill="#677ea0"
        />
        <path
          d="M144.778 1756.561h-50.61v-168.006h50.61"
          opacity={0.598}
          strokeDasharray={5}
          stroke="#677ea0"
          fill="none"
        />
        <g transform="translate(90.505 1499.976)" filter="url(#prefix_CI_c)">
          <circle
            className="prefix_CI_b"
            cx={31.987}
            cy={31.987}
            r={31.987}
            transform="translate(72.25 57)"
          />
        </g>
        <path
          d="M15.039 9.131V0H1.611A1.607 1.607 0 000 1.611v31.152a1.607 1.607 0 001.611 1.611h22.558a1.607 1.607 0 001.611-1.611V10.742h-9.13a1.616 1.616 0 01-1.611-1.611zM8.593 18.8a.537.537 0 01-.537.537h-.537a1.074 1.074 0 00-1.074 1.074v2.148a1.074 1.074 0 001.074 1.074h.537a.537.537 0 01.537.537v1.074a.537.537 0 01-.537.537h-.537A3.223 3.223 0 014.3 22.558v-2.149a3.223 3.223 0 013.223-3.223h.537a.537.537 0 01.537.537zm2.972 6.982h-.824a.537.537 0 01-.537-.537v-1.076a.537.537 0 01.537-.537h.824c.4 0 .7-.235.7-.444a.355.355 0 00-.142-.258l-1.47-1.26a2.492 2.492 0 01-.895-1.889 2.732 2.732 0 012.842-2.593h.822a.537.537 0 01.537.537V18.8a.537.537 0 01-.537.537H12.6c-.4 0-.7.235-.7.444a.355.355 0 00.142.258l1.47 1.26a2.492 2.492 0 01.895 1.889 2.73 2.73 0 01-2.846 2.593zm5.621-8.056v1.4a7.318 7.318 0 001.074 3.819 7.316 7.316 0 001.074-3.819v-1.4a.537.537 0 01.537-.537h1.074a.537.537 0 01.537.537v1.4a9.234 9.234 0 01-2.436 6.317 1.074 1.074 0 01-1.574 0 9.234 9.234 0 01-2.436-6.317v-1.4a.537.537 0 01.537-.537h1.077a.537.537 0 01.537.535zM25.31 7.049L18.738.47A1.61 1.61 0 0017.6 0h-.41v8.593h8.59v-.41a1.606 1.606 0 00-.47-1.134z"
          transform="translate(182.016 1572.253)"
          fill="url(#prefix_CI_e)"
        />
      </g>
    </svg>
  );
}

export default BoxCsvIcon;
