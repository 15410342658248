import React, { memo } from 'react';

// Componentes
import Layout from 'components/layout/Layout';
import BoxIntroduction from 'modules/swapRequest/swapRequestComponents/BoxIntroduction';
import BoxHowItWorks from 'modules/swapRequest/swapRequestComponents/BoxHowItWorks';
import BoxCompensatoryTime from 'modules/swapRequest/swapRequestComponents/BoxCompensatoryTime';

function SwapRequestPage() {
  window.scrollTo(0, 0);

  return (
    <Layout>
      <BoxIntroduction />
      <BoxHowItWorks />
      <BoxCompensatoryTime />
    </Layout>
  );
}

export default memo(SwapRequestPage);

// ==================================================
// Estilos
// ==================================================
