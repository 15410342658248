import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';

// Componentes
import Layout from 'components/layout/Layout';
import BoxIntroduction from 'modules/home/homeComponents/BoxIntroduction';
import BoxSaveTime from 'modules/home/homeComponents/BoxSaveTime';
import BoxAvoidBreakingRules from 'modules/home/homeComponents/BoxAvoidBreakingRules';
import BoxValueYourEmployees from 'modules/home/homeComponents/BoxValueYourEmployees';
import BoxReduceYourCosts from 'modules/home/homeComponents/BoxReduceYourCosts';
import BoxFacilitateEngagement from 'modules/home/homeComponents/BoxFacilitateEngagement';
import BoxAboutUs from 'modules/home/homeComponents/BoxAboutUs';

// Actions
import { openModalDemo } from 'components/modal/modalDemo/ModalDemoAction';

function DemoPage() {
  window.scrollTo(0, 0);
  const dispatch = useDispatch();

  const [initializePage, setInitializePage] = useState(false);

  useEffect(() => {
    if (!initializePage) {
      setInitializePage(true);
      dispatch(openModalDemo());
    }
  }, [dispatch, initializePage]);

  return (
    <Layout>
      <BoxIntroduction />
      <BoxSaveTime />
      <BoxAvoidBreakingRules />
      <BoxValueYourEmployees />
      <BoxReduceYourCosts />
      <BoxFacilitateEngagement />
      <BoxAboutUs />
    </Layout>
  );
}

export default memo(DemoPage);

// ==================================================
// Estilos
// ==================================================
