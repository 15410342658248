import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';

function GridContainerComponent(props) {
  return (
    <BoxContainer
      margintop={props.marginTop || '0px'}
      marginbottom={props.marginBottom || '0px'}
      marginleft={props.marginLeft || '0px'}
      marginright={props.marginRight || '0px'}
      minheight={props.minHeight || 'none'}
      maxheight={props.maxHeight || 'none'}
    >
      <Grid
        container
        item
        justifyContent={props.justify || 'flex-start'}
        alignItems={props.alignItems || 'flex-start'}
        xs={props.xs || 12}
        sm={props.sm || 12}
        md={props.md || 12}
        lg={props.lg || 12}
        xl={props.xl || 12}
      >
        {props.children}
      </Grid>
    </BoxContainer>
  );
}

export default GridContainerComponent;

// ==================================================
// Estilos
// ==================================================
const BoxContainer = styled.div`
  min-height: ${props => (props.minheight)};
  max-height: ${props => (props.maxheight)};
  width: 100%;
  margin-top: ${props => (props.margintop)};
  margin-bottom: ${props => (props.marginbottom)};
  margin-left: ${props => (props.marginleft)};
  margin-right: ${props => (props.marginright)};
`;
