import React, { useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import ButtonBig from 'components/button/ButtonBig';
import BoxApiIcon from 'modules/integration/integrationComponents/BoxApiIcon';

// Tradução
import { translate } from 'translates/TranslatesAction';


function BoxWorkschedule() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxAvoidBreakingRulesContent>
      <BoxAvoidBreakingRules>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={6} md={5} lg={5} xl={5} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-right" data-aos-duration="600">{translate('Integracao_por_API')}</Title>
              <Subtitle data-aos="fade-right" data-aos-duration="600">{translate('Disponibilizamos_documentacao_detalhada_para_que_a_integracao_aconteca__Desenvolva_internamente_ou_procure_uma_de_nossas_parcerias')}</Subtitle>
              <BoxButton>
                <Link to={{ pathname: 'https://stargrid.stoplight.io/docs/stargrid-api/ca88af0ed7241-star-grid-api' }} target="_blank" style={{ textDecoration: 'none' }}>
                  <ButtonBig
                    id="id_button_box_views_doc"
                    label={translate('Leia_a_documentacao')}
                    type="button"
                    colors="purple"
                    margins="35px 0px 0px 0px"
                    padding="0px 35px"
                  />
                </Link>
              </BoxButton>
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={6} md={7} lg={7} xl={7} alignItems="center" justify="flex-end">
            <BoxOverflow>
              <BoxIcon data-aos="fade-down" data-aos-easing="linear" data-aos-duration="600">
                <BoxApiIconSG />
              </BoxIcon>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxAvoidBreakingRules>
    </BoxAvoidBreakingRulesContent>
  );
}

export default memo(BoxWorkschedule);

// ==================================================
// Estilos
// ==================================================
const BoxAvoidBreakingRulesContent = styled.div`
  width: 100%;
  background: #F7F9FC;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const BoxAvoidBreakingRules = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 15px 0px 0px 30px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 10px 0px 0px 30px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 10px 0px 0px 30px;
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;

  @media (max-width: 599px) {
    padding: 0px 30px 0px 30px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 30px 0px 30px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 30px 0px 30px;
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxApiIconSG = styled(BoxApiIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxIcon = styled.div``;

const BoxOverflow = styled.div`
  overflow: none;
`;

const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
