import { initialize } from 'redux-form';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

// Serviços
import { sendEventAnalytics } from 'services/Analytics';

// ==================================================
// FECHAR MODAL
// ==================================================
export function closeModalContact() {
  return {
    type: 'CLOSE_MODAL_CONTACT_FETCHED',
    payload: false,
  };
}

// ==================================================
// ABRIR MODAL
// ==================================================
function openModal() {
  return {
    type: 'OPEN_MODAL_CONTACT_FETCHED',
    payload: true,
  };
}
export function openModalContact() {
  sendEventAnalytics('EVENT/CONTACT/OPEN');

  const body = {
    subject: 'Contato via site',
    name_from: '',
    email_from: '',
    phone: '',
    job: '',
    department: 'comercial',
    company: '',
    message: '',
    salt: 'YjM1YjQ5NjI1ZjJmMzQxYzg5Mzc3OGJiNGIzZGIzNzU=',
  };
  return [
    initialize('modalContactForm', body),
    openModal(),
  ];
}

// ==================================================
// Enviar mensagem
// ==================================================
export function sendMessage(form) {
  sendEventAnalytics('EVENT/CONTACT/SEND');

  const body = {
    subject: form.department === 'suporte' ? 'Suporte via site' : 'Contato via site',
    name_from: form.name_from,
    email_from: form.email_from,
    phone: form.phone,
    job: form.job,
    department: form.department,
    company: form.company,
    message: form.message,
    salt: form.salt,
  };

  return (dispatch) => {
    axios.post(`${process.env.REACT_APP_API_URL}/v1/general/send-email`, body)
      .then(() => {
        toastr.success('Obrigado!', 'Mensagem enviada com sucesso.');
        dispatch(closeModalContact());
      })
      .catch(
        (e) => {
          if (e.response.data.data) {
            if (e.response.data.data.errors) {
              const errors = Object.values(e.response.data.data.errors);
              errors.map(err => (
                err.map(e => (
                  toastr.warning('Atenção:', e)
                ))
              ));
            } else if (e.response.data.data.message) {
              toastr.warning('Atenção:', e.response.data.data.message);
            } else {
              toastr.error('Atenção:', 'Contate o suporte.');
            }
          } else {
            toastr.error('Atenção:', 'Contate o suporte.');
          }
        },
      );
  };
}
