import React, { useEffect, useState, memo, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import ModalTemplate from 'components/modal/ModalTemplate';
import LogoStarGridWhite from 'components/layout/layoutComponents/LogoStarGridWhite';
import ButtonBig from 'components/button/ButtonBig';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import ScrollView100 from 'components/scroll/ScrollView100';
import GridContainer from 'components/grids/GridContainer';
import { ModalContent } from 'components/modal/ModalComponents';

// Actions
import { closeModalSupport, sendMessage } from 'components/modal/modalSupport/ModalSupportAction';
import { toMessage } from 'components/message/MessageToasterAction';

// Tradução
import { translate } from 'translates/TranslatesAction';

function ModalSupportPage() {
  const dispatch = useDispatch();

  const statusModalSupport = useSelector(state => state.modalSupportReducer.statusModalSupport);

  const [name, setName] = useState();
  const [cell, setCell] = useState();
  const [email, setEmail] = useState();
  const [job, setJob] = useState();
  const [company, setCompany] = useState();
  const [to, setTo] = useState('suporte');
  const [message, setMessage] = useState();

  useEffect(() => {
    if (statusModalSupport) Aos.init({ duration: 2000 });
  }, [statusModalSupport]);

  if (!statusModalSupport) {
    return (<Fragment />);
  }

  const onClearState = () => {
    setName();
    setCell();
    setEmail();
    setJob();
    setCompany();
    setTo('suporte');
    setMessage();
  };

  const onClose = () => {
    onClearState();
    dispatch(closeModalSupport());
  };

  const onSend = () => {
    if (!email && !cell) {
      toMessage('INFO', translate('Preencha_o_telefone_ou_email'));
    } else {
      dispatch(sendMessage(to, name, cell, email, job, company, message));
      onClearState();
    }
  };

  const arrayTo = [
    { value: 'suporte', label: translate('Suporte') },
    { value: 'comercial', label: translate('Comercial') },
    { value: 'recursosHumanos', label: translate('Recursos_humanos') },
    { value: 'outro', label: translate('Outro') },
  ];

  return (
    <ModalTemplate
      width="800px"
      open={statusModalSupport}
      onClose={() => onClose()}
    >
      <ModalContent minheight="770px" maxheight="770px" flexdirection="row" justifycontent="space-between" padding="0px">
        <BoxA>
          <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
          <BoxATitle data-aos="fade-up" data-aos-duration="600">
            {translate('Suporte')}
          </BoxATitle>
          <BoxASubtitle>{translate('Envie_sua_mensagem')}</BoxASubtitle>
        </BoxA>
        <BoxB>
          <CircleClose onClick={() => onClose()}>X</CircleClose>
          <GridContainer alignItems="center">
            <BoxInput data-aos="fade-left" data-aos-duration="600">
              <Input
                id="name_a"
                col={12}
                input={{ value: name, onChange: e => setName(e.target.value) }}
                label={translate('Seu_nome')}
                fullWidth
                paddingRight={false}
              />
            </BoxInput>
            <BoxInput data-aos="fade-left" data-aos-duration="600">
              <Input
                id="cell_a"
                col={12}
                input={{ value: cell, onChange: e => setCell(e.target.value) }}
                label={translate('Seu_celular')}
                fullWidth
                paddingRight={false}
              />
            </BoxInput>
            <BoxInput data-aos="fade-left" data-aos-duration="600">
              <Input
                id="email_a"
                col={12}
                input={{ value: email, onChange: e => setEmail(e.target.value) }}
                label={translate('Email_corporativo')}
                fullWidth
                paddingRight={false}
              />
            </BoxInput>
            <BoxInput data-aos="fade-left" data-aos-duration="600">
              <Input
                id="job_a"
                col={12}
                input={{ value: job, onChange: e => setJob(e.target.value) }}
                label={translate('Cargo')}
                fullWidth
                paddingRight={false}
              />
            </BoxInput>
            <BoxInput data-aos="fade-left" data-aos-duration="600">
              <Input
                id="company_a"
                col={12}
                input={{ value: company, onChange: e => setCompany(e.target.value) }}
                label={translate('Empresa')}
                fullWidth
                paddingRight={false}
              />
            </BoxInput>
            <BoxInput data-aos="fade-left" data-aos-duration="600">
              <Select
                id="to_a"
                col={12}
                input={{ value: to, onChange: e => setTo(e) }}
                options={arrayTo}
                meta={{ touched: '', error: '', warning: '' }}
                label={translate('Encaminhar_para')}
                fullWidth
                paddingRight={false}
              />
            </BoxInput>
            <BoxInput data-aos="fade-left" data-aos-duration="600">
              <Input
                id="message_a"
                col={12}
                multiline
                rows={5}
                input={{ value: message, onChange: e => setMessage(e.target.value) }}
                label={translate('Mensagem')}
                fullWidth
                paddingRight={false}
              />
            </BoxInput>
          </GridContainer>
          <ButtonBig
            id="button_modal_suport_send_a"
            label={translate('Enviar')}
            type="button"
            colors="purple"
            margins="0px 0px 0px 0px"
            padding="0px 150px"
            onClick={() => onSend()}
          />
        </BoxB>

        <App>
          <ScrollView100>
            <TitleApp>
              <CircleClose onClick={() => onClose()}>X</CircleClose>
              <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
              <BoxATitle data-aos="fade-up" data-aos-duration="600">
                {translate('Suporte')}
              </BoxATitle>
            </TitleApp>
            <BoxApp>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Input
                  id="name_b"
                  col={12}
                  input={{ value: name, onChange: e => setName(e.target.value) }}
                  label={translate('Seu_nome')}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Input
                  id="cell_b"
                  col={12}
                  input={{ value: cell, onChange: e => setCell(e.target.value) }}
                  label={translate('Seu_celular')}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Input
                  id="email_b"
                  col={12}
                  input={{ value: email, onChange: e => setEmail(e.target.value) }}
                  label={translate('Email_corporativo')}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Input
                  id="job_b"
                  col={12}
                  input={{ value: job, onChange: e => setJob(e.target.value) }}
                  label={translate('Cargo')}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Input
                  id="company_b"
                  col={12}
                  input={{ value: company, onChange: e => setCompany(e.target.value) }}
                  label={translate('Empresa')}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Select
                  id="to_b"
                  col={12}
                  input={{ value: to, onChange: e => setTo(e) }}
                  options={arrayTo}
                  meta={{ touched: '', error: '', warning: '' }}
                  label={translate('Encaminhar_para')}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Input
                  id="message_b"
                  col={12}
                  multiline
                  rows={5}
                  input={{ value: message, onChange: e => setMessage(e.target.value) }}
                  label={translate('Mensagem')}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <Button
                id="button_modal_suport_send_b"
                label={translate('Enviar')}
                type="button"
                colors="purple"
                margins="20px 0px 0px 0px"
                padding="0px 150px"
                onClick={() => onSend()}
              />
            </BoxApp>
          </ScrollView100>
        </App>

      </ModalContent>
    </ModalTemplate>
  );
}

export default memo(ModalSupportPage);

// ==================================================
// Estilos
// ==================================================
const CircleClose = styled.div`
  cursor: pointer;  
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &:hover {
    box-shadow: 
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }

  @media (max-width: 599px) {
    color: #FFF;
    background: transparent;
    border: solid 1px #FFF;
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;
    border-radius: 15px;
    top: 10px;
    right: 10px;
    font-size: 9px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    color: #FFF;
    background: #transparent;
    border: solid 1px #FFF;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
`;

const BoxA = styled.div`
  background: #9751EB;
  min-width: 200px;
  max-width: 200px;
  min-height: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 0px 0px 0px;
  border-radius: 10px 0px 0px 10px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const BoxB = styled.div`
  position: relative;
  background: #FFFFFF;
  flex: 1;
  min-height: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 60px 20px 60px;
  border-radius: 0px 10px 10px 0px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const App = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  @media (max-width: 599px) {
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: flex;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const TitleApp = styled.div`
  position: relative;
  background: #9751EB;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  border-radius: 10px 10px 0px 0px;
`;

const BoxApp = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 15px 15px 15px;
`;

const BoxATitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;
  
  @media (max-width: 599px) {
    font: normal normal normal 20px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 30px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
`;

const BoxASubtitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;
  
  @media (max-width: 599px) {
    text-align: center;
    font: normal normal normal 12px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 20px 0px;
    max-width: 486px;
    text-align: center;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 486px;
  }
`;

const BoxInput = styled.div`
  width: 100%;

  @media (max-width: 599px) {
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    margin: 20px 0px 0px 0px;
  }
`;
