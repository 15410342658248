import React, { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import ButtonBig from 'components/button/ButtonBig';
import BoxWorkscheduleTypeIcon from 'modules/workschedule/workscheduleComponents/BoxWorkscheduleTypeIcon';

// Actions
import { openModalDemo } from 'components/modal/modalDemo/ModalDemoAction';

// Serviços
import { sendEventAnalytics } from 'services/Analytics';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxWorkschedule() {
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const onOpenDemo = () => {
    sendEventAnalytics('WORKSCHEDULE/BUTTON/DEMO');
    dispatch(openModalDemo());
  };

  return (
    <BoxAvoidBreakingRulesContent>
      <BoxAvoidBreakingRules>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={6} md={6} lg={5} xl={5} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-right" data-aos-duration="600">{translate('Atendemos_todo_tipo_de_escala')}</Title>
              <Subtitle data-aos="fade-right" data-aos-duration="600">{translate('Esta_funcionalidade_permite_ao_colaborador_solicitar_uma_folga_descontando_o_banco_de_horas_acumulado__O_gestor_recebe_o_pedido_analisa_e_da_a_palavra_final')}</Subtitle>
              <BoxButton>
                <ButtonBig
                  id="button_open_modal_demo"
                  label={translate('Marque_uma_demonstracao')}
                  type="button"
                  colors="purple"
                  margins="35px 0px 0px 0px"
                  padding="0px 35px"
                  onClick={() => onOpenDemo()}
                />
              </BoxButton>
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={7} xl={7} alignItems="center" justify="flex-end">
            <BoxOverflow>
              <BoxIcon data-aos="fade-down" data-aos-easing="linear" data-aos-duration="600">
                <BoxWorkscheduleTypeIconSG />
              </BoxIcon>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxAvoidBreakingRules>
    </BoxAvoidBreakingRulesContent>
  );
}

export default memo(BoxWorkschedule);

// ==================================================
// Estilos
// ==================================================
const BoxAvoidBreakingRulesContent = styled.div`
  width: 100%;
  background: #F7F9FC;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const BoxAvoidBreakingRules = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 15px 0px 0px 30px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 10px 0px 0px 30px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 10px 0px 0px 30px;
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;

  @media (max-width: 599px) {
    padding: 0px 30px 0px 30px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 30px 0px 30px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 30px 0px 30px;
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxWorkscheduleTypeIconSG = styled(BoxWorkscheduleTypeIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin-top: 80px;
    margin-bottom: 30px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin-top: 80px;
    margin-bottom: 30px;
  }
  @media (min-width: 1920px) {
    margin-top: 80px;
    margin-bottom: 30px;
  }
`;

const BoxIcon = styled.div``;

const BoxOverflow = styled.div`
  overflow: none;
`;
