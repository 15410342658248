import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Componentes
import GridItem from 'components/grids/GridItem';

// Estilos
import { blueColor, redColor } from 'styles/Colors';

// Tradução
import { translate } from 'translates/TranslatesAction';

function SelectComponent(props) {
  const onChange = (event, value) => {
    if (!value) {
      props.input.onChange('');
    } else {
      props.input.onChange(value.value || '');
    }
  };

  const { input: { value }, meta: { touched, error, warning } } = props;
  let newValue = null;
  if ((value === 0 && props.options.length > 0) || (value && props.options.length > 0)) {
    props.options.map((option) => {
      if (option.value === value) {
        return newValue = option;
      }
      return newValue;
    });
  }

  let textColor = blueColor[9];
  if (props.require && !props.requireValid) {
    textColor = redColor[9];
  }

  let paddingRight = true;
  if (props.paddingRight === false) paddingRight = false;

  let paddingTop = true;
  if (props.paddingTop === false) paddingTop = false;

  const InputLabelProps = {};
  if (props.shrink) InputLabelProps.shrink = true;

  const filterOptions = createFilterOptions({
    limit: 100,
  });

  return (
    <GridItem xs={props.col} sm={props.col} md={props.col} lg={props.col} xl={props.col} paddingRight={paddingRight} paddingTop={paddingTop}>
      <AutocompleteSG
        autoSelect={false}
        autoComplete={false}
        disableClearable={props.disableClearable}
        disabled={props.disabled}
        noOptionsText={props.noOptions || translate('Sem_dados_disponiveis')}
        onChange={(e, v) => onChange(e, v)}
        id={props.id || 'select'}
        type={props.type}
        name={props.name}
        loading={props.loading}
        loadingText={translate('Carregando')}
        value={newValue}
        options={props.options}
        filterOptions={filterOptions}
        renderOption={option => (
          <BoxLabel>
            <Label textcolor={textColor}>
              {option.label}
            </Label>
          </BoxLabel>
        )}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <InputText
            {...params}
            label={props.label || ''}
            placeholder={props.placeholder || ''}
            fullWidth
            size="small"
            InputLabelProps={InputLabelProps}
            variant={props.variant || 'standard'}
            textcolor={textColor}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete=""
          />
        )}
      />
      <Message>
        {touched
          && ((error && <span>{error}</span>)
          || (warning && <span>{warning}</span>))}
      </Message>
    </GridItem>
  );
}

export default memo(SelectComponent);

// ==================================================
// Estilos
// ==================================================
const InputText = styled(TextField)`
  .MuiInputLabel-root {
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    color: ${props => (props.textcolor)} !important; // Texto input
    margin-right: 20px !important;
  }
  .MuiInputBase-input {
    color: ${props => (props.textcolor)} !important; // Texto input
    text-align: ${props => (props.textalign ? props.textalign : 'left')};  
    margin-right: 20px !important;
  }
  .MuiFormLabel-root {
    color: ${props => (props.textcolor)} !important; // Texto label
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${props => (props.textcolor)} !important; // Texto label clicado;
  }
  .MuiAutocomplete-inputRoot {
    padding: 4px 0px 0px 0px !important;
  }
  .MuiInput-underline:after {
    border-bottom: solid 2px ${props => (props.textcolor)}; // Linha clicada;
  }
  .MuiInput-underline:before {
    border-bottom: solid 1px ${props => (props.textcolor)}; // Linha;
  }
  .MuiAutocomplete-popupIndicator {
    color: ${props => (props.textcolor)}; // Icone
  }
  .MuiAutocomplete-clearIndicator {
    color: ${props => (props.textcolor)}; // Icone
    background-color: ${({ theme }) => theme.background[1]};
    &:hover {
      background-color: ${({ theme }) => theme.background[1]};
    }
  }
  .MuiIconButton-root {
    border-radius: 30px !important;
    max-width: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
  }
`;

const AutocompleteSG = styled(Autocomplete)`
  width: 100%;
`;

const Message = styled.div`
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
  color: tomato;
`;

const BoxLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content : center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: ${({ theme }) => theme.colorText[0]} !important;
`;
