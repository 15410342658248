import React from 'react';
import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';

// Rotas
import Paths from 'routes/Paths';

function PageRoot(props) {
  if (!Paths.exist(props.location.pathname)) {
    return <Redirect to={Paths.home} />;
  }

  return (renderRoutes(props.route.routes));
}

export default PageRoot;
