import * as React from 'react';

function IconFeatherFacebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10.35}
      height={18}
      viewBox="0 0 10.35 18"
      {...props}
    >
      <path
        d="M9.85.5H7.3a4.25 4.25 0 00-4.25 4.25V7.3H.5v3.4h2.55v6.8h3.4v-6.8H9l.85-3.4h-3.4V4.75a.85.85 0 01.85-.85h2.55z"
        fill="none"
        stroke="#9751eb"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconFeatherFacebook;
