import * as React from 'react';

function BoxCompensatoryTimeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={700}
      height={429}
      viewBox="0 0 700 429"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix_CTI_b"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#009c8f" />
          <stop offset={1} stopColor="#00ccc3" />
        </linearGradient>
        <linearGradient
          id="prefix_CTI_c"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#e23452" />
          <stop offset={1} stopColor="#f75f79" />
        </linearGradient>
        <filter
          id="prefix_CTI_a"
          x={0}
          y={0}
          width={700}
          height={429}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="blur" />
          <feFlood floodOpacity={0.063} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Grupo 20765">
        <circle
          data-name="Elipse 669"
          cx={92.5}
          cy={92.5}
          r={92.5}
          transform="translate(4 214)"
          fill="#f1e7fc"
          opacity={0.452}
        />
      </g>
      <g data-name="Grupo 21327">
        <g filter="url(#prefix_CTI_a)">
          <g
            data-name="Ret\xE2ngulo 2731"
            transform="translate(60 40)"
            fill="#fff"
            stroke="#dee6f2"
          >
            <rect width={580} height={309} rx={20} stroke="none" />
            <rect
              x={0.5}
              y={0.5}
              width={579}
              height={308}
              rx={19.5}
              fill="none"
            />
          </g>
        </g>
        <text
          data-name="Pedido de folga de banco"
          transform="translate(86 67)"
          fill="#9751eb"
          fontSize={20}
          fontFamily="Roboto-Regular, Roboto"
          letterSpacing=".04em"
          opacity={0.751}
        >
          <tspan x={0} y={19}>
            Pedido de folga de banco
          </tspan>
        </text>
        <g data-name="Grupo 20754">
          <g
            data-name="Ret\xE2ngulo 2416"
            transform="translate(82 116)"
            fill="none"
            stroke="#009b8d"
            opacity={0.303}
          >
            <rect width={525} height={118} rx={10} stroke="none" />
            <rect x={0.5} y={0.5} width={524} height={117} rx={9.5} />
          </g>
          <text
            data-name="Pediu folga para:"
            transform="translate(405 150)"
            fill="#243e64"
            fontSize={13}
            fontFamily="Roboto-Regular, Roboto"
            letterSpacing=".03em"
            opacity={0.66}
          >
            <tspan x={0} y={0}>
              Pediu folga para:
            </tspan>
          </text>
          <g data-name="Grupo 20753" transform="translate(399.405 166.419)">
            <rect
              data-name="Ret\xE2ngulo 2792"
              width={175}
              height={41}
              rx={20.5}
              transform="translate(-.405 -.419)"
              fill="#d3efec"
              opacity={0.997}
            />
            <text
              data-name="ter. 15/06 | 7:00 - 17:00"
              transform="translate(14.595 24.581)"
              fill="#414141"
              fontSize={12}
              fontFamily="Roboto-Regular, Roboto"
              letterSpacing=".06em"
              opacity={0.896}
            >
              <tspan x={0} y={0}>
                ter. 15/06 | 7:00 - 17:00
              </tspan>
            </text>
          </g>
          <g data-name="Grupo 20759" transform="translate(97.524 148.094)">
            <rect
              data-name="Ret\xE2ngulo 2685"
              width={93}
              height={11}
              rx={5}
              transform="translate(74.476 -.093)"
              fill="#bfbfbf"
              opacity={0.303}
            />
            <rect
              data-name="Ret\xE2ngulo 2686"
              width={58}
              height={13}
              rx={5}
              transform="translate(74.476 18.906)"
              fill="#bfbfbf"
              opacity={0.303}
            />
            <rect
              data-name="Ret\xE2ngulo 2794"
              width={132}
              height={11}
              rx={5}
              transform="translate(74.476 39.906)"
              fill="#bfbfbf"
              opacity={0.303}
            />
            <circle
              data-name="Elipse 635"
              cx={25.5}
              cy={25.5}
              r={25.5}
              transform="translate(.476 -.093)"
              fill="rgba(151,81,235,0.44)"
            />
            <path
              data-name="Icon awesome-user-alt"
              d="M25.171 26.638a5.239 5.239 0 10-5.238-5.238 5.24 5.24 0 005.238 5.238zm4.657 1.164h-2a6.333 6.333 0 01-5.3 0h-2a4.656 4.656 0 00-4.67 4.659v.582a1.747 1.747 0 001.746 1.746h15.134a1.747 1.747 0 001.746-1.746v-.582a4.656 4.656 0 00-4.656-4.658z"
              fill="#fff"
            />
            <g data-name="Grupo 20676" opacity={0.599}>
              <path
                d="M179.911-2.59h48.13l5.3 7.849-5.3 7.646h-48.13z"
                fill="#f4b300"
              />
              <g data-name="Grupo 20590" fill="#fff">
                <text
                  data-name="30 h"
                  transform="translate(208.941 8.41)"
                  fontSize={12}
                  fontFamily="Roboto-Bold, Roboto"
                  fontWeight={700}
                >
                  <tspan x={-11.736} y={0}>
                    30 h
                  </tspan>
                </text>
                <g
                  data-name="Icon ionic-md-time"
                  stroke="#fff"
                  strokeWidth={0.5}
                >
                  <path
                    data-name="Caminho 4047"
                    d="M188.444.396a4.457 4.457 0 104.461 4.457 4.455 4.455 0 00-4.461-4.457zm0 8.023a3.566 3.566 0 113.572-3.566 3.566 3.566 0 01-3.568 3.568z"
                  />
                  <path
                    data-name="Caminho 4048"
                    d="M188.671 2.624h-.669v2.674l2.34 1.4.334-.549-2.006-1.189z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g opacity={0.714}>
            <g data-name="Grupo 20664" transform="translate(461.132 260.938)">
              <rect
                data-name="Ret\xE2ngulo 2419"
                width={146}
                height={39}
                rx={19.5}
                transform="translate(-.132 .062)"
                fill="url(#prefix_CTI_b)"
              />
              <text
                transform="translate(72.298 22.006)"
                fill="#fff"
                fontSize={13}
                fontFamily="Roboto-Regular, Roboto"
              >
                <tspan x={-28.882} y={0}>
                  APROVAR
                </tspan>
              </text>
            </g>
            <g data-name="Grupo 20663" transform="translate(305.851 260.938)">
              <rect
                data-name="Ret\xE2ngulo 2420"
                width={136}
                height={39}
                rx={19.5}
                transform="translate(.149 .063)"
                fill="url(#prefix_CTI_c)"
              />
              <text
                transform="translate(64.915 22.129)"
                fill="#fff"
                fontSize={13}
                fontFamily="Roboto-Regular, Roboto"
              >
                <tspan x={-28.241} y={0}>
                  RECUSAR
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BoxCompensatoryTimeIcon;
