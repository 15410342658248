import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import ModalTemplate from 'components/modal/ModalTemplate';
import LogoStarGridWhite from 'components/layout/layoutComponents/LogoStarGridWhite';
import ButtonBig from 'components/button/ButtonBig';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import ScrollView100 from 'components/scroll/ScrollView100';
import GridContainer from 'components/grids/GridContainer';
import { ModalContent } from 'components/modal/ModalComponents';
import Captcha from 'components/captcha/Captcha';
import LogoStarGrid from 'components/layout/layoutComponents/LogoStarGrid';

// Estilos
import { purpleColor } from 'styles/Colors';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Actions
import { closeModalAccount, sendRequestAccount } from 'components/modal/modalAccount/ModalAccountAction';
import { toMessage } from 'components/message/MessageToasterAction';

const selector = formValueSelector('modalAccountForm');

function ModalAccountPage(props) {
  const dispatch = useDispatch();

  const statusModalAccount = useSelector(state => state.modalAccountReducer.statusModalAccount);
  const statusAccount = useSelector(state => state.modalAccountReducer.statusAccount);

  const [valid, setValid] = useState(true);

  const Emoji = `${process.env.PUBLIC_URL}/images/emoji.png`;

  useEffect(() => {
    if (statusModalAccount) Aos.init({ duration: 2000 });
  }, [statusModalAccount]);

  if (!statusModalAccount) {
    return (<Fragment />);
  }

  const onClose = () => {
    dispatch(closeModalAccount());
  };

  const onSubmit = (data) => {
    if (!valid) {
      return;
    }

    if (!props.email) {
      toMessage('INFO', translate('Preencha_o_email'));
    }

    if (props.email) {
      dispatch(sendRequestAccount(data));
    }
  };

  const onRedirect = () => {
    window.location.href = process.env.REACT_APP_API_URL_APP;
  };

  if (statusAccount) {
    return (
      <ModalTemplate
        width="600px"
        open={statusModalAccount}
        onClose={() => onClose()}
      >
        <ModalContent minheight="300px" maxheight="300px" flexdirection="column" justifycontent="center" align padding="0px">
          <TitleOk>{translate('Verifique_sua_caixa_de_emails')}</TitleOk>
          <SubtitleOk>{translate('Clique_no_link_enviado_para_prosseguir_com_o_cadastro')}</SubtitleOk>

          <TitleEmail>
            <b style={{ margin: '0px 10px 0px 0px' }}>Título do e-mail:</b>[StarGrid] Continue seu cadastro! 🎉
          </TitleEmail>

          <LogoStarGrid />
        </ModalContent>
      </ModalTemplate>
    );
  }

  let contactError = '';
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (props.contact && !regexEmail.test(props.contact.replace(' ', ''))) {
    contactError = translate('Email_com_formato_incorreto');
  } else {
    contactError = '';
  }

  return (
    <ModalTemplate
      width="1200px"
      open={statusModalAccount}
      onClose={() => onClose()}
    >
      <form onSubmit={props.handleSubmit(onSubmit)} autoComplete="off" style={{ width: '100%', marginBottom: 0 }} id="new_form">
        <ModalContent minheight="440px" maxheight="440px" flexdirection="row" justifycontent="space-between" padding="0px">
          <BoxA>
            <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
            <BoxATitle data-aos="fade-up" data-aos-duration="600">
              {translate('Bem_vindo_a')}
              <Emogi src={Emoji} alt={translate('Ola')} />
            </BoxATitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 50 }}>{translate('Entre_com_suas_credenciais_ou_faca_seu_cadastro_para_comecar_a_usar_a_StarGrid')}</BoxASubtitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 50 }}>{translate('Experimente_de_graca_por_15_dias')}</BoxASubtitle>
          </BoxA>
          <BoxB>
            <CircleClose onClick={() => onClose()}>X</CircleClose>

            <BoxBTitle>{translate('Cadastro')}</BoxBTitle>
            <GridContainer>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="email"
                  id="email"
                  col={12}
                  label={translate('Email')}
                  placeholder="seuemail@site.com.br"
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                  shrink
                  error={!!contactError}
                  helperText={contactError}
                />
              </BoxInput>

              <BoxCaptch>
                <Captcha onChange={e => setValid(e)} />
              </BoxCaptch>

            </GridContainer>
            <ButtonBig
              id="button_modal_account_send"
              alignItems="center"
              label={translate('Cadastrar')}
              type="submit"
              colors="purple"
              margins="20px 0px 0px 0px"
              padding="0px 150px"
              disabled={!valid}
            />
            <ButtonBig
              id="button_modal_account_login"
              alignItems="center"
              label={translate('Ja_tenho_conta__Login')}
              type="button"
              colors="purple"
              transparent
              padding="0px 107px"
              onClick={() => onRedirect()}
            />
          </BoxB>

          <App>
            <ScrollView100>
              <TitleApp>
                <CircleClose onClick={() => onClose()}>X</CircleClose>
                <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
                <BoxATitle data-aos="fade-up" data-aos-duration="600">
                  {translate('Bem_vindo_a')}
                  <Emogi src={Emoji} alt={translate('Ola')} />
                </BoxATitle>
                <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 20 }}>{translate('Entre_com_suas_credenciais_ou_faca_seu_cadastro_para_comecar_a_usar_a_StarGrid')}</BoxASubtitle>
              </TitleApp>
              <BoxApp>
                <GridContainer alignItems="center">
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      name="email"
                      id="email_m"
                      col={12}
                      label={translate('Email')}
                      type="text"
                      component={Input}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>

                  <BoxCaptch>
                    <Captcha onChange={e => setValid(e)} />
                  </BoxCaptch>

                </GridContainer>
                <Button
                  id="button_modal_account_send_m"
                  label={translate('Cadastrar')}
                  type="submit"
                  colors="purple"
                  margins="20px 0px 0px 0px"
                  padding="0px 150px"
                  disabled={!valid}
                />
                <Button
                  id="button_modal_account_login_m"
                  label={translate('Ja_tenho_conta__Login')}
                  type="button"
                  colors="purple"
                  transparent
                  margins="20px 0px 0px 0px"
                  padding="0px 115px"
                  onClick={() => onRedirect()}
                />
              </BoxApp>
            </ScrollView100>
          </App>
        </ModalContent>
      </form>
    </ModalTemplate>
  );
}

const mapStateToProps = state => ({
  email: selector(state, 'email'),
});

export default compose(
  reduxForm({ form: 'modalAccountForm' }),
  connect(mapStateToProps),
)(ModalAccountPage);

// ==================================================
// Estilos
// ==================================================
const CircleClose = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &:hover {
    box-shadow:
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }

  @media (max-width: 599px) {
    color: #FFF;
    background: transparent;
    border: solid 1px #FFF;
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;
    border-radius: 15px;
    top: 10px;
    right: 10px;
    font-size: 9px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    color: #FFF;
    background: transparent;
    border: solid 1px #FFF;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
`;

const BoxA = styled.div`
  background: #9751EB;
  min-width: 600px;
  max-width: 600px;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 0px 0px 50px;
  border-radius: 10px 0px 0px 10px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const BoxB = styled.div`
  position: relative;
  background: #FFFFFF;
  flex: 1;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px 10px 60px;
  border-radius: 0px 10px 10px 0px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const App = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  @media (max-width: 599px) {
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: flex;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const TitleApp = styled.div`
  position: relative;
  background: #9751EB;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  border-radius: 10px 10px 0px 0px;
`;

const BoxApp = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 15px 15px 15px;
`;

const BoxATitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    font: normal normal normal 20px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 15px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 60px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 60px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 60px 0px 0px 0px;
  }
`;

const BoxASubtitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    text-align: center;
    font: normal normal normal 12px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 30px 0px 20px 0px;
    max-width: 486px;
    text-align: center;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
`;

const Emogi = styled.img`
  @media (max-width: 599px) {
    width: 20px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 1920px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
`;

const BoxInput = styled.div`
  width: 100%;

  @media (max-width: 599px) {
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin: 10px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin: 10px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 10px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    margin: 10px 0px 0px 0px;
  }
`;

const BoxBTitle = styled.div`
  width: 100%;
  letter-spacing: 0px;
  color: #000;

  @media (max-width: 599px) {
    font: normal normal normal 20px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
`;

const BoxCaptch = styled.div`
  width: 100%;
  min-height: 85px;
  margin: 40px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TitleOk = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: ${purpleColor[9]};
`;

const SubtitleOk = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 20px 0px 0px 0px;
`;

const TitleEmail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin: 20px 0px 40px 0px;
`;
