import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

// Componentes
import ButtonCircle from 'components/button/ButtonCircle';

function BoxHowItWorks(props) {
  return (
    <BoxStep active={props.active}>
      <BoxStepTitle>
        <Number>{props.value}</Number>
        <TitleStep active={props.active}>{props.title}</TitleStep>
        <ButtonCircle
          id={`button_step_${props.value}`}
          type="button"
          colors="blue"
          transparent
          icon={props.active ? faChevronUp : faChevronDown}
          onClick={() => props.setPage()}
        />
      </BoxStepTitle>
      <BoxStepContent active={props.active}>
        {props.children}
      </BoxStepContent>
    </BoxStep>
  );
}

export default memo(BoxHowItWorks);

// ==================================================
// Estilos
// ==================================================
const BoxStep = styled.div`
  border: 1px solid #DEE6F2;
  min-height: 62px;
  width: 100%;
  margin: 0px 0px 15px 0px;
  background: ${props => (props.active ? '#F5F7FAA0' : 'transparent')};
`;

const BoxStepTitle = styled.div`
  padding: 0px 15px;
  display: flex;
  justify-content: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 62px;
`;

const BoxStepContent = styled.div`
  padding: 0px 15px 15px 15px;
  display: ${props => (props.active ? 'flex' : 'none')};
  justify-content: row;
  align-items: center;
  width: 100%;
  min-height: 62px;
  font: normal normal normal 17px/28px Noto Sans;
  letter-spacing: 0px;
  color: #677EA0;
`;

const Number = styled.div`
  min-width: 33px;
  max-width: 33px;
  min-height: 33px;
  max-height: 33px;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 18px/28px Noto Sans;
  color: #FFF;
  background: #009B8D80;
`;

const TitleStep = styled.div`
  font: ${props => (props.active ? 'bold' : 'normal')} 18px/28px Noto Sans;
  letter-spacing: 0px;
  color: #172840;
  width: 100%;
  padding: 0px 15px;
`;
