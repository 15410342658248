import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={720}
      height={445}
      viewBox="0 0 720 484"
      {...props}
    >
      <defs>
        <filter
          id="prefix_WS_b"
          x={234}
          y={0}
          width={486}
          height={445}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="c" />
          <feFlood floodOpacity={0.082} />
          <feComposite operator="in" in2="c" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_WS_d"
          x={157}
          y={108.246}
          width={50}
          height={239}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={5} result="e" />
          <feFlood floodOpacity={0.043} />
          <feComposite operator="in" in2="e" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_WS_f"
          x={0}
          y={114.117}
          width={188.929}
          height={197.629}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="g" />
          <feFlood floodOpacity={0.224} />
          <feComposite operator="in" in2="g" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_WS_a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#9f64ea" />
          <stop offset={0.394} stopColor="#9f63ea" stopOpacity={0.969} />
          <stop offset={1} stopColor="#9752eb" stopOpacity={0} />
          <stop offset={1} stopColor="#9751eb" stopOpacity={0} />
        </linearGradient>
        <style>
          {
            '.prefix_WS_c{fill:#dee6f2}.prefix_WS_j{fill:#f0f0f0}.prefix_WS_k,.prefix_WS_t{fill:#fff}.prefix_WS_z{fill:#9751eb}.prefix_WS_o{fill:#bfbfbf;opacity:.527}.prefix_WS_p{fill:rgba(151,81,235,.44)}.prefix_WS_q{fill:#dbdbdb;opacity:.608}.prefix_WS_ai{fill:#6719d7}.prefix_WS_t{font-size:6px;font-family:NotoSans,Noto Sans}.prefix_WS_u{fill:#a273e7}.prefix_WS_w{fill:#e9e9e9}.prefix_WS_x{fill:#e7ccff}.prefix_WS_ak,.prefix_WS_y{fill:none}.prefix_WS_y{stroke:#e7ccff;stroke-miterlimit:10;stroke-width:4px}.prefix_WS_ai{stroke:#6719d7;stroke-width:.4px}.prefix_WS_aj{stroke:none}'
          }
        </style>
      </defs>
      <g transform="translate(-.003 .005)" filter="url(#prefix_WS_b)">
        <rect
          width={366}
          height={364}
          rx={180}
          transform="translate(294 50)"
          fill="url(#prefix_WS_a)"
        />
      </g>
      <g opacity={0.522}>
        <g transform="rotate(90 241.67 377.028)">
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 .198)"
          />
          <g transform="translate(87.983)">
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 28.198)"
            />
          </g>
        </g>
        <g transform="rotate(90 223.67 359.028)">
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 .198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 28.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 19.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 10.198)"
          />
          <circle
            className="prefix_WS_c"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 .198)"
          />
          <g transform="translate(87.983)">
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 28.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 .198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 9.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 18.198)"
            />
            <circle
              className="prefix_WS_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 28.198)"
            />
          </g>
        </g>
      </g>
      <g transform="translate(154.752 98.377)">
        <rect
          width={366.596}
          height={255.765}
          rx={5}
          transform="translate(.016 -.474)"
          fill="#c39ef3"
        />
        <path fill="#d2b6f7" d="M143.245 255.291H228.5v56.268h-85.255z" />
        <path
          opacity={0.906}
          fill="#c39ef3"
          d="M122.783 299.623h127.882v11.936H122.783z"
        />
        <circle
          cx={5.115}
          cy={5.115}
          r={5.115}
          transform="translate(34.118 33.628)"
          fill="#ff5548"
        />
        <circle
          cx={5.115}
          cy={5.115}
          r={5.115}
          transform="translate(47.759 33.628)"
          fill="#ffc056"
        />
        <circle
          cx={5.115}
          cy={5.115}
          r={5.115}
          transform="translate(61.4 33.628)"
          fill="#08af5d"
        />
        <g transform="translate(28.538 30.171)">
          <rect
            className="prefix_WS_j"
            width={328}
            height={209}
            rx={5}
            transform="translate(-11.29 -8.301)"
          />
          <rect
            className="prefix_WS_k"
            width={292}
            height={174}
            rx={5}
            transform="translate(16.71 18.699)"
          />
          <path
            className="prefix_WS_k"
            d="M8.71-7.301h303a5 5 0 015 5v12h-308v-17z"
          />
          <g transform="translate(-183.29 -128.55)" filter="url(#prefix_WS_d)">
            <path
              className="prefix_WS_k"
              d="M177 120.25h15v209h-10a10 10 0 01-10-10v-194a5 5 0 015-5z"
            />
          </g>
          <text
            transform="translate(77.71 46.698)"
            fill="#2a2e43"
            fontSize={12}
            fontFamily="Roboto-Light,Roboto"
            fontWeight={300}
          >
            <tspan x={0} y={0}>
              Seu Setor
            </tspan>
          </text>
          <text
            transform="translate(77.71 57.698)"
            fontFamily="Roboto-Regular,Roboto"
            stroke="transparent"
            fontSize={7}
            fill="#9751eb"
          >
            <tspan x={0} y={0}>
              Total de 1 turno gerado.
            </tspan>
          </text>
          <rect
            width={255}
            height={11}
            rx={5.5}
            transform="translate(28.71 83.699)"
            fill="#b178f4"
          />
          <g transform="translate(28.874 105.335)">
            <rect
              className="prefix_WS_o"
              width={27}
              height={3}
              rx={1.5}
              transform="translate(17.837 .363)"
            />
            <rect
              className="prefix_WS_o"
              width={14}
              height={4}
              rx={2}
              transform="translate(17.837 7.363)"
            />
            <circle
              className="prefix_WS_p"
              cx={5.5}
              cy={5.5}
              r={5.5}
              transform="translate(-.163 .363)"
            />
            <path
              className="prefix_WS_k"
              d="M5.347 5.595a1.113 1.113 0 10-1.112-1.113 1.113 1.113 0 001.112 1.113zm.989.247h-.425a1.345 1.345 0 01-1.127 0h-.426a.989.989 0 00-.989.989v.124a.371.371 0 00.371.371h3.215a.371.371 0 00.371-.371v-.124a.989.989 0 00-.989-.989z"
            />
          </g>
          <g transform="translate(88.225 104.8)">
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(53.485 -.102)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(125.485 -.102)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(18.485 -.102)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(89.485 -.102)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(161.485 -.102)"
            />
            <rect
              className="prefix_WS_q"
              width={13}
              height={14}
              rx={5}
              transform="translate(.485 -.102)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(71.485 -.102)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(143.485 -.102)"
            />
            <rect
              width={14}
              height={14}
              rx={5}
              transform="translate(35.485 -.102)"
              fill="#6719d7"
              opacity={0.608}
            />
            <rect
              width={14}
              height={14}
              rx={5}
              transform="translate(107.485 -.102)"
              fill="#f9931d"
              opacity={0.608}
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(179.485 -.102)"
            />
            <text className="prefix_WS_t" transform="translate(42.485 8.898)">
              <tspan x={-3.129} y={0}>
                FL
              </tspan>
            </text>
            <text className="prefix_WS_t" transform="translate(114.485 8.898)">
              <tspan x={-3.507} y={0}>
                FB
              </tspan>
            </text>
          </g>
          <g transform="translate(28.874 130.466)">
            <rect
              className="prefix_WS_o"
              width={27}
              height={4}
              rx={2}
              transform="translate(17.837 .233)"
            />
            <rect
              className="prefix_WS_o"
              width={14}
              height={4}
              rx={2}
              transform="translate(17.837 7.233)"
            />
            <circle
              className="prefix_WS_p"
              cx={5.5}
              cy={5.5}
              r={5.5}
              transform="translate(-.163 .233)"
            />
            <path
              className="prefix_WS_k"
              d="M5.347 5.595a1.113 1.113 0 10-1.112-1.113 1.113 1.113 0 001.112 1.113zm.989.247h-.425a1.345 1.345 0 01-1.127 0h-.426a.989.989 0 00-.989.989v.124a.371.371 0 00.371.371h3.215a.371.371 0 00.371-.371v-.124a.989.989 0 00-.989-.989z"
            />
          </g>
          <g transform="translate(88.225 129.931)">
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(53.485 -.233)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(125.485 -.233)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(18.485 -.233)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(89.485 -.233)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(161.485 -.233)"
            />
            <rect
              className="prefix_WS_q"
              width={13}
              height={14}
              rx={5}
              transform="translate(.485 -.233)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(71.485 -.233)"
            />
            <rect
              className="prefix_WS_u"
              width={14}
              height={14}
              rx={5}
              transform="translate(143.485 -.233)"
            />
            <g
              transform="translate(35.485 -.233)"
              stroke="#9751eb"
              fill="#e9e9e9"
            >
              <rect className="prefix_WS_aj" width={14} height={14} rx={5} />
              <rect
                className="prefix_WS_ak"
                x={0.5}
                y={0.5}
                width={13}
                height={13}
                rx={4.5}
              />
            </g>
            <rect
              className="prefix_WS_w"
              width={14}
              height={14}
              rx={5}
              transform="translate(107.485 -.233)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(179.485 -.233)"
            />
            <text className="prefix_WS_t" transform="translate(150.485 8.767)">
              <tspan x={-3.129} y={0}>
                FL
              </tspan>
            </text>
          </g>
          <g transform="translate(28.874 155.597)">
            <rect
              className="prefix_WS_o"
              width={27}
              height={4}
              rx={2}
              transform="translate(17.837 .102)"
            />
            <rect
              className="prefix_WS_o"
              width={14}
              height={4}
              rx={2}
              transform="translate(17.837 7.102)"
            />
            <circle
              className="prefix_WS_p"
              cx={5.5}
              cy={5.5}
              r={5.5}
              transform="translate(-.163 .102)"
            />
            <path
              className="prefix_WS_k"
              d="M5.347 5.595a1.113 1.113 0 10-1.112-1.113 1.113 1.113 0 001.112 1.113zm.989.247h-.425a1.345 1.345 0 01-1.127 0h-.426a.989.989 0 00-.989.989v.124a.371.371 0 00.371.371h3.215a.371.371 0 00.371-.371v-.124a.989.989 0 00-.989-.989z"
            />
          </g>
          <g transform="translate(88.225 155.062)">
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(53.485 -.363)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(125.485 -.363)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(18.485 -.363)"
            />
            <rect
              className="prefix_WS_u"
              width={14}
              height={14}
              rx={5}
              transform="translate(89.485 -.363)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(161.485 -.363)"
            />
            <rect
              className="prefix_WS_q"
              width={13}
              height={14}
              rx={5}
              transform="translate(.485 -.363)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(71.485 -.363)"
            />
            <rect
              className="prefix_WS_w"
              width={14}
              height={14}
              rx={5}
              transform="translate(143.485 -.363)"
            />
            <rect
              className="prefix_WS_w"
              width={14}
              height={14}
              rx={5}
              transform="translate(35.485 -.363)"
            />
            <rect
              className="prefix_WS_w"
              width={14}
              height={14}
              rx={5}
              transform="translate(107.485 -.363)"
            />
            <rect
              className="prefix_WS_q"
              width={14}
              height={14}
              rx={5}
              transform="translate(179.485 -.363)"
            />
            <text className="prefix_WS_t" transform="translate(96.485 8.637)">
              <tspan x={-3.129} y={0}>
                FL
              </tspan>
            </text>
          </g>
          <g>
            <path
              className="prefix_WS_x"
              d="M53.512 57.55v-1.805a1.054 1.054 0 011.053-1.053h1.8zM65.174 51.967l-.513.513-2.279-2.279.513-.513a1.209 1.209 0 011.71 0l.568.568a1.211 1.211 0 01.001 1.711zM46.713 38.208a.353.353 0 00.353-.353v-1.038a1.723 1.723 0 113.445 0v1.037a.353.353 0 00.353.353h3.452v2.061H43.26v-2.061z"
            />
            <g transform="translate(28.71 34.711)">
              <path
                className="prefix_WS_j"
                d="M4.656 16.036a.134.134 0 01-.162-.117 2.012 2.012 0 011.2-2.062 1.461 1.461 0 111.37-.3 2.013 2.013 0 011.951 1.375.132.132 0 01-.1.174l-4.264.928zM5.574 20.243a.326.326 0 00.388.249l3.622-.789a.326.326 0 00-.139-.638l-3.622.789a.326.326 0 00-.249.389zM10.084 17.446a.321.321 0 00-.133 0L4.7 18.589a.326.326 0 00.139.638l5.242-1.141q-.003-.32.003-.64zM4.132 17.539a.326.326 0 00.388.25l5.252-1.143a.326.326 0 00-.139-.637l-5.251 1.143a.326.326 0 00-.25.387z"
              />
              <path
                className="prefix_WS_j"
                d="M10.104 20.579l-6.735 1.466L.699 9.784l3.008-.655.2.9a.152.152 0 00.181.117l2.909-.633a.152.152 0 00.117-.181l-.2-.9 2.7-.587a25.464 25.464 0 01-.03-.676l-2.809.612-.5-2.285a.152.152 0 00-.181-.117l-2.909.633a.153.153 0 00-.116.181l.5 2.285-3.171.69a.5.5 0 00-.382.6l2.741 12.587a.5.5 0 00.6.382l6.781-1.476q-.024-.339-.034-.682zM5.732 6.139l.388 1.782.142.652.086.4-1.9.415-.086-.4-.142-.652-.388-1.782z"
              />
              <path
                className="prefix_WS_j"
                d="M5.677 7.462a.639.639 0 11-.761-.489.639.639 0 01.761.489z"
              />
              <path
                className="prefix_WS_x"
                d="M17.811 8.461h4.707a.326.326 0 000-.652h-4.707a.326.326 0 100 .652zM17.811 11.629h7.209a.326.326 0 000-.652h-7.209a.326.326 0 100 .652zM17.811 14.797h4.707a.326.326 0 000-.652h-4.707a.326.326 0 100 .652zM17.811 17.966h7.209a.326.326 0 000-.652h-7.209a.326.326 0 100 .652zM17.811 21.134h4.707a.326.326 0 000-.652h-4.707a.326.326 0 100 .652z"
              />
              <rect
                className="prefix_WS_y"
                width={1.668}
                height={1.668}
                rx={0.834}
                transform="translate(14.279 7.301)"
              />
              <rect
                className="prefix_WS_y"
                width={1.668}
                height={1.668}
                rx={0.834}
                transform="translate(14.279 10.469)"
              />
              <rect
                className="prefix_WS_y"
                width={1.668}
                height={1.668}
                rx={0.834}
                transform="translate(14.279 13.638)"
              />
              <rect
                className="prefix_WS_y"
                width={1.668}
                height={1.668}
                rx={0.834}
                transform="translate(14.279 16.806)"
              />
              <rect
                className="prefix_WS_y"
                width={1.668}
                height={1.668}
                rx={0.834}
                transform="translate(14.279 19.975)"
              />
              <g>
                <path
                  className="prefix_WS_j"
                  d="M35.647 17.92l-1.371 5.254a.164.164 0 01-.2.119l-3.23-.843a1.3 1.3 0 01-.258.622l3.319.866a.832.832 0 001.017-.6l1.491-5.711a1.174 1.174 0 01-.768.293zM39.32 5.516a.83.83 0 00-.509-.385l-8.272-2.159a1.293 1.293 0 01.024.7l8.079 2.108a.167.167 0 01.119.2l-2.5 9.6a1.183 1.183 0 01.507.7l2.643-10.127a.829.829 0 00-.091-.637z"
                />
                <path
                  className="prefix_WS_j"
                  d="M35.89 7.339l-5.357-1.4c0 .225.007.451.009.676l5.185 1.357a.326.326 0 00.165-.631zM35.297 9.618l-4.768-1.244q-.011.334-.03.666l4.633 1.209a.326.326 0 00.165-.631zM34.159 13.982l-3.924-1.024a1.456 1.456 0 010 .673l3.764.983a.326.326 0 00.165-.631zM34.74 11.749l-4.346-1.133a1.467 1.467 0 010 .158 1.441 1.441 0 01-.056.5l4.239 1.106a.326.326 0 00.165-.631zM32.926 16.661a1.332 1.332 0 01.227-.615l-2.762-.721a1.43 1.43 0 01-.094.65z"
                />
                <g>
                  <path d="M30.924 18.673a1.334 1.334 0 01.222-.616l-.972-.254a1.449 1.449 0 01.029.682z" />
                </g>
              </g>
              <path
                className="prefix_WS_z"
                d="M29.985 23.672v1.171a.741.741 0 01-.74.739H10.911a.741.741 0 01-.74-.739V2.671a.741.741 0 01.74-.74h6.944a2.269 2.269 0 00-.022.314v.633h-3.191a.326.326 0 00-.326.326v1.01H12.85a.86.86 0 00-.859.859v17.366a.86.86 0 00.859.859h11.674a1.038 1.038 0 00.129-.01.344.344 0 00.077.01.325.325 0 00.245-.112.967.967 0 00.231-.171l2.675-2.674a.96.96 0 00.283-.683V5.074a.86.86 0 00-.859-.859H25.84v-1.01a.326.326 0 00-.326-.326h-3.191v-.633a2.264 2.264 0 00-.022-.314h6.945a.741.741 0 01.74.74v16.287a1.166 1.166 0 01.273-.1 1.136 1.136 0 01.379-.164V2.671a1.394 1.394 0 00-1.392-1.392h-7.141a2.244 2.244 0 00-4.052 0h-7.141A1.394 1.394 0 009.52 2.671v22.171a1.394 1.394 0 001.392 1.392h18.334a1.394 1.394 0 001.392-1.392v-1.771a1.1 1.1 0 01-.308.159 1.161 1.161 0 01-.345.442zm-4.928-1.428v-1.268a.741.741 0 01.74-.74h1.268zm.783-16.482v-.9h1.465a.207.207 0 01.207.207v14.51h-1.719a1.393 1.393 0 00-1.392 1.392v1.671H12.85a.207.207 0 01-.206-.207V5.074a.207.207 0 01.206-.207h1.466v.9a.326.326 0 00.326.326h10.872a.326.326 0 00.327-.334zM18.159 3.53a.326.326 0 00.326-.326v-.959a1.593 1.593 0 113.185 0v.959a.326.326 0 00.326.326h3.197v1.906h-10.22V3.53z"
              />
              <path
                className="prefix_WS_z"
                d="M36.261 14.748a1.657 1.657 0 00-2.343 0l-6.567 6.567a.371.371 0 00-.095.162l-.863 3.118a.368.368 0 00.453.453l3.118-.864a.369.369 0 00.162-.094l6.567-6.567a1.658 1.658 0 000-2.343zm-8.108 6.807l5.375-5.375 1.734 1.734-5.375 5.375zm-.346.695l1.385 1.385-1.916.531zm8.365-5.247l-.39.39-1.734-1.734.39-.39a.92.92 0 011.3 0l.432.432a.922.922 0 01.003 1.301z"
              />
            </g>
          </g>
          <g opacity={0.667}>
            <path
              d="M298.028 4.027a.8.8 0 11-1.6 0h-2.4a.334.334 0 01-.255-.543l.8-.926a1.04 1.04 0 00.255-.607V.2a2.4 2.4 0 114.79 0v1.752a1.531 1.531 0 00.224.639l.8.926a.329.329 0 01-.255.543h-2.359zm1.34-1.022a1.772 1.772 0 01-.383-1.054V.2a1.756 1.756 0 00-3.513 0v1.752a1.772 1.772 0 01-.383 1.054l-.351.383h4.982l-.352-.384z"
              fill="#677ea0"
            />
            <circle
              cx={1.306}
              cy={1.306}
              fill="#be2a2a"
              r={1.306}
              transform="translate(300.256 -2.429)"
            />
          </g>
          <g transform="translate(-5.777 39.645)">
            <circle
              className="prefix_WS_j"
              cx={3.5}
              cy={3.5}
              r={3.5}
              transform="translate(.487 20.053)"
            />
            <circle
              className="prefix_WS_j"
              cx={3.5}
              cy={3.5}
              r={3.5}
              transform="translate(.487 40.053)"
            />
            <circle
              className="prefix_WS_j"
              cx={3.5}
              cy={3.5}
              r={3.5}
              transform="translate(.487 57.053)"
            />
            <ellipse
              className="prefix_WS_j"
              cx={3.5}
              cy={3}
              rx={3.5}
              ry={3}
              transform="translate(.487 77.053)"
            />
            <circle
              cx={3.5}
              cy={3.5}
              r={3.5}
              transform="translate(.487 .053)"
              opacity={0.302}
              fill="#9751eb"
            />
          </g>
          <g
            transform="translate(23.71 123.699)"
            strokeDasharray={5}
            opacity={0.829}
            fill="none"
            stroke="#9751eb"
          >
            <rect className="prefix_WS_aj" width={263} height={24} rx={5} />
            <rect
              className="prefix_WS_ak"
              x={0.5}
              y={0.5}
              width={262}
              height={23}
              rx={4.5}
            />
          </g>
        </g>
      </g>
      <g transform="translate(0 -.002)" filter="url(#prefix_WS_f)">
        <path
          className="prefix_WS_k"
          d="M66.692 164.12h55.545a6.692 6.692 0 016.692 6.692v64.245a6.692 6.692 0 01-6.692 6.692H66.692A6.692 6.692 0 0160 235.057v-64.245a6.692 6.692 0 016.692-6.692z"
        />
      </g>
      <text
        transform="translate(72.29 211.433)"
        fontSize={10}
        fill="#677ea0"
        fontFamily="Roboto-Regular,Roboto"
      >
        <tspan x={0.564} y={9}>
          Adicionar
        </tspan>
        <tspan x={8.921} y={21}>
          dobra
        </tspan>
      </text>
      <g transform="translate(77.206 171.287)">
        <circle
          cx={16.796}
          cy={16.796}
          r={16.796}
          fill="#7b62d4"
          opacity={0.099}
        />
        <g opacity={0.595}>
          <path
            className="prefix_WS_ai"
            d="M23.671 13.227L17.098 9.48a.506.506 0 00-.5 0l-6.573 3.747a.5.5 0 00-.255.439v9.782a.506.506 0 00.755.439l6.323-3.6 6.323 3.6a.506.506 0 00.25.066.5.5 0 00.254-.069.5.5 0 00.252-.437v-9.781a.5.5 0 00-.256-.439zm-.756 9.351l-5.561-3.17v-.502a.506.506 0 00-1.011 0v.506l-5.561 3.17v-8.618l5.566-3.173c0 .009-.005.016-.005.024v.506a.506.506 0 001.011 0v-.506c0-.009-.005-.016-.006-.025l5.567 3.173z"
          />
          <path
            className="prefix_WS_ai"
            d="M16.85 14.563a.319.319 0 00-.319.318v.318a.319.319 0 00.637 0v-.318a.319.319 0 00-.318-.318zM16.85 16.673a.319.319 0 00-.319.318v.319a.319.319 0 10.637 0v-.319a.319.319 0 00-.318-.318zM16.85 12.454a.318.318 0 00-.319.318v.318a.319.319 0 00.637 0v-.318a.318.318 0 00-.318-.318z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
