import React, { useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import BoxIntroductionIcon from 'modules/integration/integrationComponents/BoxIntroductionIcon';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxIntroductionComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxIntroductionContent>
      <BoxIntroduction>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={6} md={6} lg={6} xl={6} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-right" data-aos-duration="600">{translate('Sabemos_que_voce_ja_utiliza_muitos_sistemas')}</Title>
              <Title data-aos="fade-up-right" data-aos-duration="600">{translate('A_StarGrid_pode_conversar_com_todos_eles')}</Title>
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} xl={6} alignItems="flex-end">
            <BoxOverflow>
              <BoxIntroductionIconSG data-aos="fade-left" data-aos-duration="600" />
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxIntroduction>
    </BoxIntroductionContent>
  );
}

export default memo(BoxIntroductionComponent);

// ==================================================
// Estilos
// ==================================================
const BoxIntroductionContent = styled.div`
  width: 100%;
  background: #9751EB 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 0px;
`;

const BoxIntroduction = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    padding: 20px 20px 0px 20px;
    margin: 0px 0px 15px 0px;
    font: normal normal bold 25px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 20px 0px 20px;
    margin: 0px 0px 10px 0px;
    font: normal normal bold 25px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 25px 0px 25px;
    margin: 0px 0px 20px 0px;
    font: normal normal bold 40px/52px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 0px 0px 20px 0px;
    font: normal normal bold 40px/52px Noto Sans;
  }
  @media (min-width: 1920px) {
    margin: 0px 0px 20px 0px;
    font: normal normal bold 40px/52px Noto Sans;
  }
`;

const BoxIntroductionIconSG = styled(BoxIntroductionIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxOverflow = styled.div`
  overflow: none;
`;
