import React, { useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import BoxAboutUsItem from 'modules/home/homeComponents/BoxAboutUsItem';

// Tradução
import { translate } from 'translates/TranslatesAction';

const CarlosKlein = `${process.env.PUBLIC_URL}/images/CarlosKlein.png`;
const GuilhermePereda = `${process.env.PUBLIC_URL}/images/GuilhermePereda.png`;
const LenadroBrondaniStersi = `${process.env.PUBLIC_URL}/images/LenadroBrondaniStersi.jpg`;
const AnselmoAlves = `${process.env.PUBLIC_URL}/images/AnselmoAlves.jpeg`;

function BoxAboutUsComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxAboutUsContent>
      <BoxOverflow>
        <Title data-aos="fade-down-right" data-aos-duration="600">{translate('O_que_dizem_sobre_nos')}</Title>
        {/* <Subtitle data-aos="fade-down-left" data-aos-duration="600">{translate('Mais_de_20_mil_pessoas_confiam_na_StarGrid')}</Subtitle> */}
        <BoxAboutUs>
          <GridContainer minheight="300px" justifyContent="center">
            <BoxAboutUsItem
              col={6}
              text="Com um grupo de Founders bastante diverso e dinâmico, esta Startup está impactando positivamente o ecossistema de saúde, facilitando muito a vida dos profissionais de saúde e principalmente seus gestores."
              src={CarlosKlein}
              alt="Carlos Klein - Diretor de investimentos Ventuir"
              name="Carlos Klein"
              job="Diretor de investimentos Ventuir"
              index={2}
            />
            <BoxAboutUsItem
              col={6}
              text="Além de ganhos com a redução do absenteísmo e tempo de mão de obra em gestão de escala, podemos afirmar, hoje, que estamos em outro patamar e conseguimos adequar, com agilidade, segurança e facilidade, todo nosso fluxo funcional."
              src={AnselmoAlves}
              alt="Anselmo Alves - Gerente da Sodexo"
              name="Anselmo Alves"
              job="Gerente da Sodexo"
              index={3}
            />
            <BoxAboutUsItem
              col={6}
              text="A Stargrid é a melhor solução do mercado para montagem de escalas de trabalho (ou Grid como costumam chamar). Possibilita ao gestor cadastro da equipe e comunicação com a mesma, atendendo parâmetros complexos como contrato de trabalho, disponibilidade do colaborador e qualificação necessária para desempenho de trabalho por setor."
              src={GuilhermePereda}
              alt="Guilherme Pereda - Gerente Médico da Healthcare Alliance"
              name="Guilherme Pereda"
              job="Gerente Médico da Healthcare Alliance"
              index={1}
            />
            <BoxAboutUsItem
              col={6}
              text="A parceria com a Stargrid, trouxe ao nosso Hospital uma plataforma digital, dotada de inteligência artificial, que possibilitou enorme facilidade, flexibilidade e agilidade aos nossos processos internos de estruturação de escalas de trabalho. Diminuindo as falhas que só eram percebidas durante o mês, após a liberação das mesmas aos funcionários, o que acabavam comumente gerando inúmeras horas extras ou remanejamentos de última hora, de forma que reduzimos significativamente estes custos. Notamos a melhora no controle de faltas, férias, facilitando também o processo de solicitação de folgas onde a instituição pode concedê-las dentro das preferências de cada colaborador, melhorando assim também o relacionamento do funcionário e o seu Gestor. Só temos a agradecer a equipe da Stargrid, pelo apoio e suporte no nosso dia a dia!"
              src={LenadroBrondaniStersi}
              alt="Lenadro Brondani Stersi - Administrador"
              name="Lenadro Brondani Stersi"
              job="Administrador Hospital São Patrício"
              index={3}
            />
          </GridContainer>
        </BoxAboutUs>
      </BoxOverflow>
    </BoxAboutUsContent>
  );
}

export default memo(BoxAboutUsComponent);

// ==================================================
// Estilos
// ==================================================
const BoxAboutUsContent = styled.div`
  width: 100%;
  min-height: 630px;
  background-color: #F7F9FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 12px 0px;
`;

const BoxAboutUs = styled.div`
  padding: 0px 0px 0px 10px;

  @media (max-width: 599px) {
    width: 100%;
    min-height: 300px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
    min-height: 300px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
    min-height: 300px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
    min-height: 300px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
    min-height: 300px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 60px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 25px 25px 0px 25px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 25px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 0px 0px 25px;
    font: bold 40px Noto Sans;
    margin: 0px 0px 30px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 40px Noto Sans;
    margin: 0px 0px 30px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 40px Noto Sans;
    margin: 0px 0px 30px 0px;
  }
`;

// const Subtitle = styled.div`
//   letter-spacing: 0px;
//   color: #677EA0;
//   max-width: 410px;

//   @media (max-width: 599px) {
//     font: normal 14px Noto Sans;
//     padding: 0px 45px 0px 25px;
//   }
//   @media (min-width: 600px) and (max-width: 959px) {
//     font: normal 14px Noto Sans;
//     padding: 0px 45px 0px 25px;
//   }
//   @media (min-width: 960px) and (max-width: 1279px) {
//     font: normal 18px Noto Sans;
//     padding: 0px 0px 0px 25px;
//   }
//   @media (min-width: 1280px) and (max-width: 1919px) {
//     font: normal 18px Noto Sans;
//   }
//   @media (min-width: 1920px) {
//     font: normal 18px Noto Sans;
//   }
// `;

const BoxOverflow = styled.div`
  overflow: none;
`;
