import React, { memo } from 'react';
import styled from 'styled-components/macro';

// Componentes
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';

function Layout(props) {
  // const warning = useSelector(state => state.warningReducer.warning);
  // const darkMode = useSelector(state => state.authenticationReducer.darkMode);

  return (
    <ContainerJumbo>
      <Header />
      <Content>
        {props.children}
      </Content>
      <Footer />
    </ContainerJumbo>
  );
}

export default memo(Layout);

// ==================================================
// Estilos
// ==================================================
const ContainerJumbo = styled.div`
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  width: 100%;
  @media (max-width: 599px) {
    padding: 70px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 70px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 90px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    padding: 90px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    padding: 90px 0px 0px 0px;
  }
`;

// const BuildDate = styled.div`
//   width: 100%;
//   font-size: 8px;
//   text-align: end;
//   margin: -8px 0px 0px 0px;
//   padding: 0px 10px 0px 0px;
//   color: transparent;
// `;
