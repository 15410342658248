import React, { useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import BoxSaveTimeIcon from 'modules/home/homeComponents/BoxSaveTimeIcon';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxSaveTimeComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxSaveTimeContent>
      <BoxSaveTime>
        <GridContainer alignItems="center" minheight="655px">
          <GridItem xs={12} sm={7} md={7} lg={7} xl={7} alignItems="center" justify="flex-end">
            <BoxOverflow>
              <BoxIcon data-aos="fade-down" data-aos-easing="linear" data-aos-duration="600">
                <BoxSaveTimeIconSG />
              </BoxIcon>
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} lg={5} xl={5} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-left" data-aos-duration="600">{translate('Economize_tempo')}</Title>
              <Subtitle data-aos="fade-left" data-aos-duration="600">{translate('Monte_escalas_de_trabalho_em_poucos_minutos_com_ajuda_da_inteligencia_artificial')}</Subtitle>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxSaveTime>
    </BoxSaveTimeContent>
  );
}

export default memo(BoxSaveTimeComponent);

// ==================================================
// Estilos
// ==================================================
const BoxSaveTimeContent = styled.div`
  width: 100%;
  padding: 90px 0px 20px 0px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoxSaveTime = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 0px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 0px 0px 25px;
    font: bold 42px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 42px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 42px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;
  max-width: 380px;

  @media (max-width: 599px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal 18px Noto Sans;
    padding: 0px 0px 0px 25px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxSaveTimeIconSG = styled(BoxSaveTimeIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxIcon = styled.div``;

const BoxOverflow = styled.div`
  overflow: none;
`;
