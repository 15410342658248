import React, { memo, Fragment } from 'react';
import { Modal, Backdrop } from '@material-ui/core';
import styled from 'styled-components/macro';

// Componentes
// import { TitleContent, Title, CloseIconSG } from 'componentsV3/modal/ModalComponents';

function ModalTemplate(props) {
  if (!props.open) {
    return (<Fragment />);
  }

  return (
    <ModalSG
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.onClose}
    >
      <ModalContent width={props.width}>

        {/* <TitleContent>
          <Title>{props.title}</Title>
          <CloseIconSG size={10} onClick={props.onClose} />
        </TitleContent> */}

        <ChildrenContent>
          {props.children}
        </ChildrenContent>

      </ModalContent>
    </ModalSG>
  );
}

export default memo(ModalTemplate);

// ==================================================
// Estilos
// ==================================================
const ModalSG = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000 !important;
`;

const ModalContent = styled.div`
  outline: none;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 0px 0px 0px;


  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: ${props => (props.width ? props.width : '500px')};
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: ${props => (props.width ? props.width : '500px')};
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: ${props => (props.width ? props.width : '500px')};
  }
  @media (min-width: 1920px) {
    width: ${props => (props.width ? props.width : '500px')};
  }
`;

const ChildrenContent = styled.div`
  display: flex;
  flex-direction: column;
`;
