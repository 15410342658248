import React, { memo } from 'react';

// Componentes
import Layout from 'components/layout/Layout';
import BoxIntroduction from 'modules/workschedule/workscheduleComponents/BoxIntroduction';
import BoxWorkscheduleType from 'modules/workschedule/workscheduleComponents/BoxWorkscheduleType';
import BoxWorkschedule from 'modules/workschedule/workscheduleComponents/BoxWorkschedule';

function WorkschedulePage() {
  window.scrollTo(0, 0);

  return (
    <Layout>
      <BoxIntroduction />
      <BoxWorkschedule />
      <BoxWorkscheduleType />
    </Layout>
  );
}

export default memo(WorkschedulePage);

// ==================================================
// Estilos
// ==================================================
