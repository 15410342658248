import { toastr } from 'react-redux-toastr';

// ==================================================
// Mostrar mensagem
// ==================================================
export function toMessage(response, label) {
  console.info('setError', response, label);
  let status = false;
  let errors = false;
  let message = false;

  if (response !== undefined) {
    if (response.status) {
      status = response.status;
    }
    if (response.data) {
      if (response.data.data) {
        if (response.data.data.errors) {
          errors = response.data.data.errors;
        } else if (response.data.data.message) {
          message = response.data.data.message;
        }
      }
      if (response.data.message) {
        message = response.data.message;
      }
    }
  }

  if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
    if (message) {
      errorMessage(message);
    } else {
      responseStatus(status, label);
    }
  } else if (message === 'Token is expired') {
    logout();
  } else if (message === 'Invalid credentials.' || message === 'Unauthorized.' || message === 'Unauthenticated.') {
    unauthenticated();
  } else if (errors) {
    errorsMessage(errors);
  } else if (message) {
    errorMessage(message);
  } else {
    noMessage(label);
  }

  // if (type === 'ERROR') {
  //   return toastr.error('', message);
  // }

  // return toastr.success('', message);
}

function errorsMessage(dateErrors) {
  const errors = Object.values(dateErrors);
  errors.map(err => (
    err.map(message => (
      toastr.warning('', message)
    ))
  ));
}

function errorMessage(message) {
  toastr.warning('', message);
}

function noMessage(label) {
  toastr.error('', label);
}

function unauthenticated() {
  toastr.warning('', 'Usuário ou senha inválido');
}

function logout() {
  toastr.warning('', 'Token expirou. Faça login novamente');
}

function responseStatus(status) {
  if (status === 500) {
    toastr.warning('', 'Erro interno do servidor. (500)');
  } else if (status === 501) {
    toastr.warning('', 'Não implementado. (501)');
  } else if (status === 502) {
    toastr.warning('', 'Gateway incorreto. (502)');
  } else if (status === 503) {
    toastr.warning('', 'Serviço indisponível. (503)');
  } else if (status === 504) {
    toastr.warning('', 'Tempo limite do gateway. (504)');
  } else if (status === 505) {
    toastr.warning('', 'Versão HTTP não suportada. (505)');
  }
}
