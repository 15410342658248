import * as React from 'react';

function BoxSaveTimeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={798}
      height={534}
      viewBox="0 0 798 534"
      {...props}
    >
      <defs>
        <filter
          id="prefix_BBB_a"
          x={0}
          y={0}
          width={736}
          height={534}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="b" />
          <feFlood floodOpacity={0.102} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_BBB_c"
          x={61}
          y={50}
          width={57}
          height={404}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={5} result="d" />
          <feFlood floodOpacity={0.043} />
          <feComposite operator="in" in2="d" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_BBB_e"
          x={501}
          y={224}
          width={297}
          height={188}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="f" />
          <feFlood floodOpacity={0.114} />
          <feComposite operator="in" in2="f" />
          <feComposite in="SourceGraphic" />
        </filter>
        <style>
          {
            '.prefix_BBB_b{fill:#dee6f2}.prefix_BBB_ai,.prefix_BBB_c{fill:#fff}.prefix_BBB_d{fill:#e7ccff}.prefix_BBB_e{fill:#f0f0f0}.prefix_BBB_ac,.prefix_BBB_an,.prefix_BBB_f,.prefix_BBB_i{fill:none}.prefix_BBB_f{stroke:#e7ccff;stroke-miterlimit:10;stroke-width:4px}.prefix_BBB_g{fill:#9751eb}.prefix_BBB_i{stroke:#b177f4}.prefix_BBB_ac,.prefix_BBB_i{stroke-linecap:round;stroke-linejoin:round}.prefix_BBB_i{stroke-width:1.7px}.prefix_BBB_p{fill:#bfbfbf;opacity:.527}.prefix_BBB_q{fill:rgba(151,81,235,.44)}.prefix_BBB_r{fill:#dbdbdb;opacity:.608}.prefix_BBB_s{fill:#e9e9e9}.prefix_BBB_ac,.prefix_BBB_ai{stroke:#fff}.prefix_BBB_am{stroke:none}'
          }
        </style>
      </defs>
      <g transform="translate(-75 -863.198)">
        <circle
          cx={117}
          cy={117}
          r={117}
          transform="translate(78 1123.198)"
          fill="#f1e7fc"
          opacity={0.352}
        />
        <g transform="translate(627.357 869)">
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 .198)"
          />
          <g transform="translate(87.983)">
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 28.198)"
            />
          </g>
        </g>
        <g transform="translate(627.357 905)">
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 .198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 28.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 19.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 10.198)"
          />
          <circle
            className="prefix_BBB_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 .198)"
          />
          <g transform="translate(87.983)">
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 28.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 .198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 9.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 18.198)"
            />
            <circle
              className="prefix_BBB_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 28.198)"
            />
          </g>
        </g>
        <g transform="translate(74.997 863.195)" filter="url(#prefix_BBB_a)">
          <rect
            className="prefix_BBB_c"
            width={616}
            height={414}
            rx={15}
            transform="translate(60 40)"
          />
        </g>
        <path
          className="prefix_BBB_d"
          d="M237.291 1058.175v-2.543a1.485 1.485 0 011.484-1.483h2.546zM253.716 1050.311l-.722.723-3.209-3.209.723-.722a1.7 1.7 0 012.409 0l.8.8a1.706 1.706 0 01-.001 2.408zM227.715 1030.932a.5.5 0 00.5-.5v-1.462a2.426 2.426 0 014.852 0v1.461a.5.5 0 00.5.5h4.862v2.9h-15.576v-2.9z"
        />
        <g transform="translate(202.36 1026.008)">
          <path
            className="prefix_BBB_e"
            d="M6.557 22.585a.189.189 0 01-.228-.165 2.834 2.834 0 011.694-2.9 2.058 2.058 0 111.93-.42 2.835 2.835 0 012.748 1.937.186.186 0 01-.139.245l-6 1.307zM7.849 28.506a.459.459 0 00.547.351l5.1-1.111a.461.461 0 00-.2-.9l-5.1 1.111a.459.459 0 00-.347.549zM14.196 24.571a.451.451 0 00-.188 0l-7.4 1.61a.461.461 0 00.2.9l7.382-1.607q.006-.452.006-.903zM5.819 24.701a.459.459 0 00.547.351l7.4-1.61a.461.461 0 00-.2-.9l-7.4 1.61a.459.459 0 00-.347.549z"
          />
          <path
            className="prefix_BBB_e"
            d="M14.23 28.99l-9.486 2.065-3.76-17.269 4.236-.922.276 1.265a.215.215 0 00.256.164l4.1-.892a.214.214 0 00.164-.256l-.275-1.265 3.8-.827a33.667 33.667 0 01-.042-.952l-3.956.861-.7-3.218a.215.215 0 00-.256-.164l-4.1.892a.215.215 0 00-.164.256l.7 3.218-4.466.972a.7.7 0 00-.539.838l3.86 17.727a.7.7 0 00.838.539l9.55-2.079q-.022-.476-.036-.953zM8.073 8.653l.547 2.51.2.918.121.557-2.681.584-.121-.557-.2-.918-.547-2.51z"
          />
          <path
            className="prefix_BBB_e"
            d="M7.996 10.516a.9.9 0 11-1.071-.688.9.9 0 011.071.688z"
          />
          <path
            className="prefix_BBB_d"
            d="M25.084 11.917h6.63a.46.46 0 000-.919h-6.63a.46.46 0 000 .919zM25.084 16.379h10.149a.46.46 0 000-.919H25.084a.46.46 0 000 .919zM25.084 20.841h6.63a.46.46 0 000-.919h-6.63a.46.46 0 000 .919zM25.084 25.304h10.149a.46.46 0 000-.919H25.084a.46.46 0 000 .919zM25.084 29.766h6.63a.46.46 0 000-.919h-6.63a.46.46 0 100 .919z"
          />
          <rect
            className="prefix_BBB_f"
            width={2.349}
            height={2.349}
            rx={1.174}
            transform="translate(20.11 10.283)"
          />
          <rect
            className="prefix_BBB_f"
            width={2.349}
            height={2.349}
            rx={1.174}
            transform="translate(20.11 14.745)"
          />
          <rect
            className="prefix_BBB_f"
            width={2.349}
            height={2.349}
            rx={1.174}
            transform="translate(20.11 19.207)"
          />
          <rect
            className="prefix_BBB_f"
            width={2.349}
            height={2.349}
            rx={1.174}
            transform="translate(20.11 23.67)"
          />
          <rect
            className="prefix_BBB_f"
            width={2.349}
            height={2.349}
            rx={1.174}
            transform="translate(20.11 28.132)"
          />
          <g>
            <path
              className="prefix_BBB_e"
              d="M50.202 25.24l-1.931 7.4a.231.231 0 01-.287.168l-4.549-1.187a1.837 1.837 0 01-.363.876l4.675 1.22a1.172 1.172 0 001.432-.84l2.1-8.044a1.653 1.653 0 01-1.077.407zM55.376 7.767a1.168 1.168 0 00-.717-.543l-11.65-3.04a1.82 1.82 0 01.034.98l11.379 2.97a.235.235 0 01.168.287l-3.527 13.516a1.665 1.665 0 01.714.984l3.722-14.263a1.167 1.167 0 00-.123-.891z"
            />
            <path
              className="prefix_BBB_e"
              d="M50.548 10.337l-7.545-1.969c0 .318.01.635.012.953l7.3 1.906a.46.46 0 00.232-.889zM49.711 13.548l-6.715-1.752q-.015.47-.042.939l6.525 1.7a.46.46 0 00.232-.889zM48.108 19.693l-5.527-1.442a2.049 2.049 0 01-.007.948l5.3 1.384a.46.46 0 00.232-.889zM48.928 16.552l-6.122-1.6a2.093 2.093 0 010 .223 2.031 2.031 0 01-.079.706l5.97 1.558a.46.46 0 00.232-.889zM46.373 23.466a1.874 1.874 0 01.32-.866l-3.89-1.015a2.015 2.015 0 01-.133.915z"
            />
            <g>
              <path d="M43.552 26.3a1.879 1.879 0 01.312-.868l-1.37-.357a2.042 2.042 0 01.04.96z" />
            </g>
          </g>
          <path
            className="prefix_BBB_g"
            d="M42.231 33.339v1.649a1.043 1.043 0 01-1.042 1.041H15.368a1.044 1.044 0 01-1.042-1.041V3.759a1.043 1.043 0 011.042-1.042h9.78a3.193 3.193 0 00-.031.442v.892h-4.495a.459.459 0 00-.459.459v1.423h-2.064a1.212 1.212 0 00-1.21 1.21v24.458a1.212 1.212 0 001.21 1.21h16.442a1.464 1.464 0 00.182-.014.486.486 0 00.109.014.458.458 0 00.349-.152 1.361 1.361 0 00.325-.241l3.767-3.767a1.352 1.352 0 00.4-.962V7.146a1.211 1.211 0 00-1.21-1.21h-2.064V4.513a.459.459 0 00-.459-.459h-4.5v-.895a3.186 3.186 0 00-.032-.442h9.781a1.043 1.043 0 011.042 1.042v22.945a1.643 1.643 0 01.385-.139 1.6 1.6 0 01.534-.231V3.759a1.963 1.963 0 00-1.961-1.961H31.132a3.161 3.161 0 00-5.707 0H15.368a1.963 1.963 0 00-1.961 1.961v31.23a1.964 1.964 0 001.961 1.96h25.821a1.963 1.963 0 001.961-1.96v-2.494a1.546 1.546 0 01-.434.225 1.634 1.634 0 01-.485.619zm-6.94-2.011v-1.786a1.043 1.043 0 011.042-1.042h1.786zm1.1-23.213V6.859h2.064a.292.292 0 01.291.292v20.436h-2.417a1.962 1.962 0 00-1.961 1.96V31.9H18.099a.292.292 0 01-.291-.291V7.146a.292.292 0 01.291-.292h2.064v1.261a.459.459 0 00.459.459h15.313a.459.459 0 00.459-.458zm-10.81-3.143a.459.459 0 00.459-.459V3.159a2.243 2.243 0 114.486 0v1.35a.459.459 0 00.459.459h4.5v2.691H21.081V4.975z"
          />
          <path
            className="prefix_BBB_g"
            d="M51.071 20.771a2.333 2.333 0 00-3.3 0l-9.249 9.249a.522.522 0 00-.133.229l-1.216 4.391a.518.518 0 00.638.638l4.391-1.216a.52.52 0 00.228-.133l9.249-9.249a2.335 2.335 0 000-3.3zm-11.419 9.586l7.569-7.57 2.442 2.442-7.57 7.57zm-.488.978l1.951 1.951-2.7.747zm11.782-7.39l-.55.55-2.442-2.442.55-.55a1.3 1.3 0 011.833 0l.609.608a1.3 1.3 0 010 1.835z"
          />
        </g>
        <g>
          <g transform="translate(673 1033.198)" stroke="#b177f4" fill="none">
            <rect className="prefix_BBB_am" width={23} height={24} rx={11.5} />
            <rect
              className="prefix_BBB_an"
              x={0.5}
              y={0.5}
              width={22}
              height={23}
              rx={11}
            />
          </g>
          <path
            className="prefix_BBB_i"
            d="M686.593 1045.537a1.79 1.79 0 11-1.79-1.79 1.79 1.79 0 011.79 1.79z"
          />
          <path
            className="prefix_BBB_i"
            d="M689.219 1047.328a.985.985 0 00.2 1.086l.036.036a1.194 1.194 0 11-1.689 1.689l-.036-.036a.993.993 0 00-1.683.7v.1a1.194 1.194 0 11-2.387 0v-.054a1.033 1.033 0 00-1.731-.7l-.036.036a1.194 1.194 0 11-1.689-1.689l.036-.036a.993.993 0 00-.7-1.683h-.1a1.194 1.194 0 110-2.387h.054a1.033 1.033 0 00.7-1.731l-.036-.036a1.194 1.194 0 111.68-1.687l.04.037a.985.985 0 001.086.2h.048a.985.985 0 00.6-.9v-.1a1.194 1.194 0 112.387 0v.054a.993.993 0 001.683.7l.036-.036a1.194 1.194 0 111.686 1.682l-.036.036a.985.985 0 00-.2 1.086v.048a.985.985 0 00.9.6h.1a1.194 1.194 0 110 2.387h-.054a.985.985 0 00-.9.6z"
          />
        </g>
        <g>
          <text
            transform="translate(228 985.198)"
            fontFamily="Roboto-Light,Roboto"
            fontWeight={300}
            fontSize={12}
            fill="#6719d7"
          >
            <tspan x={0} y={0}>
              {'Aprova\xE7\xE3o de grid'}
            </tspan>
          </text>
          <g transform="translate(202.464 976.297)">
            <rect
              className="prefix_BBB_g"
              width={15}
              height={14}
              rx={7}
              transform="translate(-.464 -.098)"
            />
            <path
              className="prefix_BBB_c"
              d="M11.174 5.944v1.654a.412.412 0 01-.413.413H7.318v1.787a.413.413 0 01-.706.293L3.583 7.063a.413.413 0 010-.585l3.029-3.024a.414.414 0 01.706.293v1.785h3.443a.412.412 0 01.413.412z"
            />
          </g>
        </g>
        <g transform="translate(150.961 924.794)">
          <g
            transform="translate(.039 .404)"
            strokeWidth={1.4}
            stroke="#dee6f2"
            fill="#f0f0f0"
          >
            <rect className="prefix_BBB_am" width={584} height={374} rx={10} />
            <rect
              className="prefix_BBB_an"
              x={0.7}
              y={0.7}
              width={582.6}
              height={372.6}
              rx={9.3}
            />
          </g>
          <rect
            className="prefix_BBB_c"
            width={520}
            height={261}
            rx={10}
            transform="translate(42.039 78.404)"
          />
          <g transform="translate(396.996 90.129)" opacity={0.509}>
            <ellipse
              className="prefix_BBB_c"
              cx={13.5}
              cy={15}
              rx={13.5}
              ry={15}
              transform="translate(.043 .275)"
            />
            <ellipse
              className="prefix_BBB_c"
              cx={14}
              cy={15}
              rx={14}
              ry={15}
              transform="translate(33.043 .275)"
            />
            <ellipse
              className="prefix_BBB_c"
              cx={13.5}
              cy={15}
              rx={13.5}
              ry={15}
              transform="translate(66.043 .275)"
            />
            <ellipse
              className="prefix_BBB_c"
              cx={13}
              cy={15}
              rx={13}
              ry={15}
              transform="translate(99.043 .275)"
            />
          </g>
          <path
            className="prefix_BBB_c"
            d="M27.039.404h547a10 10 0 0110 10v22h-557v-32z"
          />
          <g transform="translate(-75.96 -61.6)" filter="url(#prefix_BBB_c)">
            <path
              className="prefix_BBB_c"
              d="M86 62h17v374H86a10 10 0 01-10-10V72a10 10 0 0110-10z"
            />
          </g>
          <text
            transform="translate(133.039 112.403)"
            fill="#2a2e43"
            fontSize={14}
            fontFamily="Roboto-Light,Roboto"
            fontWeight={300}
          >
            <tspan x={0} y={0}>
              Meu Setor
            </tspan>
          </text>
          <path stroke="#707070" fill="none" d="M132.698 125.287h183.05" />
          <path
            className="prefix_BBB_g"
            d="M310.039 115.835v2.378h2.378l7.015-7.014-2.378-2.379zm11.232-6.476a.632.632 0 000-.894l-1.484-1.484a.632.632 0 00-.894 0l-1.161 1.16 2.378 2.378 1.161-1.16z"
          />
          <rect
            width={487}
            height={22}
            rx={10}
            transform="translate(55.039 159.404)"
            fill="#b178f4"
          />
          <g transform="translate(54.721 201.717)">
            <rect
              className="prefix_BBB_p"
              width={52}
              height={7}
              rx={3.5}
              transform="translate(35.318 -.313)"
            />
            <rect
              className="prefix_BBB_p"
              width={26}
              height={8}
              rx={4}
              transform="translate(35.318 12.687)"
            />
            <circle
              className="prefix_BBB_q"
              cx={10.5}
              cy={10.5}
              r={10.5}
              transform="translate(.318 -.313)"
            />
            <path
              className="prefix_BBB_c"
              d="M10.995 10.456a2.128 2.128 0 10-2.129-2.129 2.129 2.129 0 002.129 2.129zm1.892.473h-.815a2.573 2.573 0 01-2.155 0h-.814a1.892 1.892 0 00-1.892 1.891v.236a.71.71 0 00.709.709h6.149a.71.71 0 00.709-.709v-.236a1.892 1.892 0 00-1.892-1.891z"
            />
          </g>
          <g transform="translate(169.528 200.644)">
            <rect
              className="prefix_BBB_r"
              width={27}
              height={27}
              rx={5}
              transform="translate(101.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={27}
              rx={5}
              transform="translate(68.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={27}
              rx={5}
              transform="translate(240.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={27}
              rx={5}
              transform="translate(34.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={27}
              rx={5}
              transform="translate(170.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={27}
              rx={5}
              transform="translate(307.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={27}
              rx={5}
              transform="translate(-.49 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={27}
              rx={5}
              transform="translate(136.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={27}
              rx={5}
              transform="translate(274.511 -.24)"
            />
            <rect
              className="prefix_BBB_s"
              width={26}
              height={27}
              rx={5}
              transform="translate(205.511 -.24)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={27}
              rx={5}
              transform="translate(343.511 -.24)"
            />
          </g>
          <g transform="translate(54.721 250)">
            <rect
              className="prefix_BBB_p"
              width={52}
              height={6}
              rx={3}
              transform="translate(35.318 .404)"
            />
            <rect
              className="prefix_BBB_p"
              width={26}
              height={8}
              rx={4}
              transform="translate(35.318 12.404)"
            />
            <ellipse
              className="prefix_BBB_q"
              cx={10.5}
              cy={9.5}
              rx={10.5}
              ry={9.5}
              transform="translate(.318 .404)"
            />
            <path
              className="prefix_BBB_c"
              d="M10.995 10.236a2.128 2.128 0 10-2.129-2.129 2.129 2.129 0 002.129 2.129zm1.892.473h-.815a2.573 2.573 0 01-2.155 0h-.814A1.892 1.892 0 007.211 12.6v.236a.71.71 0 00.709.709h6.149a.71.71 0 00.709-.709V12.6a1.892 1.892 0 00-1.892-1.891z"
            />
          </g>
          <g transform="translate(169.528 248.927)">
            <rect
              className="prefix_BBB_r"
              width={27}
              height={26}
              rx={5}
              transform="translate(101.511 .477)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={26}
              rx={5}
              transform="translate(240.511 .477)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={26}
              rx={5}
              transform="translate(34.511 .477)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={26}
              rx={5}
              transform="translate(170.511 .477)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={26}
              rx={5}
              transform="translate(307.511 .477)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={26}
              rx={5}
              transform="translate(-.49 .477)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={26}
              rx={5}
              transform="translate(136.511 .477)"
            />
            <rect
              className="prefix_BBB_s"
              width={27}
              height={26}
              rx={5}
              transform="translate(274.511 .477)"
            />
            <rect
              className="prefix_BBB_s"
              width={27}
              height={26}
              rx={5}
              transform="translate(67.511 .477)"
            />
            <rect
              className="prefix_BBB_s"
              width={26}
              height={26}
              rx={5}
              transform="translate(205.511 .477)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={26}
              rx={5}
              transform="translate(343.511 .477)"
            />
          </g>
          <g transform="translate(54.721 297.211)">
            <rect
              className="prefix_BBB_p"
              width={52}
              height={8}
              rx={4}
              transform="translate(35.318 .193)"
            />
            <rect
              className="prefix_BBB_p"
              width={26}
              height={7}
              rx={3.5}
              transform="translate(35.318 14.193)"
            />
            <ellipse
              className="prefix_BBB_q"
              cx={10.5}
              cy={10}
              rx={10.5}
              ry={10}
              transform="translate(.318 .193)"
            />
            <path
              className="prefix_BBB_c"
              d="M10.995 11.089A2.128 2.128 0 108.866 8.96a2.129 2.129 0 002.129 2.129zm1.892.473h-.815a2.573 2.573 0 01-2.155 0h-.814a1.892 1.892 0 00-1.892 1.891v.236a.71.71 0 00.709.709h6.149a.71.71 0 00.709-.709v-.236a1.892 1.892 0 00-1.892-1.891z"
            />
          </g>
          <g transform="translate(169.528 296.138)">
            <rect
              className="prefix_BBB_r"
              width={27}
              height={28}
              rx={5}
              transform="translate(101.511 .266)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={28}
              rx={5}
              transform="translate(240.511 .266)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={28}
              rx={5}
              transform="translate(34.511 .266)"
            />
            <rect
              className="prefix_BBB_s"
              width={27}
              height={28}
              rx={5}
              transform="translate(170.511 .266)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={28}
              rx={5}
              transform="translate(307.511 .266)"
            />
            <rect
              className="prefix_BBB_r"
              width={26}
              height={28}
              rx={5}
              transform="translate(-.49 .266)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={28}
              rx={5}
              transform="translate(136.511 .266)"
            />
            <rect
              className="prefix_BBB_s"
              width={27}
              height={28}
              rx={5}
              transform="translate(274.511 .266)"
            />
            <rect
              className="prefix_BBB_s"
              width={27}
              height={28}
              rx={5}
              transform="translate(67.511 .266)"
            />
            <rect
              className="prefix_BBB_s"
              width={26}
              height={28}
              rx={5}
              transform="translate(205.511 .266)"
            />
            <rect
              className="prefix_BBB_r"
              width={27}
              height={28}
              rx={5}
              transform="translate(343.511 .266)"
            />
          </g>
          <text
            transform="translate(290.039 173.404)"
            fontSize={8}
            fontFamily="NotoSans,Noto Sans"
            letterSpacing=".1em"
            fill="#fff"
          >
            <tspan x={-41.261} y={0}>
              {'TURNO DA MANH\xC3'}
            </tspan>
          </text>
          <path
            d="M20.507 26.062v-.065a.021.021 0 00-.022-.022c0-.022-.022-.022-.022-.043l-.022-.022-3.694-2.694a.314.314 0 00-.152-.043h-2.668a.309.309 0 00-.13.043v-2.194L13.775 21c0-.022-.022-.022-.022-.043l-.026-.022-1.738-1.26v-2.542a3.254 3.254 0 011.89-2.955 3.143 3.143 0 011.673 2.759v3.846a.228.228 0 00.109.2l1.847 1.347a.314.314 0 00.152.043h2.629a.247.247 0 00.239-.261v-5.196a5.443 5.443 0 00-5.453-5.454h-2.694a5.457 5.457 0 00-5.454 5.454v3.8a.228.228 0 00.109.2l3.672 2.672a.314.314 0 00.152.043h2.672a.309.309 0 00.13-.043v2.084a.228.228 0 00.109.2l1.738 1.26v2.129a2.69 2.69 0 01-2.607 2.694 2.731 2.731 0 01-.956-2.064v-3.915a.324.324 0 00-.087-.2l-1.847-1.325a.314.314 0 00-.152-.043H7.188a.254.254 0 00-.239.174v3.954a5.927 5.927 0 002.368 4.715l1.782 1.311a5.854 5.854 0 009.408-4.671v-3.785c.022-.022.022-.043 0-.043m-4-2.39l3 2.173h-1.825l-3-2.173zm-6.735-3.628h1.825l1.152.847h-1.822zm1.673-2.911v2.412H9.274v-2.609a3.125 3.125 0 013.107-3.129 3.775 3.775 0 01.847.109 3.8 3.8 0 00-1.782 3.216m3.607-5.193a4.952 4.952 0 014.954 4.954v4.976h-2.151v-4.954a5.437 5.437 0 00-3.194-4.954h.391zm-7.626 4.976a4.961 4.961 0 014.932-4.954h.068a4.933 4.933 0 014.932 4.954v4.736l-1.347-1v-3.715a3.619 3.619 0 10-7.235-.2v3.025a.228.228 0 00.109.2l1.738 1.26v1.651l-3.197-2.286zm5.866 6.236H11.12V21.37h2.173zm2.694 6.127v-2.26a.228.228 0 00-.109-.2l-1.738-1.257v-1.65l3.194 2.3v3.694a2.712 2.712 0 01-2.694 2.716 2.824 2.824 0 01-1.108-.239 3.182 3.182 0 002.455-3.107m-6.214-4.345l1.152.847H9.1l-1.152-.847zm-2.346 3.652v-3.411l1.347.978v3.781a5.858 5.858 0 00.8 2.955 5.466 5.466 0 01-2.151-4.3m12.6 1.325a5.369 5.369 0 01-5.388 5.367 5.45 5.45 0 01-3.15-1.021l-.109-.087a5.458 5.458 0 01-2.129-4.259v-3.649h2.18v3.65a3.206 3.206 0 006.41 0v-3.567h2.17z"
            fill="#243e64"
          />
          <path
            className="prefix_BBB_d"
            d="M93.436 135.011v-2.762a1.613 1.613 0 011.612-1.611h2.762zM111.281 126.469l-.785.785-3.487-3.487.785-.785a1.85 1.85 0 012.617 0l.869.869a1.853 1.853 0 01.001 2.618zM83.034 105.413a.54.54 0 00.54-.54v-1.588a2.636 2.636 0 015.271 0v1.587a.54.54 0 00.54.54h5.282v3.154H77.752v-3.153z"
          />
          <g transform="translate(55.489 100.067)">
            <path
              className="prefix_BBB_e"
              d="M7.124 24.536a.205.205 0 01-.247-.179 3.079 3.079 0 011.84-3.155 2.236 2.236 0 112.1-.457 3.08 3.08 0 012.985 2.1.2.2 0 01-.151.266l-6.524 1.42zM8.528 30.968a.5.5 0 00.594.382l5.542-1.207a.5.5 0 00-.212-.975L8.91 30.375a.5.5 0 00-.382.593zM15.422 26.694a.49.49 0 00-.2 0l-8.035 1.749a.5.5 0 00.213.975l8.02-1.746q-.002-.488.002-.978zM6.322 26.836a.5.5 0 00.594.382l8.035-1.75a.5.5 0 00-.213-.975l-8.035 1.75a.5.5 0 00-.381.593z"
            />
            <path
              className="prefix_BBB_e"
              d="M15.458 31.494l-10.3 2.244-4.085-18.76 4.6-1 .3 1.374a.233.233 0 00.278.178l4.451-.969a.233.233 0 00.178-.278l-.3-1.374 4.126-.9a35.5 35.5 0 01-.046-1.035l-4.3.936-.761-3.5a.233.233 0 00-.278-.178l-4.452.969a.233.233 0 00-.178.278l.761 3.5-4.849 1.052a.766.766 0 00-.585.911L4.211 34.2a.765.765 0 00.911.585l10.375-2.259q-.023-.514-.039-1.032zM8.769 9.401l.594 2.727.217 1 .132.605-2.913.634-.132-.605-.217-1-.592-2.731z"
            />
            <path
              className="prefix_BBB_e"
              d="M8.687 11.425a.978.978 0 11-1.164-.748.978.978 0 011.164.748z"
            />
            <path
              className="prefix_BBB_d"
              d="M27.251 12.946h7.2a.5.5 0 000-1h-7.2a.5.5 0 100 1zM27.251 17.793H38.28a.5.5 0 000-1H27.251a.5.5 0 100 1zM27.251 22.641h7.2a.5.5 0 000-1h-7.2a.5.5 0 100 1zM27.251 27.489H38.28a.5.5 0 000-1H27.251a.5.5 0 100 1zM27.251 32.336h7.2a.5.5 0 000-1h-7.2a.5.5 0 100 1z"
            />
            <rect
              className="prefix_BBB_f"
              width={2.551}
              height={2.551}
              rx={1.276}
              transform="translate(21.847 11.171)"
            />
            <rect
              className="prefix_BBB_f"
              width={2.551}
              height={2.551}
              rx={1.276}
              transform="translate(21.847 16.018)"
            />
            <rect
              className="prefix_BBB_f"
              width={2.551}
              height={2.552}
              rx={1.276}
              transform="translate(21.847 20.866)"
            />
            <rect
              className="prefix_BBB_f"
              width={2.551}
              height={2.551}
              rx={1.276}
              transform="translate(21.847 25.714)"
            />
            <rect
              className="prefix_BBB_f"
              width={2.551}
              height={2.551}
              rx={1.276}
              transform="translate(21.847 30.562)"
            />
            <g>
              <path
                className="prefix_BBB_e"
                d="M54.537 27.419l-2.1 8.039a.251.251 0 01-.311.183l-4.942-1.29a2 2 0 01-.394.952l5.078 1.325a1.274 1.274 0 001.556-.912l2.281-8.739a1.8 1.8 0 01-1.168.442zM60.158 8.437a1.269 1.269 0 00-.779-.589l-12.656-3.3a1.978 1.978 0 01.036 1.064l12.362 3.226a.255.255 0 01.182.311l-3.832 14.683a1.809 1.809 0 01.776 1.069l4.044-15.5a1.268 1.268 0 00-.133-.964z"
              />
              <path
                className="prefix_BBB_e"
                d="M54.914 11.229l-8.2-2.139c.005.345.01.69.013 1.035l7.932 2.07a.5.5 0 00.252-.966zM54.005 14.718l-7.295-1.9q-.016.511-.046 1.02l7.089 1.85a.5.5 0 00.252-.966zM52.263 21.394l-6-1.567a2.227 2.227 0 01-.007 1.03l5.759 1.5a.5.5 0 00.252-.966zM53.153 17.978l-6.65-1.735a2.253 2.253 0 010 .242 2.208 2.208 0 01-.086.766l6.485 1.693a.5.5 0 00.252-.966zM50.378 25.493a2.036 2.036 0 01.348-.941l-4.226-1.1a2.189 2.189 0 01-.144.994z"
              />
              <g>
                <path d="M47.314 28.572a2.041 2.041 0 01.339-.943l-1.488-.388a2.218 2.218 0 01.044 1.043z" />
              </g>
            </g>
            <path
              className="prefix_BBB_g"
              d="M45.878 36.219v1.792a1.133 1.133 0 01-1.132 1.131H16.695a1.134 1.134 0 01-1.132-1.131V4.087a1.133 1.133 0 011.132-1.132H27.32a3.463 3.463 0 00-.034.48v.969h-4.883a.5.5 0 00-.5.5V6.45H19.66a1.316 1.316 0 00-1.314 1.315v26.57a1.316 1.316 0 001.314 1.315h17.862a1.6 1.6 0 00.2-.015.53.53 0 00.118.015.5.5 0 00.376-.171 1.48 1.48 0 00.353-.262l4.093-4.092a1.468 1.468 0 00.432-1.045V7.759a1.316 1.316 0 00-1.314-1.315h-2.241V4.898a.5.5 0 00-.5-.5h-4.883v-.969a3.468 3.468 0 00-.034-.48h10.625a1.133 1.133 0 011.132 1.132v24.927a1.785 1.785 0 01.418-.151 1.74 1.74 0 01.58-.251V4.087a2.132 2.132 0 00-2.13-2.13H33.821a3.434 3.434 0 00-6.2 0H16.695a2.132 2.132 0 00-2.13 2.13V38.01a2.133 2.133 0 002.13 2.129h28.051a2.133 2.133 0 002.13-2.129V35.3a1.679 1.679 0 01-.472.244 1.775 1.775 0 01-.526.675zm-7.54-2.185v-1.94a1.133 1.133 0 011.132-1.132h1.94zm1.2-25.217v-1.37h2.242a.317.317 0 01.316.317v22.2h-2.625a2.132 2.132 0 00-2.13 2.13v2.556H19.662a.317.317 0 01-.316-.317V7.759a.317.317 0 01.316-.317h2.243v1.37a.5.5 0 00.5.5h16.634a.5.5 0 00.5-.495zM27.786 5.402a.5.5 0 00.5-.5V3.434a2.437 2.437 0 114.874 0v1.467a.5.5 0 00.5.5h4.879v2.916H22.902V5.401z"
            />
            <path
              className="prefix_BBB_g"
              d="M55.482 22.565a2.535 2.535 0 00-3.584 0L41.85 32.613a.567.567 0 00-.145.248l-1.321 4.77a.563.563 0 00.693.693l4.771-1.321a.565.565 0 00.248-.144l10.048-10.048a2.537 2.537 0 000-3.584zm-12.4 10.414l8.223-8.224 2.652 2.653-8.224 8.223zm-.53 1.063l2.119 2.119-2.931.812zm12.8-8.028l-.6.6-2.652-2.653.6-.6a1.408 1.408 0 011.991 0l.661.661a1.411 1.411 0 01-.006 1.992z"
            />
          </g>
          <g opacity={0.667}>
            <path
              d="M547.448 22.643a1.527 1.527 0 11-3.054 0h-4.585a.638.638 0 01-.489-1.038l1.531-1.772a1.99 1.99 0 00.489-1.16v-3.359a4.581 4.581 0 119.162 0v3.359a2.927 2.927 0 00.428 1.222l1.527 1.771a.63.63 0 01-.489 1.038h-4.52zm2.561-1.954a3.388 3.388 0 01-.733-2.016v-3.359a3.36 3.36 0 00-6.719 0v3.359a3.388 3.388 0 01-.733 2.016l-.672.733h9.528l-.672-.733z"
              fill="#677ea0"
            />
            <circle
              cx={2.497}
              cy={2.497}
              fill="#be2a2a"
              r={2.497}
              transform="translate(551.711 10.296)"
            />
          </g>
          <text
            transform="translate(73.038 59.403)"
            fontSize={13}
            fontFamily="Roboto-Light,Roboto"
            fontWeight={300}
            fill="#6719d7"
          >
            <tspan x={0} y={0}>
              Editar template
            </tspan>
          </text>
          <g transform="translate(47.21 47.21)">
            <rect
              className="prefix_BBB_g"
              width={15}
              height={15}
              rx={7.5}
              transform="translate(-.172 .193)"
            />
            <path
              className="prefix_BBB_c"
              d="M11.264 7.258v1.636a.408.408 0 01-.409.409H7.446v1.769a.409.409 0 01-.7.29l-3-3a.409.409 0 010-.579l3-2.994a.41.41 0 01.7.29v1.767h3.409a.408.408 0 01.409.412z"
            />
          </g>
          <g transform="translate(6.438 81.545)">
            <circle
              className="prefix_BBB_e"
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-.399 38.859)"
            />
            <circle
              className="prefix_BBB_e"
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-.399 75.859)"
            />
            <circle
              className="prefix_BBB_e"
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-.399 108.859)"
            />
            <ellipse
              className="prefix_BBB_e"
              cx={6.5}
              cy={6}
              rx={6.5}
              ry={6}
              transform="translate(-.399 146.859)"
            />
            <circle
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-.399 -.141)"
              opacity={0.302}
              fill="#9751eb"
            />
          </g>
          <g transform="translate(46.137 8.584)">
            <text
              transform="translate(24.901 10.82)"
              fontFamily="Roboto-Regular,Roboto"
              fill="#939393"
              fontSize={10}
            >
              <tspan x={0} y={0}>
                sua empresa
              </tspan>
            </text>
            <circle
              cx={9.5}
              cy={9.5}
              r={9.5}
              transform="translate(-.099 -.18)"
              fill="#d6d6d6"
            />
            <path
              className="prefix_BBB_ac"
              d="M6.601 6.569h7.536a.942.942 0 01.942.942v4.71a.942.942 0 01-.942.942H6.601a.942.942 0 01-.942-.942v-4.71a.942.942 0 01.942-.942z"
            />
            <path
              className="prefix_BBB_ac"
              d="M12.253 13.163V5.627a.942.942 0 00-.942-.942H9.427a.942.942 0 00-.942.942v7.536"
            />
          </g>
          <g transform="translate(.039 .404)" stroke="#dee6f2" fill="none">
            <rect className="prefix_BBB_am" width={584} height={374} rx={10} />
            <rect
              className="prefix_BBB_an"
              x={0.5}
              y={0.5}
              width={583}
              height={373}
              rx={9.5}
            />
          </g>
          <path
            className="prefix_BBB_c"
            d="M365.26 173.181v1.7h1.7l5.03-5.022-1.7-1.7zm8.048-4.64a.453.453 0 000-.641l-1.063-1.063a.453.453 0 00-.641 0l-.832.832 1.7 1.7.832-.832z"
          />
          <text
            transform="translate(503.039 173.404)"
            fontSize={9}
            fontFamily="Roboto-Italic,Roboto"
            fontStyle="italic"
            fill="#fff"
          >
            <tspan x={0} y={0}>
              6x1
            </tspan>
          </text>
          <path
            d="M.039 345.404h584v19a10 10 0 01-10 10h-564a10 10 0 01-10-10v-19z"
            fill="#e7d5fd"
          />
          <g>
            <path stroke="#fff" fill="none" d="M190.988 359.468h202.48" />
            <g
              transform="translate(181.039 355.403)"
              fill="#009b8d"
              strokeWidth={1.5}
              stroke="#fff"
            >
              <ellipse className="prefix_BBB_am" cx={5} cy={4} rx={5} ry={4} />
              <ellipse
                className="prefix_BBB_an"
                cx={5}
                cy={4}
                rx={4.25}
                ry={3.25}
              />
            </g>
            <g className="prefix_BBB_ai" transform="translate(288.039 355.403)">
              <ellipse className="prefix_BBB_am" cx={4.5} cy={4} rx={4.5} ry={4} />
              <ellipse className="prefix_BBB_an" cx={4.5} cy={4} rx={4} ry={3.5} />
            </g>
            <g className="prefix_BBB_ai" transform="translate(388.039 355.403)">
              <ellipse className="prefix_BBB_am" cx={5} cy={4} rx={5} ry={4} />
              <ellipse className="prefix_BBB_an" cx={5} cy={4} rx={4.5} ry={3.5} />
            </g>
          </g>
          <rect
            width={79}
            height={20}
            rx={10}
            transform="translate(463.039 350.404)"
            opacity={0.652}
            fill="#9751eb"
          />
          <text
            transform="translate(476.039 362.404)"
            fontSize={7}
            letterSpacing=".03em"
            fontFamily="Roboto-Regular,Roboto"
            fill="#fff"
          >
            <tspan x={0} y={0}>
              PROSSEGUIR
            </tspan>
          </text>
        </g>
        <g>
          <g transform="translate(75 863.201)" filter="url(#prefix_BBB_e)">
            <rect
              className="prefix_BBB_c"
              width={177}
              height={68}
              rx={10}
              transform="translate(561 274)"
            />
          </g>
          <path className="prefix_BBB_b" d="M647 1159.198h155v4H647z" />
          <path className="prefix_BBB_g" d="M647 1159.198h53v4h-53z" />
          <text
            transform="translate(647 1185.198)"
            fontFamily="Roboto-Regular,Roboto"
            fill="#677ea0"
            fontSize={12}
          >
            <tspan x={0} y={0}>
              {'Configurando folgas\u2026'}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default BoxSaveTimeIcon;
