import React, { memo } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components/macro';

// Componentes
import GridItem from 'components/grids/GridItem';

// Estilos
import { blueColor, whiteColor } from 'styles/Colors';

function PhoneMask(props) {
  const { validMask, inputRef, ...other } = props;
  const mask = () => ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  return (
    <MaskedInput
      {...other}
      ref={(ref) => { inputRef(ref ? ref.inputElement : null); }}
      mask={mask}
      guide
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

function InputV3Component(props) {
  let textColor = blueColor[9];

  if (props.colors === 'white') {
    textColor = whiteColor;
  }

  let paddingRight = true;
  if (props.paddingRight === false) paddingRight = false;

  let paddingTop = true;
  if (props.paddingTop === false) paddingTop = false;

  const onChange = (e) => {
    if (props.validMask === 'NUMBER') {
      props.input.onChange(parseInt(e.currentTarget.value.split(/\D+/).join(''), 10));
    } else if (props.validMask === 'PHONE') {
      props.input.onChange(parseInt(e.currentTarget.value.split(/\D+/).join(''), 10));
    } else {
      props.input.onChange(e);
    }
  };

  const newValue = props.input.value;

  return (
    <GridItem xs={props.col} sm={props.col} md={props.col} lg={props.col} xl={props.col} paddingRight={paddingRight} paddingTop={paddingTop}>
      <InputText
        helperText={props.helperText || ''}
        id={props.id || 'input'}
        name={props.name}
        type={props.type || 'text'}
        label={props.label}
        placeholder={props.placeholder}
        disabled={props.disabled}
        fullWidth={props.fullWidth || false}
        value={newValue || ''}
        multiline={props.multiline || false}
        rows={props.rows || 3}
        onChange={e => onChange(e)}
        autoFocus={props.autoFocus}
        textcolor={textColor}
        textalign={props.textAlign}
        error={props.error || false}
        InputProps={{
          'aria-label': 'naked',
          inputComponent: props.validMask === 'PHONE' ? PhoneMask : 'input',
          inputProps: {
            min: props.minNumber || undefined,
            max: props.maxNumber || undefined,
          },
          endAdornment: (
            props.icon && (
              <InputAdornment position="end">
                <Icon icon={props.icon} onClick={props.onClickIcon} cursorpointer={props.cursorPointer ? props.cursorPointer.toString() : 'false'} />
              </InputAdornment>
            )
          ),
        }}
        InputLabelProps={{
          shrink: props.shrink, // true, false
        }}
        autoComplete="__"
      />
    </GridItem>
  );
}

export default memo(InputV3Component);

// ==================================================
// Estilos
// ==================================================
const InputText = styled(TextField)`
  .MuiInputBase-input {
    color: ${props => (props.textcolor)} !important; // Texto input
    text-align: ${props => (props.textalign ? props.textalign : 'left')};
  }
  .MuiFormLabel-root {
    color:  #677EA0; // Texto label
  }
  .MuiFormLabel-root.Mui-focused {
    color:  #677EA0; // Texto label clicado;
  }
  .MuiInput-underline:after {
    border-bottom: solid 2px ${props => (props.textcolor)} !important; // Linha clicada;
  }
  .MuiInput-underline:before {
    border-bottom: solid 1px ${props => (props.textcolor)}; // Linha;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${({ theme }) => theme.colorText[1]};
  cursor: ${props => (props.cursorpointer === 'true' ? 'pointer' : 'default')}
`;
