import React, { useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import BoxValueYourEmployeesIcon from 'modules/home/homeComponents/BoxValueYourEmployeesIcon';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxValueYourEmployees() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxValueYourEmployeesContent>
      <BoxIntroduction>
        <BoxOverflow>
          <Title data-aos="fade-down-right" data-aos-duration="600">{translate('Valorize_seus_colaboradores')}</Title>
          <Subtitle data-aos="fade-down-left" data-aos-duration="600">{translate('Conceda_facilmente_folgas_nos_dias_que_eles_solicitam_sem_quebrar_regras_trabalhistas_e_permitindo_trocas_ocasionais')}</Subtitle>
          <BoxIcon data-aos="flip-up" data-aos-duration="600">
            <BoxValueYourEmployeesIconSG />
          </BoxIcon>
        </BoxOverflow>
      </BoxIntroduction>
    </BoxValueYourEmployeesContent>
  );
}

export default memo(BoxValueYourEmployees);

// ==================================================
// Estilos
// ==================================================
const BoxValueYourEmployeesContent = styled.div`
  width: 100%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const BoxIntroduction = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;
  text-align: center;

  @media (max-width: 599px) {
    padding: 0px 25px 0px 25px;
    font: bold 20px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 0px 0px 25px;
    font: bold 40px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 40px Noto Sans;
    margin: 0px 0px 25px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 40px Noto Sans;
    margin: 0px 0px 25px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;
  text-align: center;

  @media (max-width: 599px) {
    font: normal 12px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal 12px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal 18px Noto Sans;
    padding: 0px 0px 0px 25px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxValueYourEmployeesIconSG = styled(BoxValueYourEmployeesIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 150px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 160px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 310px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 380px;
  }
  @media (min-width: 1920px) {
    height: 380px;
  }
`;

const BoxIcon = styled.div``;

const BoxOverflow = styled.div`
  overflow: none;
`;
