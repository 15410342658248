import React, { useState, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import intl from 'react-intl-universal';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

// Componentes
import ModalDemoPage from 'components/modal/modalDemo/ModalDemoPage';
import ModalAccountPage from 'components/modal/modalAccount/ModalAccountPage';
import ModalSupportPage from 'components/modal/modalSupport/ModalSupportPage';
import ModalContactPage from 'components/modal/modalContact/ModalContactPage';
// import FloatButtonDemo from 'components/randomComponents/FloatButtonDemo';
import MessageToaster from 'components/message/MessageToaster';
import LoaderGeneral from 'components/loader/loaderGeneral/LoaderGeneral';

// Estilos
import GlobalStyles from 'styles/GlobalStyle';
import Theme from 'styles/Theme';

// Rotas
import Routes from 'routes/Routes';

// Serviços
import { initAnalytics, sendEventAnalytics } from 'services/Analytics';

// Tradução
import ptBR from 'translates/pt-BR.json';

function App() {
  const darkMode = false;

  const theme = useMemo(() => Theme(darkMode), [darkMode]);

  const [currentLocale] = useState('pt-br');
  const locales = { 'pt-br': ptBR };
  intl.init({ currentLocale, locales });

  initAnalytics();
  sendEventAnalytics('SITE/NEW/ACCESS');

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <MessageToaster />
        <Switch>{Routes}</Switch>
        <ModalDemoPage />
        <ModalAccountPage />
        <ModalSupportPage />
        <ModalContactPage />
        {/* <FloatButtonDemo /> */}
        <LoaderGeneral />
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
