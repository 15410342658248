/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useRef, memo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCompressAlt, faThLarge, faRetweet, faBars, faHome, faStar, faFileAlt } from '@fortawesome/free-solid-svg-icons';
// faPlay, faUserFriends,
import { Collapse } from '@material-ui/core';
import ButtonM from '@material-ui/core/Button';
import PopperM from '@material-ui/core/Popper';
import MenuItemM from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';

// Componentes
import LogoStarGrid from 'components/layout/layoutComponents/LogoStarGrid';
import Button from 'components/button/Button';

// Actions
import { openModalDemo } from 'components/modal/modalDemo/ModalDemoAction';
import { openModalAccount } from 'components/modal/modalAccount/ModalAccountAction';

// Rotas
import Paths from 'routes/Paths';

// Serviços
import { sendEventAnalytics } from 'services/Analytics';

// Tradução
import { translate } from 'translates/TranslatesAction';

function HeaderComponent() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openMenuApp, setOpenMenuApp] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [openContent, setOpenContent] = useState(false);

  const anchorRefProduct = useRef(null);
  const anchorRefContent = useRef(null);

  const prevOpenProduct = useRef(openProduct);
  const prevOpenContent = useRef(openContent);

  useEffect(() => {
    if (prevOpenProduct.current === true && openProduct === false) {
      anchorRefProduct.current.focus();
    }
    prevOpenProduct.current = openProduct;
  }, [openProduct]);

  useEffect(() => {
    if (prevOpenContent.current === true && openContent === false) {
      anchorRefContent.current.focus();
    }
    prevOpenContent.current = openContent;
  }, [openContent]);

  const onOpenMenuProduct = () => {
    setOpenProduct(prevOpenProduct => !prevOpenProduct);
  };

  const onOpenMenuApp = () => {
    setOpenMenuApp(!openMenuApp);
  };

  const onOpenMenuContent = () => {
    setOpenContent(prevOpenContent => !prevOpenContent);
  };

  const onCloseMenuProduct = (event) => {
    if (anchorRefProduct.current && anchorRefProduct.current.contains(event.target)) {
      return;
    }
    setOpenProduct(false);
  };

  const onCloseMenuContent = (event) => {
    if (anchorRefContent.current && anchorRefContent.current.contains(event.target)) {
      return;
    }
    setOpenContent(false);
  };

  function onKeyDownMenuProduct(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProduct(false);
    }
  }

  function onKeyDownMenuContent(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenContent(false);
    }
  }

  const onOpenPlans = () => {
    sendEventAnalytics('HEADER/MENU/HOME');
    setOpenMenuApp(false);
    history.push(Paths.plans);
  };

  const onOpenHome = () => {
    sendEventAnalytics('HEADER/MENU/PLANS');
    setOpenMenuApp(false);
    history.push(Paths.home);
  };

  const onOpenSwapRequest = (event) => {
    sendEventAnalytics('HEADER/MENU/SWAP-REQUEST');
    onCloseMenuProduct(event);
    setOpenMenuApp(false);
    history.push(Paths.swapRequest);
  };

  const onOpenWorkschedule = (event) => {
    sendEventAnalytics('HEADER/MENU/WORKSCHEDULE');
    onCloseMenuProduct(event);
    setOpenMenuApp(false);
    history.push(Paths.workschedule);
  };

  const onOpenIntegration = (event) => {
    sendEventAnalytics('HEADER/MENU/INTEGRATION');
    onCloseMenuProduct(event);
    setOpenMenuApp(false);
    history.push(Paths.integration);
  };

  // const onOpenBlog = (event) => {
  //   sendEventAnalytics('HEADER/MENU/BLOG');
  //   onCloseMenuContent(event);
  //   setOpenContent(false);
  //   history.push(Paths.blog);
  // };

  // const onOpenTraining = (event) => {
  // sendEventAnalytics('HEADER/MENU/TRAINING');
  //   onCloseMenuContent(event);
  //   history.push('/training');
  // };

  // const onOpenVideo = (event) => {
  // sendEventAnalytics('HEADER/MENU/VIDEO');
  //   onCloseMenuContent(event);
  //   history.push('/video');
  // };

  const onOpenDemo = () => {
    sendEventAnalytics('HEADER/MENU/DEMO');
    setOpenMenuApp(false);
    dispatch(openModalDemo());
  };

  const onAccount = () => {
    sendEventAnalytics('HEADER/MENU/ACCOUNT');
    setOpenMenuApp(false);
    dispatch(openModalAccount());
  };

  return (
    <Fragment>
      <HeaderContainer open={openMenuApp}>
        <HeaderContent>

          <BoxLinksApp>
            <ButtonMenuApp active={openMenuApp.toString()} onClick={() => onOpenMenuApp()}>
              <IconMenu icon={faBars} className="chevron" />
            </ButtonMenuApp>
          </BoxLinksApp>

          <BoxLogo>
            <LogoStarGrid />
          </BoxLogo>

          <BoxLinksApp />

          <BoxLinks>
            <ButtonMenu minwidth="60px" margins="0px 10px 0px 0px" onClick={onOpenHome} active="false">
              {translate('Home')}
            </ButtonMenu>

            <ButtonMenu minwidth="100px" margins="0px 10px 0px 0px" active={openProduct.toString()} ref={anchorRefProduct} aria-controls={openProduct ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={onOpenMenuProduct}>
              {translate('Produtos')}
              <Icon icon={faChevronDown} className="chevron" />
            </ButtonMenu>
            <Popper open={openProduct} anchorEl={anchorRefProduct.current} role={undefined} transition disablePortal placement="bottom-start">
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}>
                  <Paper>
                    <ClickAwayListener onClickAway={onCloseMenuProduct}>
                      <MenuList autoFocusItem={openProduct} id="menu-list-grow" onKeyDown={onKeyDownMenuProduct}>
                        <MenuItem onClick={onOpenSwapRequest}>
                          <MenuItemCircleIcon className="circle">
                            <MenuItemIcon icon={faRetweet} />
                          </MenuItemCircleIcon>
                          <MenuItemBoxText>
                            <MenuItemTitle>{translate('Trocas_de_folgas')}</MenuItemTitle>
                            <MenuItemSubtitle>{translate('Gerenciar_pedidos_de_colaboradores_na_palma_da_mao')}</MenuItemSubtitle>
                          </MenuItemBoxText>
                        </MenuItem>
                        <MenuItem onClick={onOpenWorkschedule}>
                          <MenuItemCircleIcon className="circle">
                            <MenuItemIcon icon={faThLarge} />
                          </MenuItemCircleIcon>
                          <MenuItemBoxText>
                            <MenuItemTitle>{translate('Escalas_de_trabalho')}</MenuItemTitle>
                            <MenuItemSubtitle>{translate('Inteligencia_artificial_a_seu_favor')}</MenuItemSubtitle>
                          </MenuItemBoxText>
                        </MenuItem>
                        <MenuItem onClick={onOpenIntegration}>
                          <MenuItemCircleIcon className="circle">
                            <MenuItemIcon icon={faCompressAlt} />
                          </MenuItemCircleIcon>
                          <MenuItemBoxText>
                            <MenuItemTitle>{translate('Integracoes')}</MenuItemTitle>
                            <MenuItemSubtitle>{translate('Flexibilidade_para_interagir_com_o_seu_sistema')}</MenuItemSubtitle>
                          </MenuItemBoxText>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <ButtonMenu minwidth="100px" margins="0px 10px 0px 0px" active={openContent.toString()} ref={anchorRefContent} aria-controls={openContent ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={onOpenMenuContent}>
              {translate('Conteudo')}
              <Icon icon={faChevronDown} className="chevron" />
            </ButtonMenu>
            <Popper open={openContent} anchorEl={anchorRefContent.current} role={undefined} transition disablePortal placement="bottom-start">
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}>
                  <Paper>
                    <ClickAwayListener onClickAway={onCloseMenuContent}>
                      <MenuList autoFocusItem={openContent} id="menu-list-grow" onKeyDown={onKeyDownMenuContent}>
                        <Link to={{ pathname: 'https://stargridapp.wordpress.com/' }} target="_blank" style={{ textDecoration: 'none' }}>
                          <MenuItem>
                            <MenuItemCircleIcon className="circle">
                              <MenuItemIcon icon={faFileAlt} />
                            </MenuItemCircleIcon>
                            <MenuItemBoxText>
                              <MenuItemTitle>{translate('Blog')}</MenuItemTitle>
                              <MenuItemSubtitle>{translate('Acompanhe_nosso_conteudo')}</MenuItemSubtitle>
                            </MenuItemBoxText>
                          </MenuItem>

                        </Link>
                        {/* <MenuItem onClick={onOpenVideo}>
                          <MenuItemCircleIcon className="circle">
                            <MenuItemIcon icon={faPlay} />
                          </MenuItemCircleIcon>
                          <MenuItemBoxText>
                            <MenuItemTitle>{translate('Videos')}</MenuItemTitle>
                            <MenuItemSubtitle>Lorem ipsum dolor sit amet, consectetur adipiscing eli.</MenuItemSubtitle>
                          </MenuItemBoxText>
                        </MenuItem> */}
                        {/* <MenuItem onClick={onOpenTraining}>
                          <MenuItemCircleIcon className="circle">
                            <MenuItemIcon icon={faUserFriends} />
                          </MenuItemCircleIcon>
                          <MenuItemBoxText>
                            <MenuItemTitle>{translate('Treinamentos')}</MenuItemTitle>
                            <MenuItemSubtitle>Lorem ipsum dolor sit amet, consectetur adipiscing eli.</MenuItemSubtitle>
                          </MenuItemBoxText>
                        </MenuItem> */}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <ButtonMenu minwidth="60px" margins="0px 10px 0px 0px" onClick={onOpenPlans} active="false">
              {translate('Planos')}
            </ButtonMenu>
          </BoxLinks>

          <BoxButtons>
            <Button
              id="button_header_login"
              label={translate('Experimente_gratis_por_30_dias')}
              type="button"
              colors="purple"
              lineLight
              margins="0px 0px 0px 10px"
              padding="0px 20px"
              onClick={() => onAccount()}
            />
            <Button
              id="button_header_account"
              label={translate('Marque_uma_demonstracao')}
              type="button"
              colors="purple"
              margins="0px 0px 0px 20px"
              padding="0px 20px"
              onClick={() => onOpenDemo()}
            />
          </BoxButtons>
        </HeaderContent>
      </HeaderContainer>

      <CollapseSG in={openMenuApp}>
        <ContentMenuApp>
          <MenuItemApp onClick={onOpenHome}>
            <MenuItemAppCircleIcon className="circleapp">
              <MenuItemAppIcon icon={faHome} />
            </MenuItemAppCircleIcon>
            <MenuItemAppBoxText>
              <MenuItemAppTitle>{translate('Home')}</MenuItemAppTitle>
              <MenuItemAppSubtitle>{translate('Pagina_inicial')}</MenuItemAppSubtitle>
            </MenuItemAppBoxText>
          </MenuItemApp>

          {/* <Divider style={{ margin: '0px 0px 0px 0px', width: '100%' }} /> */}

          <MenuItemApp onClick={() => onAccount()}>
            <MenuItemAppCircleIcon className="circle">
              <MenuItemAppIcon icon={faStar} />
            </MenuItemAppCircleIcon>
            <MenuItemAppBoxText>
              <MenuItemAppTitle>{translate('Experimente_gratis_por_30_dias')}</MenuItemAppTitle>
              {/* <MenuItemAppSubtitle>{translate('Experimente_de_graca')}</MenuItemAppSubtitle> */}
            </MenuItemAppBoxText>
          </MenuItemApp>

          <MenuItemApp onClick={() => onOpenDemo()}>
            <MenuItemAppCircleIcon className="circle">
              <MenuItemAppIcon icon={faStar} />
            </MenuItemAppCircleIcon>
            <MenuItemAppBoxText>
              <MenuItemAppTitle>{translate('Marque_uma_demonstracao')}</MenuItemAppTitle>
              <MenuItemAppSubtitle>{translate('Conheca_a_StarGrid')}</MenuItemAppSubtitle>
            </MenuItemAppBoxText>
          </MenuItemApp>

          {/* <Divider style={{ margin: '0px 0px 0px 0px', width: '100%' }} /> */}

          <MenuItemApp onClick={onOpenSwapRequest}>
            <MenuItemAppCircleIcon className="circleapp">
              <MenuItemAppIcon icon={faRetweet} />
            </MenuItemAppCircleIcon>
            <MenuItemAppBoxText>
              <MenuItemAppTitle>{translate('Trocas_de_folgas')}</MenuItemAppTitle>
              <MenuItemAppSubtitle>{translate('Gerenciar_pedidos_de_colaboradores_na_palma_da_mao')}</MenuItemAppSubtitle>
            </MenuItemAppBoxText>
          </MenuItemApp>
          <MenuItemApp onClick={onOpenWorkschedule}>
            <MenuItemAppCircleIcon className="circle">
              <MenuItemAppIcon icon={faThLarge} />
            </MenuItemAppCircleIcon>
            <MenuItemAppBoxText>
              <MenuItemAppTitle>{translate('Escalas_de_trabalho')}</MenuItemAppTitle>
              <MenuItemAppSubtitle>{translate('Inteligencia_artificial_a_seu_favor')}</MenuItemAppSubtitle>
            </MenuItemAppBoxText>
          </MenuItemApp>
          <MenuItemApp onClick={onOpenIntegration}>
            <MenuItemAppCircleIcon className="circle">
              <MenuItemAppIcon icon={faCompressAlt} />
            </MenuItemAppCircleIcon>
            <MenuItemAppBoxText>
              <MenuItemAppTitle>{translate('Integracoes')}</MenuItemAppTitle>
              <MenuItemAppSubtitle>{translate('Flexibilidade_para_interagir_com_o_seu_sistema')}</MenuItemAppSubtitle>
            </MenuItemAppBoxText>
          </MenuItemApp>
        </ContentMenuApp>
      </CollapseSG>
    </Fragment>
  );
}

export default memo(HeaderComponent);

// ==================================================
// Estilos
// ==================================================
const HeaderContainer = styled.div`
  z-index: 999;
  transition-duration: 300ms;
  position: fixed;
  width: 100%;
  background-color: ${({ theme }) => theme.background[1]};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    min-height: 70px;
    padding: 0px 15px 0px 15px;
    box-shadow: ${props => (props.open ? 'none' : '0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)')};
  }
  @media (min-width: 600px) and (max-width: 959px) {
    min-height: 70px;
    padding: 0px 15px 0px 15px;
    box-shadow: ${props => (props.open ? 'none' : '0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)')};
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    min-height: 90px;
    padding: 0px 15px 0px 15px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-height: 90px;
    padding: 0px 15px 0px 15px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  }
  @media (min-width: 1920px) {
    min-height: 90px;
    padding: 0px 15px 0px 15px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const BoxLinksApp = styled.div`
  margin: 0px 10px 0px 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  min-width: 40px;
  max-width: 40px;

  @media (max-width: 599px) {
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: flex;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const BoxLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    flex: 1;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    flex: 1;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
  }
  @media (min-width: 1920px) {
  }
`;

const BoxLinks = styled.div`
  margin: 0px 15px 0px 50px;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const ButtonMenuApp = styled(ButtonM)`
  max-width: 50px !important;
  min-width: 50px !important;
  padding: 6px 0px !important;
  margin: 0px 0px 0px 0px !important;
  letter-spacing: 0px !important;
  color: ${props => (props.active === 'true' ? '#9751EB' : '#677EA0')} !important;
  font: normal normal normal ${props => (props.active === 'true' ? 'bold' : 'normal')} 14px/19px Noto Sans !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center!important;
  align-items: center !important;
  &:hover {
    font: normal normal bold 14px/19px Noto Sans !important;
    color: #9751EB !important;
    background: transparent !important;
    .chevron {
      color: #9751EB !important;
    }
  }
`;

const ButtonMenu = styled(ButtonM)`
  margin: ${props => (props.margins)} !important;
  letter-spacing: 0px !important;
  color: ${props => (props.active === 'true' ? '#9751EB' : '#677EA0')} !important;
  font: normal normal normal ${props => (props.active === 'true' ? 'bold' : 'normal')} 14px/19px Noto Sans !important;
  text-transform: none !important;
  min-width: ${props => (props.minwidth)} !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  &:hover {
    font: normal normal bold 14px/19px Noto Sans !important;
    color: #9751EB !important;
    background: transparent !important;
    .chevron {
      color: #9751EB !important;
    }
  }
`;

const Popper = styled(PopperM)`
  .MuiPaper-rounded {
    border-radius: 10px !important;
    box-shadow: 0px 7px 40px #00000029 !important;
    border: 1px solid #DEE6F2 !important;
  }
  .MuiList-padding {
    padding: 10px;
  }
`;

const MenuItem = styled(MenuItemM)`
  min-width: 468px !important;
  min-height: 68px !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background: #ECF1F8 !important;
    .circle {
      background: #FFFFFF !important;
    }
  }
`;

const MenuItemCircleIcon = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F3F0FB;
`;

const MenuItemIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #9751EB;
`;

const MenuItemBoxText = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px 0px 0px 20px;
`;

const MenuItemTitle = styled.div`
  font: normal normal bold 14px/19px Noto Sans;
  letter-spacing: 0px;
  color: #243E64;
`;

const MenuItemSubtitle = styled.div`
  font: normal normal normal 14px/19px Noto Sans;
  letter-spacing: 0px;
  color: #677EA0;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 9px !important;
  color: #677EA0 !important;
  margin: 4px 0px 0px 0px;
`;

const IconMenu = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 20px !important;
  color: #677EA0 !important;
  margin: 0px 10px 0px 0px;
  @media (max-width: 599px) {
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: flex;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const CollapseSG = styled(Collapse)`
  .MuiCollapse-wrapper {
    position: fixed;
    z-index: 998;
    width: 100% !important;
  }
  @media (max-width: 599px) {
    position: fixed;
    z-index: 998;
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    position: fixed;
    z-index: 998;
    display: flex;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const ContentMenuApp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 75px 15px 15px 15px;
  background-color: ${({ theme }) => theme.background[1]};
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);

  @media (max-width: 599px) {
    display: flex;
    width: 100% !important;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: flex;
    width: 100% !important;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const MenuItemApp = styled(MenuItemM)`
  min-width: 100% !important;
  min-height: 68px !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px !important;
  &:hover {
    background: #ECF1F8 !important;
    .circleapp {
      background: #FFFFFF !important;
    }
  }
`;

const MenuItemAppCircleIcon = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F3F0FB;
`;

const MenuItemAppBoxText = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px 0px 0px 20px;
`;

const MenuItemAppTitle = styled.div`
  font: normal normal bold 14px/19px Noto Sans;
  letter-spacing: 0px;
  color: #243E64;
`;

const MenuItemAppSubtitle = styled.div`
  font: normal normal normal 10px/19px Noto Sans;
  letter-spacing: 0px;
  color: #677EA0;
`;

const MenuItemAppIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #9751EB;
`;
