import { initialize } from 'redux-form';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Serviços
import ApiRD from 'services/ApiRD';
import { sendEventAnalytics } from 'services/Analytics';

// Actions
import { toMessage } from 'components/message/MessageToasterAction';

// ==================================================
// FECHAR MODAL
// ==================================================
export function closeModalDemo() {
  return {
    type: 'CLOSE_MODAL_DEMO_FETCHED',
    payload: false,
  };
}

// ==================================================
// ABRIR MODAL
// ==================================================
function openModal() {
  return {
    type: 'OPEN_MODAL_DEMO_FETCHED',
    payload: true,
  };
}
export function openModalDemo() {
  sendEventAnalytics('SITE/NEW/FORM/OPEN');

  const body = {
    '': '',
    client_tracking_id: '',
    query_params: '',
    company_name: '',
    conversion_identifier: 'nova-lp',
    internal_source: '2',
    _rd_experiment_content_id: '',
    c_utmz: '',
    traffic_source: 'encoded_eyJmaXJzdF9zZXNzaW9uIjp7InZhbHVlIjoiKG5vbmUpIiwiZXh0cmFfcGFyYW1zIjp7fX0sImN1cnJlbnRfc2Vzc2lvbiI6eyJ2YWx1ZSI6Iihub25lKSIsImV4dHJhX3BhcmFtcyI6e319LCJjcmVhdGVkX2F0IjoxNjMxNTQyNjIzMDc2fQ==',
    privacy_data: {
      browser: '',
    },
    name: '',
    cf_celular_para_contato: '',
    email: '',
    job_title: '',
    cf_segmento: '',
    cf_numero_medio_de_colaboradores: '',
    captcha: '',
    emP7yF13ld: '',
    sh0uldN07ch4ng3: '',
    redirect_to: '',
  };

  return [
    initialize('modalDemoForm', body),
    openModal(),
  ];
}

// ==================================================
// Solicita demo
// ==================================================
export function sendRequestDemo(form) {
  sendEventAnalytics('SITE/NEW/FORM/SEND');

  let counterValue = '0-50';
  if (form.cf_numero_medio_de_colaboradores === 2) counterValue = '51-100';
  if (form.cf_numero_medio_de_colaboradores === 3) counterValue = '101-300';
  if (form.cf_numero_medio_de_colaboradores === 4) counterValue = '301-600';
  if (form.cf_numero_medio_de_colaboradores === 5) counterValue = '601-1.000';
  if (form.cf_numero_medio_de_colaboradores === 6) counterValue = '+1.000';

  const body = {
    '': '',
    client_tracking_id: '',
    query_params: '',
    company_name: form.company_name,
    conversion_identifier: 'nova-lp',
    internal_source: '2',
    _rd_experiment_content_id: '',
    c_utmz: '',
    traffic_source: 'encoded_eyJmaXJzdF9zZXNzaW9uIjp7InZhbHVlIjoiKG5vbmUpIiwiZXh0cmFfcGFyYW1zIjp7fX0sImN1cnJlbnRfc2Vzc2lvbiI6eyJ2YWx1ZSI6Iihub25lKSIsImV4dHJhX3BhcmFtcyI6e319LCJjcmVhdGVkX2F0IjoxNjMxNTQyNjIzMDc2fQ==',
    privacy_data: {
      browser: '',
    },
    name: form.name,
    cf_celular_para_contato: form.cf_celular_para_contato,
    email: form.email,
    job_title: form.job_title,
    cf_segmento: form.cf_segmento,
    cf_numero_medio_de_colaboradores: counterValue,
    captcha: '',
    emP7yF13ld: '',
    sh0uldN07ch4ng3: '',
    redirect_to: '',
  };

  return (dispatch) => {
    ApiRD().post('', body)
      .then(() => {
        toMessage('INFO', translate('Solicitacao_enviada_com_sucesso'));
        initialize('modalDemoForm', {});
        dispatch(closeModalDemo());
      })
      .catch(() => {
        toMessage('ERROR', translate('Problema_ao_enviar_solicitacao'));
      })
      .finally(() => {
      });
  };
}
