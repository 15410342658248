import React, { useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridItem from 'components/grids/GridItem';

function BoxAboutUsComponent(props) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <GridItem xs={12} sm={12} md={props.col} lg={props.col} xl={props.col} alignItems="center" paddingRight paddingTop>
      <BoxItem data-aos="flip-left" data-aos-duration={(props.index * 1300).toString()}>
        <TextItem>{props.text}</TextItem>
        <BoxUserItem>
          <BoxAvatar>
            <Avatar src={props.src} alt={props.alt} />
          </BoxAvatar>
          <BoxUser>
            <Name>{props.name}</Name>
            <Job>{props.job}</Job>
          </BoxUser>
        </BoxUserItem>
      </BoxItem>
    </GridItem>
  );
}

export default memo(BoxAboutUsComponent);

// ==================================================
// Estilos
// ==================================================
const BoxItem = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 20px 40px #00000010;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
`;

const TextItem = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #677EA0;
  min-height: 100px;
  padding: 0px 0px 10px 0px;

  @media (max-width: 599px) {
    font: italic normal normal 13px/24px Roboto;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: italic normal normal 13px/24px Roboto;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: italic normal normal 13px/24px Roboto;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: italic normal normal 14px/24px Roboto;
  }
  @media (min-width: 1920px) {
    font: italic normal normal 14px/24px Roboto;
  }
`;

const BoxUserItem = styled.div`
  border-top: solid 1px #DEE6F2;
  min-height: 60px;
  width: 100%;
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const BoxAvatar = styled.div`
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 45px;
`;

const Avatar = styled.img`
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
`;

const BoxUser = styled.div`
  width: 100%;
  margin: 0px 0px 0px 15px;
`;

const Name = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #9751EB;

  @media (max-width: 599px) {
    font: normal normal bold 14px/20px Roboto;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal bold 14px/20px Roboto;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal bold 14px/20px Roboto;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal bold 16px/20px Roboto;
  }
  @media (min-width: 1920px) {
    font: normal normal bold 16px/20px Roboto;
  }
`;

const Job = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #929BA8;

  @media (max-width: 599px) {
    font: normal normal normal 12px/20px Roboto;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 12px/20px Roboto;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 12px/20px Roboto;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 14px/20px Roboto;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 14px/20px Roboto;
  }
`;
