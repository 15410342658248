import React, { memo } from 'react';
import styled from 'styled-components/macro';

// Estilos
import { whiteColor, greyColor } from 'styles/Colors';

function ScrollView100(props) {
  return (
    <ScrollView>
      {props.children}
    </ScrollView>
  );
}

export default memo(ScrollView100);

// ==================================================
// Estilos
// ==================================================
const ScrollView = styled.div`
  background:
    linear-gradient(${whiteColor} 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), ${whiteColor} 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  position: relative;
  margin-right: 0px;
  padding-right: 2px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: tranparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: ${greyColor[4]};
  }
`;
