import React, { memo } from 'react';
// import { useDispatch } from 'react-redux';

// Componentes
import Layout from 'components/layout/Layout';
// import BoxIntroduction from 'modules/blog/blogComponents/BoxIntroduction';

// import { getPostMedium } from 'modules/blog/BlogActions';

// Utilitários
// import clientConfig from 'utils/client-config';

// Serviços
// import ApiWordpress from 'services/ApiWordpress';

function BlogPage() {
  window.scrollTo(0, 0);
  // const dispatch = useDispatch();

  // const [loading, setLoading] = useState();
  // const [posts, setPosts] = useState();

  // useEffect(() => {
  //   const wordPressSiteURL = clientConfig.siteUrl;
  //   console.log('==>>', wordPressSiteURL);

  //   ApiWordpress().get('')
  //     .then((e) => {
  //       console.log('FOI', e.data);
  //     })
  //     .catch((e) => {
  //       console.log('ERRO', e.data);
  //     })
  //     .finally(() => {
  //       console.log('FIM');
  //     });
  //   // setLoading({ loading: true }, () => {
  //   //   axios.get(`${wordPressSiteURL}/wp-json/wp/v2/posts/`)
  //   //     .then((res) => {
  //   //       if (res.status === 200) {
  //   //         if (res.data.length) {
  //   //           setEmail({ loading: false, posts: res.data });
  //   //           setPosts(res.data)
  //   //         } else {
  //   //           setEmail({ loading: false, error: 'No Posts Found' });
  //   //         }
  //   //       }
  //   //     })
  //   //     .catch(err => this.setState({ loading: false, error: err }));
  //   // });
  // }, []);

  return (
    <Layout>
      Em breve...
      {/* <BoxIntroduction /> */}
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </Layout>
  );
}

export default memo(BlogPage);

// ==================================================
// Estilos
// ==================================================
