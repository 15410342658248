import React, { useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import BoxReduceYourCostsIcon from 'modules/home/homeComponents/BoxReduceYourCostsIcon';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxReduceYourCostsComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxReduceYourCostsContent>
      <BoxReduceYourCosts>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={5} md={5} lg={5} xl={5} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-right" data-aos-duration="600">{translate('Reduza_seus_custos')}</Title>
              <Subtitle data-aos="fade-up-right" data-aos-duration="600">{translate('Tenha_informacoes_valiosas_sobre_as_escalas_de_trabalho_para_auxiliar_nos_seus_indicadores_de_turnover_horas_extras_e_passivos_trabalhistas')}</Subtitle>
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={7} md={7} lg={7} xl={7} alignItems="center" justify="flex-end">
            <BoxOverflow>
              <BoxIcon data-aos="flip-left" data-aos-duration="600">
                <BoxReduceYourCostsIconSG />
              </BoxIcon>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxReduceYourCosts>
    </BoxReduceYourCostsContent>
  );
}

export default memo(BoxReduceYourCostsComponent);

// ==================================================
// Estilos
// ==================================================
const BoxReduceYourCostsContent = styled.div`
  width: 100%;
  background: transparent linear-gradient(180deg, #8E58E3 0%, #CC7AFD 100%) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const BoxReduceYourCosts = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 60px;
  color: #FFF;

  @media (max-width: 599px) {
    padding: 0px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 0px 0px 25px;
    font: bold 40px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 40px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 40px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #FFF;
  max-width: 410px;

  @media (max-width: 599px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal 18px Noto Sans;
    padding: 0px 0px 0px 25px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxReduceYourCostsIconSG = styled(BoxReduceYourCostsIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxIcon = styled.div``;

const BoxOverflow = styled.div`
  overflow: none;
`;
