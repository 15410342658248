import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';

function GridItemComponent(props) {
  return (
    <BoxGridItem
      paddingright={props.paddingRight ? '10px' : '0px'}
      paddingtop={props.paddingTop ? '10px' : '0px'}
      paddingbottom={props.paddingBottom ? '10px' : '0px'}
      container
      item
      direction={props.direction || 'row'}
      justifyContent={props.justify || 'flex-start'}
      alignItems={props.alignItems || 'flex-start'}
      xs={props.xs || 12}
      sm={props.sm || 12}
      md={props.md || 12}
      lg={props.lg || 12}
      xl={props.xl || 12}
    >
      {props.children}
    </BoxGridItem>
  );
}

export default GridItemComponent;

// ==================================================
// Estilos
// ==================================================
const BoxGridItem = styled(Grid)`
  padding-top: ${props => (props.paddingtop)} !important;
  padding-bottom: ${props => (props.paddingbottom)} !important;
  padding-right: ${props => (props.paddingright)} !important;
`;
