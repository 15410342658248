import React, { memo } from 'react';
import styled from 'styled-components/macro';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxHowItWorks() {
  return (
    <BoxSaveTimeContent>
      <BoxSaveTime>
        <Title>{translate('Politica_de_privacidade_para_StarGrid_Tecnologia_Ltda')}</Title>
        <Subtitle>
          {translate('Todas_as_suas_informacoes_pessoais_recolhidas_serao_usadas_para_o_ajudar_a_tornar_a_sua_visita_no_nosso_site_o_mais_produtiva_e_agradavel_possivel')}<br />
          {translate('A_garantia_da_confidencialidade_dos_dados_pessoais_dos_utilizadores_do_nosso_site_e_importante_para_a_StarGrid')}<br />
          {translate('Todas_as_informacoes_pessoais_relativas_a_membros_assinantes_clientes_ou_visitantes_que_usem_o_StarGrid_serao_tratadas_em_concordancia_com_a_Lei_da_Protecao_de_Dados_Pessoais_de_26_de_outubro_de_1998__Lei_n_67_98')}<br />
          {translate('A_informacao_pessoal_recolhida_pode_incluir_o_seu_nome_email_numero_de_telefone_e_ou_celular_data_de_nascimento_e_ou_outros')}<br />
          {translate('O_uso_da_StarGrid_pressupoe_a_aceitacao_deste_Acordo_de_privacidade__A_equipa_do_StarGrid_reserva_se_ao_direito_de_alterar_este_acordo_sem_aviso_previo__Deste_modo_recomendamos_que_consulte_a_nossa_politica_de_privacidade_com_regularidade_de_forma_a_estar_sempre_atualizado')}<br />
        </Subtitle>

        <Title>{translate('Os_anuncios')}</Title>
        <Subtitle>
          {translate('Tal_como_outros_websites_coletamos_e_utilizamos_informacao_contida_nos_anuncios')}<br />
          {translate('A_informacao_contida_nos_anuncios_inclui_o_seu_endereco_IP__Internet_Protocol__o_seu_ISP__Internet_Service_Provider_como_o_Sapo_Clix_ou_outro__o_browser_que_utilizou_ao_visitar_o_nosso_website__como_o_Chrome_ou_o_Firefox__o_tempo_da_sua_visita_e_que_paginas_visitou_dentro_do_nosso_website')}
        </Subtitle>

        <Title>{translate('Os_Cookies_e_Web_Beacons')}</Title>
        <Subtitle>
          {translate('Utilizamos_cookies_para_armazenar_informacao_tais_como_as_suas_preferencias_pessoas_quando_visita_o_nosso_website')}<br />
          {translate('Isto_podera_incluir_um_simples_popup_ou_uma_ligacao_em_varios_servicos_que_providenciamos_tais_como_foruns')}<br />
          {translate('Voce_detem_o_poder_de_desligar_os_seus_cookies_nas_opcoes_do_seu_browser_ou_efetuando_alteracoes_nas_ferramentas_de_programas_AntiVirus_como_o_Norton_Internet_Security')}<br />
          {translate('No_entanto_isso_podera_alterar_a_forma_como_interage_com_o_nosso_website_ou_outros_websites')}<br />
          {translate('Isso_podera_afetar_ou_nao_permitir_que_faca_logins_em_programas_sites_ou_foruns_da_nossa_e_de_outras_redes')}
        </Subtitle>

        <Title>{translate('Ligacoes_a_Sites_de_terceiros')}</Title>
        <Subtitle>
          {translate('A_StarGrid_possui_ligacoes_para_outros_sites_os_quais_a_nosso_ver_podem_conter_informacoes_ferramentas_uteis_para_os_nossos_visitantes')}<br />
          {translate('A_nossa_politica_de_privacidade_nao_e_aplicada_a_sites_de_terceiros_pelo_que_caso_visite_outro_site_a_partir_do_nosso_devera_ler_a_politica_de_privacidade_do_mesmo')}<br />
          {translate('Nao_nos_responsabilizamos_pela_politica_de_privacidade_ou_conteudo_presente_nesses_mesmos_sites')}
        </Subtitle>
      </BoxSaveTime>
    </BoxSaveTimeContent>
  );
}

export default memo(BoxHowItWorks);

// ==================================================
// Estilos
// ==================================================
const BoxSaveTimeContent = styled.div`
  width: 100%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    padding: 20px 0px;
    min-height: 510px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 20px 0px;
    min-height: 340px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 30px 0px;
    min-height: 500px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    padding: 50px 0px;
    min-height: 500px;
  }
  @media (min-width: 1920px) {
    padding: 50px 0px;
    min-height: 500px;
  }
`;

const BoxSaveTime = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 10px 15px 0px 15px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 10px 0px 0px 0px;
    font: bold 22px Noto Sans;
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 10px 15px 0px 10px;
    font: bold 32px Noto Sans;
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 0px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;

  @media (max-width: 599px) {
    padding: 0px 15px 0px 15px;
    font: normal 14px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 15px 0px 0px;
    font: normal 14px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 15px 0px 10px;
    font: normal 18px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;
