import intl from 'react-intl-universal';

// Serviços
import ApiDiscord from 'services/ApiDiscord';

// ==================================================
// Envia de tradução
// ==================================================
export function translate(value) {
  const text = intl.get(value);

  if (!text) {
    console.info('Adicionar ao vocabulário =>', value);

    ApiDiscord().post('', {
      content: 'Adicionar no arquivo de tradução SITE:',
      tts: false,
      embeds: [
        {
          type: 'rich',
          title: `${value} `,
          description: '',
          color: 0x00FFFF,
        },
      ],
    });
  }

  return text || value;
}
