import React, { memo } from 'react';

// Componentes
import Layout from 'components/layout/Layout';
import BoxIntroduction from 'modules/home/homeComponents/BoxIntroduction';
import BoxSaveTime from 'modules/home/homeComponents/BoxSaveTime';
import BoxAvoidBreakingRules from 'modules/home/homeComponents/BoxAvoidBreakingRules';
import BoxValueYourEmployees from 'modules/home/homeComponents/BoxValueYourEmployees';
import BoxReduceYourCosts from 'modules/home/homeComponents/BoxReduceYourCosts';
import BoxFacilitateEngagement from 'modules/home/homeComponents/BoxFacilitateEngagement';
import BoxAboutUs from 'modules/home/homeComponents/BoxAboutUs';

function HomePage() {
  window.scrollTo(0, 0);

  return (
    <Layout>
      <BoxIntroduction />
      <BoxSaveTime />
      <BoxAvoidBreakingRules />
      <BoxValueYourEmployees />
      <BoxReduceYourCosts />
      <BoxFacilitateEngagement />
      <BoxAboutUs />
    </Layout>
  );
}

export default memo(HomePage);

// ==================================================
// Estilos
// ==================================================
