import React, { useEffect, useState, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import Step from 'components/randomComponents/Step';
import BoxHowItWorksIcon1 from 'modules/swapRequest/swapRequestComponents/BoxHowItWorksIcon1';
import BoxHowItWorksIcon2 from 'modules/swapRequest/swapRequestComponents/BoxHowItWorksIcon2';
import BoxHowItWorksIcon3 from 'modules/swapRequest/swapRequestComponents/BoxHowItWorksIcon3';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxHowItWorks() {
  const [page, setPage] = useState(1);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxSaveTimeContent>
      <BoxSaveTime>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={7} md={7} lg={7} xl={7} alignItems="center" justify="flex-end">
            <BoxOverflow>
              {page === 1 && (
                <BoxIcon data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                  <BoxHowItWorksIcon1SG />
                </BoxIcon>
              )}

              {page === 2 && (
                <BoxIcon data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                  <BoxHowItWorksIcon2SG />
                </BoxIcon>
              )}

              {page === 3 && (
                <BoxIcon data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                  <BoxHowItWorksIcon3SG />
                </BoxIcon>
              )}
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} lg={5} xl={5} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-left" data-aos-duration="600">{translate('Como_funciona_a_troca')}</Title>
              <Subtitle data-aos="fade-left" data-aos-duration="600">{translate('A_troca_acontece_em_3_passos_simples__o_pedido_do_colaborador_o_match_com_um_colega_e_a_confirmacao_do_gestor')}</Subtitle>

              <Step active={page === 1} value={1} title={translate('Pedido_de_troca')} setPage={() => setPage(1)}>
                {translate('O_colaborador_escolhe_o_dia_em_que_quer_folgar_e_por_quais_dias_de_trabalho_esta_disposto_a_trocar')}
              </Step>
              <Step active={page === 2} value={2} title={translate('Match_com_um_colega')} setPage={() => setPage(2)}>
                {translate('Os_colegas_cadastrados_no_grid_que_tem_cargos_compativeis_aos_do_solicitante_recebem_o_pedido_de_troca')}
              </Step>
              <Step active={page === 3} value={3} title={translate('Confirmacao_do_gestor')} setPage={() => setPage(3)}>
                {translate('Apos_o_match__o_gestor_da_a_palavra_final__aceitando_ou_recusando_a_troca_com_base_em_dados_importantes__como_a_quebra_de_regras_e_a_compatibilidade')}
              </Step>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxSaveTime>
    </BoxSaveTimeContent>
  );
}

export default memo(BoxHowItWorks);

// ==================================================
// Estilos
// ==================================================
const BoxSaveTimeContent = styled.div`
  width: 100%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const BoxSaveTime = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 10px 15px 0px 15px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 10px 0px 0px 0px;
    font: bold 22px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 10px 15px 0px 10px;
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;
  margin-bottom: 20px;

  @media (max-width: 599px) {
    padding: 0px 15px 0px 15px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 15px 0px 0px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 15px 0px 10px;
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxIcon = styled.div`
  background: #F7F9FC;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    height: 260px;
    margin-right: 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 300px;
    margin-right: 50px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 520px;
    margin-right: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 520px;
    margin-right: 50px;
  }
  @media (min-width: 1920px) {
    height: 520px;
    margin-right: 50px;
  }
`;

const BoxHowItWorksIcon1SG = styled(BoxHowItWorksIcon1)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxHowItWorksIcon2SG = styled(BoxHowItWorksIcon2)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxHowItWorksIcon3SG = styled(BoxHowItWorksIcon3)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxOverflow = styled.div`
  overflow: none;
`;
