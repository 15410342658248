// Cores do sistema
const greenColor = ['#E5F5F3', '#CCEBE8', '#B2E1DD', '#99D7D2', '#7FCDC7', '#66C3BB', '#4CB9B0', '#32AFA5', '#19A59A', '#009C8F']; // [9] Cor padrao STARGRID
const blueColor = ['#E9EBEF', '#D3D8E0', '#BDC5D0', '#A7B1C1', '#919EB1', '#78849E', '#657792', '#4F6483', '#395173', '#243E64']; // [9] Cor padrao STARGRID
const greyColor = ['#F2F2F2', '#D9D9D9', '#C6C6C6', '#B3B3B3', '#A3A2A2', '#8D8D8D', '#7A7A7A', '#676767', '#545454', '#424242']; // [4] Cor padrao STARGRID
const purpleColor = ['#F2EEFB', '#E6DEF7', '#D9CDF4', '#CDBDF0', '#C1ADED', '#B49CE9', '#A88CE5', '#9B7BE2', '#8F6BDE', '#835BDB']; // [9] Cor padrao STARGRID
const redColor = ['#F8E9E9', '#F2D4D4', '#EBBFBF', '#E5A9A9', '#DE9494', '#D87F7F', '#D16969', '#CB5454', '#C43F3F', '#BE2A2A']; // [9] Cor padrao STARGRID
const orangeColor = ['#FEF4E8', '#FEE9D1', '#FEDFBB', '#FDD4A4', '#FDCA8E', '#FDBF77', '#FCB460', '#FCAA4A', '#FC9F33', '#FC951D']; // [9] Cor padrao STARGRID
const darkColor = ['#E8E9EA', '#D2D3D5', '#BBBEC0', '#A5A8AB', '#8E9396', '#787D81', '#61676C', '#4A5257', '#2B343B', '#1E272E']; // [9] Cor padrao STARGRID
const pinkColor = '#F5DAE7';
const tomatoColor = 'tomato';
const transparentColor = 'transparent';
const blackColor = '#000000';
const whiteColor = '#FFFFFF';

export {
  greenColor,
  blueColor,
  greyColor,
  purpleColor,
  redColor,
  orangeColor,
  darkColor,
  pinkColor,
  tomatoColor,
  transparentColor,
  blackColor,
  whiteColor,
};
