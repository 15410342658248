import { applyMiddleware, createStore } from 'redux';
import Promise from 'redux-promise';
import Multi from 'redux-multi';
import Thunk from 'redux-thunk';

// Estado global
import Reducers from 'state/Reducer';

// DevTools
const DevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// Middlewares
const Middlewares = applyMiddleware(Multi, Thunk, Promise)(createStore)(Reducers, DevTools);

export default Middlewares;
