import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// Estado global
import Middlewares from 'state/Middlewares';

// Aplicativo
import App from './App';

render(
  <Provider store={Middlewares}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('app'),
);
