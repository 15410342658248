import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import ModalTemplate from 'components/modal/ModalTemplate';
import LogoStarGridWhite from 'components/layout/layoutComponents/LogoStarGridWhite';
import ButtonBig from 'components/button/ButtonBig';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import Slider from 'components/slider/Slider';
import ScrollView100 from 'components/scroll/ScrollView100';
import GridContainer from 'components/grids/GridContainer';
import { ModalContent } from 'components/modal/ModalComponents';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Actions
import { closeModalDemo, sendRequestDemo } from 'components/modal/modalDemo/ModalDemoAction';
import { toMessage } from 'components/message/MessageToasterAction';

const selector = formValueSelector('modalDemoForm');

function ModalDemoPage(props) {
  const dispatch = useDispatch();

  const statusModalDemo = useSelector(state => state.modalDemoReducer.statusModalDemo);

  const Emoji = `${process.env.PUBLIC_URL}/images/emoji.png`;

  useEffect(() => {
    if (statusModalDemo) Aos.init({ duration: 2000 });
  }, [statusModalDemo]);

  if (!statusModalDemo) {
    return (<Fragment />);
  }

  const onClose = () => {
    dispatch(closeModalDemo());
  };

  const onSubmit = (data) => {
    if (!props.name || !props.cell || !props.email || !props.job || !props.company || !props.segment || !props.counter) {
      toMessage('INFO', translate('Preencha_todos_os_campos'));
    } else {
      dispatch(sendRequestDemo(data));
    }
  };

  const arraySegment = [
    { value: 'Logística', label: 'Logística' },
    { value: 'Educação', label: 'Educação' },
    { value: 'Restaurantes', label: 'Restaurantes' },
    { value: 'Comércio', label: 'Comércio' },
    { value: 'Serviços Financeiros', label: 'Serviços Financeiros' },
    { value: 'Supermercados', label: 'Supermercados' },
    { value: 'Construção', label: 'Construção' },
    { value: 'Hospital/Saúde', label: 'Hospital/Saúde' },
    { value: 'Segurança', label: 'Segurança' },
    { value: 'Call Centers', label: 'Call Centers' },
    { value: 'Hotéis', label: 'Hotéis' },
    { value: 'Varejo', label: 'Varejo' },
    { value: 'Indústria', label: 'Indústria' },
    { value: 'Tecnologia', label: 'Tecnologia' },
    { value: 'Outro', label: 'Outro' },
  ];

  return (
    <ModalTemplate
      width="1200px"
      open={statusModalDemo}
      onClose={() => onClose()}
    >
      <form onSubmit={props.handleSubmit(onSubmit)} autoComplete="off" style={{ width: '100%', marginBottom: 0 }} id="new_form">
        <ModalContent minheight="765px" maxheight="765px" flexdirection="row" justifycontent="space-between" padding="0px">
          <BoxA>
            <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
            <BoxATitle data-aos="fade-up" data-aos-duration="600">
              {translate('Vamos_comecar')}
              <Emogi src={Emoji} alt={translate('Ola')} />
            </BoxATitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 50 }}>{translate('A_StarGrid_e_uma_plataforma_de_gestao_de_escalas_de_trabalho_que_usa_inteligencia_artificial_para_que_voce_possa_parar_de_fazer_escalas_em_excel__papel_ou_whastapp__Com_os_nossos_apps_voce')}</BoxASubtitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 10 }}>{translate('Economiza_70_do_tempo_que_passa_gerenciando_escalas_de_trabalho')}</BoxASubtitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 10 }}>{translate('Diminui_a_incidencia_de_gastos_com_passivos_trabalhistas__o_turnover_e_o_absenteismo')}</BoxASubtitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 10 }}>{translate('Evita_desperdicios_com_impressao_de_papel')}</BoxASubtitle>
          </BoxA>
          <BoxB>
            <CircleClose onClick={() => onClose()}>X</CircleClose>
            <GridContainer>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="name"
                  id="name"
                  col={12}
                  label={translate('Seu_nome')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="cf_celular_para_contato"
                  id="cf_celular_para_contato"
                  col={12}
                  label={translate('Seu_celular')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="email"
                  id="email"
                  col={12}
                  label={translate('Email_corporativo')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="job_title"
                  id="job_title"
                  col={12}
                  label={translate('Cargo')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="company_name"
                  id="company_name"
                  col={12}
                  label={translate('Empresa')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="cf_segmento"
                  id="cf_segmento"
                  col={12}
                  label={translate('Segmento_da_empresa')}
                  type="text"
                  component={Select}
                  options={arraySegment}
                  meta={{ touched: '', error: '', warning: '' }}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput alignItems="center" data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="cf_numero_medio_de_colaboradores"
                  id="cf_numero_medio_de_colaboradores"
                  col={12}
                  label={translate('Quantidade_de_funcionarios')}
                  type="text"
                  component={Slider}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
            </GridContainer>
            <ButtonBig
              alignItems="center"
              id="button_modal_account_send_a"
              label={translate('Enviar')}
              type="submit"
              colors="purple"
              margins="0px 0px 0px 0px"
              padding="0px 150px"
            />
          </BoxB>

          <App>
            <ScrollView100>
              <TitleApp>
                <CircleClose onClick={() => onClose()}>X</CircleClose>
                <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
                <BoxATitle data-aos="fade-up" data-aos-duration="600">
                  {translate('Vamos_comecar')}
                  <Emogi src={Emoji} alt={translate('Ola')} />
                </BoxATitle>
                <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 20 }}>{translate('A_StarGrid_e_uma_plataforma_de_gestao_de_escalas_de_trabalho_que_usa_inteligencia_artificial_para_que_voce_possa_parar_de_fazer_escalas_em_excel__papel_ou_whastapp__Com_os_nossos_apps_voce')}</BoxASubtitle>
                <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 10 }}>{translate('Economiza_70_do_tempo_que_passa_gerenciando_escalas_de_trabalho')}</BoxASubtitle>
                <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 10 }}>{translate('Diminui_a_incidencia_de_gastos_com_passivos_trabalhistas__o_turnover_e_o_absenteismo')}</BoxASubtitle>
                <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 10 }}>{translate('Evita_desperdicios_com_impressao_de_papel')}</BoxASubtitle>
              </TitleApp>
              <BoxApp>
                <GridContainer alignItems="center">
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      col={12}
                      label={translate('Seu_nome')}
                      name="name"
                      id="name"
                      component={Input}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      name="cf_celular_para_contato"
                      id="cf_celular_para_contato"
                      col={12}
                      label={translate('Seu_celular')}
                      type="text"
                      component={Input}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      name="email"
                      id="email"
                      col={12}
                      label={translate('Email_corporativo')}
                      type="text"
                      component={Input}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      name="job_title"
                      id="job_title"
                      col={12}
                      label={translate('Cargo')}
                      type="text"
                      component={Input}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      name="company_name"
                      id="company_name"
                      col={12}
                      label={translate('Empresa')}
                      type="text"
                      component={Input}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      name="cf_segmento"
                      id="cf_segmento"
                      col={12}
                      label={translate('Segmento_da_empresa')}
                      type="text"
                      component={Select}
                      options={arraySegment}
                      meta={{ touched: '', error: '', warning: '' }}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>
                  <BoxInput data-aos="fade-left" data-aos-duration="600">
                    <Field
                      name="cf_numero_medio_de_colaboradores"
                      id="cf_numero_medio_de_colaboradores"
                      col={12}
                      label={translate('Quantidade_de_funcionarios')}
                      type="text"
                      component={Slider}
                      fullWidth
                      paddingRight={false}
                    />
                  </BoxInput>
                </GridContainer>
                <Button
                  id="button_modal_account_send_b"
                  label={translate('Enviar')}
                  type="submit"
                  colors="purple"
                  margins="20px 0px 0px 0px"
                  padding="0px 150px"
                />
              </BoxApp>
            </ScrollView100>
          </App>
        </ModalContent>
      </form>
    </ModalTemplate>
  );
}

const mapStateToProps = state => ({
  name: selector(state, 'name'),
  cell: selector(state, 'cf_celular_para_contato'),
  email: selector(state, 'email'),
  job: selector(state, 'job_title'),
  company: selector(state, 'company_name'),
  segment: selector(state, 'cf_segmento'),
  counter: selector(state, 'cf_numero_medio_de_colaboradores'),
});

export default compose(
  reduxForm({ form: 'modalDemoForm' }),
  connect(mapStateToProps),
)(ModalDemoPage);

// ==================================================
// Estilos
// ==================================================
const CircleClose = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &:hover {
    box-shadow:
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }

  @media (max-width: 599px) {
    color: #FFF;
    background: transparent;
    border: solid 1px #FFF;
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;
    border-radius: 15px;
    top: 10px;
    right: 10px;
    font-size: 9px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    color: #FFF;
    background: #transparent;
    border: solid 1px #FFF;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
`;

const BoxA = styled.div`
  background: #9751EB;
  min-width: 600px;
  max-width: 600px;
  min-height: 765px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 0px 0px 50px;
  border-radius: 10px 0px 0px 10px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const BoxB = styled.div`
  position: relative;
  background: #FFFFFF;
  flex: 1;
  min-height: 765px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 60px 20px 60px;
  border-radius: 0px 10px 10px 0px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const App = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  @media (max-width: 599px) {
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: flex;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const TitleApp = styled.div`
  position: relative;
  background: #9751EB;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  border-radius: 10px 10px 0px 0px;
`;

const BoxApp = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 15px 15px 15px;
`;

const BoxATitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    font: normal normal normal 20px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 30px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
`;

const BoxASubtitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    text-align: center;
    font: normal normal normal 12px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 30px 0px 20px 0px;
    max-width: 486px;
    text-align: center;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
`;

const Emogi = styled.img`
  @media (max-width: 599px) {
    width: 20px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 1920px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
`;

const BoxInput = styled.div`
  width: 100%;

  @media (max-width: 599px) {
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    margin: 20px 0px 0px 0px;
  }
`;
