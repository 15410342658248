import * as React from 'react';

function BoxIntroductionIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={722.691}
      height={445}
      viewBox="0 0 722.691 440"
      {...props}
    >
      <defs>
        <filter
          id="prefix_SW_a"
          x={183}
          y={0}
          width={486}
          height={445}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur" />
          <feFlood floodOpacity={0.082} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_SW_c"
          x={19}
          y={116}
          width={356}
          height={204}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-2" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_SW_e"
          x={0}
          y={98}
          width={356}
          height={204}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-3" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_SW_b"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#9f64ea" />
          <stop offset={0.409} stopColor="#9f63ea" stopOpacity={0.969} />
          <stop offset={1} stopColor="#9752eb" stopOpacity={0.043} />
          <stop offset={1} stopColor="#9751eb" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix_SW_d"
          x1={0.654}
          y1={0.207}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.67} stopColor="#cecece" stopOpacity={0.616} />
          <stop offset={1} stopColor="gray" stopOpacity={0} />
        </linearGradient>
      </defs>
      <g transform="translate(-.003 .005)" filter="url(#prefix_SW_a)">
        <rect
          data-name="Ret\xE2ngulo 2815"
          width={366}
          height={364}
          rx={180}
          transform="translate(243 50)"
          fill="url(#prefix_SW_b)"
        />
      </g>
      <g
        data-name="Grupo 20543"
        transform="translate(282.212 109)"
        opacity={0.998}
      >
        <path
          data-name="Caminho 4029"
          d="M0 337.492h90.727l7.526-20.262a28.661 28.661 0 0115.1-16.1 72.159 72.159 0 0023.986-17.991 166.663 166.663 0 0025.533-38.811l-56.224-.276s5.06-31.449-22.884-73.22a23.644 23.644 0 01-3.885-15.2l2.649-30.463s18.989-21.221 7.338-47.549c0 0-11.141-15.425-15.56-9.778 0 0-33.652 80.136-36.716 92.052s-7.149 19.4-3.064 56.854a226.977 226.977 0 011.456 24.979 88.642 88.642 0 01-8.522 37.729z"
          fill="#ffd0bb"
        />
        <path
          data-name="Caminho 4030"
          d="M84.616 285.509c-8.421-9.349-20.119-7.874-32.42-12.137S35.949 238.36 35.949 238.36c.02 1.159.029 2.286.027 3.372a88.635 88.635 0 01-8.521 37.729L.003 337.495h90.725l7.526-20.261a28.658 28.658 0 0115.1-16.1 70.612 70.612 0 0020.146-13.971c-24.996 10.497-40.463 7.695-48.884-1.654z"
          fill="#ffc0bb"
          opacity={0.2}
          style={{
            mixBlendMode: 'multiply',
            isolation: 'isolate',
          }}
        />
        <g data-name="Grupo 20542" fill="#ffd0bb">
          <path
            data-name="Caminho 4031"
            d="M213.589 14.416s19.216-12.861 23.6-4.388-24.965 38.457-24.965 38.457z"
          />
          <path
            data-name="Caminho 4032"
            d="M213.589 70.449s19.216-12.86 23.6-4.387-24.965 38.456-24.965 38.456z"
          />
          <path
            data-name="Caminho 4033"
            d="M213.589 108.628s19.216-12.86 23.6-4.387-24.965 38.456-24.965 38.456z"
          />
        </g>
        <path
          data-name="Caminho 4034"
          d="M200.317 0H94.824a14.225 14.225 0 00-14.225 14.225v215.6a14.228 14.228 0 0014.225 14.225h105.493a14.225 14.225 0 0014.225-14.225v-215.6A14.228 14.228 0 00200.317 0z"
          fill="#c9a0ff"
        />
        <path
          data-name="Caminho 4035"
          d="M90.21 181.278s-19.081 46.709-17.23 55.541 13.43 20.642 41.839 16.705a436.494 436.494 0 0048.045-9.2s1.187-63.038 0-66.234-52.267-25.887-54.277-25.887-18.377 29.075-18.377 29.075z"
          fill="#ffc0bb"
          opacity={0.2}
          style={{
            mixBlendMode: 'multiply',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Ret\xE2ngulo 2587"
          fill="#fff"
          d="M87.495 19.388h120.152v205.273H87.495z"
        />
        <path
          data-name="Caminho 4036"
          d="M172.089 12.936h-48.842a1.286 1.286 0 01-1.286-1.286 1.286 1.286 0 011.286-1.285h48.842a1.286 1.286 0 011.286 1.285 1.286 1.286 0 01-1.286 1.286z"
          fill="#fff"
        />
        <rect
          data-name="Ret\xE2ngulo 2590"
          width={88.546}
          height={121.224}
          rx={7}
          transform="translate(106.466 60.794)"
          fill="#e5e5e5"
        />
        <path
          data-name="Caminho 4037"
          d="M78.207 143.554a8.977 8.977 0 002.364 4.021l1.948-22.405s13.969-15.611 10.594-36.666a31.461 31.461 0 00-10.223-18.66c-3.13-2.75-6.567-4.589-8.593-2 0 0-15.825 37.686-26.76 65.212z"
          fill="#ffd0bb"
        />
        <path
          data-name="Caminho 4038"
          d="M187.323 154.583s-16.227 1.362-16.8 5.674.681 11.916 16 13.391h15.869s-4.748-13.164-4.521-13.618-10.548-5.447-10.548-5.447z"
          fill="#ffd0bb"
        />
        <path
          data-name="Caminho 4039"
          d="M161.285 218.911a4.4 4.4 0 00-1.715 6.1c7.467 12.88 37.161 64.063 39.838 68.226 3.064 4.766 10.554 16 35.406 21.789s35.066 22.469 35.066 22.469h76.6s-18.043-28.6-33.023-102.814c0 0-11.575-61.619-33.364-59.577 0 0-13.277-13.959-26.214-11.235 0 0-24.512-14.639-38.47-14.639l-33.528-39.624a9.7 9.7 0 00-13.247-1.569 6.354 6.354 0 00-2.59 4.084c-1.021 6.469 7.49 22.81 21.108 42.555s47.321 82.388 32.683 109.964a148.059 148.059 0 01-27.916-29.279c-10.834-15.111-23.577-20.194-30.634-16.45z"
          fill="#ffd0bb"
        />
        <path
          data-name="Caminho 4040"
          d="M215.533 149.242a139.719 139.719 0 0112.556 12.279 152.714 152.714 0 0111.093 13.656 108.655 108.655 0 019.153 15.061 56.75 56.75 0 015.534 16.679l-.1.021a64.622 64.622 0 00-6.147-16.33 122.5 122.5 0 00-9.27-14.835c-3.39-4.752-7.054-9.307-10.872-13.723-3.6-4.148-8.121-9-12.019-12.732z"
          fill="#eaa0a0"
        />
        <path
          data-name="Caminho 4041"
          d="M253.661 163.76a37.829 37.829 0 019.7 7.357 47.555 47.555 0 0111.952 21.162 46.343 46.343 0 011.539 12.075l-.1.008a59.464 59.464 0 00-2.208-11.855 53.787 53.787 0 00-11.776-20.846 45.32 45.32 0 00-9.158-7.811z"
          fill="#eaa0a0"
        />
        <path
          data-name="Caminho 4042"
          d="M279.825 174.829a33.664 33.664 0 018 9.589 42.323 42.323 0 015.644 19.934l-.1.013c-1.363-7.889-2.705-12.367-6.387-19.487a45.742 45.742 0 00-7.214-9.986z"
          fill="#eaa0a0"
        />
        <path
          data-name="Caminho 4043"
          d="M191.202 160.887c15.018 24.133 41.965 79.565 28.632 103.751l8.849 8.733s22.357-24.768-13.22-79.387a364.255 364.255 0 00-24.261-33.097z"
          fill="#ffc0bb"
          opacity={0.2}
          style={{
            mixBlendMode: 'multiply',
            isolation: 'isolate',
          }}
        />
        <rect
          data-name="Ret\xE2ngulo 2592"
          width={20.028}
          height={18.974}
          rx={9.487}
          transform="translate(176.038 29.171)"
          fill="#efefef"
        />
        <rect
          data-name="Ret\xE2ngulo 2593"
          width={88.546}
          height={17.92}
          rx={8.96}
          transform="translate(106.466 191.505)"
          fill="#9751eb"
          opacity={0.223}
        />
      </g>
      <g transform="translate(-.003 .005)" filter="url(#prefix_SW_c)">
        <rect
          data-name="Ret\xE2ngulo 2818"
          width={236}
          height={84}
          rx={10}
          transform="translate(79 166)"
          opacity={0.175}
          fill="url(#prefix_SW_d)"
        />
      </g>
      <g transform="translate(0 .004)" filter="url(#prefix_SW_e)">
        <rect
          data-name="Ret\xE2ngulo 2416"
          width={236}
          height={84}
          rx={10}
          transform="translate(60 148)"
          fill="#fff"
          opacity={0.997}
        />
      </g>
      <g data-name="Grupo 20409" transform="translate(75.591 200.818)">
        <rect
          data-name="Ret\xE2ngulo 2417"
          width={204}
          height={21}
          rx={10.5}
          transform="translate(.409 .183)"
          fill="#f2f2f2"
          opacity={0.653}
        />
        <text
          data-name="15/07"
          transform="translate(38.409 14.183)"
          fill="#1e2b3c"
          fontSize={10}
          fontFamily="Roboto-Regular, Roboto"
        >
          <tspan x={0} y={0}>
            15/07
          </tspan>
        </text>
        <text
          data-name="21/07"
          transform="translate(132.409 14.183)"
          fill="#1e2b3c"
          fontSize={10}
          fontFamily="Roboto-Regular, Roboto"
        >
          <tspan x={0} y={0}>
            21/07
          </tspan>
        </text>
        <g
          data-name="Icon feather-refresh-ccw"
          fill="none"
          stroke="#009b8d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path data-name="Caminho 2729" d="M96.312 7.499v2.412h2.412" />
          <path data-name="Caminho 2730" d="M105.157 13.932V11.52h-2.412" />
          <path
            data-name="Caminho 2731"
            d="M104.148 9.51a3.619 3.619 0 00-5.97-1.351l-1.866 1.753m8.846 1.608l-1.866 1.753a3.619 3.619 0 01-5.971-1.351"
          />
        </g>
      </g>
      <g data-name="Grupo 20760" transform="translate(75.591 160.464)">
        <rect
          data-name="Ret\xE2ngulo 2685"
          width={106}
          height={6}
          rx={3}
          transform="translate(44.409 6.537)"
          fill="#bfbfbf"
          opacity={0.297}
        />
        <rect
          data-name="Ret\xE2ngulo 2686"
          width={65}
          height={7}
          rx={3.5}
          transform="translate(44.409 17.537)"
          fill="#bfbfbf"
          opacity={0.297}
        />
        <ellipse
          data-name="Elipse 635"
          cx={15}
          cy={15.5}
          rx={15}
          ry={15.5}
          transform="translate(.409 -.463)"
          fill="rgba(151,81,235,0.45)"
        />
        <path
          data-name="Icon awesome-user-alt"
          d="M14.752 15.731a3.091 3.091 0 10-3.1-3.091 3.092 3.092 0 003.1 3.091zm2.748.687h-1.188a3.737 3.737 0 01-3.13 0H12a2.748 2.748 0 00-2.748 2.748v.343a1.031 1.031 0 001.03 1.03h8.93a1.031 1.031 0 001.03-1.03v-.343a2.748 2.748 0 00-2.747-2.748z"
          fill="#fff"
        />
      </g>
      <text
        data-name="Pedido de troca"
        transform="translate(58 109)"
        fill="#9751eb"
        fontSize={16}
        fontFamily="Roboto-Regular, Roboto"
        letterSpacing=".04em"
        opacity={0.778}
      >
        <tspan x={0} y={15}>
          Pedido de troca
        </tspan>
      </text>
      <g opacity={0.522}>
        <g data-name="Grupo 20704">
          <g data-name="Grupo 20699" fill="#dee6f2">
            <g data-name="Grupo 20696" transform="rotate(-90 456.319 -198.175)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 28.198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 19.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 10.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 .198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 28.198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 19.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 10.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 .198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 28.198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 19.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 10.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 .198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 28.198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 19.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 10.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 .198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 28.198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 19.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 10.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 .198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 28.198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 19.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 10.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 .198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 28.198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 19.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 10.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 .198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 28.198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 19.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 10.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 .198)"
              />
            </g>
            <g data-name="Grupo 20697" transform="rotate(-90 412.328 -242.167)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
        </g>
        <g data-name="Grupo 21318">
          <g data-name="Grupo 20699" fill="#dee6f2">
            <g data-name="Grupo 20696" transform="rotate(-90 474.319 -216.175)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 28.198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 19.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 10.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 .198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 28.198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 19.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 10.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 .198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 28.198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 19.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 10.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 .198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 28.198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 19.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 10.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 .198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 28.198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 19.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 10.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 .198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 28.198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 19.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 10.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 .198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 28.198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 19.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 10.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 .198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 28.198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 19.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 10.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 .198)"
              />
            </g>
            <g data-name="Grupo 20697" transform="rotate(-90 430.328 -260.167)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BoxIntroductionIcon;
