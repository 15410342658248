import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import ModalTemplate from 'components/modal/ModalTemplate';
import LogoStarGridWhite from 'components/layout/layoutComponents/LogoStarGridWhite';
import ButtonBig from 'components/button/ButtonBig';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import ScrollView100 from 'components/scroll/ScrollView100';
import GridContainer from 'components/grids/GridContainer';
import { ModalContent } from 'components/modal/ModalComponents';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Actions
import { closeModalContact, sendMessage } from 'components/modal/modalContact/ModalContactAction';
import { toMessage } from 'components/message/MessageToasterAction';

const selector = formValueSelector('modalContactForm');

function ModalContactPage(props) {
  const dispatch = useDispatch();

  const statusModalContact = useSelector(state => state.modalContactReducer.statusModalContact);

  useEffect(() => {
    if (statusModalContact) Aos.init({ duration: 2000 });
  }, [statusModalContact]);

  if (!statusModalContact) {
    return (<Fragment />);
  }

  const onClose = () => {
    dispatch(closeModalContact());
  };

  const onSubmit = (data) => {
    if (!props.email_from && !props.phone) {
      toMessage('INFO', translate('Preencha_o_telefone_ou_email'));
    } else {
      dispatch(sendMessage(data));
    }
  };

  const arrayTo = [
    { value: 'suporte', label: translate('Suporte') },
    { value: 'comercial', label: translate('Comercial') },
    { value: 'recursosHumanos', label: translate('Recursos_humanos') },
    { value: 'outro', label: translate('Outro') },
  ];

  return (
    <ModalTemplate
      width="800px"
      open={statusModalContact}
      onClose={() => onClose()}
    >
      <form onSubmit={props.handleSubmit(onSubmit)} autoComplete="off" style={{ width: '100%', marginBottom: 0 }} id="contact_form">
        <ModalContent minheight="770px" maxheight="770px" flexdirection="row" justifycontent="space-between" padding="0px">
          <BoxA>
            <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
            <BoxATitle data-aos="fade-up" data-aos-duration="600">
              {translate('Contato')}
            </BoxATitle>
            <BoxASubtitle>{translate('Envie_sua_mensagem')}</BoxASubtitle>
          </BoxA>
          <BoxB>
            <CircleClose onClick={() => onClose()}>X</CircleClose>
            <GridContainer alignItems="center">
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="name_from"
                  id="name_from"
                  col={12}
                  label={translate('Seu_nome')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="phone"
                  id="phone"
                  col={12}
                  label={translate('Seu_celular')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="email_from"
                  id="email_from"
                  col={12}
                  label={translate('Email_corporativo')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="job"
                  id="job"
                  col={12}
                  label={translate('Cargo')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="company"
                  id="company"
                  col={12}
                  label={translate('Empresa')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="department"
                  id="department"
                  col={12}
                  label={translate('Encaminhar_para')}
                  type="text"
                  component={Select}
                  options={arrayTo}
                  meta={{ touched: '', error: '', warning: '' }}
                  fullWidth
                  paddingRight={false}
                />
              </BoxInput>
              <BoxInput data-aos="fade-left" data-aos-duration="600">
                <Field
                  name="message"
                  id="message"
                  col={12}
                  label={translate('Mensagem')}
                  type="text"
                  component={Input}
                  fullWidth
                  paddingRight={false}
                  multiline
                  rows={5}
                />
              </BoxInput>
            </GridContainer>
            <ButtonBig
              id="button_modal_suport_send_a"
              label={translate('Enviar')}
              type="submit"
              colors="purple"
              margins="0px 0px 0px 0px"
              padding="0px 150px"
            />
          </BoxB>

          <App>
            <ScrollView100>
              <TitleApp>
                <CircleClose onClick={() => onClose()}>X</CircleClose>
                <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
                <BoxATitle data-aos="fade-up" data-aos-duration="600">
                  {translate('Suporte')}
                </BoxATitle>
              </TitleApp>
              <BoxApp>
                <BoxInput data-aos="fade-left" data-aos-duration="600">
                  <Field
                    name="name_from"
                    id="name_from"
                    col={12}
                    label={translate('Seu_nome')}
                    type="text"
                    component={Input}
                    fullWidth
                    paddingRight={false}
                  />
                </BoxInput>
                <BoxInput data-aos="fade-left" data-aos-duration="600">
                  <Field
                    name="phone"
                    id="phone"
                    col={12}
                    label={translate('Seu_celular')}
                    type="text"
                    component={Input}
                    fullWidth
                    paddingRight={false}
                  />
                </BoxInput>
                <BoxInput data-aos="fade-left" data-aos-duration="600">
                  <Field
                    name="email_from"
                    id="email_from"
                    col={12}
                    label={translate('Email_corporativo')}
                    type="text"
                    component={Input}
                    fullWidth
                    paddingRight={false}
                  />
                </BoxInput>
                <BoxInput data-aos="fade-left" data-aos-duration="600">
                  <Field
                    name="job"
                    id="job"
                    col={12}
                    label={translate('Cargo')}
                    type="text"
                    component={Input}
                    fullWidth
                    paddingRight={false}
                  />
                </BoxInput>
                <BoxInput data-aos="fade-left" data-aos-duration="600">
                  <Field
                    name="company"
                    id="company"
                    col={12}
                    label={translate('Empresa')}
                    type="text"
                    component={Input}
                    fullWidth
                    paddingRight={false}
                  />
                </BoxInput>
                <BoxInput data-aos="fade-left" data-aos-duration="600">
                  <Field
                    name="department"
                    id="department"
                    col={12}
                    label={translate('Encaminhar_para')}
                    type="text"
                    component={Select}
                    options={arrayTo}
                    meta={{ touched: '', error: '', warning: '' }}
                    fullWidth
                    paddingRight={false}
                  />
                </BoxInput>
                <BoxInput data-aos="fade-left" data-aos-duration="600">
                  <Field
                    name="message"
                    id="message"
                    col={12}
                    label={translate('Mensagem')}
                    type="text"
                    component={Input}
                    fullWidth
                    paddingRight={false}
                    multiline
                    rows={5}
                  />
                </BoxInput>
                <Button
                  id="button_modal_suport_send_b"
                  label={translate('Enviar')}
                  type="submit"
                  colors="purple"
                  margins="20px 0px 0px 0px"
                  padding="0px 150px"
                />
              </BoxApp>
            </ScrollView100>
          </App>
        </ModalContent>
      </form>
    </ModalTemplate>
  );
}

const mapStateToProps = state => ({
  email_from: selector(state, 'email_from'),
  phone: selector(state, 'phone'),
});

export default compose(
  reduxForm({ form: 'modalContactForm' }),
  connect(mapStateToProps),
)(ModalContactPage);

// ==================================================
// Estilos
// ==================================================
const CircleClose = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &:hover {
    box-shadow:
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }

  @media (max-width: 599px) {
    color: #FFF;
    background: transparent;
    border: solid 1px #FFF;
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;
    border-radius: 15px;
    top: 10px;
    right: 10px;
    font-size: 9px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    color: #FFF;
    background: #transparent;
    border: solid 1px #FFF;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
`;

const BoxA = styled.div`
  background: #9751EB;
  min-width: 200px;
  max-width: 200px;
  min-height: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 0px 0px 0px;
  border-radius: 10px 0px 0px 10px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const BoxB = styled.div`
  position: relative;
  background: #FFFFFF;
  flex: 1;
  min-height: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 60px 20px 60px;
  border-radius: 0px 10px 10px 0px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const App = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  @media (max-width: 599px) {
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: flex;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    display: none;
  }
`;

const TitleApp = styled.div`
  position: relative;
  background: #9751EB;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  border-radius: 10px 10px 0px 0px;
`;

const BoxApp = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 15px 15px 15px;
`;

const BoxATitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    font: normal normal normal 20px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 30px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
`;

const BoxASubtitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    text-align: center;
    font: normal normal normal 12px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 20px 0px;
    max-width: 486px;
    text-align: center;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 14px/28px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 486px;
  }
`;

const BoxInput = styled.div`
  width: 100%;

  @media (max-width: 599px) {
    margin: 0px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 20px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    margin: 20px 0px 0px 0px;
  }
`;
