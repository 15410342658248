import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Backdrop } from '@material-ui/core';
import styled from 'styled-components/macro';

// Componentes
import LoaderIcon from 'components/icons/GeneralLoader';

// Tradução
import { translate } from 'translates/TranslatesAction';

function LoaderGeneralComponent() {
  const statusLoader = useSelector(state => state.loaderGeneralReducer.setStatusLoader);

  return (
    <ModalSG
      disableEnforceFocus
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={statusLoader || false}
      onClose={() => {}}
    >
      <ContentModal>
        <LoaderIcon />
        <Subtitle>{translate('Carregando_aguarde___')}</Subtitle>
      </ContentModal>
    </ModalSG>
  );
}

export default LoaderGeneralComponent;

// ==================================================
// Estilos
// ==================================================
const ModalSG = styled(Modal)`
  z-index: 9999999 !important;
`;

const ContentModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
`;

const Subtitle = styled.div`
  font-size: 12px;
  position: absolute;
  margin-top: 110px;
`;
