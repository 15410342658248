import { createGlobalStyle } from 'styled-components/macro';

// ==================================================
// Define estilo globar StarGrid
// ==================================================
const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-variant-ligatures: none;
  }

  body {
    margin: 0;
    font-family: 'Noto+Sans', sans-serif !important;
    color: ${({ theme }) => theme.colorText[0]} !important;
    background-color: ${({ theme }) => theme.background[0]} !important;
    line-height: 1 !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

export default GlobalStyles;
