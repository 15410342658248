import * as React from 'react';

function BoxApiIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={516.531}
      height={476.616}
      viewBox="0 0 516.531 476.616"
      {...props}
    >
      <defs>
        <filter
          id="prefix_AI_a"
          x={216.134}
          y={0}
          width={185.03}
          height={185.03}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={20} result="b" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_AI_d"
          x={39.35}
          y={116.877}
          width={477.181}
          height={359.738}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="e" />
          <feFlood floodOpacity={0.102} />
          <feComposite operator="in" in2="e" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_AI_c"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#009c8f" />
          <stop offset={1} stopColor="#00ccc3" />
        </linearGradient>
        <style>{'.prefix_AI_b{fill:#9751eb}.prefix_AI_f{fill:#fff}'}</style>
      </defs>
      <g opacity={0.292}>
        <g transform="rotate(-180 85.322 154.151)">
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 .198)"
          />
          <g transform="translate(87.983)">
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 28.198)"
            />
          </g>
        </g>
        <g transform="rotate(-180 85.322 136.151)">
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(-.357 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(44.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(10.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(55.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(22.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(67.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(33.643 .198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 28.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 19.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 10.198)"
          />
          <circle
            className="prefix_AI_b"
            cx={2}
            cy={2}
            r={2}
            transform="translate(78.643 .198)"
          />
          <g transform="translate(87.983)">
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.34 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.66 28.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 .198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 9.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 18.198)"
            />
            <circle
              className="prefix_AI_b"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.66 28.198)"
            />
          </g>
        </g>
      </g>
      <g strokeDasharray={5} stroke="#677ea0" fill="none">
        <path d="M470.185 233.563h14.154V89.409h-132.7" opacity={0.656} />
        <path d="M171.309 89.409h88.372" />
      </g>
      <path
        d="M150.983 93.969v-.139a.045.045 0 00-.046-.046c0-.046-.046-.046-.046-.093l-.046-.046-7.895-5.759a.671.671 0 00-.325-.093h-5.713a.66.66 0 00-.279.093v-4.691l-.046-.046c0-.046-.046-.046-.046-.093l-.046-.046-3.715-2.694v-5.434a6.956 6.956 0 014.04-6.316 6.718 6.718 0 013.576 5.9v8.22a.488.488 0 00.232.418l3.947 2.879a.671.671 0 00.325.093h5.619a.527.527 0 00.511-.557v-11.1a11.634 11.634 0 00-11.657-11.658h-5.758a11.664 11.664 0 00-11.657 11.657v8.127a.488.488 0 00.232.418l7.848 5.712a.671.671 0 00.325.093h5.712a.66.66 0 00.279-.093v4.458a.488.488 0 00.232.418l3.715 2.694v4.551a5.751 5.751 0 01-5.573 5.759 5.837 5.837 0 01-2.043-4.412v-8.36a.692.692 0 00-.186-.418l-3.947-2.833a.671.671 0 00-.325-.093h-5.712a.543.543 0 00-.511.372v8.45a12.669 12.669 0 005.062 10.08l3.808 2.786a12.514 12.514 0 0020.109-9.985v-8.081c.046-.046.046-.093 0-.093m-8.545-5.107l6.409 4.644h-3.9l-6.41-4.644zm-14.4-7.756h3.9l2.461 1.811h-3.9zm3.576-6.223v5.155h-4.64v-5.576a6.679 6.679 0 016.641-6.687 8.068 8.068 0 011.811.232 8.124 8.124 0 00-3.808 6.873m7.709-11.1a10.584 10.584 0 0110.588 10.592v10.635h-4.6V74.418a11.622 11.622 0 00-6.827-10.588h.836zm-16.3 10.635a10.6 10.6 0 0110.541-10.585h.139a10.544 10.544 0 0110.542 10.589v10.124l-2.879-2.136v-7.945a7.735 7.735 0 10-15.465-.418v6.458a.488.488 0 00.232.418l3.715 2.694v3.529l-6.827-4.876zm12.538 13.332h-4.644v-3.808h4.644zm5.759 13.1v-4.83a.488.488 0 00-.232-.418l-3.716-2.697v-3.53l6.827 4.923v7.895a5.8 5.8 0 01-5.759 5.805 6.035 6.035 0 01-2.368-.511 6.8 6.8 0 005.248-6.641m-13.282-9.288l2.461 1.807h-3.9l-2.461-1.811zm-5.016 7.8v-7.293l2.879 2.09v8.081a12.52 12.52 0 001.718 6.316 11.682 11.682 0 01-4.6-9.2m26.935 2.841a11.476 11.476 0 01-11.514 11.472 11.649 11.649 0 01-6.734-2.183l-.232-.186a11.666 11.666 0 01-4.551-9.1v-7.8h4.644v7.8a6.852 6.852 0 0013.7 0v-7.619h4.644z"
        opacity={0.594}
        fill="#677ea0"
      />
      <g transform="translate(-.002)" filter="url(#prefix_AI_a)">
        <circle
          className="prefix_AI_f"
          cx={32.515}
          cy={32.515}
          r={32.515}
          transform="translate(276.13 57)"
        />
      </g>
      <path
        d="M36.689 15.531a6.559 6.559 0 00-6.115-8.913 6.518 6.518 0 00-3.637 1.106 10.92 10.92 0 00-20.385 5.445c0 .184.007.369.014.553a9.83 9.83 0 003.262 19.1h25.114a8.735 8.735 0 001.747-17.293z"
        transform="translate(286.81 66.51)"
        fill="url(#prefix_AI_c)"
      />
      <text
        transform="translate(295.411 91.762)"
        fontSize={15}
        fontFamily="Roboto-Bold,Roboto"
        fontWeight={700}
        fill="#fff"
      >
        <tspan x={0} y={0}>
          API
        </tspan>
      </text>
      <g transform="translate(99.35 156.878)">
        <g transform="translate(-99.35 -156.88)" filter="url(#prefix_AI_d)">
          <rect
            className="prefix_AI_f"
            width={357.181}
            height={239.738}
            rx={15}
            transform="translate(99.35 156.88)"
          />
        </g>
        <path
          d="M23.588 23.294h310a10 10 0 0110 10V49.5h-330V33.294a10 10 0 0110-10z"
          fill="#dee6f2"
        />
        <g
          transform="translate(13.588 23.294)"
          opacity={0.25}
          stroke="#677ea0"
          fill="none"
        >
          <rect width={330.975} height={200.914} rx={10} stroke="none" />
          <rect x={0.5} y={0.5} width={329.975} height={199.914} rx={9.5} />
        </g>
        <circle
          cx={2.912}
          cy={2.912}
          r={2.912}
          transform="translate(25.236 33.486)"
          fill="#ff5548"
        />
        <circle
          cx={2.912}
          cy={2.912}
          r={2.912}
          transform="translate(33 33.486)"
          fill="#ffc056"
        />
        <circle
          cx={2.912}
          cy={2.912}
          r={2.912}
          transform="translate(41.736 33.486)"
          fill="#08af5d"
        />
        <path
          d="M198.683 116.742a6.518 6.518 0 011.345 2.129 6.464 6.464 0 01.56 2.465v32.269a2.678 2.678 0 01-2.689 2.689h-37.647a2.678 2.678 0 01-2.689-2.689v-44.818a2.593 2.593 0 01.784-1.9 2.593 2.593 0 011.905-.793h25.1a6.466 6.466 0 012.465.56 6.518 6.518 0 012.129 1.345zm-12.437-6.835v10.532h10.532a3.06 3.06 0 00-.616-1.145l-8.77-8.771a3.059 3.059 0 00-1.148-.616zm10.756 42.8v-28.682H185.35a2.678 2.678 0 01-2.689-2.689v-11.653h-21.513v43.025h35.855zm-25.994-25.1a.818.818 0 01.588-.35.927.927 0 01.672.182l1.429 1.064a.818.818 0 01.35.588.927.927 0 01-.182.672l-5.1 6.807 5.1 6.807a.927.927 0 01.182.672.818.818 0 01-.35.588l-1.429 1.064a.927.927 0 01-.672.182.818.818 0 01-.588-.35l-6.33-8.427a.82.82 0 010-1.064zm22.465 8.431a.82.82 0 010 1.064l-6.331 8.431a.817.817 0 01-.588.35.927.927 0 01-.672-.182l-1.429-1.064a.818.818 0 01-.35-.588.927.927 0 01.182-.672l5.1-6.807-5.1-6.807a.928.928 0 01-.182-.672.818.818 0 01.35-.588l1.429-1.064a.927.927 0 01.672-.182.818.818 0 01.588.35zm-17.367 12.917a.819.819 0 01-.574-.364.909.909 0 01-.154-.672l3.866-23.277a.819.819 0 01.364-.574.909.909 0 01.672-.154l1.765.28a.819.819 0 01.574.364.909.909 0 01.154.672l-3.866 23.276a.819.819 0 01-.364.574.909.909 0 01-.672.154z"
          stroke="#fff"
          opacity={0.301}
          fill="#677ea0"
        />
      </g>
    </svg>
  );
}

export default BoxApiIcon;
