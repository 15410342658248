import { initialize } from 'redux-form';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Serviços
import ApiAuth from 'services/ApiAuth';

// Actions
import { toMessage } from 'components/message/MessageToasterAction';
import { setStatusLoader } from 'components/loader/loaderGeneral/LoaderGeneralAction';

// ==================================================
// Validar login
// ==================================================
function setStatus(status) {
  return {
    type: 'SIGN_UP_STATUS',
    payload: status,
  };
}
export function sendRequestAccountValidate(token) {
  const payload = {
    token,
  };

  return (dispatch) => {
    ApiAuth().post('/v4/installations/trial/validate', payload)
      .then((data) => {
        dispatch(setStatus(data.data.success));
        if (data.data.success) {
          const body = {
            token: data.data.data.token,
            installation: {
              name: '',
              type: 'TRIAL',
              indication: '',
            },
            user: {
              name: '',
              email: data.data.data.email,
              phone_number: '',
              password: '',
              password_confirm: '',
            },
          };
          dispatch(initialize('signUpAccountForm', body));
        } else {
          toMessage('', data.data.message);
        }
      })
      .catch((e) => {
        toMessage(e.response, translate('Problema_ao_validar_email'));
        dispatch(setStatus(false));
      })
      .finally(() => {
        dispatch(setStatusLoader(false));
      });
  };
}

// ==================================================
// Solicita login
// ==================================================
function setRequestAccount(value) {
  return {
    type: 'STATUS_ACCOUNT_FETCHED',
    payload: value,
  };
}
export function sendRequestAccount(payload) {
  return (dispatch) => {
    ApiAuth().post('/v4/installations', payload)
      .then(() => {
        dispatch(setRequestAccount(true));
      })
      .catch((e) => {
        toMessage(e.response, translate('Problema_ao_realizar_cadastro'));
        dispatch(setRequestAccount(false));
      })
      .finally(() => {
        dispatch(setStatusLoader(false));
      });
  };
}
