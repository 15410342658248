import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={652}
      height={445}
      viewBox="0 0 500 450"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix_III_a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#9f64ea" />
          <stop offset={0.394} stopColor="#9f63ea" stopOpacity={0.969} />
          <stop offset={1} stopColor="#9752eb" stopOpacity={0} />
          <stop offset={1} stopColor="#9751eb" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix_III_d"
          x1={0.5}
          y1={0.119}
          x2={1.519}
          y2={0.749}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#8e58e3" />
          <stop offset={1} stopColor="#cc7afd" />
        </linearGradient>
        <filter
          id="prefix_III_b"
          x={166}
          y={0}
          width={486}
          height={484}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="c" />
          <feFlood floodOpacity={0.082} />
          <feComposite operator="in" in2="c" />
          <feComposite in="SourceGraphic" />
        </filter>
        <style>
          {
            '.prefix_III_k,.prefix_III_l{fill:none}.prefix_III_k{stroke:#fff;stroke-dasharray:5}.prefix_III_l{stroke:#ca9ffd;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}'
          }
        </style>
      </defs>
      <g transform="translate(-.003 .005)" filter="url(#prefix_III_b)">
        <rect
          width={366}
          height={364}
          rx={180}
          transform="translate(226 50)"
          fill="url(#prefix_III_a)"
        />
      </g>
      <path
        d="M28.737 103.951l4.23.748-4.662-6.538-6.615 4.545 4.235.748a8.542 8.542 0 01-9.94 6.815 8.653 8.653 0 01-3.777-1.667l-2.4 1.647a11.449 11.449 0 0018.93-6.298zm-19.745-3.488a8.543 8.543 0 019.93-6.815 8.66 8.66 0 013.778 1.666l2.4-1.647a11.19 11.19 0 00-5.688-2.79A11.39 11.39 0 006.17 99.965l-4.236-.748 4.663 6.537 6.62-4.544z"
        fill="#ca9ffd"
      />
      <g transform="translate(46.292 187.625)">
        <rect
          width={316.429}
          height={207.316}
          rx={5}
          transform="translate(37.271 -.1)"
          fill="#c39ef3"
        />
        <path fill="#fff" d="M50.001 14.448h287.332V189.03H50.001z" />
        <path
          d="M34.883 219.621a3.338 3.338 0 01-.967-.144l-31.551-9.572a3.333 3.333 0 01.968-6.522h385.71a3.333 3.333 0 011.213 6.437l-24.477 9.571a3.312 3.312 0 01-1.214.229z"
          fill="#b085e6"
        />
        <circle
          cx={5.456}
          cy={5.456}
          r={5.456}
          transform="translate(64.55 28.997)"
          fill="#ff5548"
        />
        <circle
          cx={5.456}
          cy={5.456}
          r={5.456}
          transform="translate(79.098 28.997)"
          fill="#ffc056"
        />
        <circle
          cx={5.456}
          cy={5.456}
          r={5.456}
          transform="translate(95.465 28.997)"
          fill="#08af5d"
        />
        <path
          d="M63.222 63.631v-.173a.056.056 0 00-.058-.058c0-.058-.058-.058-.058-.115l-.058-.058-9.8-7.147a.833.833 0 00-.4-.115h-7.089a.819.819 0 00-.346.115v-5.82l-.058-.058c0-.058-.058-.058-.058-.115l-.058-.058-4.611-3.343v-6.743a8.633 8.633 0 015.015-7.843 8.337 8.337 0 014.438 7.32v10.2a.605.605 0 00.288.519l4.9 3.573a.833.833 0 00.4.115h6.974a.654.654 0 00.634-.692V39.367A14.439 14.439 0 0048.813 24.9h-7.146A14.476 14.476 0 0027.2 39.367v10.086a.605.605 0 00.288.519l9.74 7.089a.833.833 0 00.4.115h7.089a.819.819 0 00.346-.115v5.533a.605.605 0 00.288.519l4.611 3.343V72.1a7.137 7.137 0 01-6.912 7.15 7.244 7.244 0 01-2.536-5.475V63.4a.858.858 0 00-.231-.519l-4.9-3.516a.833.833 0 00-.4-.115h-7.091a.674.674 0 00-.634.461V70.2a15.723 15.723 0 006.282 12.508l4.726 3.458a15.53 15.53 0 0024.956-12.391V63.746c.058-.058.058-.115 0-.115m-10.6-6.34l7.954 5.764H55.73l-7.954-5.764zM34.75 47.666h4.841l3.055 2.248H37.8zm4.438-7.723v6.4h-5.763v-6.919a8.29 8.29 0 018.242-8.3 10.013 10.013 0 012.248.288 10.082 10.082 0 00-4.726 8.53m9.567-13.775A13.135 13.135 0 0161.9 39.309v13.2h-5.709V39.367a14.423 14.423 0 00-8.472-13.141h1.037zm-20.23 13.2a13.16 13.16 0 0113.083-13.141h.173a13.086 13.086 0 0113.083 13.141v12.564l-3.573-2.651v-9.856a9.6 9.6 0 10-19.192-.519v8.011a.605.605 0 00.288.519L37 50.778v4.38l-8.472-6.052zm15.561 16.541h-5.763v-4.726h5.764zm7.147 16.253v-5.994a.605.605 0 00-.288-.519l-4.611-3.343v-4.38l8.472 6.109v9.8a7.2 7.2 0 01-7.147 7.2 7.49 7.49 0 01-2.939-.634 8.441 8.441 0 006.513-8.242M34.75 60.634l3.05 2.248h-4.836l-3.055-2.248zm-6.225 9.683v-9.049l3.575 2.594V73.89a15.538 15.538 0 002.133 7.838 14.5 14.5 0 01-5.706-11.412m33.429 3.516a14.242 14.242 0 01-14.295 14.237A14.457 14.457 0 0139.3 85.36l-.288-.231a14.478 14.478 0 01-5.648-11.3V64.15h5.764v9.683a8.5 8.5 0 0017 0V64.38H61.9z"
          transform="translate(150.947 44.622)"
          fill="url(#prefix_III_d)"
        />
        <circle
          cx={24}
          cy={24}
          r={24}
          transform="translate(190.375 91.9)"
          fill="#9751eb"
          opacity={0.126}
        />
      </g>
      <path
        className="prefix_III_k"
        d="M64.432 267.869H17.463V135.025M420.526 267.868h46.969V195.38M198.136 111.589h-46.969v72.488M322.98 177.025l.188-85"
      />
      <path
        className="prefix_III_l"
        d="M239.48 115.775l9-9-9-9M227.48 97.775l-9 9 9 9M476.5 162.025h-1.89a12 12 0 10-11.61 15h13.5a7.5 7.5 0 000-15z"
      />
      <g>
        <path
          className="prefix_III_l"
          d="M336.667 67.275v6a3 3 0 01-3 3h-21a3 3 0 01-3-3v-6M330.667 56.775l-7.5-7.5-7.5 7.5M323.167 49.275v18"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
