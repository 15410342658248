import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxIntroductionComponent() {
  return (
    <BoxIntroductionContent>
      <BoxIntroduction>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={6} md={5} lg={5} xl={5} direction="column" alignItems="flex-start">
            <Title data-aos="fade-down-right" data-aos-duration="600">{translate('Politica_de_privacidade')}</Title>
          </GridItem>
          <GridItem xs={12} sm={6} md={7} lg={7} xl={7} direction="column" alignItems="center">
            <IconLock icon={faUserLock} data-aos="fade-left" data-aos-duration="600" />
          </GridItem>
        </GridContainer>
      </BoxIntroduction>
    </BoxIntroductionContent>
  );
}

export default memo(BoxIntroductionComponent);

// ==================================================
// Estilos
// ==================================================
const BoxIntroductionContent = styled.div`
  width: 100%;
  background: #9751EB 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 599px) {
    min-height: 445px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    min-height: 330px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    min-height: 445px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-height: 445px;
  }
  @media (min-width: 1920px) {
    min-height: 445px;
  }
`;

const BoxIntroduction = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    padding: 0px 20px 0px 20px;
    margin: 0px 0px 15px 0px;
    font: normal normal bold 30px/52px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 20px 0px 20px;
    margin: 0px 0px 10px 0px;
    font: normal normal bold 30px/52px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 25px 0px 25px;
    margin: 0px 0px 20px 0px;
    font: normal normal bold 40px/52px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 0px 0px 20px 0px;
    font: normal normal bold 40px/52px Noto Sans;
  }
  @media (min-width: 1920px) {
    margin: 0px 0px 20px 0px;
    font: normal normal bold 40px/52px Noto Sans;
  }
`;

// const Subtitle = styled.div`
//   letter-spacing: 0px;
//   color: #FFFFFF;
//   margin: 0px 0px 60px 0px;

//   @media (max-width: 599px) {
//     padding: 0px 20px 0px 20px;
//     margin: 0px 0px 20px 0px;
//     font: normal normal normal 14px/28px Noto Sans;
//   }
//   @media (min-width: 600px) and (max-width: 959px) {
//     padding: 0px 20px 0px 20px;
//     margin: 0px 0px 20px 0px;
//     font: normal normal normal 14px/28px Noto Sans;
//   }
//   @media (min-width: 960px) and (max-width: 1279px) {
//     padding: 0px 25px 0px 25px;
//     margin: 0px 0px 80px 0px;
//     font: normal normal normal 18px/28px Noto Sans;
//   }
//   @media (min-width: 1280px) and (max-width: 1919px) {
//     margin: 0px 0px 6px 0px;
//     font: normal normal normal 18px/28px Noto Sans;
//   }
//   @media (min-width: 1920px) {
//     margin: 0px 0px 6px 0px;
//     font: normal normal normal 18px/28px Noto Sans;
//   }
// `;

const IconLock = styled(FontAwesomeIcon)`
  color: #FFF;

  @media (max-width: 599px) {
    margin: 0px 0px 0px 20px;
    font-size: 200px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin: 0px 0px 0px 20px;
    font-size: 200px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin: 0px 0px 0px 25px;
    font-size: 200px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 0px 0px 0px 0px;
    font-size: 200px;
  }
  @media (min-width: 1920px) {
    font-size: 200px;
    margin: 0px 0px 0px 0px;
  }
`;
