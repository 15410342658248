import React from 'react';
import { Slider } from '@material-ui/core';
import styled from 'styled-components/macro';

// Componentes
import GridItem from 'components/grids/GridItem';

// Estilos
import { purpleColor } from 'styles/Colors';

function SliderComponent(props) {
  let newValue = 0;
  if (props.input.value !== undefined && props.input.value !== '') newValue = props.input.value;

  let paddingRight = true;
  if (props.paddingRight === false) paddingRight = false;

  let paddingTop = true;
  if (props.paddingTop === false) paddingTop = false;

  const onChange = (event, value) => {
    props.input.onChange(value);
  };

  const marks = [
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
    {
      value: 5,
    },
    {
      value: 6,
    },
  ];

  return (
    <GridItem xs={props.col} sm={props.col} md={props.col} lg={props.col} xl={props.col} direction={props.direction || 'column'} alignItems={props.alignItems || 'center'} paddingRight={paddingRight} paddingTop={paddingTop}>
      {props.label && (
        <Label visible={!!props.label}>{props.label}</Label>
      )}
      <SliderSG
        marks={marks}
        disabled={props.disabled}
        id={props.id || 'slider'}
        name={props.name}
        step={1}
        min={1}
        max={6}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="off"
        value={newValue}
        onChange={(e, v) => onChange(e, v)}
      />
      <Subtitles>
        <Subtitle>0-50</Subtitle>
        <Subtitle>51-100</Subtitle>
        <Subtitle>101-300</Subtitle>
        <Subtitle>301-600</Subtitle>
        <Subtitle>601-1000</Subtitle>
        <Subtitle>+1000</Subtitle>
      </Subtitles>
    </GridItem>
  );
}

export default SliderComponent;

// ==================================================
// Estilos
// ==================================================
const SliderSG = styled(Slider)`
  margin: 5px 0px 0px 0px;
  color: ${purpleColor[9]} !important;
  .MuiSlider-mark {
    margin-top: -10px !important;
    width: 1px !important;
    height: 24px !important;
    position: absolute !important;
    border-radius: 1px !important;
    background-color: #B3BFD0 !important;
}
`;

const Label = styled.div`
  width: 100%;
  justify-content: flex-start;
  display: flex;
  font: normal normal normal 16px/28px Noto Sans;
  letter-spacing: 0px;
  color: #677EA0;
`;

const Subtitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  font: normal normal normal 15px/20px Noto Sans;
  letter-spacing: 0px;
  color: #121212;
`;
