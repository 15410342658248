import * as React from 'react';

function BoxWorkscheduleTypeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={792}
      height={436}
      viewBox="0 0 792 436"
      {...props}
    >
      <defs>
        <filter
          id="prefix_WTI_a"
          x={0}
          y={133}
          width={218}
          height={170}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="b" />
          <feFlood floodOpacity={0.071} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_WTI_c"
          x={551}
          y={133}
          width={241}
          height={182}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="d" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="d" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_WTI_e"
          x={390}
          y={273}
          width={204}
          height={163}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="f" />
          <feFlood floodOpacity={0.071} />
          <feComposite operator="in" in2="f" />
          <feComposite in="SourceGraphic" />
        </filter>
        <style>
          {
            '.prefix_WTI_c,.prefix_WTI_x{fill:#9751eb}.prefix_WTI_d,.prefix_WTI_m,.prefix_WTI_t,.prefix_WTI_u{fill:#fff}.prefix_WTI_m{font-size:12px}.prefix_WTI_h{fill:#bfbfbf;opacity:.527}.prefix_WTI_i{fill:rgba(151,81,235,.44)}.prefix_WTI_j{fill:#dbdbdb;opacity:.608}.prefix_WTI_m{font-family:NotoSans,Noto Sans}.prefix_WTI_n{fill:#a273e7}.prefix_WTI_o{fill:#e9e9e9}.prefix_WTI_p{fill:#e7ccff}.prefix_WTI_q{fill:#f0f0f0}.prefix_WTI_r{fill:none;stroke:#e7ccff;stroke-miterlimit:10;stroke-width:4px}.prefix_WTI_u{stroke:#fff}.prefix_WTI_t{font-size:9px;font-family:Roboto-Bold,Roboto;font-weight:700}.prefix_WTI_u,.prefix_WTI_x{stroke-width:.5px}.prefix_WTI_x{stroke:#9751eb}'
          }
        </style>
      </defs>
      <g transform="translate(-646 -1315)">
        <circle
          cx={92.5}
          cy={92.5}
          r={92.5}
          transform="translate(755 1482)"
          fill="#f1e7fc"
          opacity={0.452}
        />
        <g opacity={0.212}>
          <g transform="rotate(180 675.322 691.599)">
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 .198)"
            />
            <g transform="translate(87.983)">
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
          <g transform="rotate(180 675.322 673.599)">
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(-.357 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(44.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(10.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(55.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(22.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(67.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(33.643 .198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 28.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 19.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 10.198)"
            />
            <circle
              className="prefix_WTI_c"
              cx={2}
              cy={2}
              r={2}
              transform="translate(78.643 .198)"
            />
            <g transform="translate(87.983)">
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                className="prefix_WTI_c"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
        </g>
        <g transform="translate(38 1065)">
          <rect
            className="prefix_WTI_d"
            width={510}
            height={276}
            rx={10}
            transform="translate(782 284)"
          />
          <text
            transform="translate(871 324)"
            fill="#2a2e43"
            fontSize={20}
            fontFamily="Roboto-Light,Roboto"
            fontWeight={300}
          >
            <tspan x={0} y={0}>
              Seu Setor
            </tspan>
          </text>
          <text
            transform="translate(871 340)"
            fontFamily="Roboto-Regular,Roboto"
            fontSize={12}
            stroke="transparent"
            fill="#9751eb"
          >
            <tspan x={0} y={0}>
              Total de 1 turno gerado.
            </tspan>
          </text>
          <rect
            width={476}
            height={20}
            rx={10}
            transform="translate(796 364)"
            fill="#b178f4"
          />
          <g transform="translate(796 403.646)">
            <rect
              className="prefix_WTI_h"
              width={51}
              height={7}
              rx={3.5}
              transform="translate(33 .354)"
            />
            <rect
              className="prefix_WTI_h"
              width={26}
              height={8}
              rx={4}
              transform="translate(33 13.354)"
            />
            <circle
              className="prefix_WTI_i"
              cx={10}
              cy={10}
              r={10}
              transform="translate(0 .354)"
            />
            <path
              className="prefix_WTI_d"
              d="M10 10.816a2.081 2.081 0 10-2.081-2.081A2.082 2.082 0 0010 10.816zm1.85.462h-.8a2.516 2.516 0 01-2.107 0h-.8A1.85 1.85 0 006.3 13.129v.231a.694.694 0 00.694.694h6.012a.694.694 0 00.694-.694v-.231a1.85 1.85 0 00-1.85-1.85z"
            />
          </g>
          <g transform="translate(907 399.646)">
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(100 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(235 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(34 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(168 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(302 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(0 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(134 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(269 3.354)"
            />
            <rect
              width={25}
              height={26}
              rx={5}
              transform="translate(67 3.354)"
              fill="#6719d7"
              opacity={0.608}
            />
            <rect
              width={26}
              height={26}
              rx={5}
              transform="translate(201 3.354)"
              fill="#f9931d"
              opacity={0.608}
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(336 3.354)"
            />
            <text className="prefix_WTI_m" transform="translate(80 21.354)">
              <tspan x={-6.258} y={0}>
                FL
              </tspan>
            </text>
            <text className="prefix_WTI_m" transform="translate(214 21.354)">
              <tspan x={-7.014} y={0}>
                FB
              </tspan>
            </text>
          </g>
          <g transform="translate(796 450.646)">
            <rect
              className="prefix_WTI_h"
              width={51}
              height={7}
              rx={3.5}
              transform="translate(33 .354)"
            />
            <rect
              className="prefix_WTI_h"
              width={26}
              height={8}
              rx={4}
              transform="translate(33 13.354)"
            />
            <circle
              className="prefix_WTI_i"
              cx={10}
              cy={10}
              r={10}
              transform="translate(0 .354)"
            />
            <path
              className="prefix_WTI_d"
              d="M10 10.816a2.081 2.081 0 10-2.081-2.081A2.082 2.082 0 0010 10.816zm1.85.462h-.8a2.516 2.516 0 01-2.107 0h-.8A1.85 1.85 0 006.3 13.129v.231a.694.694 0 00.694.694h6.012a.694.694 0 00.694-.694v-.231a1.85 1.85 0 00-1.85-1.85z"
            />
          </g>
          <g transform="translate(907 446.646)">
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(100 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(235 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(34 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(168 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(302 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(0 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(134 3.354)"
            />
            <rect
              className="prefix_WTI_n"
              width={26}
              height={26}
              rx={5}
              transform="translate(269 3.354)"
            />
            <rect
              className="prefix_WTI_o"
              width={25}
              height={26}
              rx={5}
              transform="translate(67 3.354)"
            />
            <rect
              className="prefix_WTI_o"
              width={26}
              height={26}
              rx={5}
              transform="translate(201 3.354)"
            />
            <rect
              className="prefix_WTI_j"
              width={26}
              height={26}
              rx={5}
              transform="translate(336 3.354)"
            />
            <text className="prefix_WTI_m" transform="translate(282 21.354)">
              <tspan x={-6.258} y={0}>
                FL
              </tspan>
            </text>
          </g>
          <g>
            <g transform="translate(796 497.646)">
              <rect
                className="prefix_WTI_h"
                width={51}
                height={7}
                rx={3.5}
                transform="translate(33 .354)"
              />
              <rect
                className="prefix_WTI_h"
                width={26}
                height={8}
                rx={4}
                transform="translate(33 13.354)"
              />
              <circle
                className="prefix_WTI_i"
                cx={10}
                cy={10}
                r={10}
                transform="translate(0 .354)"
              />
              <path
                className="prefix_WTI_d"
                d="M10 10.816a2.081 2.081 0 10-2.081-2.081A2.082 2.082 0 0010 10.816zm1.85.462h-.8a2.516 2.516 0 01-2.107 0h-.8A1.85 1.85 0 006.3 13.129v.231a.694.694 0 00.694.694h6.012a.694.694 0 00.694-.694v-.231a1.85 1.85 0 00-1.85-1.85z"
              />
            </g>
            <g transform="translate(907 493.646)">
              <rect
                className="prefix_WTI_j"
                width={26}
                height={26}
                rx={5}
                transform="translate(100 3.354)"
              />
              <rect
                className="prefix_WTI_j"
                width={26}
                height={26}
                rx={5}
                transform="translate(235 3.354)"
              />
              <rect
                className="prefix_WTI_j"
                width={26}
                height={26}
                rx={5}
                transform="translate(34 3.354)"
              />
              <rect
                className="prefix_WTI_n"
                width={26}
                height={26}
                rx={5}
                transform="translate(168 3.354)"
              />
              <rect
                className="prefix_WTI_j"
                width={26}
                height={26}
                rx={5}
                transform="translate(302 3.354)"
              />
              <rect
                className="prefix_WTI_j"
                width={26}
                height={26}
                rx={5}
                transform="translate(0 3.354)"
              />
              <rect
                className="prefix_WTI_j"
                width={26}
                height={26}
                rx={5}
                transform="translate(134 3.354)"
              />
              <rect
                className="prefix_WTI_o"
                width={26}
                height={26}
                rx={5}
                transform="translate(269 3.354)"
              />
              <rect
                className="prefix_WTI_o"
                width={25}
                height={26}
                rx={5}
                transform="translate(67 3.354)"
              />
              <rect
                className="prefix_WTI_o"
                width={26}
                height={26}
                rx={5}
                transform="translate(201 3.354)"
              />
              <rect
                className="prefix_WTI_j"
                width={26}
                height={26}
                rx={5}
                transform="translate(336 3.354)"
              />
              <text className="prefix_WTI_m" transform="translate(181 21.354)">
                <tspan x={-6.258} y={0}>
                  FL
                </tspan>
              </text>
            </g>
          </g>
          <g>
            <path
              className="prefix_WTI_p"
              d="M833.108 339.01v-2.7a1.578 1.578 0 011.576-1.576h2.7zM850.558 330.656l-.767.768-3.409-3.409.768-.767a1.809 1.809 0 012.559 0l.85.85a1.812 1.812 0 01-.001 2.558zM822.935 310.071a.528.528 0 00.528-.528v-1.553a2.577 2.577 0 015.154 0v1.552a.528.528 0 00.528.528h5.165v3.084h-16.54v-3.083z"
            />
            <g transform="translate(796 304.838)">
              <path
                className="prefix_WTI_q"
                d="M6.967 23.994a.2.2 0 01-.242-.175 3.011 3.011 0 011.8-3.085 2.186 2.186 0 112.05-.447 3.012 3.012 0 012.919 2.058.2.2 0 01-.147.26l-6.379 1.389zM8.339 30.283a.488.488 0 00.581.373l5.419-1.18a.488.488 0 00-.208-.954l-5.423 1.176a.487.487 0 00-.369.585zM15.085 26.102a.479.479 0 00-.2 0l-7.857 1.711a.488.488 0 00.208.954l7.842-1.708q-.002-.478.007-.957zM6.182 26.241a.488.488 0 00.581.373l7.857-1.711a.488.488 0 00-.208-.954L6.555 25.66a.487.487 0 00-.373.581z"
              />
              <path
                className="prefix_WTI_q"
                d="M15.117 30.798L5.04 32.992 1.045 14.649l4.5-.98.293 1.344a.228.228 0 00.271.174l4.353-.948a.228.228 0 00.174-.271l-.293-1.344 4.035-.878c-.02-.337-.034-.674-.045-1.012l-4.2.915-.744-3.419a.228.228 0 00-.271-.174l-4.353.948a.228.228 0 00-.174.271l.744 3.419-4.744 1.033a.749.749 0 00-.572.891l4.098 18.831a.748.748 0 00.891.572l10.145-2.209q-.021-.508-.036-1.014zm-6.541-21.6l.581 2.666.212.975.129.592-2.848.62-.129-.592-.212-.975-.581-2.666z"
              />
              <path
                className="prefix_WTI_q"
                d="M8.495 11.172a.956.956 0 11-1.138-.731.956.956 0 011.138.731z"
              />
              <path
                className="prefix_WTI_p"
                d="M26.648 12.659h7.043a.488.488 0 000-.976h-7.043a.488.488 0 100 .976zM26.648 17.4h10.785a.488.488 0 000-.976H26.648a.488.488 0 100 .976zM26.648 22.14h7.043a.488.488 0 000-.976h-7.043a.488.488 0 100 .976zM26.648 26.88h10.785a.488.488 0 000-.976H26.648a.488.488 0 100 .976zM26.648 31.621h7.043a.488.488 0 000-.976h-7.043a.488.488 0 100 .976z"
              />
              <rect
                className="prefix_WTI_r"
                width={2.495}
                height={2.495}
                rx={1.248}
                transform="translate(21.364 10.924)"
              />
              <rect
                className="prefix_WTI_r"
                width={2.495}
                height={2.495}
                rx={1.248}
                transform="translate(21.364 15.664)"
              />
              <rect
                className="prefix_WTI_r"
                width={2.495}
                height={2.495}
                rx={1.248}
                transform="translate(21.364 20.405)"
              />
              <rect
                className="prefix_WTI_r"
                width={2.495}
                height={2.495}
                rx={1.248}
                transform="translate(21.364 25.145)"
              />
              <rect
                className="prefix_WTI_r"
                width={2.495}
                height={2.495}
                rx={1.248}
                transform="translate(21.364 29.886)"
              />
              <g>
                <path
                  className="prefix_WTI_q"
                  d="M53.331 26.812l-2.051 7.861a.246.246 0 01-.3.179l-4.832-1.261a1.951 1.951 0 01-.386.931l4.966 1.3a1.245 1.245 0 001.522-.892l2.23-8.545a1.756 1.756 0 01-1.149.427zM58.828 8.25a1.241 1.241 0 00-.761-.576l-12.376-3.23a1.934 1.934 0 01.036 1.041L57.82 8.64a.25.25 0 01.178.3l-3.747 14.359a1.769 1.769 0 01.758 1.046l3.954-15.152a1.24 1.24 0 00-.135-.943z"
                />
                <path
                  className="prefix_WTI_q"
                  d="M53.7 10.981l-8.016-2.092c.005.337.01.675.013 1.012l7.756 2.024a.488.488 0 00.247-.944zM52.81 14.392l-7.133-1.862q-.016.5-.045 1l6.932 1.809a.488.488 0 00.246-.945zM51.109 20.92l-5.871-1.532a2.178 2.178 0 01-.007 1.007l5.632 1.47a.488.488 0 00.246-.945zM51.978 17.583l-6.5-1.7a2.2 2.2 0 010 .237 2.158 2.158 0 01-.084.75l6.342 1.655a.488.488 0 00.246-.944zM49.264 24.929a1.992 1.992 0 01.34-.92l-4.132-1.078a2.14 2.14 0 01-.141.972z"
                />
                <g>
                  <path d="M46.265 27.939a2 2 0 01.332-.922l-1.455-.38a2.169 2.169 0 01.043 1.02z" />
                </g>
              </g>
              <path
                className="prefix_WTI_c"
                d="M44.864 35.417v1.752a1.108 1.108 0 01-1.107 1.106H16.326a1.109 1.109 0 01-1.107-1.106V3.997a1.108 1.108 0 011.107-1.107h10.391a3.391 3.391 0 00-.033.469v.947h-4.775a.488.488 0 00-.488.488v1.512h-2.194a1.287 1.287 0 00-1.285 1.286v25.983a1.287 1.287 0 001.285 1.285h17.467a1.557 1.557 0 00.193-.014.516.516 0 00.116.014.487.487 0 00.367-.168 1.447 1.447 0 00.346-.256l4-4a1.436 1.436 0 00.423-1.022V7.592a1.287 1.287 0 00-1.285-1.286h-2.193V4.794a.488.488 0 00-.488-.488h-4.775v-.947a3.391 3.391 0 00-.033-.469h10.39a1.108 1.108 0 011.107 1.107v24.376a1.745 1.745 0 01.409-.148 1.7 1.7 0 01.567-.245V3.997a2.085 2.085 0 00-2.083-2.083H33.073a3.358 3.358 0 00-6.063 0H16.326a2.085 2.085 0 00-2.083 2.083v33.172a2.086 2.086 0 002.083 2.082h27.431a2.085 2.085 0 002.083-2.082v-2.65a1.643 1.643 0 01-.461.239 1.736 1.736 0 01-.515.659zm-7.373-2.136v-1.9a1.108 1.108 0 011.107-1.107h1.9zm1.172-24.659v-1.34h2.193a.31.31 0 01.309.31v21.71h-2.567a2.085 2.085 0 00-2.083 2.083v2.5H19.227a.31.31 0 01-.309-.31V7.592a.31.31 0 01.309-.31h2.19v1.34a.488.488 0 00.488.488h16.267a.488.488 0 00.491-.488zM27.171 5.283a.488.488 0 00.488-.488V3.359a2.383 2.383 0 114.766 0v1.435a.488.488 0 00.488.488h4.775v2.851H22.396V5.282z"
              />
              <path
                className="prefix_WTI_c"
                d="M54.254 22.069a2.479 2.479 0 00-3.505 0l-9.826 9.826a.555.555 0 00-.141.243l-1.292 4.664a.551.551 0 00.677.678l4.665-1.292a.552.552 0 00.242-.141l9.825-9.826a2.481 2.481 0 000-3.5zM42.123 32.253l8.041-8.042 2.594 2.594-8.042 8.041zm-.518 1.039l2.072 2.072-2.866.794zm12.516-7.851l-.584.584-2.594-2.594.584-.584a1.377 1.377 0 011.947 0l.646.646a1.379 1.379 0 01.002 1.945z"
              />
            </g>
          </g>
          <g>
            <path
              d="M1094.681 374.369a2.506 2.506 0 10-2.848 0 4.305 4.305 0 00-2.874 4.055v.362h8.6v-.362a4.305 4.305 0 00-2.878-4.055z"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#fff"
              fill="none"
            />
            <text className="prefix_WTI_t" transform="translate(1115.846 378.801)">
              <tspan x={-10.327} y={0}>
                26
              </tspan>
            </text>
            <text className="prefix_WTI_t" transform="translate(1199.917 377.801)">
              <tspan x={-49.197} y={0}>
                7:00 - 13:15
              </tspan>
            </text>
            <path
              className="prefix_WTI_d"
              d="M1137.747 370.846a4.555 4.555 0 104.555 4.555 4.561 4.561 0 00-4.555-4.555zm0 .828a3.727 3.727 0 11-3.727 3.727 3.72 3.72 0 013.727-3.727zm-.086.811a.414.414 0 00-.414.414v2.761a.414.414 0 00.414.414h2.484a.414.414 0 000-.828h-2.07v-2.347a.414.414 0 00-.414-.414z"
            />
            <g>
              <text className="prefix_WTI_t" transform="translate(1255.999 378)">
                <tspan x={-19.389} y={0}>
                  6 x 1
                </tspan>
              </text>
              <path
                className="prefix_WTI_u"
                d="M1221.854 370.849h.233a.116.116 0 100-.233h-.233a.78.78 0 00-.78.78v6.586a.78.78 0 00.78.78h6.586a.78.78 0 00.78-.78v-6.586a.78.78 0 00-.78-.78h-.266a.116.116 0 100 .233h.268a.546.546 0 01.547.547v1.431h-7.68v-1.431a.546.546 0 01.545-.547zm7.133 2.211v4.922a.546.546 0 01-.547.547h-6.586a.546.546 0 01-.547-.547v-4.922z"
              />
              <path
                className="prefix_WTI_u"
                d="M1223.669 370.732a.11.11 0 00.116.116h2.711a.116.116 0 000-.233h-2.711a.117.117 0 00-.116.117z"
              />
              <path
                className="prefix_WTI_d"
                d="M1222.587 375.27h.582a.343.343 0 00.349-.349v-.582a.343.343 0 00-.349-.349h-.582a.343.343 0 00-.349.349v.582a.343.343 0 00.349.349zM1224.797 375.27h.582a.343.343 0 00.349-.349v-.582a.343.343 0 00-.349-.349h-.582a.343.343 0 00-.349.349v.582a.343.343 0 00.349.349zM1227.008 375.27h.582a.343.343 0 00.349-.349v-.582a.343.343 0 00-.349-.349h-.582a.343.343 0 00-.349.349v.582a.343.343 0 00.349.349zM1222.587 377.481h.582a.343.343 0 00.349-.349v-.582a.343.343 0 00-.349-.349h-.582a.343.343 0 00-.349.349v.582a.343.343 0 00.349.349zM1224.797 377.481h.582a.343.343 0 00.349-.349v-.582a.343.343 0 00-.349-.349h-.582a.343.343 0 00-.349.349v.582a.343.343 0 00.349.349zM1227.008 377.481h.582a.343.343 0 00.349-.349v-.582a.343.343 0 00-.349-.349h-.582a.343.343 0 00-.349.349v.582a.343.343 0 00.349.349zM1226.892 370.15v1.164a.343.343 0 00.349.349h.233a.343.343 0 00.349-.349v-1.164a.343.343 0 00-.349-.349h-.233a.343.343 0 00-.349.349zM1223.052 371.663a.343.343 0 00.349-.349v-1.164a.343.343 0 00-.349-.349h-.233a.343.343 0 00-.349.349v1.164a.343.343 0 00.349.349z"
              />
            </g>
          </g>
        </g>
        <g transform="translate(646 1315)" filter="url(#prefix_WTI_a)">
          <rect
            className="prefix_WTI_d"
            width={98}
            height={50}
            rx={10}
            transform="translate(60 183)"
          />
        </g>
        <text
          transform="translate(780.336 1527)"
          fontSize={14}
          fill="#677ea0"
          fontFamily="Roboto-Regular,Roboto"
        >
          <tspan x={-29.593} y={0}>
            6 x 1
          </tspan>
        </text>
        <g opacity={0.535}>
          <path
            className="prefix_WTI_x"
            d="M723.458 1515.504h.417a.206.206 0 100-.413h-.413a1.384 1.384 0 00-1.387 1.383v11.688a1.384 1.384 0 001.383 1.383h11.687a1.384 1.384 0 001.383-1.383v-11.688a1.384 1.384 0 00-1.382-1.383h-.471a.206.206 0 100 .413h.475a.969.969 0 01.97.97v2.54h-13.632v-2.54a.969.969 0 01.97-.97zm12.658 3.923v8.735a.969.969 0 01-.97.97h-11.688a.969.969 0 01-.971-.97v-8.735z"
          />
          <path
            className="prefix_WTI_x"
            d="M726.68 1515.297a.2.2 0 00.207.207h4.811a.206.206 0 000-.413h-4.811a.207.207 0 00-.207.206z"
          />
          <path
            className="prefix_WTI_c"
            d="M724.759 1523.351h1.032a.608.608 0 00.619-.619v-1.032a.608.608 0 00-.619-.619h-1.032a.608.608 0 00-.619.619v1.032a.608.608 0 00.619.619zM728.682 1523.351h1.032a.608.608 0 00.619-.619v-1.032a.608.608 0 00-.619-.619h-1.032a.608.608 0 00-.619.619v1.032a.608.608 0 00.619.619zM732.606 1523.351h1.032a.608.608 0 00.619-.619v-1.032a.608.608 0 00-.619-.619h-1.032a.608.608 0 00-.62.619v1.032a.608.608 0 00.62.619zM724.759 1527.274h1.032a.608.608 0 00.619-.619v-1.032a.608.608 0 00-.619-.619h-1.032a.608.608 0 00-.619.619v1.032a.608.608 0 00.619.619zM728.682 1527.274h1.032a.608.608 0 00.619-.619v-1.032a.608.608 0 00-.619-.619h-1.032a.608.608 0 00-.619.619v1.032a.608.608 0 00.619.619zM732.606 1527.274h1.032a.608.608 0 00.619-.619v-1.032a.608.608 0 00-.619-.619h-1.032a.608.608 0 00-.62.619v1.032a.608.608 0 00.62.619zM732.4 1514.265v2.065a.608.608 0 00.619.619h.413a.608.608 0 00.62-.619v-2.065a.608.608 0 00-.62-.619h-.413a.608.608 0 00-.619.619zM725.585 1516.946a.608.608 0 00.62-.619v-2.062a.608.608 0 00-.62-.619h-.413a.608.608 0 00-.619.619v2.065a.608.608 0 00.619.619z"
          />
        </g>
        <g>
          <g transform="translate(646 1314.997)" filter="url(#prefix_WTI_c)">
            <rect
              className="prefix_WTI_d"
              width={121}
              height={62}
              rx={10}
              transform="translate(611 183)"
            />
          </g>
          <text
            transform="translate(1345.774 1532.994)"
            fontSize={16}
            fill="#677ea0"
            fontFamily="Roboto-Regular,Roboto"
          >
            <tspan x={-33.82} y={0}>
              5 x 2
            </tspan>
          </text>
          <g opacity={0.536}>
            <path
              className="prefix_WTI_x"
              d="M1278.568 1519.853h.51a.255.255 0 100-.51h-.51a1.709 1.709 0 00-1.709 1.709v14.439a1.709 1.709 0 001.709 1.709h14.439a1.709 1.709 0 001.709-1.709v-14.439a1.709 1.709 0 00-1.709-1.709h-.587a.255.255 0 100 .51h.587a1.2 1.2 0 011.2 1.2v3.138h-16.838v-3.139a1.2 1.2 0 011.199-1.199zm15.638 4.847v10.791a1.2 1.2 0 01-1.2 1.2h-14.438a1.2 1.2 0 01-1.2-1.2V1524.7z"
            />
            <path
              className="prefix_WTI_x"
              d="M1282.548 1519.598a.241.241 0 00.255.255h5.944a.255.255 0 000-.51h-5.944a.256.256 0 00-.255.255z"
            />
            <path
              className="prefix_WTI_c"
              d="M1280.175 1529.547h1.276a.752.752 0 00.765-.765v-1.276a.752.752 0 00-.765-.765h-1.276a.752.752 0 00-.765.765v1.276a.752.752 0 00.765.765zM1285.022 1529.547h1.276a.752.752 0 00.765-.765v-1.276a.752.752 0 00-.765-.765h-1.276a.752.752 0 00-.765.765v1.276a.752.752 0 00.765.765zM1289.869 1529.547h1.276a.752.752 0 00.765-.765v-1.276a.752.752 0 00-.765-.765h-1.276a.752.752 0 00-.765.765v1.276a.752.752 0 00.765.765zM1280.175 1534.394h1.276a.752.752 0 00.765-.765v-1.276a.752.752 0 00-.765-.765h-1.276a.752.752 0 00-.765.765v1.276a.752.752 0 00.765.765zM1285.022 1534.394h1.276a.752.752 0 00.765-.765v-1.276a.752.752 0 00-.765-.765h-1.276a.752.752 0 00-.765.765v1.276a.752.752 0 00.765.765zM1289.869 1534.394h1.276a.752.752 0 00.765-.765v-1.276a.752.752 0 00-.765-.765h-1.276a.752.752 0 00-.765.765v1.276a.752.752 0 00.765.765zM1289.614 1518.322v2.551a.752.752 0 00.765.765h.51a.752.752 0 00.765-.765v-2.551a.752.752 0 00-.765-.765h-.51a.752.752 0 00-.765.765zM1281.196 1521.639a.752.752 0 00.765-.765v-2.552a.752.752 0 00-.765-.765h-.51a.752.752 0 00-.765.765v2.551a.752.752 0 00.765.765z"
            />
          </g>
        </g>
        <g>
          <g transform="translate(646 1315)" filter="url(#prefix_WTI_e)">
            <rect
              className="prefix_WTI_d"
              width={84}
              height={43}
              rx={10}
              transform="translate(450 323)"
            />
          </g>
          <text
            transform="translate(1164.969 1661.456)"
            fontSize={11}
            fill="#677ea0"
            fontFamily="Roboto-Regular,Roboto"
          >
            <tspan x={-35.605} y={0}>
              12 x 36
            </tspan>
          </text>
          <g opacity={0.52}>
            <path
              className="prefix_WTI_x"
              d="M1111.014 1653.054h.355a.178.178 0 000-.355h-.355a1.19 1.19 0 00-1.19 1.19v10.051a1.19 1.19 0 001.19 1.19h10.051a1.19 1.19 0 001.19-1.19v-10.051a1.19 1.19 0 00-1.19-1.19h-.408a.178.178 0 100 .355h.408a.834.834 0 01.835.835v2.184h-11.721v-2.184a.834.834 0 01.835-.835zm10.886 3.374v7.512a.834.834 0 01-.835.835h-10.051a.834.834 0 01-.835-.835v-7.512z"
            />
            <path
              className="prefix_WTI_x"
              d="M1113.784 1652.877a.168.168 0 00.178.178h4.138a.178.178 0 000-.355h-4.138a.178.178 0 00-.178.177z"
            />
            <path
              className="prefix_WTI_c"
              d="M1112.133 1659.802h.888a.523.523 0 00.533-.533v-.888a.523.523 0 00-.533-.533h-.888a.523.523 0 00-.533.533v.888a.523.523 0 00.533.533zM1115.507 1659.802h.888a.523.523 0 00.533-.533v-.888a.523.523 0 00-.533-.533h-.888a.523.523 0 00-.533.533v.888a.523.523 0 00.533.533zM1118.881 1659.802h.888a.523.523 0 00.533-.533v-.888a.523.523 0 00-.533-.533h-.888a.523.523 0 00-.533.533v.888a.523.523 0 00.533.533zM1112.133 1663.176h.888a.523.523 0 00.533-.533v-.888a.523.523 0 00-.533-.533h-.888a.523.523 0 00-.533.533v.888a.523.523 0 00.533.533zM1115.507 1663.176h.888a.523.523 0 00.533-.533v-.888a.523.523 0 00-.533-.533h-.888a.523.523 0 00-.533.533v.888a.523.523 0 00.533.533zM1118.881 1663.176h.888a.523.523 0 00.533-.533v-.888a.523.523 0 00-.533-.533h-.888a.523.523 0 00-.533.533v.888a.523.523 0 00.533.533zM1118.703 1651.989v1.776a.523.523 0 00.533.533h.355a.523.523 0 00.533-.533v-1.776a.523.523 0 00-.533-.533h-.355a.523.523 0 00-.533.533zM1112.843 1654.297a.523.523 0 00.533-.533v-1.775a.523.523 0 00-.533-.533h-.355a.523.523 0 00-.533.533v1.776a.523.523 0 00.533.533z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BoxWorkscheduleTypeIcon;
