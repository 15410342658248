import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components/macro';

// Componentes
import LogoStarGrid from 'components/layout/layoutComponents/LogoStarGrid';
import IconFeatherInstagram from 'components/layout/layoutComponents/IconFeatherInstagram';
import IconFeatherFacebook from 'components/layout/layoutComponents/IconFeatherFacebook';
// import IconFeatherYoutube from 'components/layout/layoutComponents/IconFeatherYoutube';
import IconFeatherLinkedin from 'components/layout/layoutComponents/IconFeatherLinkedin';
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';

// Rotas
import Paths from 'routes/Paths';

// Serviços
import { sendEventAnalytics } from 'services/Analytics';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Actions
import { openModalSupport } from 'components/modal/modalSupport/ModalSupportAction';
import { openModalContact } from 'components/modal/modalContact/ModalContactAction';

function FooterComponent() {
  const dispatch = useDispatch();
  const history = useHistory();

  const onOpenHome = () => {
    sendEventAnalytics('FOOTER/MENU/HOME');
    history.push(Paths.home);
  };

  const onOpenSwapRequest = () => {
    sendEventAnalytics('FOOTER/MENU/SWAP-REQUEST');
    history.push(Paths.swapRequest);
  };

  const onOpenWorkschedule = () => {
    sendEventAnalytics('FOOTER/MENU/WORKSCHEDULE');
    history.push(Paths.workschedule);
  };

  const onOpenIntegration = () => {
    sendEventAnalytics('FOOTER/MENU/INTEGRATION');
    history.push(Paths.integration);
  };

  // const onOpenBlog = () => {
  //   sendEventAnalytics('FOOTER/MENU/BLOG');
  //   history.push(Paths.blog);
  // };

  // const onOpenTraining = (event) => {
  // sendEventAnalytics('HEADER/MENU/TRAINING');
  //   onCloseMenuContent(event);
  //   history.push('/training');
  // };

  // const onOpenVideo = (event) => {
  // sendEventAnalytics('HEADER/MENU/VIDEO');
  //   onCloseMenuContent(event);
  //   history.push('/video');
  // };

  const onOpenPrivacyPolicy = () => {
    sendEventAnalytics('FOOTER/MENU/PRIVACY-POLICE');
    history.push(Paths.privacyPolicy);
  };

  const onOpenSupport = () => {
    sendEventAnalytics('FOOTER/MENU/SUPPORT');
    dispatch(openModalSupport());
  };

  const onOpenContact = () => {
    sendEventAnalytics('FOOTER/MENU/CONTACT');
    dispatch(openModalContact());
  };

  const onOpenSocial = (type) => {
    if (type === 'INSTAGRAM') sendEventAnalytics('FOOTER/SOCIAL/INSTAGRAM');
    if (type === 'FACEBOOK') sendEventAnalytics('FOOTER/SOCIAL/FACEBOOK');
    if (type === 'LINKEDIN') sendEventAnalytics('FOOTER/SOCIAL/LINKEDIN');
  };

  return (
    <FooterContent>
      <Footer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3} direction="column" alignItems="flex-start">
            <LogoStarGrid style={{ cursor: 'pointer' }} onClick={() => onOpenHome()} />
          </GridItem>
          <GridItem xs={6} sm={3} md={3} lg={3} xl={3} direction="column" alignItems="flex-start">
            <Title>{translate('Produto')}</Title>
            <Subtitle active onClick={() => onOpenSwapRequest()}>{translate('Trocas_de_folgas')}</Subtitle>
            <Subtitle active onClick={() => onOpenWorkschedule()}>{translate('Escalas_de_trabalho')}</Subtitle>
            <Subtitle active onClick={() => onOpenIntegration()}>{translate('Integracoes')}</Subtitle>
          </GridItem>
          {/* <GridItem xs={6} sm={3} md={2} lg={2} xl={2} direction="column" alignItems="flex-start">
            <Title>{translate('Conteudo')}</Title>
            <Subtitle active onClick={() => onOpenBlog()}>{translate('Blog')}</Subtitle>
          </GridItem> */}
          <GridItem xs={6} sm={3} md={3} lg={3} xl={3} direction="column" alignItems="flex-start">
            <Title>{translate('A_StarGrid')}</Title>
            <Subtitle active onClick={() => onOpenPrivacyPolicy()}>{translate('Politica_de_privacidade')}</Subtitle>
            <Subtitle active onClick={() => onOpenSupport()}>{translate('Suporte')}</Subtitle>
            <Subtitle active onClick={() => onOpenContact()}>{translate('Contato')}</Subtitle>
          </GridItem>
          <GridItem xs={6} sm={3} md={3} lg={3} xl={3} direction="column" alignItems="flex-start">
            <Title>{translate('Siga')}</Title>
            <BoxIcons>
              <Link to={{ pathname: 'https://www.instagram.com/stargridapp' }} target="_blank">
                <IconFeatherInstagram style={{ cursor: 'pointer' }} onClick={() => onOpenSocial('INSTAGRAM')} />
              </Link>
              <Link to={{ pathname: 'https://www.facebook.com/StarGridApp' }} target="_blank">
                <IconFeatherFacebook style={{ cursor: 'pointer' }} onClick={() => onOpenSocial('FACEBOOK')} />
              </Link>
              {/* <IconFeatherYoutube style={{ cursor: 'not-allowed' }} /> */}
              <Link to={{ pathname: 'https://www.linkedin.com/company/stargrid' }} target="_blank">
                <IconFeatherLinkedin style={{ cursor: 'pointer' }} onClick={() => onOpenSocial('LINKEDIN')} />
              </Link>
            </BoxIcons>
            <Copyright>{translate('StarGrid_2021')}</Copyright>
            <Copyright>{translate('Todos_os_Direitos_Reservados')}</Copyright>
          </GridItem>
        </GridContainer>
      </Footer>
    </FooterContent>
  );
}

export default memo(FooterComponent);

// ==================================================
// Estilos
// ==================================================
const FooterContent = styled.div`
  width: 100%;
  min-height: 250px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  @media (max-width: 599px) {
    width: 100%;
    min-height: 155px;
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
    min-height: 155px;
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
    min-height: 155px;
    padding: 0px 20px 0px 20px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1280px;
    max-width: 1280px;
    min-height: 155px;
    max-height: 155px;
  }
  @media (min-width: 1920px) {
    min-width: 1366px;
    max-width: 1366px;
    min-height: 155px;
    max-height: 155px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  color: #243E64;
  @media (max-width: 599px) {
    margin-top: 20px;
    font: normal normal bold 14px/32px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 10px;
    font: normal normal bold 14px/32px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin-top: 0px;
    font: normal normal bold 14px/32px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin-top: 0px;
    font: normal normal bold 16px/32px Noto Sans;
  }
  @media (min-width: 1920px) {
    margin-top: 0px;
    font: normal normal bold 16px/32px Noto Sans;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;
  cursor: ${props => (props.active ? 'pointer' : 'not-allowed')};

  @media (max-width: 599px) {
    font: normal normal normal 14px/32px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 14px/32px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 14px/32px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 16px/32px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 16px/32px Noto Sans;
  }

  &:hover {
    font-weight: ${props => (props.active ? 'bold' : 'normal')};
  }
`;

const BoxIcons = styled.div`
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 599px) {
    min-height: 50px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    min-height: 50px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    min-height: 75px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-height: 75px;
  }
  @media (min-width: 1920px) {
    min-height: 75px;
  }
`;

const Copyright = styled.div`
  letter-spacing: 0px;
  color: #677EA0;

  @media (max-width: 599px) {
    font: normal normal normal 14px/24px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 14px/24px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 14px/24px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 16px/24px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 16px/24px Noto Sans;
  }
`;
