// Estilos
import { purpleColor, greyColor, blueColor, greenColor, whiteColor, transparentColor } from 'styles/Colors';

// ==================================================
// Busca as opções dos botões
// ==================================================
export function getOptions(origin, colors, transparent, line, lineLight, disabled, pin, favorite, padding, margins, marginsIcon) {
  const options = {
    pin: pin || false,
    favorite: favorite || false,
  };

  // BOTÃO DEFAULT
  if (origin === 'default') {
    options.backgrondColor = `transparent linear-gradient(180deg, ${greyColor[1]} 0%, ${greyColor[2]} 100%) 0% 0% no-repeat padding-box`;
    options.textColor = whiteColor;
    options.borderColor = 'none';
    options.line = false;
    options.lineType = 'solid 2px';
    options.padding = padding || '';

    if (!line && !lineLight && !transparent) {
      // BOTÃO PADRÃO
      options.line = false;
      options.borderColor = 'none';
      if (colors === 'purple') {
        options.backgrondColor = `transparent linear-gradient(180deg, ${purpleColor[9]} 0%, ${purpleColor[6]} 100%) 0% 0% no-repeat padding-box`;
        options.textColor = whiteColor;
      }
      if (colors === 'green') {
        options.backgrondColor = `transparent linear-gradient(180deg, ${greenColor[9]} 0%, ${greenColor[5]} 100%) 0% 0% no-repeat padding-box`;
        options.textColor = whiteColor;
      }
      // if (colors === 'red') {
      //   options.backgrondColor = `transparent linear-gradient(180deg, ${redColor[2]} 0%, ${redColor[3]} 100%) 0% 0% no-repeat padding-box`;
      //   options.textColor = whiteColor;
      // }
      // if (colors === 'blue') {
      //   options.backgrondColor = `transparent linear-gradient(180deg, ${blueColor[0]} 0%, ${blueColor[3]} 100%) 0% 0% no-repeat padding-box`;
      //   options.textColor = whiteColor;
      // }
      // if (colors === 'grey') {
      //   options.backgrondColor = `transparent linear-gradient(180deg, ${greyColor[1]} 0%, ${greyColor[2]} 100%) 0% 0% no-repeat padding-box`;
      //   options.textColor = whiteColor;
      // }
      // if (colors === 'white') {
      //   options.backgrondColor = `transparent linear-gradient(180deg, ${whiteColor} 0%, ${whiteColor} 100%) 0% 0% no-repeat padding-box`;
      //   options.textColor = blueColor[0];
      // }
      if (disabled) {
        options.backgrondColor = `transparent linear-gradient(180deg, ${greyColor[1]} 0%, ${greyColor[2]} 100%) 0% 0% no-repeat padding-box`;
        options.textColor = whiteColor;
      }
    } else if (line || lineLight) {
      // BOTÃO LINHA
      options.backgrondColor = `transparent linear-gradient(180deg, ${transparentColor} 0%, ${transparentColor} 100%) 0% 0% no-repeat padding-box`;
      options.line = true;
      if (lineLight) {
        options.lineType = 'solid 1px';
      }
      if (colors === 'purple') {
        options.textColor = purpleColor[9];
        options.borderColor = purpleColor[9];
      }
      if (colors === 'green') {
        options.textColor = greenColor[9];
        options.borderColor = greenColor[9];
      }
      // if (colors === 'red') {
      //   options.textColor = redColor[2];
      //   options.borderColor = redColor[2];
      // }
      // if (colors === 'blue') {
      //   options.textColor = ({ theme }) => theme.colorText[7];
      //   options.borderColor = ({ theme }) => theme.colorText[7];
      // }
      if (colors === 'grey') {
        options.textColor = greyColor[4];
        options.borderColor = greyColor[4];
      }
      // if (colors === 'white') {
      //   options.textColor = whiteColor;
      //   options.borderColor = whiteColor;
      // }
      // if (disabled) {
      //   options.textColor = greyColor[1];
      //   options.borderColor = greyColor[1];
      // }
    } else if (transparent) {
      // BOTÃO TRANSPARENTE
      options.backgrondColor = `transparent linear-gradient(180deg, ${transparentColor} 0%, ${transparentColor} 100%) 0% 0% no-repeat padding-box`;
      options.line = false;
      options.borderColor = 'none';
      if (colors === 'purple') options.textColor = purpleColor[9];
      if (colors === 'green') options.textColor = greenColor[9];
      if (colors === 'blue') options.textColor = blueColor[9];
      // if (colors === 'grey') options.textColor = greyColor[0];
      // if (colors === 'red') options.textColor = redColor[2];
      if (disabled) {
        options.textColor = greyColor[2];
      }
    }
  }

  // Define a margem se for necessário
  options.margins = margins || '0px';
  options.marginsIcon = marginsIcon || '0px';

  return options;
}
