import React, { memo } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

// Actions
import { getOptions } from 'components/button/ButtonAction';

function ButtonBigComponent(props) {
  const options = getOptions('default', props.colors, props.transparent, props.line, props.lineLight, props.disabled, props.pin, props.favorite, props.padding, props.margins, props.marginsIcon);

  return (
    <div style={{ display: props.fullWidth ? 'contents' : 'flex' }}>
      <ButtonSG
        id={props.id || 'button_v2'}
        textcolor={options.textColor}
        backgrondcolor={options.backgrondColor}
        bordercolor={options.borderColor}
        margins={options.margins}
        linetype={options.lineType}
        padding={options.padding}
        startIcon={
          props.progress ? <CircularProgress size={20} />
            : props.icon ? <Icon icon={props.icon} textcolor={options.textColor} />
              : <NoneIcon />
        }
        fullWidth={props.fullWidth || false}
        type={props.type || 'button'}
        disabled={props.disabled || false}
        onClick={props.onClick}
      >
        {props.label}
      </ButtonSG>
    </div>
  );
}

export default memo(ButtonBigComponent);

// ==================================================
// Estilos
// ==================================================
const ButtonSG = styled(Button)`
  display: flex !important;
  min-height: 56px !important;
  max-height: 56px !important;
  border-radius: 56px !important;
  font: normal normal normal 16px/22px Noto Sans !important;
  letter-spacing: 0px !important;

  // OPCIONAIS
  padding: ${props => (props.padding)} !important;
  color: ${props => (props.textcolor)} !important;
  background: ${props => (props.backgrondcolor)} !important;
  border: ${props => props.linetype} ${props => (props.bordercolor)} !important;
  margin: ${props => (props.margins)} !important;
  
  text-transform: none !important;
  box-shadow: none !important;
  &:hover {
    box-shadow: 
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px !important;
  color: ${props => (props.textcolor)} !important;
`;

const NoneIcon = styled.div`
  display: none;
`;
