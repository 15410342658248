import { createTheme } from '@material-ui/core/styles';

// Estilos
import { darkColor, greyColor, whiteColor, blueColor } from 'styles/Colors';

export default darkMode => createTheme({
  background: [
    darkMode ? darkColor[9] : greyColor[0],
    darkMode ? darkColor[8] : whiteColor,
  ],
  colorText: [
    darkMode ? whiteColor : blueColor[9],
    darkMode ? greyColor[4] : blueColor[9],
  ],
  lineInsetGrey: darkMode ? whiteColor : `${greyColor[1]}`,
  lineInsetGreyLigth: darkMode ? whiteColor : `${greyColor[1]}`,
});

// @media (max-width: 699px) {}
// @media (min-width: 700px) and (max-width: 999px) {}
// @media (min-width: 1000px) and (max-width: 1299px) {}
// @media (min-width: 1300px) and (max-width: 1599px) {}
// @media (min-width: 1600px) {}
