import React, { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import ButtonBig from 'components/button/ButtonBig';
import BoxFacilitateEngagementIcon from 'modules/home/homeComponents/BoxFacilitateEngagementIcon';

// Actions
import { openModalDemo } from 'components/modal/modalDemo/ModalDemoAction';

// Serviços
import { sendEventAnalytics } from 'services/Analytics';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxFacilitateEngagementComponent() {
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const onOpenDemo = () => {
    sendEventAnalytics('HOME/BUTTON/DEMO');
    dispatch(openModalDemo());
  };

  return (
    <BoxFacilitateEngagementContent>
      <BoxFacilitateEngagement>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={6} md={6} lg={6} xl={6} alignItems="center" justify="flex-end">
            <BoxOverflow>
              <BoxIcon data-aos="flip-left" data-aos-duration="600">
                <BoxFacilitateEngagementIconSG />
              </BoxIcon>
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} xl={6} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-left" data-aos-duration="600">{translate('Facilite_o_engajamento_na_sua_equipe')}</Title>
              <Subtitle data-aos="fade-up-left" data-aos-duration="600">{translate('Apps_para_gestores_de_escala_e_colaboradores_super_faceis_de_usar')}</Subtitle>
              <BoxButton>
                <ButtonBig
                  id="button_open_modal_demo"
                  label={translate('Marque_uma_demonstracao')}
                  type="button"
                  colors="purple"
                  margins="0px 0px 0px 0px"
                  padding="0px 35px"
                  onClick={() => onOpenDemo()}
                />
              </BoxButton>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxFacilitateEngagement>
    </BoxFacilitateEngagementContent>
  );
}

export default memo(BoxFacilitateEngagementComponent);

// ==================================================
// Estilos
// ==================================================
const BoxFacilitateEngagementContent = styled.div`
  width: 100%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;


const BoxFacilitateEngagement = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 0px 25px 0px 25px;
    font: bold 20px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 25px 0px 25px;
    font: bold 20px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 0px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 30px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 30px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;
  max-width: 410px;

  @media (max-width: 599px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal 18px Noto Sans;
    padding: 0px 0px 0px 25px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin-top: 80px;
    margin-bottom: 30px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin-top: 80px;
    margin-bottom: 30px;
  }
  @media (min-width: 1920px) {
    margin-top: 80px;
    margin-bottom: 30px;
  }
`;

const BoxFacilitateEngagementIconSG = styled(BoxFacilitateEngagementIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 450px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 460px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 610px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 680px;
  }
  @media (min-width: 1920px) {
    height: 680px;
  }
`;

const BoxIcon = styled.div``;

const BoxOverflow = styled.div`
  overflow: none;
`;
