import axios from 'axios';

export const axiosInstance = () => axios.create({ baseURL: 'https://discord.com/api/webhooks/1007661089745555567/HljFzCof9qq3vQvIfe0if5WXoCMjSeIIEAxqdOx3JFBD6tk4RWM1qpagclQEjzDrtAtM' });
export const instance = axiosInstance();

export const axiosInstanceWithAuth = (inst) => {
  const { defaults } = inst;
  return { ...inst, defaults };
};

export default () => axiosInstanceWithAuth(instance);
