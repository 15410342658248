const INITIAL_STATE = {
  statusModalAccount: false,
  statusAccount: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_ACCOUNT_FETCHED':
      return { ...state, statusModalAccount: INITIAL_STATE.statusModalAccount };
    case 'OPEN_MODAL_ACCOUNT_FETCHED':
      return { ...state, statusModalAccount: action.payload };
    case 'CLEAN_STATUS_ACCOUNT_FETCHED':
      return { ...state, statusAccount: INITIAL_STATE.generalBoxesList };
    case 'STATUS_ACCOUNT_FETCHED':
      return { ...state, statusAccount: action.payload };
    default:
      return state;
  }
};
