import * as React from 'react';

function IconFeatherInstagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix_INSTA_a{fill:none;stroke:#9751eb;stroke-linecap:round;stroke-linejoin:round}'
          }
        </style>
      </defs>
      <path
        className="prefix_INSTA_a"
        d="M4.75.5h8.5a4.25 4.25 0 014.25 4.25v8.5a4.25 4.25 0 01-4.25 4.25h-8.5A4.25 4.25 0 01.5 13.25v-8.5A4.25 4.25 0 014.75.5z"
      />
      <path
        className="prefix_INSTA_a"
        d="M12.4 8.464a3.4 3.4 0 11-2.865-2.865A3.4 3.4 0 0112.4 8.464zM13.675 4.325"
      />
    </svg>
  );
}

export default IconFeatherInstagram;
