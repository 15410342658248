import React, { useEffect, useState, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import Step from 'components/randomComponents/Step';
import BoxWorkscheduleIcon1 from 'modules/workschedule/workscheduleComponents/BoxWorkscheduleIcon1';
import BoxWorkscheduleIcon2 from 'modules/workschedule/workscheduleComponents/BoxWorkscheduleIcon2';
import BoxWorkscheduleIcon3 from 'modules/workschedule/workscheduleComponents/BoxWorkscheduleIcon3';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxHowItWorks() {
  const [page, setPage] = useState(1);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxSaveTimeContent>
      <BoxSaveTime>
        <GridContainer alignItems="center" minheight="655px">
          <GridItem xs={12} sm={7} md={7} lg={7} xl={7} alignItems="center" justify="flex-end">
            <BoxOverflow>
              {page === 1 && (
                <BoxIcon data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                  <BoxWorkscheduleIcon1SG />
                </BoxIcon>
              )}

              {page === 2 && (
                <BoxIcon data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                  <BoxWorkscheduleIcon2SG />
                </BoxIcon>
              )}

              {page === 3 && (
                <BoxIcon data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                  <BoxWorkscheduleIcon3SG />
                </BoxIcon>
              )}
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} lg={5} xl={5} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-left" data-aos-duration="600">{translate('Como_criar_uma_escala')}</Title>
              <Subtitle data-aos="fade-left" data-aos-duration="600">{translate('De_forma_muito_parecida_como_voce_faz_hoje_configure_seus_times_e_deixe_que_a_inteligencia_da_StarGrid_faca_o_resto')}</Subtitle>

              <Step active={page === 1} value={1} title={translate('Cadastro_do_time')} setPage={() => setPage(1)}>
                {translate('Voce_classifica_seus_times_por_horarios__Por_cargos_Seja_como_for__e_muito_simples_configurar')}
              </Step>
              <Step active={page === 2} value={2} title={translate('Definicao_de_regras')} setPage={() => setPage(2)}>
                {translate('Fim_de_semana_sua_demanda_diminui__Em_feriados_aumenta_Sem_problemas')}
              </Step>
              <Step active={page === 3} value={3} title={translate('Seu_grid_pronto')} setPage={() => setPage(3)}>
                {translate('Sem_esforco__deixe_que_a_StarGrid_indique_a_melhor_combinacao_possivel_para_voce__E_se_voce_alterar_algo__a_StarGrid_aprende_com_isso')}
              </Step>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxSaveTime>
    </BoxSaveTimeContent>
  );
}

export default memo(BoxHowItWorks);

// ==================================================
// Estilos
// ==================================================
const BoxSaveTimeContent = styled.div`
  width: 100%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const BoxSaveTime = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 10px 15px 0px 15px;
    font: bold 25px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 10px 0px 0px 0px;
    font: bold 22px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 10px 15px 0px 10px;
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 32px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;
  margin-bottom: 20px;

  @media (max-width: 599px) {
    padding: 0px 15px 0px 15px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 15px 0px 0px;
    font: normal 14px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 15px 0px 10px;
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxIcon = styled.div`
  background: #F7F9FC;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    height: 260px;
    margin-right: 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 300px;
    margin-right: 50px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 520px;
    margin-right: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 520px;
    margin-right: 50px;
  }
  @media (min-width: 1920px) {
    height: 520px;
    margin-right: 50px;
  }
`;

const BoxWorkscheduleIcon1SG = styled(BoxWorkscheduleIcon1)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxWorkscheduleIcon2SG = styled(BoxWorkscheduleIcon2)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxWorkscheduleIcon3SG = styled(BoxWorkscheduleIcon3)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxOverflow = styled.div`
  overflow: none;
`;
