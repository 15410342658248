
import * as React from 'react';

function BoxAvoidBreakingRulesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={795}
      height={549}
      viewBox="0 0 795 549"
      {...props}
    >
      <defs>
        <filter
          id="prefix_CCC_a"
          x={0}
          y={0}
          width={722}
          height={549}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="b" />
          <feFlood floodOpacity={0.071} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_CCC_c"
          x={61}
          y={49}
          width={56}
          height={419}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={5} result="d" />
          <feFlood floodOpacity={0.043} />
          <feComposite operator="in" in2="d" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_CCC_f"
          x={460}
          y={190.123}
          width={335}
          height={227.754}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={20} result="g" />
          <feFlood floodOpacity={0.09} />
          <feComposite operator="in" in2="g" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_CCC_e"
          y1={0.5}
          x2={1.096}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ff5858" />
          <stop offset={1} stopColor="#802c2c" stopOpacity={0} />
        </linearGradient>
        <style>
          {
            '.prefix_CCC_b,.prefix_CCC_t{fill:#fff}.prefix_CCC_c{fill:#e7ccff}.prefix_CCC_d{fill:#f0f0f0}.prefix_CCC_aj,.prefix_CCC_ar,.prefix_CCC_e,.prefix_CCC_h{fill:none}.prefix_CCC_e{stroke:#e7ccff;stroke-miterlimit:10;stroke-width:4px}.prefix_CCC_f,.prefix_CCC_w{fill:#9751eb}.prefix_CCC_h{stroke:#b177f4}.prefix_CCC_aj,.prefix_CCC_h{stroke-linecap:round;stroke-linejoin:round}.prefix_CCC_h{stroke-width:1.7px}.prefix_CCC_i{fill:#6719d7;font-size:14px;font-family:Roboto-Light,Roboto;font-weight:300}.prefix_CCC_t{font-size:12px}.prefix_CCC_o{fill:#bfbfbf;opacity:.527}.prefix_CCC_p{fill:rgba(151,81,235,.44)}.prefix_CCC_q{fill:#dbdbdb}.prefix_CCC_q,.prefix_CCC_w{opacity:.608}.prefix_CCC_t{font-family:NotoSans,Noto Sans}.prefix_CCC_u{fill:#e9e9e9}.prefix_CCC_aj{stroke:#fff}.prefix_CCC_aq{stroke:none}'
          }
        </style>
      </defs>
      <g transform="translate(-586 -1426)">
        <circle
          cx={109}
          cy={109}
          r={109}
          transform="translate(604 1714)"
          fill="#f1e7fc"
          opacity={0.452}
        />
        <g transform="translate(586 1426)" filter="url(#prefix_CCC_a)">
          <path
            className="prefix_CCC_b"
            d="M75 40h572a15 15 0 0115 15v399a15 15 0 01-15 15H75a15 15 0 01-15-15V55a15 15 0 0115-15z"
          />
        </g>
        <path
          className="prefix_CCC_c"
          d="M746.158 1617.417v-2.486a1.452 1.452 0 011.451-1.45h2.486zM762.221 1609.723l-.706.707-3.138-3.138.707-.706a1.665 1.665 0 012.356 0l.782.782a1.668 1.668 0 01-.001 2.355zM736.795 1590.773a.486.486 0 00.486-.486v-1.429a2.372 2.372 0 014.745 0v1.429a.486.486 0 00.486.486h4.754v2.839H732.04v-2.839z"
        />
        <g transform="translate(712 1585.958)">
          <path
            className="prefix_CCC_d"
            d="M6.413 22.086a.185.185 0 01-.223-.161 2.772 2.772 0 011.656-2.84 2.012 2.012 0 111.887-.411 2.772 2.772 0 012.687 1.894.182.182 0 01-.136.239l-5.872 1.279zM7.677 27.876a.449.449 0 00.534.344l4.988-1.086a.45.45 0 00-.191-.878L8.02 27.342a.448.448 0 00-.343.534zM13.882 24.028a.441.441 0 00-.184 0l-7.232 1.575a.45.45 0 00.191.878l7.219-1.572.006-.881zM5.691 24.155a.449.449 0 00.534.344l7.233-1.575a.45.45 0 00-.191-.878l-7.232 1.575a.449.449 0 00-.344.534z"
          />
          <path
            className="prefix_CCC_d"
            d="M13.915 28.349l-9.276 2.02L.958 13.482l4.143-.9.269 1.237a.21.21 0 00.25.161l4.007-.873a.21.21 0 00.161-.25l-.269-1.237 3.714-.809c-.019-.31-.032-.621-.041-.931l-3.869.842-.685-3.147a.21.21 0 00-.25-.161l-4.007.872a.21.21 0 00-.16.25l.685 3.147-4.367.951a.689.689 0 00-.527.82l3.774 17.335a.689.689 0 00.82.527l9.339-2.033-.03-.934zM7.894 8.462l.534 2.454.2.9.119.545-2.622.571-.119-.545-.2-.9-.534-2.454z"
          />
          <path
            className="prefix_CCC_d"
            d="M7.819 10.285a.88.88 0 11-1.048-.673.88.88 0 011.048.673z"
          />
          <path
            className="prefix_CCC_c"
            d="M24.53 11.652h6.483a.45.45 0 000-.9H24.53a.45.45 0 000 .9zM24.53 16.016h9.928a.45.45 0 000-.9H24.53a.45.45 0 000 .9zM24.53 20.38h6.483a.45.45 0 000-.9H24.53a.45.45 0 000 .9zM24.53 24.743h9.928a.45.45 0 000-.9H24.53a.45.45 0 000 .9zM24.53 29.107h6.483a.45.45 0 000-.9H24.53a.45.45 0 100 .9z"
          />
          <rect
            className="prefix_CCC_e"
            width={2.297}
            height={2.297}
            rx={1.148}
            transform="translate(19.665 10.055)"
          />
          <rect
            className="prefix_CCC_e"
            width={2.297}
            height={2.297}
            rx={1.148}
            transform="translate(19.665 14.419)"
          />
          <rect
            className="prefix_CCC_e"
            width={2.297}
            height={2.297}
            rx={1.148}
            transform="translate(19.665 18.783)"
          />
          <rect
            className="prefix_CCC_e"
            width={2.297}
            height={2.297}
            rx={1.148}
            transform="translate(19.665 23.146)"
          />
          <rect
            className="prefix_CCC_e"
            width={2.297}
            height={2.297}
            rx={1.148}
            transform="translate(19.665 27.51)"
          />
          <g>
            <path
              className="prefix_CCC_d"
              d="M49.091 24.681l-1.888 7.236a.226.226 0 01-.28.164l-4.448-1.161a1.8 1.8 0 01-.355.857l4.571 1.193a1.146 1.146 0 001.4-.821l2.053-7.866a1.616 1.616 0 01-1.053.398zM54.151 7.595a1.143 1.143 0 00-.7-.531L42.059 4.091a1.781 1.781 0 01.033.958l11.127 2.9a.23.23 0 01.164.28l-3.449 13.217a1.628 1.628 0 01.7.963l3.64-13.948a1.141 1.141 0 00-.123-.866z"
            />
            <path
              className="prefix_CCC_d"
              d="M49.431 10.109l-7.379-1.926c0 .311.009.621.012.932l7.14 1.864a.45.45 0 00.227-.869zM48.612 13.248l-6.566-1.714q-.015.46-.041.918l6.381 1.665a.45.45 0 00.227-.87zM47.044 19.257l-5.4-1.41a2 2 0 01-.006.927l5.175 1.353a.45.45 0 00.227-.87zM47.846 16.182l-5.987-1.561a2.032 2.032 0 010 .218 1.986 1.986 0 01-.078.69l5.838 1.524a.45.45 0 00.227-.869zM45.347 22.947a1.834 1.834 0 01.313-.847l-3.8-.993a1.97 1.97 0 01-.13.895z"
            />
            <g>
              <path d="M42.589 25.715a1.838 1.838 0 01.305-.849l-1.339-.35a2 2 0 01.039.939z" />
            </g>
          </g>
          <path
            className="prefix_CCC_f"
            d="M41.296 32.602v1.613a1.02 1.02 0 01-1.019 1.018h-25.25a1.02 1.02 0 01-1.019-1.018V3.679a1.02 1.02 0 011.019-1.02h9.564a3.123 3.123 0 00-.031.432v.872h-4.4a.449.449 0 00-.449.449v1.392h-2.019a1.185 1.185 0 00-1.183 1.183v23.917a1.185 1.185 0 001.183 1.183H33.77a1.432 1.432 0 00.178-.013.476.476 0 00.107.013.448.448 0 00.338-.154 1.331 1.331 0 00.318-.235l3.684-3.683a1.322 1.322 0 00.389-.94V6.988a1.185 1.185 0 00-1.183-1.183h-2.017V4.413a.449.449 0 00-.449-.449h-4.4v-.872a3.123 3.123 0 00-.031-.432h9.564a1.02 1.02 0 011.019 1.019v22.438a1.608 1.608 0 01.376-.136 1.566 1.566 0 01.522-.226V3.679a1.919 1.919 0 00-1.917-1.917h-9.835a3.091 3.091 0 00-5.581 0h-9.835A1.92 1.92 0 0013.1 3.679v30.535a1.92 1.92 0 001.917 1.917h25.25a1.92 1.92 0 001.917-1.917v-2.439a1.513 1.513 0 01-.424.22 1.6 1.6 0 01-.464.607zm-6.787-1.966v-1.747a1.02 1.02 0 011.019-1.019h1.746zm1.079-22.7V6.703h2.018a.286.286 0 01.285.285v19.984h-2.363a1.919 1.919 0 00-1.917 1.917v2.3H17.698a.285.285 0 01-.284-.285V6.988a.286.286 0 01.284-.285h2.019v1.233a.449.449 0 00.449.449H35.14a.449.449 0 00.444-.449zM25.01 4.859a.449.449 0 00.449-.449V3.089a2.193 2.193 0 114.387 0V4.41a.449.449 0 00.449.449h4.4v2.625h-14.08V4.859z"
          />
          <path
            className="prefix_CCC_f"
            d="M49.941 20.312a2.282 2.282 0 00-3.226 0l-9.045 9.045a.511.511 0 00-.13.223l-1.189 4.294a.507.507 0 00.624.624l4.294-1.189a.508.508 0 00.223-.13l9.044-9.045a2.284 2.284 0 000-3.226zm-11.166 9.374l7.4-7.4 2.388 2.388-7.4 7.4zm-.477.957l1.907 1.907-2.638.731zm11.521-7.226l-.537.538-2.388-2.388.538-.537a1.267 1.267 0 011.792 0l.6.595a1.27 1.27 0 01-.005 1.792z"
          />
        </g>
        <g>
          <g transform="translate(1172 1593)" stroke="#b177f4" fill="none">
            <rect className="prefix_CCC_aq" width={23} height={23} rx={11.5} />
            <rect
              className="prefix_CCC_ar"
              x={0.5}
              y={0.5}
              width={22}
              height={22}
              rx={11}
            />
          </g>
          <path
            className="prefix_CCC_h"
            d="M1185.518 1605.058a1.751 1.751 0 11-1.749-1.751 1.751 1.751 0 011.749 1.751z"
          />
          <path
            className="prefix_CCC_h"
            d="M1188.087 1606.808a.963.963 0 00.193 1.062l.035.035a1.168 1.168 0 11-1.652 1.652l-.035-.035a.971.971 0 00-1.646.689v.1a1.167 1.167 0 11-2.334 0v-.053a1.01 1.01 0 00-1.692-.689l-.035.035a1.168 1.168 0 11-1.652-1.652l.035-.035a.971.971 0 00-.689-1.646h-.1a1.167 1.167 0 010-2.334h.054a1.01 1.01 0 00.689-1.692l-.035-.035a1.168 1.168 0 111.651-1.652l.035.035a.963.963 0 001.062.193h.047a.963.963 0 00.584-.881v-.1a1.167 1.167 0 112.334 0v.053a.971.971 0 001.646.689l.035-.035a1.168 1.168 0 111.652 1.652l-.035.035a.963.963 0 00-.193 1.062v.047a.963.963 0 00.881.584h.1a1.167 1.167 0 110 2.334h-.053a.963.963 0 00-.881.584z"
          />
        </g>
        <g>
          <text className="prefix_CCC_i" transform="translate(737 1549)">
            <tspan x={0} y={0}>
              {'Aprova\xE7\xE3o de grid'}
            </tspan>
          </text>
          <g transform="translate(712 1536.698)">
            <rect
              className="prefix_CCC_f"
              width={15}
              height={14}
              rx={7}
              transform="translate(0 .302)"
            />
            <path
              className="prefix_CCC_b"
              d="M11.027 6.462v1.611a.4.4 0 01-.4.4h-3.37v1.747a.4.4 0 01-.69.286l-2.962-2.95a.4.4 0 010-.572l2.962-2.957a.4.4 0 01.69.286v1.745h3.366a.4.4 0 01.404.404z"
            />
          </g>
        </g>
        <g transform="translate(5091 -514.167)">
          <g
            transform="translate(-4429 2001.167)"
            strokeWidth={1.4}
            stroke="#dee6f2"
            fill="#f0f0f0"
          >
            <rect className="prefix_CCC_aq" width={570} height={389} rx={10} />
            <rect
              className="prefix_CCC_ar"
              x={0.7}
              y={0.7}
              width={568.6}
              height={387.6}
              rx={9.3}
            />
          </g>
          <rect
            className="prefix_CCC_b"
            width={510}
            height={297}
            rx={10}
            transform="translate(-4389 2078.167)"
          />
          <g transform="translate(-4041 2088.68)" opacity={0.509}>
            <ellipse
              className="prefix_CCC_b"
              cx={13}
              cy={14.5}
              rx={13}
              ry={14.5}
              transform="translate(0 .486)"
            />
            <ellipse
              className="prefix_CCC_b"
              cx={13}
              cy={14.5}
              rx={13}
              ry={14.5}
              transform="translate(33 .486)"
            />
            <ellipse
              className="prefix_CCC_b"
              cx={13}
              cy={14.5}
              rx={13}
              ry={14.5}
              transform="translate(65 .486)"
            />
            <ellipse
              className="prefix_CCC_b"
              cx={13}
              cy={14.5}
              rx={13}
              ry={14.5}
              transform="translate(97 .486)"
            />
          </g>
          <path
            className="prefix_CCC_b"
            d="M-4403 2001.167h534a10 10 0 0110 10v22h-544v-32z"
          />
          <g transform="translate(-4505 1940.17)" filter="url(#prefix_CCC_c)">
            <path
              className="prefix_CCC_b"
              d="M86 61h16v389H86a10 10 0 01-10-10V71a10 10 0 0110-10z"
            />
          </g>
          <text
            transform="translate(-4300 2118.166)"
            fill="#2a2e43"
            fontSize={20}
            fontFamily="Roboto-Light,Roboto"
            fontWeight={300}
          >
            <tspan x={0} y={0}>
              Seu Setor
            </tspan>
          </text>
          <text
            transform="translate(-4300 2134.166)"
            fontFamily="Roboto-Regular,Roboto"
            fontSize={12}
            stroke="transparent"
            fill="#9751eb"
          >
            <tspan x={0} y={0}>
              Total de 1 turno gerado.
            </tspan>
          </text>
          <rect
            width={476}
            height={20}
            rx={10}
            transform="translate(-4378 2151.167)"
            fill="#b178f4"
          />
          <g transform="translate(-4375 2197.813)">
            <rect
              className="prefix_CCC_o"
              width={51}
              height={7}
              rx={3.5}
              transform="translate(33 .354)"
            />
            <rect
              className="prefix_CCC_o"
              width={26}
              height={8}
              rx={4}
              transform="translate(33 13.354)"
            />
            <circle
              className="prefix_CCC_p"
              cx={10}
              cy={10}
              r={10}
              transform="translate(0 .354)"
            />
            <path
              className="prefix_CCC_b"
              d="M10 10.816a2.081 2.081 0 10-2.081-2.081A2.082 2.082 0 0010 10.816zm1.85.462h-.8a2.516 2.516 0 01-2.107 0h-.8A1.85 1.85 0 006.3 13.129v.231a.694.694 0 00.694.694h6.012a.694.694 0 00.694-.694v-.231a1.85 1.85 0 00-1.85-1.85z"
            />
          </g>
          <g transform="translate(-4264 2193.813)">
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(100 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(235 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(34 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(168 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(302 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(0 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(134 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(269 3.354)"
            />
            <rect
              width={25}
              height={26}
              rx={5}
              transform="translate(67 3.354)"
              opacity={0.608}
              fill="#6719d7"
            />
            <rect
              width={26}
              height={26}
              rx={5}
              transform="translate(201 3.354)"
              fill="#f9931d"
              opacity={0.608}
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(336 3.354)"
            />
            <text className="prefix_CCC_t" transform="translate(80 21.354)">
              <tspan x={-6.258} y={0}>
                FL
              </tspan>
            </text>
            <text className="prefix_CCC_t" transform="translate(214 21.354)">
              <tspan x={-7.014} y={0}>
                FB
              </tspan>
            </text>
          </g>
          <g transform="translate(-4375 2244.813)">
            <rect
              className="prefix_CCC_o"
              width={51}
              height={7}
              rx={3.5}
              transform="translate(33 .354)"
            />
            <rect
              className="prefix_CCC_o"
              width={26}
              height={8}
              rx={4}
              transform="translate(33 13.354)"
            />
            <circle
              className="prefix_CCC_p"
              cx={10}
              cy={10}
              r={10}
              transform="translate(0 .354)"
            />
            <path
              className="prefix_CCC_b"
              d="M10 10.816a2.081 2.081 0 10-2.081-2.081A2.082 2.082 0 0010 10.816zm1.85.462h-.8a2.516 2.516 0 01-2.107 0h-.8A1.85 1.85 0 006.3 13.129v.231a.694.694 0 00.694.694h6.012a.694.694 0 00.694-.694v-.231a1.85 1.85 0 00-1.85-1.85z"
            />
          </g>
          <g transform="translate(-4264 2240.813)">
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(100 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(235 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(34 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(168 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(302 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(0 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(134 3.354)"
            />
            <rect
              className="prefix_CCC_u"
              width={26}
              height={26}
              rx={5}
              transform="translate(269 3.354)"
            />
            <rect
              className="prefix_CCC_u"
              width={25}
              height={26}
              rx={5}
              transform="translate(67 3.354)"
            />
            <rect
              className="prefix_CCC_u"
              width={26}
              height={26}
              rx={5}
              transform="translate(201 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(336 3.354)"
            />
          </g>
          <g transform="translate(-4375 2291.813)">
            <rect
              className="prefix_CCC_o"
              width={51}
              height={7}
              rx={3.5}
              transform="translate(33 .354)"
            />
            <rect
              className="prefix_CCC_o"
              width={26}
              height={8}
              rx={4}
              transform="translate(33 13.354)"
            />
            <circle
              className="prefix_CCC_p"
              cx={10}
              cy={10}
              r={10}
              transform="translate(0 .354)"
            />
            <path
              className="prefix_CCC_b"
              d="M10 10.816a2.081 2.081 0 10-2.081-2.081A2.082 2.082 0 0010 10.816zm1.85.462h-.8a2.516 2.516 0 01-2.107 0h-.8A1.85 1.85 0 006.3 13.129v.231a.694.694 0 00.694.694h6.012a.694.694 0 00.694-.694v-.231a1.85 1.85 0 00-1.85-1.85z"
            />
          </g>
          <g transform="translate(-4264 2287.813)">
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(100 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(235 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(34 3.354)"
            />
            <rect
              width={26}
              height={26}
              rx={5}
              transform="translate(168 3.354)"
              fill="#fbbd75"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(302 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(0 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(134 3.354)"
            />
            <rect
              className="prefix_CCC_u"
              width={26}
              height={26}
              rx={5}
              transform="translate(269 3.354)"
            />
            <rect
              className="prefix_CCC_u"
              width={25}
              height={26}
              rx={5}
              transform="translate(67 3.354)"
            />
            <rect
              className="prefix_CCC_u"
              width={26}
              height={26}
              rx={5}
              transform="translate(201 3.354)"
            />
            <rect
              className="prefix_CCC_q"
              width={26}
              height={26}
              rx={5}
              transform="translate(336 3.354)"
            />
            <text className="prefix_CCC_t" transform="translate(181 21.354)">
              <tspan x={-7.014} y={0}>
                FB
              </tspan>
            </text>
          </g>
          <g transform="translate(-4264 2331.813)">
            <rect
              className="prefix_CCC_w"
              width={26}
              height={26}
              rx={5}
              transform="translate(100 3.354)"
            />
            <rect
              className="prefix_CCC_w"
              width={26}
              height={26}
              rx={5}
              transform="translate(235 3.354)"
            />
            <rect
              className="prefix_CCC_w"
              width={26}
              height={26}
              rx={5}
              transform="translate(34 3.354)"
            />
            <rect
              className="prefix_CCC_f"
              width={26}
              height={26}
              rx={5}
              transform="translate(168 3.354)"
            />
            <rect
              className="prefix_CCC_w"
              width={26}
              height={26}
              rx={5}
              transform="translate(302 3.354)"
            />
            <rect
              className="prefix_CCC_w"
              width={26}
              height={26}
              rx={5}
              transform="translate(0 3.354)"
            />
            <rect
              className="prefix_CCC_w"
              width={26}
              height={26}
              rx={5}
              transform="translate(134 3.354)"
            />
            <rect
              className="prefix_CCC_f"
              width={26}
              height={26}
              rx={5}
              transform="translate(269 3.354)"
            />
            <rect
              className="prefix_CCC_f"
              width={25}
              height={26}
              rx={5}
              transform="translate(67 3.354)"
            />
            <rect
              className="prefix_CCC_f"
              width={26}
              height={26}
              rx={5}
              transform="translate(201 3.354)"
            />
            <rect
              className="prefix_CCC_w"
              width={26}
              height={26}
              rx={5}
              transform="translate(336 3.354)"
            />
            <text
              transform="translate(181 21.354)"
              fontFamily="NotoSans,Noto Sans"
              fontSize={12}
              fill="#9751eb"
            >
              <tspan x={-6.258} y={0}>
                FL
              </tspan>
            </text>
            <text
              transform="translate(11 20.354)"
              letterSpacing="1.25em"
              fontSize={10}
              fontFamily="NotoSans,Noto Sans"
              fill="#fff"
            >
              <tspan x={0} y={0}>
                {'3 2 3 3 3 2 3 '}
              </tspan>
              <tspan y={0} letterSpacing="1.3em">
                2 3 3 3
              </tspan>
            </text>
          </g>
          <text
            transform="translate(-4373 2345.167)"
            fontSize={10}
            fontFamily="NotoSans,Noto Sans"
            fill="#9751eb"
          >
            <tspan x={0} y={0}>
              Total de
            </tspan>
            <tspan x={0} y={12}>
              Colaboradores
            </tspan>
          </text>
          <text
            transform="translate(-4134 2165.167)"
            letterSpacing=".1em"
            fontSize={10}
            fontFamily="NotoSans,Noto Sans"
            fill="#fff"
          >
            <tspan x={-51.576} y={0}>
              {'TURNO DA MANH\xC3'}
            </tspan>
          </text>
          <path
            d="M-4409.209 2026.637v-.064a.021.021 0 00-.021-.021c0-.021-.021-.021-.021-.042l-.021-.021-3.616-2.636a.307.307 0 00-.149-.042h-2.61a.3.3 0 00-.127.042v-2.146l-.021-.021c0-.021-.021-.021-.021-.042l-.021-.021-1.7-1.232v-2.487a3.182 3.182 0 011.849-2.889 3.073 3.073 0 011.636 2.7v3.761a.223.223 0 00.106.191l1.806 1.317a.307.307 0 00.149.042h2.571a.241.241 0 00.234-.255v-5.079a5.323 5.323 0 00-5.335-5.333h-2.634a5.336 5.336 0 00-5.333 5.333v3.718a.223.223 0 00.106.191l3.594 2.614a.307.307 0 00.149.042h2.613a.3.3 0 00.127-.042v2.044a.223.223 0 00.106.191l1.7 1.232v2.077a2.631 2.631 0 01-2.55 2.635 2.671 2.671 0 01-.935-2.018v-3.824a.316.316 0 00-.085-.191l-1.806-1.3a.307.307 0 00-.149-.042h-2.615a.249.249 0 00-.234.17v3.87a5.8 5.8 0 002.316 4.61l1.742 1.275a5.725 5.725 0 009.2-4.568v-3.7c.021-.021.021-.042 0-.042m-3.909-2.334l2.93 2.124h-1.783l-2.932-2.125zm-6.586-3.548h1.785l1.126.829h-1.786zm1.636-2.847v2.354h-2.125v-2.55a3.056 3.056 0 013.038-3.06 3.691 3.691 0 01.829.106 3.717 3.717 0 00-1.742 3.144m3.527-5.078a4.842 4.842 0 014.844 4.844v4.865h-2.1v-4.838a5.317 5.317 0 00-3.123-4.844h.382zm-7.458 4.865a4.851 4.851 0 014.823-4.844h.064a4.824 4.824 0 014.824 4.85v4.632l-1.317-.977v-3.634a3.539 3.539 0 10-7.075-.191v2.953a.223.223 0 00.106.191l1.7 1.232v1.615l-3.123-2.231zm5.737 6.1h-2.126v-1.739h2.125zm2.635 5.992v-2.21a.223.223 0 00-.106-.191l-1.7-1.232v-1.612l3.123 2.252v3.612a2.652 2.652 0 01-2.635 2.656 2.761 2.761 0 01-1.084-.234 3.112 3.112 0 002.4-3.038m-6.076-4.249l1.126.829h-1.784l-1.125-.829zm-2.295 3.569v-3.335l1.317.956v3.7a5.728 5.728 0 00.786 2.89 5.345 5.345 0 01-2.1-4.207m12.323 1.3a5.25 5.25 0 01-5.269 5.248 5.33 5.33 0 01-3.081-1l-.106-.085a5.337 5.337 0 01-2.082-4.164v-3.576h2.124v3.569a3.135 3.135 0 006.268 0v-3.484h2.122z"
            fill="#243e64"
          />
          <path
            className="prefix_CCC_c"
            d="M-4337.892 2133.176v-2.7a1.578 1.578 0 011.576-1.576h2.7zM-4320.442 2124.822l-.767.768-3.409-3.409.768-.767a1.809 1.809 0 012.559 0l.85.85a1.812 1.812 0 01-.001 2.558zM-4348.065 2104.237a.528.528 0 00.528-.528v-1.553a2.577 2.577 0 015.154 0v1.552a.528.528 0 00.528.528h5.165v3.084h-16.54v-3.083z"
          />
          <g transform="translate(-4375 2099.004)">
            <path
              className="prefix_CCC_d"
              d="M6.967 23.994a.2.2 0 01-.242-.175 3.011 3.011 0 011.8-3.085 2.186 2.186 0 112.05-.447 3.012 3.012 0 012.919 2.058.2.2 0 01-.147.26l-6.379 1.389zM8.339 30.283a.488.488 0 00.581.373l5.419-1.18a.488.488 0 00-.208-.954l-5.423 1.176a.487.487 0 00-.369.585zM15.085 26.102a.479.479 0 00-.2 0l-7.857 1.711a.488.488 0 00.208.954l7.842-1.708q-.002-.478.007-.957zM6.182 26.241a.488.488 0 00.581.373l7.857-1.711a.488.488 0 00-.208-.954L6.555 25.66a.487.487 0 00-.373.581z"
            />
            <path
              className="prefix_CCC_d"
              d="M15.117 30.798L5.04 32.992 1.045 14.649l4.5-.98.293 1.344a.228.228 0 00.271.174l4.353-.948a.228.228 0 00.174-.271l-.293-1.344 4.035-.878c-.02-.337-.034-.674-.045-1.012l-4.2.915-.744-3.419a.228.228 0 00-.271-.174l-4.353.948a.228.228 0 00-.174.271l.744 3.419-4.744 1.033a.749.749 0 00-.572.891l4.098 18.831a.748.748 0 00.891.572l10.145-2.209q-.021-.508-.036-1.014zm-6.541-21.6l.581 2.666.212.975.129.592-2.848.62-.129-.592-.212-.975-.581-2.666z"
            />
            <path
              className="prefix_CCC_d"
              d="M8.495 11.172a.956.956 0 11-1.138-.731.956.956 0 011.138.731z"
            />
            <path
              className="prefix_CCC_c"
              d="M26.648 12.659h7.043a.488.488 0 000-.976h-7.043a.488.488 0 100 .976zM26.648 17.4h10.785a.488.488 0 000-.976H26.648a.488.488 0 100 .976zM26.648 22.14h7.043a.488.488 0 000-.976h-7.043a.488.488 0 100 .976zM26.648 26.88h10.785a.488.488 0 000-.976H26.648a.488.488 0 100 .976zM26.648 31.621h7.043a.488.488 0 000-.976h-7.043a.488.488 0 100 .976z"
            />
            <rect
              className="prefix_CCC_e"
              width={2.495}
              height={2.495}
              rx={1.248}
              transform="translate(21.364 10.924)"
            />
            <rect
              className="prefix_CCC_e"
              width={2.495}
              height={2.495}
              rx={1.248}
              transform="translate(21.364 15.664)"
            />
            <rect
              className="prefix_CCC_e"
              width={2.495}
              height={2.495}
              rx={1.248}
              transform="translate(21.364 20.405)"
            />
            <rect
              className="prefix_CCC_e"
              width={2.495}
              height={2.495}
              rx={1.248}
              transform="translate(21.364 25.145)"
            />
            <rect
              className="prefix_CCC_e"
              width={2.495}
              height={2.495}
              rx={1.248}
              transform="translate(21.364 29.886)"
            />
            <g>
              <path
                className="prefix_CCC_d"
                d="M53.331 26.812l-2.051 7.861a.246.246 0 01-.3.179l-4.832-1.261a1.951 1.951 0 01-.386.931l4.966 1.3a1.245 1.245 0 001.522-.892l2.23-8.545a1.756 1.756 0 01-1.149.427zM58.828 8.25a1.241 1.241 0 00-.761-.576l-12.376-3.23a1.934 1.934 0 01.036 1.041L57.82 8.64a.25.25 0 01.178.3l-3.747 14.359a1.769 1.769 0 01.758 1.046l3.954-15.152a1.24 1.24 0 00-.135-.943z"
              />
              <path
                className="prefix_CCC_d"
                d="M53.7 10.981l-8.016-2.092c.005.337.01.675.013 1.012l7.756 2.024a.488.488 0 00.247-.944zM52.81 14.392l-7.133-1.862q-.016.5-.045 1l6.932 1.809a.488.488 0 00.246-.945zM51.109 20.92l-5.871-1.532a2.178 2.178 0 01-.007 1.007l5.632 1.47a.488.488 0 00.246-.945zM51.978 17.583l-6.5-1.7a2.2 2.2 0 010 .237 2.158 2.158 0 01-.084.75l6.342 1.655a.488.488 0 00.246-.944zM49.264 24.929a1.992 1.992 0 01.34-.92l-4.132-1.078a2.14 2.14 0 01-.141.972z"
              />
              <g>
                <path d="M46.265 27.939a2 2 0 01.332-.922l-1.455-.38a2.169 2.169 0 01.043 1.02z" />
              </g>
            </g>
            <path
              className="prefix_CCC_f"
              d="M44.864 35.417v1.752a1.108 1.108 0 01-1.107 1.106H16.326a1.109 1.109 0 01-1.107-1.106V3.997a1.108 1.108 0 011.107-1.107h10.391a3.391 3.391 0 00-.033.469v.947h-4.775a.488.488 0 00-.488.488v1.512h-2.194a1.287 1.287 0 00-1.285 1.286v25.983a1.287 1.287 0 001.285 1.285h17.467a1.557 1.557 0 00.193-.014.516.516 0 00.116.014.487.487 0 00.367-.168 1.447 1.447 0 00.346-.256l4-4a1.436 1.436 0 00.423-1.022V7.592a1.287 1.287 0 00-1.285-1.286h-2.193V4.794a.488.488 0 00-.488-.488h-4.775v-.947a3.391 3.391 0 00-.033-.469h10.39a1.108 1.108 0 011.107 1.107v24.376a1.745 1.745 0 01.409-.148 1.7 1.7 0 01.567-.245V3.997a2.085 2.085 0 00-2.083-2.083H33.073a3.358 3.358 0 00-6.063 0H16.326a2.085 2.085 0 00-2.083 2.083v33.172a2.086 2.086 0 002.083 2.082h27.431a2.085 2.085 0 002.083-2.082v-2.65a1.643 1.643 0 01-.461.239 1.736 1.736 0 01-.515.659zm-7.373-2.136v-1.9a1.108 1.108 0 011.107-1.107h1.9zm1.172-24.659v-1.34h2.193a.31.31 0 01.309.31v21.71h-2.567a2.085 2.085 0 00-2.083 2.083v2.5H19.227a.31.31 0 01-.309-.31V7.592a.31.31 0 01.309-.31h2.19v1.34a.488.488 0 00.488.488h16.267a.488.488 0 00.491-.488zM27.171 5.283a.488.488 0 00.488-.488V3.359a2.383 2.383 0 114.766 0v1.435a.488.488 0 00.488.488h4.775v2.851H22.396V5.282z"
            />
            <path
              className="prefix_CCC_f"
              d="M54.254 22.069a2.479 2.479 0 00-3.505 0l-9.826 9.826a.555.555 0 00-.141.243l-1.292 4.664a.551.551 0 00.677.678l4.665-1.292a.552.552 0 00.242-.141l9.825-9.826a2.481 2.481 0 000-3.5zM42.123 32.253l8.041-8.042 2.594 2.594-8.042 8.041zm-.518 1.039l2.072 2.072-2.866.794zm12.516-7.851l-.584.584-2.594-2.594.584-.584a1.377 1.377 0 011.947 0l.646.646a1.379 1.379 0 01.002 1.945z"
            />
          </g>
          <g opacity={0.667}>
            <path
              d="M-3893.926 2023.294a1.493 1.493 0 11-2.986 0h-4.48a.624.624 0 01-.478-1.015l1.493-1.732a1.946 1.946 0 00.478-1.135v-3.286a4.48 4.48 0 018.959 0v3.285a2.862 2.862 0 00.418 1.195l1.493 1.732a.616.616 0 01-.478 1.015h-4.42zm2.509-1.911a3.313 3.313 0 01-.717-1.971v-3.286a3.285 3.285 0 00-6.57 0v3.285a3.313 3.313 0 01-.717 1.971l-.657.717h9.317l-.657-.717z"
              fill="#677ea0"
            />
            <circle
              cx={2.442}
              cy={2.442}
              fill="#be2a2a"
              r={2.442}
              transform="translate(-3889.757 2011.219)"
            />
          </g>
          <g>
            <text className="prefix_CCC_i" transform="translate(-4358 2060.166)">
              <tspan x={0} y={0}>
                {'Aprova\xE7\xE3o de grid'}
              </tspan>
            </text>
            <g transform="translate(-4383 2048.015)">
              <rect
                className="prefix_CCC_f"
                width={15}
                height={14}
                rx={7}
                transform="translate(0 .151)"
              />
              <path
                className="prefix_CCC_b"
                d="M10.919 6.398v1.6a.4.4 0 01-.4.4H7.186v1.73a.4.4 0 01-.684.283L3.569 7.482a.4.4 0 010-.567l2.933-2.928a.4.4 0 01.683.283v1.728h3.334a.4.4 0 01.4.4z"
              />
            </g>
          </g>
          <g transform="translate(-1 3)">
            <circle
              className="prefix_CCC_d"
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-4422 2115.167)"
            />
            <circle
              className="prefix_CCC_d"
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-4422 2152.167)"
            />
            <circle
              className="prefix_CCC_d"
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-4422 2184.167)"
            />
            <circle
              className="prefix_CCC_d"
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-4422 2221.167)"
            />
            <circle
              cx={6.5}
              cy={6.5}
              r={6.5}
              transform="translate(-4422 2078.167)"
              opacity={0.302}
              fill="#9751eb"
            />
          </g>
          <g transform="translate(1)">
            <text
              transform="translate(-4361 2022.167)"
              fill="#939393"
              fontFamily="Roboto-Regular,Roboto"
              fontSize={12}
            >
              <tspan x={0} y={0}>
                sua empresa
              </tspan>
            </text>
            <circle
              cx={9.5}
              cy={9.5}
              r={9.5}
              transform="translate(-4385 2009.167)"
              fill="#d6d6d6"
            />
            <path
              className="prefix_CCC_aj"
              d="M-4378.69 2015.968h7.369a.921.921 0 01.921.921v4.606a.921.921 0 01-.921.921h-7.369a.921.921 0 01-.921-.921v-4.606a.921.921 0 01.921-.921z"
            />
            <path
              className="prefix_CCC_aj"
              d="M-4373.163 2022.416v-7.369a.921.921 0 00-.921-.921h-1.843a.921.921 0 00-.921.921v7.369"
            />
          </g>
          <g transform="translate(-4429 2001.167)" stroke="#dee6f2" fill="none">
            <rect className="prefix_CCC_aq" width={570} height={389} rx={10} />
            <rect
              className="prefix_CCC_ar"
              x={0.5}
              y={0.5}
              width={569}
              height={388}
              rx={9.5}
            />
          </g>
          <rect
            width={491}
            height={46}
            rx={5}
            transform="translate(-4384 2233.167)"
            opacity={0.252}
            fill="url(#prefix_CCC_e)"
          />
        </g>
        <g>
          <g transform="translate(586 1426.003)" filter="url(#prefix_CCC_f)">
            <path
              className="prefix_CCC_b"
              d="M525.003 243.12h205a5 5 0 015 5v97.754a5 5 0 01-5 5h-205a5 5 0 01-5-5V248.12a5 5 0 015-5z"
            />
          </g>
          <text
            transform="translate(1150.957 1679.123)"
            fontSize={16}
            fill="#e23452"
            fontFamily="Roboto-Regular,Roboto"
          >
            <tspan x={10.488} y={15}>
              Quebra de regra
            </tspan>
          </text>
          <text
            transform="translate(1117 1721.123)"
            fill="#677ea0"
            fontFamily="Roboto-Regular,Roboto"
            fontSize={14}
          >
            <tspan x={0} y={13}>
              {'Colaborador n\xE3o possui folgas '}
            </tspan>
            <tspan x={0} y={29}>
              em domingos.
            </tspan>
          </text>
          <path d="M1117 1704.623h193.914" stroke="#e23452" fill="none" />
          <path
            d="M1148.509 1694.217a1.224 1.224 0 01-1.059 1.834h-12.226a1.224 1.224 0 01-1.059-1.834l6.113-10.599a1.224 1.224 0 012.122 0zm-7.172-2.191a1.172 1.172 0 101.172 1.172 1.172 1.172 0 00-1.172-1.172zm-1.113-4.213l.189 3.465a.306.306 0 00.305.289h1.238a.306.306 0 00.305-.289l.189-3.465a.306.306 0 00-.305-.322h-1.615a.306.306 0 00-.305.322z"
            fill="#e23452"
          />
        </g>
      </g>
    </svg>
  );
}

export default BoxAvoidBreakingRulesIcon;
