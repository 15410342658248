import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';

// Componentes
import Layout from 'components/layout/Layout';
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import Button from 'components/button/Button';

// Estilos
import { greenColor, purpleColor, tomatoColor } from 'styles/Colors';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Serviços
import { sendEventAnalytics } from 'services/Analytics';

// Actions
import { openModalDemo } from 'components/modal/modalDemo/ModalDemoAction';
import { openModalAccount } from 'components/modal/modalAccount/ModalAccountAction';

function PlansPage() {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const onOpenDemo = () => {
    sendEventAnalytics('MODULES/PLANS/DEMO');
    dispatch(openModalDemo());
  };

  const onOpenAccount = () => {
    sendEventAnalytics('MODULES/PLANS/ACCOUNT');
    dispatch(openModalAccount());
  };

  return (
    <Layout>
      <ContentPage>
        <Title>{translate('Conheca_nossos_planos')}</Title>

        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={6} lg={3} xl={3} alignItems="center" justify="center" paddingRight>
            <BoxContentStripe>
              <TitleStripe>{translate('StarGrid_Light')}</TitleStripe>
              <Subtitle>{translate('Ideal_para_quem_precisa_de_agilidade_na_criacao_de_diversos_tipos_de_escalas')}</Subtitle>

              <Label>{translate('Funcionalidades')}:</Label>
              <Text><IconYes icon={faCheck} />{translate('Criacao_inteligente_de_escalas')}</Text>
              <Text><IconYes icon={faCheck} />{translate('Impressao_de_escalas')}</Text>
              <Text><IconYes icon={faCheck} />{translate('App_para_gestores')}</Text>
              <Text><IconNo icon={faTimes} />{translate('App_para_colaboradores')}</Text>
              <Text><IconNo icon={faTimes} />{translate('Pedido_de_folga_pelo_app')}</Text>
              <Text><IconNo icon={faTimes} />{translate('Pedido_de_troca_pelo_app')}</Text>

              <Button
                type="button"
                id="id_bnutton_plans_light"
                label={translate('Experimente_gratis_por_30_dias')}
                colors="purple"
                margins="16px 0px 0px 0px"
                fullWidth
                onClick={() => onOpenAccount()}
              />

            </BoxContentStripe>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={3} xl={3} alignItems="center" justify="center">
            <BoxContentStripe>
              <TitleStripe>{translate('StarGrid_Business')}</TitleStripe>
              <Subtitle>{translate('Ideal_para_quem_deseja_que_o_colabodor_tenha_a_sua_escala_na_palma_da_mao')}</Subtitle>

              <Label>{translate('Funcionalidades')}:</Label>
              <Text><IconYes icon={faCheck} />{translate('Criacao_inteligente_de_escalas')}</Text>
              <Text><IconYes icon={faCheck} />{translate('Impressao_de_escalas')}</Text>
              <Text><IconYes icon={faCheck} />{translate('App_para_gestores')}</Text>
              <Text><IconYes icon={faCheck} />{translate('App_para_colaboradores')}</Text>
              <Text><IconYes icon={faCheck} />{translate('Pedido_de_folga_pelo_app')}</Text>
              <Text><IconYes icon={faCheck} />{translate('Pedido_de_troca_pelo_app')}</Text>

              <Button
                type="button"
                id="id_bnutton_plans_business"
                label={translate('Marque_uma_demonstracao')}
                colors="purple"
                margins="16px 0px 0px 0px"
                fullWidth
                onClick={() => onOpenDemo()}
              />

            </BoxContentStripe>
          </GridItem>
        </GridContainer>
      </ContentPage>
    </Layout>
  );
}

export default memo(PlansPage);

// ==================================================
// Estilos
// ==================================================
const ContentPage = styled.div`
  width: 100%;
  min-height: calc(100vh - 200px) !important;
  background-color: ${purpleColor[9]};
  min-height: 500px;
  padding: 24px;
`;

const Title = styled.div`
  font: bold 25px Noto Sans;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  margin: 0px 0px 20px 0px;
`;

const BoxContentStripe = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 25px;
  background-color: #FFFFFF;
  margin: 12px 12px;
`;

const TitleStripe = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #1A1A1A;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: #00000070;
  margin: 16px 0px 16px 0px;
  line-height: 1.5
`;

const Label = styled.div`
  font-size: 14px;
  color: ${purpleColor[9]};
  font-weight: bold;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.2;
  color: #1A1A1A;
  margin: 8px 0px 0px 0px;
`;

const IconYes = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${greenColor[8]};
  margin: 0px 12px 0px 0px;
`;

const IconNo = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${tomatoColor};
  margin: 0px 12px 0px 0px;
`;
