import axios from 'axios';
import { toastr } from 'react-redux-toastr';

// Serviços
import { sendEventAnalytics } from 'services/Analytics';

// ==================================================
// FECHAR MODAL
// ==================================================
export function closeModalSupport() {
  return {
    type: 'CLOSE_MODAL_SUPPORT_FETCHED',
    payload: false,
  };
}

// ==================================================
// ABRIR MODAL
// ==================================================
function openModal() {
  return {
    type: 'OPEN_MODAL_SUPPORT_FETCHED',
    payload: true,
  };
}
export function openModalSupport() {
  sendEventAnalytics('EVENT/SUPPPORT/OPEN');
  return [
    openModal(),
  ];
}

// ==================================================
// Enviar mensagem
// ==================================================
export function sendMessage(department, name_from, phone, email_from, job, company, message) {
  const body = {
    subject: department === 'suporte' ? 'Suporte via site' : 'Contato via site',
    name_from,
    email_from,
    phone,
    job,
    department,
    company,
    message,
    salt: 'YjM1YjQ5NjI1ZjJmMzQxYzg5Mzc3OGJiNGIzZGIzNzU=',
  };

  sendEventAnalytics('EVENT/SUPPPORT/SEND');

  return (dispatch) => {
    axios.post(`${process.env.REACT_APP_API_URL}/v1/general/send-email`, body)
      .then(() => {
        toastr.success('Obrigado!', 'Mensagem enviada com sucesso.');
        dispatch(closeModalSupport());
      })
      .catch(
        (e) => {
          if (e.response.data.data) {
            if (e.response.data.data.errors) {
              const errors = Object.values(e.response.data.data.errors);
              errors.map(err => (
                err.map(e => (
                  toastr.warning('Atenção:', e)
                ))
              ));
            } else if (e.response.data.data.message) {
              toastr.warning('Atenção:', e.response.data.data.message);
            } else {
              toastr.error('Atenção:', 'Contate o suporte.');
            }
          } else {
            toastr.error('Atenção:', 'Contate o suporte.');
          }
        },
      );
  };
}
