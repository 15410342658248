import styled from 'styled-components/macro';

// Componentes
// import CloseIcon from 'componentsV2_DEPRECATED/icons/CloseIcon';

// Utilitários
// import { purpleColor, greyColor, greenColor } from 'utilsV3/Colors';

// export const Title = styled.div`
//   font-size: 20px;
//   color: ${purpleColor[0]};
// `;

// export const CloseIconSG = styled(CloseIcon)`
//   cursor: pointer;
//   transition: all 200ms;
//   &:hover{
//     transition-duration: 200ms;
//     transform: rotateZ(-90deg);
//   }
// `;

// export const TitleContent = styled.div`
//   border-bottom: 1px solid ${greyColor[15]};
//   min-height: 54px;
//   max-height: 54px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-right: 30px;
//   margin-left: 30px;
// `;

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.justifycontent ? props.justifycontent : 'center')};
  border: 1px inset ${({ theme }) => theme.lineInsetGrey};
  background-color: ${({ theme }) => theme.background[1]};
  padding: ${props => (props.padding ? props.padding : '0px 0px 0px 0px')};
  border-radius: 8px;
  margin: ${props => (props.margin ? props.margin : '0px 0px 0px 0px')};
`;

export const BoxButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.justifycontent ? props.justifycontent : 'flex-end')};
  align-items: center;
  height: 60px;
  margin-top: 0px;
  padding: 0px 30px 0px 30px;
`;

export const ModalContent = styled.div`

  display: flex;
  flex-direction: ${props => (props.flexdirection ? props.flexdirection : 'column')};
  align-items: ${props => (props.alignitems ? props.alignitems : 'center')};;
  justify-content: ${props => (props.justifycontent ? props.justifycontent : 'center')};
  padding: ${props => (props.padding ? props.padding : '15px 30px 0px 30px')};

  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
    min-height: ${props => (props.minheight ? props.minheight : '120px')};
    max-height: ${props => (props.maxheight ? props.maxheight : '520px')};
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
    min-height: ${props => (props.minheight ? props.minheight : '120px')};
    max-height: ${props => (props.maxheight ? props.maxheight : '520px')};
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 100%;
    min-height: ${props => (props.minheight ? props.minheight : '120px')};
    max-height: ${props => (props.maxheight ? props.maxheight : '520px')};
  }
  @media (min-width: 1920px) {
    width: 100%;
    min-height: ${props => (props.minheight ? props.minheight : '120px')};
    max-height: ${props => (props.maxheight ? props.maxheight : '520px')};
  }
`;

export const ModalContentRow = styled.div`
  width: 100%;
  min-height: ${props => (props.minheight ? props.minheight : '120px')};
  max-height: ${props => (props.maxheight ? props.maxheight : '520px')};
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.justifycontent ? props.justifycontent : 'center')};
  align-items: center;
  padding: ${props => (props.padding ? props.padding : '15px 30px 0px 30px')};
`;

export const Visible = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
`;

export const TextA = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: ${props => (props.bold ? 'bold' : 'lighter')};
`;

export const TextB = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: ${props => (props.bold ? 'bold' : 'lighter')};
  margin-bottom: 10px;
`;

// export const SystemData = styled.div`
//   width: 100%;
//   font-size: 12px;
//   color: ${greenColor[0]};
//   margin-top: 5px;
// `;
