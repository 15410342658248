import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import styled from 'styled-components/macro';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

function MessageToasterComponent() {
  return (
    <Toastr>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar
      />
    </Toastr>
  );
}

export default MessageToasterComponent;

// ==================================================
// Estilos
// ==================================================
const Toastr = styled.div`
  .redux-toastr .toastr.rrt-success {
    background-color: #FFF;
    color: #10958c;
  }
  .redux-toastr .toastr.rrt-warning {
    background-color: #FFF;
    color: #10958c;
  }
  .redux-toastr .toastr.rrt-error {
    background-color: #FFF;
    color: #10958c;
  }

  .rrt-progress-container {
    background-color: transparent;
  }

  .redux-toastr .toastr.rrt-success .rrt-progressbar {
    background-color: #88e5df;
  }
  .redux-toastr .toastr.rrt-warning .rrt-progressbar {
    background-color: #FF4500;
  }
  .redux-toastr .toastr.rrt-error .rrt-progressbar {
    background-color: #FF0000;
  }

  .redux-toastr .toastr.rrt-success .rrt-left-container {
    width: 5px;
    background-color: #88e5df;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0;
  }
  .redux-toastr .toastr.rrt-warning .rrt-left-container {
    width: 5px;
    background-color: #FF4500;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0;
  }
  .redux-toastr .toastr.rrt-error .rrt-left-container {
    width: 5px;
    background-color: #FF0000;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0;
  }

  .redux-toastr .toastr {
    background-color: #fcfcfc;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 0px;
    position: relative;
    z-index: 1;
    color: #333;
    opacity: .94;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
  }

  .redux-toastr .toastr .rrt-middle-container {
    width: 100%;
    margin-left: 15px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px;
    display: flex;
  }

  .redux-toastr .toastr .rrt-middle-container .rrt-title {
    margin-top: 15px;
  }

  .redux-toastr .toastr .rrt-middle-container .rrt-text {
    margin-top: 16px;
    margin-left: 15px;
  }

  .redux-toastr .top-center,
  .redux-toastr .bottom-center {
  }

  .redux-toastr .top-left,
  .redux-toastr .top-right,
  .redux-toastr .top-center,
  .redux-toastr .bottom-left,
  .redux-toastr .bottom-right,
  .redux-toastr .bottom-center {
    width: 500px;
    position: fixed;
    z-index: 99999999;
    padding: 0 10px;
  }

  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
    top: 80px;
  }
`;
