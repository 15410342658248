import { renderRoutes } from 'react-router-config';

// Modulos
import HomePage from 'modules/home/HomePage';
import SwapRequestPage from 'modules/swapRequest/SwapRequestPage';
import WorkschedulePage from 'modules/workschedule/WorkschedulePage';
import IntegrationPage from 'modules/integration/IntegrationPage';
import BlogPage from 'modules/blog/BlogPage';
// import TrainingPage from 'modules/training/TrainingPage';
// import VideoPage from 'modules/video/VideoPage';
import PlansPage from 'modules/plans/PlansPage';
import PrivacyPolicyPage from 'modules/privacyPolicy/PrivacyPolicyPage';
import SignUpPage from 'modules/signUp/SignUpPage';
import DemoPage from 'modules/demo/DemoPage';

// Rotas
import PageRoot from 'routes/PageRoot';
import Paths, { route } from 'routes/Paths';
// import { ADM, MAN, EMP } from 'utils/Roles';

export const routes = [
  // ROTAS ABERTAS
  route(Paths.home, HomePage),
  route(Paths.swapRequest, SwapRequestPage),
  route(Paths.workschedule, WorkschedulePage),
  route(Paths.integration, IntegrationPage),
  route(Paths.blog, BlogPage),
  // route(Paths.training, TrainingPage),
  // route(Paths.video, VideoPage),
  route(Paths.plans, PlansPage),
  route(Paths.privacyPolicy, PrivacyPolicyPage),
  route(Paths.signUp, SignUpPage),
  route(Paths.demo, DemoPage),
];

export default renderRoutes([
  {
    component: PageRoot,
    routes,
  },
]);
