const Paths = {
  // ==================================================
  // Rotas
  // ==================================================
  home: '/',
  integration: '/integracoes',
  swapRequest: '/trocas-de-folgas',
  workschedule: '/escalas-de-trabalho',
  plans: '/plans',
  privacyPolicy: '/politica-de-privacidade',
  blog: '/blog',
  signUp: '/sign-up',
  demo: '/demonstracao',
  // training: '/training',
  // video: '/video',

  // ==================================================
  // Validar rotas existentes
  // ==================================================
  get existRoutes() {
    return [
      `^${this.home}$`,
      `^${this.integration}$`,
      `^${this.swapRequest}$`,
      `^${this.workschedule}$`,
      `^${this.plans}$`,
      `^${this.privacyPolicy}$`,
      `^${this.blog}$`,
      `^${this.signUp}$`,
      `^${this.demo}$`,
      // `^${this.training}$`,
      // `^${this.video}$`,
    ];
  },
  exist(route) {
    return this.existRoutes.find(path => RegExp(path).test(route));
  },

  // ==================================================
  // Validar rotas publicas
  // ==================================================
  get publicRoutes() {
    return [
      `^${this.home}$`,
      `^${this.integration}$`,
      `^${this.swapRequest}$`,
      `^${this.workschedule}$`,
      `^${this.plans}$`,
      `^${this.privacyPolicy}$`,
      `^${this.blog}$`,
      `^${this.signUp}$`,
      `^${this.demo}$`,
      // `^${this.training}$`,
      // `^${this.video}$`,
    ];
  },
  isPublic(route) {
    return !!this.publicRoutes.find(path => RegExp(path).test(route));
  },
};

export const route = (path, component, opts = {}) => ({
  path,
  component,
  exact: true,
  roles: opts.roles || [],
  employeeOnly: opts.employeeOnly,
});

export default Paths;
