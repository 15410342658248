import * as React from 'react';

function BoxFacilitateEngagementIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={577}
      height={777}
      viewBox="0 0 577 777"
      {...props}
    >
      <defs>
        <filter
          id="prefix_FFF_a"
          x={1}
          y={0}
          width={336}
          height={603}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="blur" />
          <feFlood floodOpacity={0.063} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_FFF_b"
          x={17}
          y={127}
          width={303}
          height={197}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-2" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_FFF_c"
          x={17}
          y={214}
          width={303}
          height={197}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-3" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_FFF_d"
          x={240}
          y={174}
          width={337}
          height={603}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="blur-4" />
          <feFlood floodOpacity={0.063} />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Grupo 20705" transform="translate(.019 39.939)">
        <circle
          data-name="Elipse 672"
          cx={113.5}
          cy={113.5}
          r={113.5}
          transform="translate(-.019 312.061)"
          fill="#f1e7fc"
          opacity={0.352}
        />
        <g data-name="Grupo 20779" transform="translate(60.539)">
          <g transform="translate(-60.56 -39.94)" filter="url(#prefix_FFF_a)">
            <g
              data-name="Ret\xE2ngulo 2802"
              transform="translate(61 40)"
              fill="#fff"
              stroke="#dee6f2"
            >
              <rect width={216} height={483} rx={20} stroke="none" />
              <rect
                x={0.5}
                y={0.5}
                width={215}
                height={482}
                rx={19.5}
                fill="none"
              />
            </g>
          </g>
          <g
            data-name="Ret\xE2ngulo 2803"
            transform="translate(8.442 13.061)"
            fill="#f0f0f0"
            stroke="#dee6f2"
            opacity={0.701}
          >
            <rect width={199} height={455} rx={20} stroke="none" />
            <rect
              x={0.5}
              y={0.5}
              width={198}
              height={454}
              rx={19.5}
              fill="none"
            />
          </g>
          <text
            data-name="Ol\xE1, Eduardo"
            transform="translate(21.442 82.061)"
            fill="#677ea0"
            fontSize={16}
            fontFamily="Roboto-Regular, Roboto"
            letterSpacing=".04em"
          >
            <tspan x={0} y={0}>
              {'Ol\xE1, Eduardo'}
            </tspan>
          </text>
          <g transform="translate(-60.563 -39.935)" filter="url(#prefix_FFF_b)">
            <g
              data-name="Ret\xE2ngulo 2416"
              transform="translate(77 177)"
              fill="#fff"
              stroke="#dee6f2"
              opacity={0.997}
            >
              <rect width={183} height={77} rx={10} stroke="none" />
              <rect
                x={0.5}
                y={0.5}
                width={182}
                height={76}
                rx={9.5}
                fill="none"
              />
            </g>
          </g>
          <g data-name="Grupo 20409" transform="translate(30.782 185.721)">
            <rect
              data-name="Ret\xE2ngulo 2417"
              width={158}
              height={19}
              rx={9.5}
              transform="translate(-.34 .34)"
              fill="#f2f2f2"
              opacity={0.653}
            />
            <text
              data-name="15/07"
              transform="translate(16.66 13.34)"
              fill="#1e2b3c"
              fontSize={9}
              fontFamily="Roboto-Regular, Roboto"
            >
              <tspan x={0} y={0}>
                15/07
              </tspan>
            </text>
            <text
              data-name="21/07"
              transform="translate(102.66 13.34)"
              fill="#1e2b3c"
              fontSize={9}
              fontFamily="Roboto-Regular, Roboto"
            >
              <tspan x={0} y={0}>
                21/07
              </tspan>
            </text>
            <g
              data-name="Icon feather-refresh-ccw"
              fill="none"
              stroke="#009b8d"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
            >
              <path data-name="Caminho 2729" d="M73.378 6.976v2.247h2.247" />
              <path data-name="Caminho 2730" d="M81.618 12.968v-2.247h-2.247" />
              <path
                data-name="Caminho 2731"
                d="M80.678 8.849a3.371 3.371 0 00-5.562-1.259l-1.738 1.633m8.24 1.5l-1.74 1.631a3.371 3.371 0 01-5.56-1.255"
              />
            </g>
          </g>
          <g data-name="Grupo 20760" transform="translate(30.782 147.756)">
            <rect
              data-name="Ret\xE2ngulo 2685"
              width={103}
              height={5}
              rx={2.5}
              transform="translate(42.66 7.305)"
              fill="#bfbfbf"
              opacity={0.297}
            />
            <rect
              data-name="Ret\xE2ngulo 2686"
              width={63}
              height={6}
              rx={3}
              transform="translate(42.66 17.305)"
              fill="#bfbfbf"
              opacity={0.297}
            />
            <ellipse
              data-name="Elipse 635"
              cx={14.5}
              cy={14}
              rx={14.5}
              ry={14}
              transform="translate(-.34 .305)"
              fill="rgba(151,81,235,0.45)"
            />
            <path
              data-name="Icon awesome-user-alt"
              d="M14.162 15.019a2.88 2.88 0 10-2.879-2.879 2.88 2.88 0 002.879 2.879zm2.56.64h-1.1a3.481 3.481 0 01-2.915 0h-1.104a2.559 2.559 0 00-2.56 2.559v.32a.96.96 0 00.96.96h8.318a.96.96 0 00.96-.96v-.32a2.559 2.559 0 00-2.559-2.558z"
              fill="#fff"
            />
          </g>
          <g data-name="card">
            <g transform="translate(-60.563 -39.944)" filter="url(#prefix_FFF_c)">
              <g
                data-name="Ret\xE2ngulo 2416"
                transform="translate(77 264)"
                fill="#fff"
                stroke="#dee6f2"
                opacity={0.997}
              >
                <rect width={183} height={77} rx={10} stroke="none" />
                <rect
                  x={0.5}
                  y={0.5}
                  width={182}
                  height={76}
                  rx={9.5}
                  fill="none"
                />
              </g>
            </g>
            <g data-name="Grupo 20409" transform="translate(30.782 271.912)">
              <rect
                data-name="Ret\xE2ngulo 2417"
                width={158}
                height={19}
                rx={9.5}
                transform="translate(-.34 .149)"
                fill="#f2f2f2"
                opacity={0.653}
              />
              <text
                data-name="17/07"
                transform="translate(16.66 13.149)"
                fill="#1e2b3c"
                fontSize={9}
                fontFamily="Roboto-Regular, Roboto"
              >
                <tspan x={0} y={0}>
                  17/07
                </tspan>
              </text>
              <text
                data-name="24/07"
                transform="translate(102.66 13.149)"
                fill="#1e2b3c"
                fontSize={9}
                fontFamily="Roboto-Regular, Roboto"
              >
                <tspan x={0} y={0}>
                  24/07
                </tspan>
              </text>
              <g
                data-name="Icon feather-refresh-ccw"
                fill="none"
                stroke="#009b8d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              >
                <path data-name="Caminho 2729" d="M73.378 7.111v2.247h2.247" />
                <path
                  data-name="Caminho 2730"
                  d="M81.618 13.103v-2.247h-2.247"
                />
                <path
                  data-name="Caminho 2731"
                  d="M80.678 8.984a3.371 3.371 0 00-5.562-1.259l-1.738 1.633m8.24 1.5l-1.74 1.631a3.371 3.371 0 01-5.56-1.255"
                />
              </g>
            </g>
            <g data-name="Grupo 20760" transform="translate(30.782 234.973)">
              <rect
                data-name="Ret\xE2ngulo 2685"
                width={78}
                height={5}
                rx={2.5}
                transform="translate(42.66 6.088)"
                fill="#bfbfbf"
                opacity={0.297}
              />
              <rect
                data-name="Ret\xE2ngulo 2686"
                width={63}
                height={6}
                rx={3}
                transform="translate(42.66 16.088)"
                fill="#bfbfbf"
                opacity={0.297}
              />
              <ellipse
                data-name="Elipse 635"
                cx={14.5}
                cy={14}
                rx={14.5}
                ry={14}
                transform="translate(-.34 .088)"
                fill="rgba(180,147,219,0.45)"
              />
              <path
                data-name="Icon awesome-user-alt"
                d="M14.162 14.128a2.88 2.88 0 10-2.879-2.879 2.88 2.88 0 002.879 2.879zm2.56.64h-1.1a3.481 3.481 0 01-2.915 0h-1.104a2.559 2.559 0 00-2.56 2.559v.32a.96.96 0 00.96.96h8.318a.96.96 0 00.96-.96v-.32a2.559 2.559 0 00-2.559-2.558z"
                fill="#fff"
              />
            </g>
          </g>
          <text
            data-name="Solicita\xE7\xF5es de troca"
            transform="translate(20.442 109.061)"
            fill="#191919"
            fontSize={12}
            fontFamily="Roboto-Bold, Roboto"
            fontWeight={700}
            letterSpacing=".04em"
            opacity={0.778}
          >
            <tspan x={0} y={11}>
              {'Solicita\xE7\xF5es de troca'}
            </tspan>
          </text>
          <g data-name="Ret\xE2ngulo 2804" fill="#fff" stroke="#dee6f2">
            <path
              d="M8.442 429.061h199v19a20 20 0 01-20 20h-159a20 20 0 01-20-20v-19z"
              stroke="none"
            />
            <path
              d="M9.442 429.561h197a.5.5 0 01.5.5v18a19.5 19.5 0 01-19.5 19.5h-159a19.5 19.5 0 01-19.5-19.5v-18a.5.5 0 01.5-.5z"
              fill="none"
            />
          </g>
          <g
            data-name="Icon feather-home"
            opacity={0.981}
            fill="none"
            stroke="#677ea0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          >
            <path
              data-name="Caminho 4324"
              d="M52.3 442.817l5.38-4.184 5.38 4.184v6.576a1.2 1.2 0 01-1.2 1.2H53.5a1.2 1.2 0 01-1.2-1.2z"
            />
            <path
              data-name="Caminho 4325"
              d="M55.887 450.589v-5.978h3.587v5.978"
            />
          </g>
          <g
            data-name="Icon feather-bell"
            opacity={0.572}
            fill="none"
            stroke="#677ea0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          >
            <path
              data-name="Caminho 4326"
              d="M161.115 442.22a3.587 3.587 0 10-7.174 0c0 4.185-1.794 5.381-1.794 5.381h10.762s-1.794-1.2-1.794-5.381"
            />
            <path
              data-name="Caminho 4327"
              d="M158.563 449.993a1.2 1.2 0 01-2.069 0"
            />
          </g>
          <circle
            data-name="Elipse 683"
            cx={2}
            cy={2}
            r={2}
            transform="translate(162.442 436.061)"
            fill="#fc6767"
          />
          <g>
            <path
              data-name="Caminho 1"
              d="M34.573 41.368v-.063a.021.021 0 00-.021-.021c0-.021-.021-.021-.021-.042l-.021-.021-3.58-2.612a.3.3 0 00-.147-.042h-2.591a.3.3 0 00-.126.042v-2.127l-.021-.021c0-.021-.021-.021-.021-.042l-.021-.021-1.685-1.222v-2.461a3.155 3.155 0 011.832-2.864 3.047 3.047 0 011.622 2.675v3.728a.221.221 0 00.105.19l1.79 1.306a.3.3 0 00.147.042h2.548a.239.239 0 00.232-.253v-5.038a5.276 5.276 0 00-5.284-5.286h-2.614a5.29 5.29 0 00-5.286 5.286v3.686a.221.221 0 00.105.19l3.559 2.591a.3.3 0 00.147.042h2.589a.3.3 0 00.126-.042v2.022a.221.221 0 00.105.19l1.685 1.222v2.064a2.608 2.608 0 01-2.527 2.612 2.647 2.647 0 01-.927-2v-3.794a.314.314 0 00-.084-.19l-1.79-1.285a.3.3 0 00-.147-.042h-2.588a.246.246 0 00-.232.168v3.833a5.746 5.746 0 002.3 4.57l1.727 1.264a5.675 5.675 0 009.119-4.528v-3.659c.021-.021.021-.042 0-.042m-3.875-2.317l2.906 2.106h-1.773l-2.906-2.106zm-6.529-3.517h1.769l1.116.821h-1.773zm1.618-2.824v2.338h-2.106v-2.531a3.029 3.029 0 013.012-3.033 3.659 3.659 0 01.821.105 3.684 3.684 0 00-1.727 3.121m3.5-5.034a4.8 4.8 0 014.8 4.8v4.823h-2.087v-4.8a5.271 5.271 0 00-3.1-4.8h.379zm-7.392 4.823a4.809 4.809 0 014.781-4.8h.063a4.782 4.782 0 014.781 4.8v4.591l-1.314-.971v-3.6a3.508 3.508 0 10-7.013-.19v2.928a.221.221 0 00.105.19l1.685 1.222v1.6l-3.1-2.211zm5.687 6.045h-2.111v-1.734h2.106zm2.612 5.939v-2.193a.221.221 0 00-.105-.19l-1.685-1.222v-1.6l3.1 2.232v3.58a2.629 2.629 0 01-2.612 2.633 2.737 2.737 0 01-1.074-.232 3.085 3.085 0 002.38-3.012m-6.024-4.212l1.116.821h-1.778l-1.116-.821zm-2.284 3.543v-3.31l1.306.948v3.662a5.678 5.678 0 00.779 2.864 5.3 5.3 0 01-2.085-4.17m12.216 1.287a5.2 5.2 0 01-5.223 5.2 5.283 5.283 0 01-3.054-.99l-.105-.084a5.29 5.29 0 01-2.064-4.128v-3.536h2.106v3.538a3.107 3.107 0 006.213 0v-3.454h2.106z"
              fill="#243e64"
            />
          </g>
        </g>
        <g data-name="Grupo 21315" transform="translate(299.617 174.434)">
          <g data-name="Grupo 20778">
            <g transform="translate(-299.64 -214.37)" filter="url(#prefix_FFF_d)">
              <g
                data-name="Ret\xE2ngulo 2802"
                transform="translate(300 214)"
                fill="#fff"
                stroke="#dee6f2"
              >
                <rect width={217} height={483} rx={20} stroke="none" />
                <rect
                  x={0.5}
                  y={0.5}
                  width={216}
                  height={482}
                  rx={19.5}
                  fill="none"
                />
              </g>
            </g>
            <g
              data-name="Ret\xE2ngulo 2803"
              transform="translate(8.365 12.627)"
              fill="#f0f0f0"
              stroke="#dee6f2"
              opacity={0.703}
            >
              <rect width={199} height={454} rx={20} stroke="none" />
              <rect
                x={0.5}
                y={0.5}
                width={198}
                height={453}
                rx={19.5}
                fill="none"
              />
            </g>
            <text
              data-name="Meu grid"
              transform="translate(74.364 88.627)"
              fill="#9751eb"
              fontSize={16}
              fontFamily="Roboto-Regular, Roboto"
              letterSpacing=".04em"
            >
              <tspan x={0} y={0}>
                Meu grid
              </tspan>
            </text>
            <g data-name="Ret\xE2ngulo 2804" fill="#fff" stroke="#dee6f2">
              <path
                d="M8.365 427.627h199v19a20 20 0 01-20 20h-159a20 20 0 01-20-20v-19z"
                stroke="none"
              />
              <path
                d="M9.365 428.127h197a.5.5 0 01.5.5v18a19.5 19.5 0 01-19.5 19.5h-159a19.5 19.5 0 01-19.5-19.5v-18a.5.5 0 01.5-.5z"
                fill="none"
              />
            </g>
            <g data-name="Ret\xE2ngulo 2805" fill="#fff" stroke="#dee6f2">
              <path
                d="M207.365 61.627h-199v-29a20 20 0 0120-20h159a20 20 0 0120 20v29z"
                stroke="none"
              />
              <path
                d="M206.365 61.127h-197a.5.5 0 01-.5-.5v-28a19.5 19.5 0 0119.5-19.5h159a19.5 19.5 0 0119.5 19.5v28a.5.5 0 01-.5.5z"
                fill="none"
              />
            </g>
            <g data-name="Layer_2_1_">
              <g data-name="Layer_1-2">
                <path
                  data-name="Caminho 1"
                  d="M34.712 40.626v-.063a.021.021 0 00-.021-.021c0-.021-.021-.021-.021-.042l-.021-.021-3.58-2.612a.3.3 0 00-.147-.042h-2.591a.3.3 0 00-.126.042V35.74l-.021-.021c0-.021-.021-.021-.021-.042l-.021-.021-1.685-1.222v-2.461a3.155 3.155 0 011.832-2.864 3.047 3.047 0 011.622 2.675v3.728a.221.221 0 00.105.19l1.79 1.306a.3.3 0 00.147.042h2.548a.239.239 0 00.232-.253v-5.038a5.276 5.276 0 00-5.284-5.286h-2.614a5.29 5.29 0 00-5.286 5.286v3.686a.221.221 0 00.105.19l3.559 2.591a.3.3 0 00.147.042h2.589a.3.3 0 00.126-.042v2.022a.221.221 0 00.105.19l1.685 1.222v2.064a2.608 2.608 0 01-2.527 2.612 2.647 2.647 0 01-.927-2v-3.794a.314.314 0 00-.084-.19l-1.79-1.285a.3.3 0 00-.147-.042h-2.588a.246.246 0 00-.232.168v3.833a5.746 5.746 0 002.3 4.57l1.727 1.264a5.675 5.675 0 009.119-4.528v-3.659c.021-.021.021-.042 0-.042m-3.875-2.317l2.906 2.106h-1.773l-2.906-2.106zm-6.529-3.517h1.769l1.116.821h-1.773zm1.618-2.824v2.338h-2.106V31.78a3.029 3.029 0 013.012-3.033 3.659 3.659 0 01.821.105 3.684 3.684 0 00-1.727 3.121m3.5-5.034a4.8 4.8 0 014.8 4.8v4.823h-2.087v-4.8a5.271 5.271 0 00-3.1-4.8h.379zm-7.392 4.823a4.809 4.809 0 014.781-4.8h.063a4.782 4.782 0 014.781 4.8v4.591l-1.314-.971v-3.6a3.508 3.508 0 10-7.013-.19v2.928a.221.221 0 00.105.19l1.685 1.222v1.6l-3.1-2.211zm5.687 6.045h-2.111v-1.734h2.106zm2.612 5.939v-2.193a.221.221 0 00-.105-.19l-1.685-1.222v-1.6l3.1 2.232v3.58a2.629 2.629 0 01-2.612 2.633 2.737 2.737 0 01-1.074-.232 3.085 3.085 0 002.38-3.012m-6.024-4.212l1.116.821h-1.778l-1.116-.821zm-2.284 3.543v-3.31l1.306.948v3.662a5.678 5.678 0 00.779 2.864 5.3 5.3 0 01-2.085-4.17m12.216 1.287a5.2 5.2 0 01-5.223 5.2 5.283 5.283 0 01-3.054-.99l-.105-.084a5.29 5.29 0 01-2.064-4.128v-3.536h2.106v3.538a3.107 3.107 0 006.213 0V40.9h2.106z"
                  fill="#243e64"
                />
              </g>
            </g>
            <g data-name="Grupo 20780" transform="translate(169.304 25.652)">
              <ellipse
                data-name="Elipse 684"
                cx={12.5}
                cy={12}
                rx={12.5}
                ry={12}
                transform="translate(.061 -.025)"
                fill="rgba(151,81,235,0.45)"
              />
              <path
                data-name="Icon awesome-user-alt"
                d="M12.176 12.373a2.487 2.487 0 10-2.487-2.487 2.487 2.487 0 002.487 2.487zm2.21.553h-.951a3.006 3.006 0 01-2.518 0h-.952a2.21 2.21 0 00-2.21 2.21v.276a.829.829 0 00.829.829h7.184a.829.829 0 00.829-.829v-.276a2.21 2.21 0 00-2.211-2.211z"
                fill="#fff"
              />
            </g>
            <g data-name="Grupo 21314" transform="translate(12.313 108.765)">
              <rect
                data-name="Ret\xE2ngulo 2806"
                width={190}
                height={196}
                rx={10}
                transform="translate(.051 -.138)"
                fill="#fff"
              />
              <g transform="translate(5.723 14.429)" opacity={0.756}>
                <text
                  transform="translate(86.651 9)"
                  fill="#0e968c"
                  fontSize={10}
                  fontFamily="Roboto-Bold, Roboto"
                  fontWeight={700}
                  letterSpacing=".08em"
                >
                  <tspan x={-17.376} y={0}>
                    JULHO
                  </tspan>
                </text>
                <path
                  data-name="Caminho 366"
                  d="M9.237 4.038L6.215 7.06l3.022 3.022"
                  fill="none"
                  stroke="#009b8d"
                  strokeWidth={2.4}
                />
                <path
                  data-name="Caminho 367"
                  d="M167.579 4.038l3.022 3.022-3.022 3.022"
                  fill="none"
                  stroke="#009b8d"
                  strokeWidth={2.4}
                />
                <ellipse
                  data-name="Elipse 17"
                  cx={7.415}
                  cy={7.183}
                  rx={7.415}
                  ry={7.183}
                  transform="translate(26.176 90.407)"
                  fill="#0d968a"
                />
                <ellipse
                  data-name="Elipse 687"
                  cx={7.415}
                  cy={7.183}
                  rx={7.415}
                  ry={7.183}
                  transform="translate(80.759 69.606)"
                  fill="#0d968a"
                />
                <ellipse
                  data-name="Elipse 685"
                  cx={7.415}
                  cy={7.183}
                  rx={7.415}
                  ry={7.183}
                  transform="translate(106.262 107.049)"
                  fill="#0d968a"
                />
                <ellipse
                  data-name="Elipse 686"
                  cx={7.415}
                  cy={7.183}
                  rx={7.415}
                  ry={7.183}
                  transform="translate(132.263 124.73)"
                  fill="#0d968a"
                />
                <g
                  data-name="Grupo 21313"
                  fontSize={8}
                  fontFamily="Inter-Regular, Inter"
                >
                  <text
                    data-name={25}
                    transform="translate(7.473 62.157)"
                    fill="#aaa"
                  >
                    <tspan x={-4.852} y={0}>
                      25
                    </tspan>
                  </text>
                  <text
                    data-name={26}
                    transform="translate(32.875 62.157)"
                    fill="#aaa"
                  >
                    <tspan x={-4.915} y={0}>
                      26
                    </tspan>
                  </text>
                  <text
                    data-name={27}
                    transform="translate(58.345 62.157)"
                    fill="#aaa"
                  >
                    <tspan x={-4.705} y={0}>
                      27
                    </tspan>
                  </text>
                  <text
                    data-name={28}
                    transform="translate(88.884 62.157)"
                    fill="#aaa"
                  >
                    <tspan x={-4.886} y={0}>
                      28
                    </tspan>
                  </text>
                  <text
                    data-name={29}
                    transform="translate(113.443 62.157)"
                    fill="#aaa"
                  >
                    <tspan x={-4.915} y={0}>
                      29
                    </tspan>
                  </text>
                  <text
                    data-name={30}
                    transform="translate(139.368 62.157)"
                    fill="#aaa"
                  >
                    <tspan x={-5.045} y={0}>
                      30
                    </tspan>
                  </text>
                  <text data-name={1} transform="translate(168.256 59.037)">
                    <tspan x={-1.858} y={0}>
                      1
                    </tspan>
                  </text>
                  <text data-name={2} transform="translate(7.367 79.191)">
                    <tspan x={-2.42} y={0}>
                      2
                    </tspan>
                  </text>
                  <text data-name={3} transform="translate(33.619 79.191)">
                    <tspan x={-2.545} y={0}>
                      3
                    </tspan>
                  </text>
                  <text data-name={4} transform="translate(59.1 79.191)">
                    <tspan x={-2.568} y={0}>
                      4
                    </tspan>
                  </text>
                  <text
                    data-name={5}
                    transform="translate(88.326 79.191)"
                    fill="#fff"
                  >
                    <tspan x={-2.432} y={0}>
                      5
                    </tspan>
                  </text>
                  <text data-name={6} transform="translate(113.807 79.191)">
                    <tspan x={-2.494} y={0}>
                      6
                    </tspan>
                  </text>
                  <text data-name={7} transform="translate(139.288 79.078)">
                    <tspan x={-2.284} y={0}>
                      7
                    </tspan>
                  </text>
                  <text data-name={8} transform="translate(168.369 79.191)">
                    <tspan x={-2.466} y={0}>
                      8
                    </tspan>
                  </text>
                  <text data-name={9} transform="translate(7.367 97.729)">
                    <tspan x={-2.494} y={0}>
                      9
                    </tspan>
                  </text>
                  <text
                    data-name={10}
                    transform="translate(33.374 99.809)"
                    fill="#fff"
                  >
                    <tspan x={-4.358} y={0}>
                      10
                    </tspan>
                  </text>
                  <text data-name={11} transform="translate(57.812 98.769)">
                    <tspan x={-3.716} y={0}>
                      11
                    </tspan>
                  </text>
                  <text data-name={12} transform="translate(89.388 99.809)">
                    <tspan x={-4.278} y={0}>
                      12
                    </tspan>
                  </text>
                  <text data-name={13} transform="translate(113.951 99.809)">
                    <tspan x={-4.403} y={0}>
                      13
                    </tspan>
                  </text>
                  <text data-name={14} transform="translate(139.408 98.769)">
                    <tspan x={-4.426} y={0}>
                      14
                    </tspan>
                  </text>
                  <text data-name={15} transform="translate(168.979 99.809)">
                    <tspan x={-4.29} y={0}>
                      15
                    </tspan>
                  </text>
                  <text data-name={16} transform="translate(7.977 117.419)">
                    <tspan x={-4.352} y={0}>
                      16
                    </tspan>
                  </text>
                  <text data-name={17} transform="translate(33.374 117.419)">
                    <tspan x={-4.142} y={0}>
                      17
                    </tspan>
                  </text>
                  <text data-name={18} transform="translate(58.846 117.419)">
                    <tspan x={-4.324} y={0}>
                      18
                    </tspan>
                  </text>
                  <text data-name={19} transform="translate(89.388 117.419)">
                    <tspan x={-4.352} y={0}>
                      19
                    </tspan>
                  </text>
                  <text
                    data-name={20}
                    transform="translate(112.976 116.379)"
                    fill="#fff"
                  >
                    <tspan x={-4.92} y={0}>
                      20
                    </tspan>
                  </text>
                  <text data-name={21} transform="translate(139.878 117.419)">
                    <tspan x={-4.278} y={0}>
                      21
                    </tspan>
                  </text>
                  <text data-name={22} transform="translate(168.475 116.379)">
                    <tspan x={-4.841} y={0}>
                      22
                    </tspan>
                  </text>
                  <text data-name={23} transform="translate(7.473 134.917)">
                    <tspan x={-4.966} y={0}>
                      23
                    </tspan>
                  </text>
                  <text data-name={24} transform="translate(32.417 134.917)">
                    <tspan x={-4.926} y={0}>
                      24
                    </tspan>
                  </text>
                  <text data-name={25} transform="translate(58.345 134.917)">
                    <tspan x={-4.852} y={0}>
                      25
                    </tspan>
                  </text>
                  <text data-name={26} transform="translate(88.884 134.917)">
                    <tspan x={-4.915} y={0}>
                      26
                    </tspan>
                  </text>
                  <text data-name={27} transform="translate(113.443 134.917)">
                    <tspan x={-4.705} y={0}>
                      27
                    </tspan>
                  </text>
                  <text
                    data-name={28}
                    transform="translate(139.368 134.917)"
                    fill="#fff"
                  >
                    <tspan x={-4.886} y={0}>
                      28
                    </tspan>
                  </text>
                  <text data-name={29} transform="translate(168.475 134.917)">
                    <tspan x={-4.915} y={0}>
                      29
                    </tspan>
                  </text>
                  <text data-name={30} transform="translate(7.473 152.991)">
                    <tspan x={-5.045} y={0}>
                      30
                    </tspan>
                  </text>
                  <text data-name={31} transform="translate(33.374 154.031)">
                    <tspan x={-4.403} y={0}>
                      31
                    </tspan>
                  </text>
                  <text
                    data-name={1}
                    transform="translate(59.023 152.991)"
                    fill="#aaa"
                  >
                    <tspan x={-1.858} y={0}>
                      1
                    </tspan>
                  </text>
                  <text
                    data-name={2}
                    transform="translate(88.326 151.951)"
                    fill="#aaa"
                  >
                    <tspan x={-2.42} y={0}>
                      2
                    </tspan>
                  </text>
                  <text
                    data-name={3}
                    transform="translate(113.767 151.951)"
                    fill="#aaa"
                  >
                    <tspan x={-2.545} y={0}>
                      3
                    </tspan>
                  </text>
                  <text
                    data-name={4}
                    transform="translate(138.793 151.951)"
                    fill="#aaa"
                  >
                    <tspan x={-2.568} y={0}>
                      4
                    </tspan>
                  </text>
                  <text
                    data-name={5}
                    transform="translate(168.369 151.951)"
                    fill="#aaa"
                  >
                    <tspan x={-2.432} y={0}>
                      5
                    </tspan>
                  </text>
                </g>
                <text
                  data-name="D s t q q s s"
                  transform="translate(84 25.794)"
                  fill="#888"
                  fontSize={8}
                  fontFamily="Inter-Regular, Inter"
                  letterSpacing="1.2em"
                >
                  <tspan x={-83.538} y={0}>
                    D S T Q Q S S
                  </tspan>
                </text>
                <path
                  data-name="Linha 58"
                  fill="none"
                  stroke="#7d80da"
                  opacity={0.203}
                  d="M4.32 39.615h171.845"
                />
              </g>
            </g>
            <g
              data-name="Icon feather-calendar"
              fill="none"
              stroke="#9751eb"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
            >
              <path
                data-name="Caminho 4328"
                d="M57.57 439.084h8.37a1.2 1.2 0 011.2 1.2v8.37a1.2 1.2 0 01-1.2 1.2h-8.37a1.2 1.2 0 01-1.2-1.2v-8.37a1.2 1.2 0 011.2-1.2z"
              />
              <path data-name="Caminho 4329" d="M64.142 437.888v2.392" />
              <path data-name="Caminho 4330" d="M59.359 437.888v2.392" />
              <path data-name="Caminho 4331" d="M56.37 442.671h10.762" />
            </g>
            <g
              data-name="Grupo 21316"
              opacity={0.516}
              fill="none"
              stroke="#677ea0"
              strokeLinecap="round"
              strokeWidth={1.5}
            >
              <path
                data-name="Caminho 4332"
                d="M156.211 439.06l-8.457 11.826 14.641-2.575z"
                strokeLinejoin="round"
              />
              <path
                data-name="Caminho 4333"
                d="M159.519 440.244s2.185-.75 1.267-2.369"
              />
              <path
                data-name="Caminho 4334"
                d="M164.717 443.17s-2.305.157-1.838 1.96"
                strokeWidth={1.5001799999999998}
              />
              <path data-name="Linha 346" d="M161.242 442.137l4.895-3.077" />
            </g>
          </g>
          <circle
            data-name="Elipse 688"
            cx={4}
            cy={4}
            r={4}
            transform="translate(18.365 321.627)"
            fill="#009b8d"
          />
          <text
            data-name="Suas folgas"
            transform="translate(65.364 328.627)"
            fill="#009b8d"
            fontSize={10}
            fontFamily="Inter-Regular, Inter"
          >
            <tspan x={-27.273} y={0}>
              Suas folgas
            </tspan>
          </text>
        </g>
        <g data-name="Grupo 20704">
          <g data-name="Grupo 20699" fill="#dee6f2">
            <g data-name="Grupo 20696" transform="translate(394.338 27.863)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 28.198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 19.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 10.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 .198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 28.198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 19.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 10.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 .198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 28.198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 19.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 10.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 .198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 28.198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 19.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 10.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 .198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 28.198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 19.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 10.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 .198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 28.198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 19.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 10.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 .198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 28.198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 19.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 10.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 .198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 28.198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 19.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 10.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 .198)"
              />
            </g>
            <g data-name="Grupo 20697" transform="translate(482.321 27.863)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
        </g>
        <g data-name="Grupo 21318">
          <g data-name="Grupo 20699" fill="#dee6f2">
            <g data-name="Grupo 20696" transform="translate(394.338 63.863)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 28.198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 19.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 10.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.357 .198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 28.198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 19.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 10.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.643 .198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 28.198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 19.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 10.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.643 .198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 28.198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 19.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 10.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.643 .198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 28.198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 19.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 10.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.643 .198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 28.198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 19.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 10.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.643 .198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 28.198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 19.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 10.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.643 .198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 28.198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 19.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 10.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.643 .198)"
              />
            </g>
            <g data-name="Grupo 20697" transform="translate(482.321 63.863)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 .198)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 9.198)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 18.198)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.34 28.198)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 .198)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 9.198)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 18.198)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(44.66 28.198)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 .198)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 9.198)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 18.198)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(10.66 28.198)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 .198)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 9.198)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 18.198)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(55.66 28.198)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 .198)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 9.198)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 18.198)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(22.66 28.198)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 .198)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 9.198)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 18.198)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(67.66 28.198)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 .198)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 9.198)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 18.198)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(33.66 28.198)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 .198)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 9.198)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 18.198)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(78.66 28.198)"
              />
            </g>
          </g>
        </g>
      </g>
      <text
        transform="translate(99 18)"
        fill="#9751eb"
        fontSize={14}
        fontFamily="NotoSans, Noto Sans"
        letterSpacing=".05em"
        opacity={0.597}
      >
        <tspan x={-28.226} y={0}>
          GESTOR
        </tspan>
      </text>
      <text
        transform="translate(366 194)"
        fill="#9751eb"
        fontSize={14}
        fontFamily="NotoSans, Noto Sans"
        letterSpacing=".05em"
        opacity={0.602}
      >
        <tspan x={-55.234} y={0}>
          COLABORADOR
        </tspan>
      </text>
    </svg>
  );
}

export default BoxFacilitateEngagementIcon;
