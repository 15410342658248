import * as React from 'react';

function BoxViewsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={474.849}
      height={463.198}
      viewBox="0 0 474.849 463.198"
      {...props}
    >
      <defs>
        <filter
          id="prefix_VWI_a"
          x={137.408}
          y={0}
          width={184.606}
          height={184.606}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={20} result="b" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_VWI_d"
          x={0}
          y={105.025}
          width={474.849}
          height={358.173}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="e" />
          <feFlood floodOpacity={0.102} />
          <feComposite operator="in" in2="e" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_VWI_c"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#009c8f" />
          <stop offset={1} stopColor="#00ccc3" />
        </linearGradient>
        <style>{'.prefix_VWI_a{fill:#fff}'}</style>
      </defs>
      <g transform="translate(-625.407 1163.033)">
        <g transform="translate(625.41 -1163.03)" filter="url(#prefix_VWI_a)">
          <circle
            className="prefix_VWI_a"
            cx={32.303}
            cy={32.303}
            r={32.303}
            transform="translate(197.41 57)"
          />
        </g>
        <g strokeDasharray={5} stroke="#677ea0" fill="none">
          <path d="M669.214-956.827h-24.717v-112.148h170.979" opacity={0.656} />
          <path d="M910.893-1068.972h102.373" />
        </g>
        <path
          d="M1069.683-1063.904v-.14a.046.046 0 00-.047-.047c0-.047-.047-.047-.047-.094l-.047-.047-8-5.838a.681.681 0 00-.33-.094h-5.795a.669.669 0 00-.283.094v-4.755l-.047-.047c0-.047-.047-.047-.047-.094l-.047-.047-3.766-2.731v-5.511a7.053 7.053 0 014.1-6.4 6.811 6.811 0 013.625 5.98v8.331a.494.494 0 00.235.424l4 2.919a.681.681 0 00.33.094h5.7a.534.534 0 00.518-.565v-11.254a11.8 11.8 0 00-11.823-11.818h-5.838a11.825 11.825 0 00-11.818 11.818v8.24a.494.494 0 00.235.424l7.957 5.791a.681.681 0 00.33.094h5.791a.669.669 0 00.283-.094v4.52a.494.494 0 00.235.424l3.769 2.73v4.614a5.83 5.83 0 01-5.65 5.838 5.918 5.918 0 01-2.072-4.473v-8.474a.7.7 0 00-.188-.424l-4-2.872a.68.68 0 00-.33-.094h-5.795a.551.551 0 00-.518.377v8.569a12.845 12.845 0 005.132 10.217l3.861 2.825a12.687 12.687 0 0020.387-10.123v-8.192c.047-.047.047-.094 0-.094m-8.663-5.179l6.5 4.708h-3.957l-6.5-4.708zm-14.596-7.862h3.955l2.5 1.836h-3.96zm3.625-6.309v5.226h-4.708v-5.65a6.772 6.772 0 016.733-6.78 8.18 8.18 0 011.836.235 8.236 8.236 0 00-3.861 6.968m7.816-11.253a10.73 10.73 0 0110.735 10.734v10.782h-4.661v-10.735a11.783 11.783 0 00-6.921-10.735h.848zm-16.526 10.781a10.751 10.751 0 0110.688-10.735h.141a10.69 10.69 0 0110.688 10.735v10.264l-2.919-2.166v-8.051a7.843 7.843 0 10-15.681-.424v6.545a.494.494 0 00.235.424l3.765 2.731v3.578l-6.921-4.944zm12.717 13.513h-4.713v-3.861h4.713zm5.838 13.278v-4.9a.494.494 0 00-.235-.424l-3.767-2.731v-3.575l6.921 4.991v8a5.878 5.878 0 01-5.838 5.885 6.119 6.119 0 01-2.4-.518 6.9 6.9 0 005.32-6.733m-13.471-9.412l2.5 1.836h-3.96l-2.5-1.836zm-5.085 7.908v-7.39l2.917 2.119v8.193a12.694 12.694 0 001.742 6.4 11.844 11.844 0 01-4.659-9.322m27.309 2.872a11.635 11.635 0 01-11.677 11.628 11.811 11.811 0 01-6.827-2.213l-.235-.188a11.827 11.827 0 01-4.614-9.228v-7.91h4.708v7.91a6.947 6.947 0 0013.89 0v-7.719h4.708z"
          fill="#677ea0"
          opacity={0.594}
        />
        <path
          d="M25.155 9.056a11.393 11.393 0 00-18.826 5.53.816.816 0 01-.79.62H1.654a.813.813 0 01-.8-.961 16.816 16.816 0 0128.138-9.026L31.413 2.8a1.627 1.627 0 012.778 1.151v9.089a1.627 1.627 0 01-1.627 1.627h-9.089a1.627 1.627 0 01-1.151-2.778zM2.19 20.089h9.089a1.627 1.627 0 011.151 2.778L9.6 25.7a11.393 11.393 0 0018.826-5.53.816.816 0 01.79-.62H33.1a.813.813 0 01.8.961 16.816 16.816 0 01-28.138 9.023L3.34 31.956a1.627 1.627 0 01-2.777-1.151v-9.089a1.627 1.627 0 011.627-1.627z"
          transform="translate(837.741 -1091.107)"
          fill="url(#prefix_VWI_c)"
        />
        <circle
          cx={99.5}
          cy={99.5}
          r={99.5}
          transform="translate(900.423 -922.437)"
          fill="#f4eff9"
        />
        <g transform="translate(685.407 -1018.008)">
          <g transform="translate(-60 -145.02)" filter="url(#prefix_VWI_d)">
            <rect
              className="prefix_VWI_a"
              width={354.849}
              height={238.173}
              rx={15}
              transform="translate(60 145.02)"
            />
          </g>
          <path
            d="M23.5 23.142h307.85a10 10 0 0110 10v16.035H13.5V33.142a10 10 0 0110-10z"
            fill="#dee6f2"
          />
          <g
            transform="translate(13.5 23.142)"
            opacity={0.257}
            stroke="#677ea0"
            fill="none"
          >
            <rect width={328.814} height={199.603} rx={10} stroke="none" />
            <rect x={0.5} y={0.5} width={327.814} height={198.603} rx={9.5} />
          </g>
          <circle
            cx={2.893}
            cy={2.893}
            r={2.893}
            transform="translate(25.071 33.267)"
            fill="#ff5548"
          />
          <circle
            cx={2.893}
            cy={2.893}
            r={2.893}
            transform="translate(32.785 33.267)"
            fill="#ffc056"
          />
          <circle
            cx={2.893}
            cy={2.893}
            r={2.893}
            transform="translate(41.463 33.267)"
            fill="#08af5d"
          />
          <path
            d="M209.612 133.033l-7.49 12.84a6.619 6.619 0 01-5.717 3.284H155.04a2.482 2.482 0 01-2.144-3.733l7.488-12.84a6.619 6.619 0 015.717-3.284h41.367a2.482 2.482 0 012.144 3.733zm-43.508-7.042h33.921v-4.964a4.964 4.964 0 00-4.964-4.964h-16.547l-6.619-6.619h-16.547a4.964 4.964 0 00-4.964 4.964v28.755l7.144-12.246a9.962 9.962 0 018.576-4.926z"
            strokeWidth={2}
            opacity={0.301}
            stroke="#677ea0"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
}

export default BoxViewsIcon;
