import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as form } from 'redux-form';

// Componentes
import modalDemoReducer from 'components/modal/modalDemo/ModalDemoReducer';
import modalAccountReducer from 'components/modal/modalAccount/ModalAccountReducer';
import modalSupportReducer from 'components/modal/modalSupport/ModalSupportReducer';
import modalContactReducer from 'components/modal/modalContact/ModalContactReducer';
// import InternationalizationReducer from 'components/internationalization/InternationalizationReducer';
import loaderGeneralReducer from 'components/loader/loaderGeneral/LoaderGeneralReducer';
import signUpReducer from 'modules/signUp/SignUpReducer';

const appReducers = combineReducers({
  form,
  toastr: toastrReducer,
  modalDemoReducer,
  modalAccountReducer,
  modalSupportReducer,
  modalContactReducer,
  // internationalizationReducer: InternationalizationReducer,
  loaderGeneralReducer,
  signUpReducer,
});

export default appReducers;
