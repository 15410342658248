import * as React from 'react';

function IconFeatherLinkedin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={17.15}
      viewBox="0 0 18 17.15"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix_LD_a{fill:none;stroke:#9751eb;stroke-linecap:round;stroke-linejoin:round}'
          }
        </style>
      </defs>
      <path
        className="prefix_LD_a"
        d="M12.4 5.6a5.1 5.1 0 015.1 5.1v5.95h-3.4V10.7a1.7 1.7 0 10-3.4 0v5.95H7.3V10.7a5.1 5.1 0 015.1-5.1zM.5 6.45h3.4v10.2H.5zM3.9 2.2A1.7 1.7 0 112.2.5a1.7 1.7 0 011.7 1.7z"
      />
    </svg>
  );
}

export default IconFeatherLinkedin;
