import * as React from 'react';

function BoxValueYourEmployeesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1247}
      height={429}
      viewBox="0 0 1247 429"
      {...props}
    >
      <defs>
        <filter
          id="prefix_DDD_a"
          x={315}
          y={0}
          width={700}
          height={429}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="blur" />
          <feFlood floodOpacity={0.063} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_DDD_b"
          x={0}
          y={170}
          width={377}
          height={212}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-2" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_DDD_e"
          x={953}
          y={130}
          width={294}
          height={215}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-3" />
          <feFlood floodOpacity={0.114} />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_DDD_f"
          x={153}
          y={0}
          width={224}
          height={193}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-4" />
          <feFlood floodOpacity={0.114} />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_DDD_c"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#009c8f" />
          <stop offset={1} stopColor="#00ccc3" />
        </linearGradient>
        <linearGradient
          id="prefix_DDD_d"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#e23452" />
          <stop offset={1} stopColor="#f75f79" />
        </linearGradient>
      </defs>
      <g transform="translate(-38.511 -2111.928)">
        <circle
          data-name="Elipse 670"
          cx={105}
          cy={105}
          r={105}
          transform="translate(308.511 2296.928)"
          fill="#f1e7fc"
          opacity={0.452}
        />
        <g data-name="Grupo 20704">
          <g data-name="Grupo 20699" fill="#dee6f2">
            <g data-name="Grupo 20696" transform="translate(952.563 2173.219)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 -.29)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 8.71)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 16.71)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 24.71)"
              />
              <ellipse
                data-name="Elipse 643"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 -.29)"
              />
              <ellipse
                data-name="Elipse 644"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 8.71)"
              />
              <ellipse
                data-name="Elipse 652"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 16.71)"
              />
              <ellipse
                data-name="Elipse 660"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 24.71)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 -.29)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 8.71)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 16.71)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 24.71)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 -.29)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 8.71)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 16.71)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 24.71)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 -.29)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 8.71)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 16.71)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 24.71)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 -.29)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 8.71)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 16.71)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 24.71)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 -.29)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 8.71)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 16.71)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 24.71)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 -.29)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 8.71)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 16.71)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 24.71)"
              />
            </g>
            <g data-name="Grupo 20697" transform="translate(1031.65 2173.219)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 -.29)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 8.71)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 16.71)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 24.71)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 -.29)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 8.71)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 16.71)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 24.71)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 -.29)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 8.71)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 16.71)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 24.71)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 -.29)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 8.71)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 16.71)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 24.71)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 -.29)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 8.71)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 16.71)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 24.71)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 -.29)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 8.71)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 16.71)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 24.71)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 -.29)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 8.71)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 16.71)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 24.71)"
              />
              <ellipse
                data-name="Elipse 642"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 -.29)"
              />
              <ellipse
                data-name="Elipse 650"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 8.71)"
              />
              <ellipse
                data-name="Elipse 658"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 16.71)"
              />
              <ellipse
                data-name="Elipse 666"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 24.71)"
              />
            </g>
          </g>
        </g>
        <g data-name="Grupo 20738">
          <g data-name="Grupo 20699" fill="#dee6f2">
            <g data-name="Grupo 20696" transform="translate(952.563 2206.011)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 -.083)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 8.917)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 15.917)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.053 24.917)"
              />
              <ellipse
                data-name="Elipse 643"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 -.083)"
              />
              <ellipse
                data-name="Elipse 644"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 8.917)"
              />
              <ellipse
                data-name="Elipse 652"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 15.917)"
              />
              <ellipse
                data-name="Elipse 660"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(40.947 24.917)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 -.083)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 8.917)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 15.917)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.947 24.917)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 -.083)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 8.917)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 15.917)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.947 24.917)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 -.083)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 8.917)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 15.917)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.947 24.917)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 -.083)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 8.917)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 15.917)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.947 24.917)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 -.083)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 8.917)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 15.917)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.947 24.917)"
              />
              <circle
                data-name="Elipse 642"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 -.083)"
              />
              <circle
                data-name="Elipse 650"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 8.917)"
              />
              <circle
                data-name="Elipse 658"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 15.917)"
              />
              <circle
                data-name="Elipse 666"
                cx={2}
                cy={2}
                r={2}
                transform="translate(69.947 24.917)"
              />
            </g>
            <g data-name="Grupo 20697" transform="translate(1031.65 2206.011)">
              <circle
                data-name="Elipse 636"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 -.083)"
              />
              <circle
                data-name="Elipse 651"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 8.917)"
              />
              <circle
                data-name="Elipse 659"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 15.917)"
              />
              <circle
                data-name="Elipse 667"
                cx={2}
                cy={2}
                r={2}
                transform="translate(-.14 24.917)"
              />
              <circle
                data-name="Elipse 643"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 -.083)"
              />
              <circle
                data-name="Elipse 644"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 8.917)"
              />
              <circle
                data-name="Elipse 652"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 15.917)"
              />
              <circle
                data-name="Elipse 660"
                cx={2}
                cy={2}
                r={2}
                transform="translate(40.86 24.917)"
              />
              <circle
                data-name="Elipse 637"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 -.083)"
              />
              <circle
                data-name="Elipse 645"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 8.917)"
              />
              <circle
                data-name="Elipse 653"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 15.917)"
              />
              <circle
                data-name="Elipse 661"
                cx={2}
                cy={2}
                r={2}
                transform="translate(9.86 24.917)"
              />
              <circle
                data-name="Elipse 640"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 -.083)"
              />
              <circle
                data-name="Elipse 646"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 8.917)"
              />
              <circle
                data-name="Elipse 654"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 15.917)"
              />
              <circle
                data-name="Elipse 662"
                cx={2}
                cy={2}
                r={2}
                transform="translate(49.86 24.917)"
              />
              <circle
                data-name="Elipse 638"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 -.083)"
              />
              <circle
                data-name="Elipse 647"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 8.917)"
              />
              <circle
                data-name="Elipse 655"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 15.917)"
              />
              <circle
                data-name="Elipse 663"
                cx={2}
                cy={2}
                r={2}
                transform="translate(19.86 24.917)"
              />
              <circle
                data-name="Elipse 641"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 -.083)"
              />
              <circle
                data-name="Elipse 648"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 8.917)"
              />
              <circle
                data-name="Elipse 656"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 15.917)"
              />
              <circle
                data-name="Elipse 664"
                cx={2}
                cy={2}
                r={2}
                transform="translate(60.86 24.917)"
              />
              <circle
                data-name="Elipse 639"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 -.083)"
              />
              <circle
                data-name="Elipse 649"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 8.917)"
              />
              <circle
                data-name="Elipse 657"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 15.917)"
              />
              <circle
                data-name="Elipse 665"
                cx={2}
                cy={2}
                r={2}
                transform="translate(29.86 24.917)"
              />
              <ellipse
                data-name="Elipse 642"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 -.083)"
              />
              <ellipse
                data-name="Elipse 650"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 8.917)"
              />
              <ellipse
                data-name="Elipse 658"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 15.917)"
              />
              <ellipse
                data-name="Elipse 666"
                cx={1.5}
                cy={2}
                rx={1.5}
                ry={2}
                transform="translate(70.86 24.917)"
              />
            </g>
          </g>
        </g>
        <g transform="translate(38.51 2111.93)" filter="url(#prefix_DDD_a)">
          <g
            data-name="Ret\xE2ngulo 2731"
            transform="translate(375 40)"
            fill="#fff"
            stroke="#dee6f2"
          >
            <rect width={580} height={309} rx={20} stroke="none" />
            <rect
              x={0.5}
              y={0.5}
              width={579}
              height={308}
              rx={19.5}
              fill="none"
            />
          </g>
        </g>
        <g transform="translate(38.51 2111.927)" filter="url(#prefix_DDD_b)">
          <rect
            data-name="Ret\xE2ngulo 2416"
            width={257}
            height={92}
            rx={10}
            transform="translate(60 220)"
            fill="#fff"
            opacity={0.997}
          />
        </g>
        <g data-name="Grupo 20409" transform="translate(115.396 2390.226)">
          <rect
            data-name="Ret\xE2ngulo 2417"
            width={223}
            height={23}
            rx={11.5}
            transform="translate(.115 -.298)"
            fill="#f2f2f2"
            opacity={0.653}
          />
          <text
            data-name="15/07"
            transform="translate(42.115 15.702)"
            fill="#1e2b3c"
            fontSize={11}
            fontFamily="Roboto-Regular, Roboto"
          >
            <tspan x={0} y={0}>
              15/07
            </tspan>
          </text>
          <text
            data-name="21/07"
            transform="translate(144.115 15.702)"
            fill="#1e2b3c"
            fontSize={11}
            fontFamily="Roboto-Regular, Roboto"
          >
            <tspan x={0} y={0}>
              21/07
            </tspan>
          </text>
          <g
            data-name="Icon feather-refresh-ccw"
            fill="none"
            stroke="#009b8d"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          >
            <path data-name="Caminho 2729" d="M105.128 7.879v2.63h2.63" />
            <path data-name="Caminho 2730" d="M114.772 14.893v-2.63h-2.63" />
            <path
              data-name="Caminho 2731"
              d="M113.672 10.072a3.946 3.946 0 00-6.51-1.473l-2.034 1.911m9.645 1.754l-2.034 1.911a3.946 3.946 0 01-6.511-1.473"
            />
          </g>
        </g>
        <g data-name="Grupo 20760" transform="translate(115.396 2346.037)">
          <rect
            data-name="Ret\xE2ngulo 2685"
            width={116}
            height={7}
            rx={3.5}
            transform="translate(48.115 6.89)"
            fill="#bfbfbf"
            opacity={0.297}
          />
          <rect
            data-name="Ret\xE2ngulo 2686"
            width={71}
            height={8}
            rx={4}
            transform="translate(48.115 18.89)"
            fill="#bfbfbf"
            opacity={0.297}
          />
          <circle
            data-name="Elipse 635"
            cx={16.5}
            cy={16.5}
            r={16.5}
            transform="translate(.115 -.11)"
            fill="rgba(151,81,235,0.45)"
          />
          <path
            data-name="Icon awesome-user-alt"
            d="M16.195 17.043a3.371 3.371 0 10-3.37-3.37 3.371 3.371 0 003.37 3.37zm3 .749h-1.292a4.074 4.074 0 01-3.413 0h-1.287a3 3 0 00-3 3v.375a1.124 1.124 0 001.124 1.124h9.737a1.124 1.124 0 001.124-1.124v-.375a3 3 0 00-2.997-3z"
            fill="#fff"
          />
        </g>
        <text
          data-name="Pedidos de troca"
          transform="translate(105.511 2296.928)"
          fill="#9751eb"
          fontSize={16}
          fontFamily="Roboto-Regular, Roboto"
          letterSpacing=".04em"
          opacity={0.778}
        >
          <tspan x={0} y={15}>
            Pedidos de troca
          </tspan>
        </text>
        <text
          data-name="Pedidos de folga"
          transform="translate(439.511 2178.928)"
          fill="#9751eb"
          fontSize={20}
          fontFamily="Roboto-Regular, Roboto"
          letterSpacing=".04em"
          opacity={0.751}
        >
          <tspan x={0} y={19}>
            Pedidos de folga
          </tspan>
        </text>
        <g data-name="Grupo 20754">
          <g data-name="card">
            <g
              data-name="Ret\xE2ngulo 2416"
              transform="translate(435.511 2227.928)"
              fill="none"
              stroke="#009b8d"
              opacity={0.303}
            >
              <rect width={525} height={118} rx={10} stroke="none" />
              <rect x={0.5} y={0.5} width={524} height={117} rx={9.5} />
            </g>
            <text
              data-name="Pediu folga para:"
              transform="translate(758.511 2261.928)"
              fill="#243e64"
              fontSize={13}
              fontFamily="Roboto-Regular, Roboto"
              letterSpacing=".03em"
              opacity={0.66}
            >
              <tspan x={0} y={0}>
                Pediu folga para:
              </tspan>
            </text>
            <g data-name="Grupo 20753" transform="translate(752.916 2278.347)">
              <rect
                data-name="Ret\xE2ngulo 2792"
                width={175}
                height={41}
                rx={20.5}
                transform="translate(-.405 -.419)"
                fill="#d3efec"
                opacity={0.997}
              />
              <text
                data-name="ter. 15/06 | 7:00 - 17:00"
                transform="translate(14.595 24.581)"
                fill="#414141"
                fontSize={12}
                fontFamily="Roboto-Regular, Roboto"
                letterSpacing=".06em"
                opacity={0.896}
              >
                <tspan x={0} y={0}>
                  ter. 15/06 | 7:00 - 17:00
                </tspan>
              </text>
            </g>
            <g data-name="Grupo 20759" transform="translate(451.035 2260.022)">
              <rect
                data-name="Ret\xE2ngulo 2685"
                width={115}
                height={11}
                rx={5}
                transform="translate(74.476 -.093)"
                fill="#bfbfbf"
                opacity={0.303}
              />
              <rect
                data-name="Ret\xE2ngulo 2686"
                width={58}
                height={13}
                rx={5}
                transform="translate(74.476 18.906)"
                fill="#bfbfbf"
                opacity={0.303}
              />
              <rect
                data-name="Ret\xE2ngulo 2794"
                width={122}
                height={11}
                rx={5}
                transform="translate(74.476 39.906)"
                fill="#bfbfbf"
                opacity={0.303}
              />
              <circle
                data-name="Elipse 635"
                cx={25.5}
                cy={25.5}
                r={25.5}
                transform="translate(.476 -.093)"
                fill="rgba(151,81,235,0.44)"
              />
              <path
                data-name="Icon awesome-user-alt"
                d="M25.171 26.638a5.239 5.239 0 10-5.238-5.238 5.24 5.24 0 005.238 5.238zm4.657 1.164h-2a6.333 6.333 0 01-5.3 0h-2a4.656 4.656 0 00-4.67 4.659v.582a1.747 1.747 0 001.746 1.746h15.134a1.747 1.747 0 001.746-1.746v-.582a4.656 4.656 0 00-4.656-4.658z"
                fill="#fff"
              />
            </g>
          </g>
          <g opacity={0.714}>
            <g data-name="Grupo 20664" transform="translate(814.643 2372.866)">
              <rect
                data-name="Ret\xE2ngulo 2419"
                width={146}
                height={39}
                rx={19.5}
                transform="translate(-.132 .062)"
                fill="url(#prefix_DDD_c)"
              />
              <text
                transform="translate(72.298 22.006)"
                fill="#fff"
                fontSize={13}
                fontFamily="Roboto-Regular, Roboto"
              >
                <tspan x={-28.882} y={0}>
                  APROVAR
                </tspan>
              </text>
            </g>
            <g data-name="Grupo 20663" transform="translate(659.362 2372.866)">
              <rect
                data-name="Ret\xE2ngulo 2420"
                width={136}
                height={39}
                rx={19.5}
                transform="translate(.149 .063)"
                fill="url(#prefix_DDD_d)"
              />
              <text
                transform="translate(64.915 22.129)"
                fill="#fff"
                fontSize={13}
                fontFamily="Roboto-Regular, Roboto"
              >
                <tspan x={-28.241} y={0}>
                  RECUSAR
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g data-name="Grupo 20756">
          <g transform="translate(38.515 2111.929)" filter="url(#prefix_DDD_e)">
            <rect
              data-name="Ret\xE2ngulo 2732"
              width={174}
              height={95}
              rx={10}
              transform="translate(1013 180)"
              fill="#fff"
            />
          </g>
          <text
            data-name="Banco de horas:"
            transform="translate(1077.511 2310.927)"
            fill="#9751eb"
            fontSize={16}
            fontFamily="Roboto-Regular, Roboto"
            letterSpacing=".04em"
            opacity={0.751}
          >
            <tspan x={0} y={15}>
              Banco de horas:
            </tspan>
          </text>
          <g data-name="Grupo 20676" opacity={0.599}>
            <path
              d="M1077.946 2339.097h59.947l6.6 9.777-6.6 9.524h-59.947z"
              fill="#f4b300"
            />
            <g data-name="Grupo 20590" fill="#fff">
              <text
                data-name="30 h"
                transform="translate(1114.158 2353.097)"
                fontSize={15}
                fontFamily="Roboto-Bold, Roboto"
                fontWeight={700}
              >
                <tspan x={-14.67} y={0}>
                  30 h
                </tspan>
              </text>
              <g data-name="Icon ionic-md-time" stroke="#fff" strokeWidth={0.5}>
                <path
                  data-name="Caminho 4047"
                  d="M1088.574 2342.816a5.551 5.551 0 105.557 5.551 5.549 5.549 0 00-5.557-5.551zm.005 9.992a4.441 4.441 0 114.441-4.441 4.441 4.441 0 01-4.441 4.441z"
                />
                <path
                  data-name="Caminho 4048"
                  d="M1088.857 2345.591h-.833v3.331l2.914 1.748.416-.683-2.497-1.483z"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Grupo 20757" opacity={0.878}>
          <g transform="translate(38.508 2111.925)" filter="url(#prefix_DDD_f)">
            <rect
              data-name="Ret\xE2ngulo 2732"
              width={104}
              height={73}
              rx={10}
              transform="translate(213 50)"
              fill="#fff"
            />
          </g>
          <text
            transform="translate(258.511 2169.928)"
            fill="#009b8d"
            fontSize={12}
            fontFamily="Roboto-Regular, Roboto"
            opacity={0.751}
          >
            <tspan x={0} y={11}>
              Compatibilidade
            </tspan>
          </text>
          <g data-name="Grupo 20660">
            <g data-name="Caminho 4321" fill="none" opacity={0.694}>
              <path d="M303.298 2190.964a17.743 17.743 0 11-17.743 17.743 17.743 17.743 0 0117.743-17.743z" />
              <path
                d="M303.298 2195.964a12.66 12.66 0 00-9.01 3.732 12.66 12.66 0 00-3.733 9.01 12.66 12.66 0 003.732 9.011 12.66 12.66 0 009.011 3.733 12.66 12.66 0 009.01-3.733 12.66 12.66 0 003.733-9.01 12.66 12.66 0 00-3.732-9.01 12.66 12.66 0 00-9.011-3.733m0-5c9.8 0 17.743 7.944 17.743 17.743s-7.944 17.743-17.743 17.743c-9.8 0-17.743-7.944-17.743-17.743 0-9.8 7.944-17.743 17.743-17.743z"
                fill="#009b8d"
              />
            </g>
            <path
              data-name="Ret\xE2ngulo 2791"
              fill="#fff"
              d="M310.511 2191.929h22v24h-22z"
            />
            <text
              data-name="90%"
              transform="translate(294.511 2210.928)"
              fill="#009b8d"
              stroke="rgba(0,0,0,0)"
              fontSize={9}
              fontFamily="Roboto-Regular, Roboto"
            >
              <tspan x={0} y={0}>
                90%
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BoxValueYourEmployeesIcon;
