import * as React from 'react';

function BoxHowItWorksIcon1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={591}
      height={345}
      viewBox="0 0 591 345"
      {...props}
    >
      <defs>
        <filter
          id="prefix_HIW_a"
          x={3}
          y={0}
          width={588}
          height={345}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="blur" />
          <feFlood floodOpacity={0.063} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_HIW_b"
          x={22}
          y={67}
          width={291}
          height={180}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-2" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_HIW_c"
          x={282}
          y={67}
          width={291}
          height={180}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="blur-3" />
          <feFlood floodOpacity={0.078} />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_HIW_d"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#009c8f" />
          <stop offset={1} stopColor="#00ccc3" />
        </linearGradient>
        <linearGradient
          id="prefix_HIW_e"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#e23452" />
          <stop offset={1} stopColor="#f75f79" />
        </linearGradient>
      </defs>
      <g transform="translate(-624 2288.517)">
        <circle
          cx={106.5}
          cy={106.5}
          r={106.5}
          transform="translate(624 -2163.517)"
          fill="#f1e7fc"
          opacity={0.591}
        />
        <g transform="translate(624 -2288.52)" filter="url(#prefix_HIW_a)">
          <rect
            data-name="Ret\xE2ngulo 2814"
            width={468}
            height={225}
            rx={20}
            transform="translate(63 40)"
            fill="#fff"
          />
        </g>
        <g transform="translate(624.004 -2288.514)" filter="url(#prefix_HIW_b)">
          <rect
            data-name="Ret\xE2ngulo 2416"
            width={171}
            height={60}
            rx={10}
            transform="translate(82 117)"
            fill="#fff"
            opacity={0.997}
          />
        </g>
        <g data-name="Grupo 20760" transform="translate(717.072 -2162.115)">
          <rect
            data-name="Ret\xE2ngulo 2685"
            width={95}
            height={5}
            rx={2.5}
            transform="translate(37.928 7.598)"
            fill="#bfbfbf"
            opacity={0.297}
          />
          <rect
            data-name="Ret\xE2ngulo 2686"
            width={59}
            height={5}
            rx={2.5}
            transform="translate(37.928 17.598)"
            fill="#bfbfbf"
            opacity={0.297}
          />
          <rect
            data-name="Ret\xE2ngulo 2816"
            width={84}
            height={5}
            rx={2.5}
            transform="translate(37.928 27.598)"
            fill="#bfbfbf"
            opacity={0.297}
          />
          <circle
            data-name="Elipse 635"
            cx={15}
            cy={15}
            r={15}
            transform="translate(-2.072 5.598)"
            fill="rgba(151,81,235,0.24)"
          />
          <path
            data-name="Icon awesome-user-alt"
            d="M12.636 21.943a3 3 0 10-3-3 3 3 0 003 3zm2.67.668h-1.15a3.631 3.631 0 01-3.042 0H9.965a2.67 2.67 0 00-2.67 2.67v.334a1 1 0 001 1h8.68a1 1 0 001-1v-.334a2.67 2.67 0 00-2.669-2.67z"
            fill="#fff"
          />
        </g>
        <g data-name="card">
          <g transform="translate(624.004 -2288.514)" filter="url(#prefix_HIW_c)">
            <rect
              data-name="Ret\xE2ngulo 2416"
              width={171}
              height={60}
              rx={10}
              transform="translate(342 117)"
              fill="#fff"
              opacity={0.997}
            />
          </g>
          <g data-name="Grupo 20760" transform="translate(977.072 -2162.115)">
            <rect
              data-name="Ret\xE2ngulo 2685"
              width={76}
              height={5}
              rx={2.5}
              transform="translate(37.928 7.598)"
              fill="#bfbfbf"
              opacity={0.297}
            />
            <rect
              data-name="Ret\xE2ngulo 2686"
              width={44}
              height={5}
              rx={2.5}
              transform="translate(37.928 17.598)"
              fill="#bfbfbf"
              opacity={0.297}
            />
            <rect
              data-name="Ret\xE2ngulo 2816"
              width={64}
              height={5}
              rx={2.5}
              transform="translate(37.928 27.598)"
              fill="#bfbfbf"
              opacity={0.297}
            />
            <circle
              data-name="Elipse 635"
              cx={15}
              cy={15}
              r={15}
              transform="translate(-2.072 5.598)"
              fill="rgba(151,81,235,0.24)"
              opacity={0.506}
            />
            <path
              data-name="Icon awesome-user-alt"
              d="M12.636 21.943a3 3 0 10-3-3 3 3 0 003 3zm2.67.668h-1.15a3.631 3.631 0 01-3.042 0H9.965a2.67 2.67 0 00-2.67 2.67v.334a1 1 0 001 1h8.68a1 1 0 001-1v-.334a2.67 2.67 0 00-2.669-2.67z"
              fill="#fff"
            />
          </g>
        </g>
        <text
          data-name="Pedido de troca"
          transform="translate(706 -2222.517)"
          fill="#9751eb"
          fontSize={18}
          fontFamily="Roboto-Regular, Roboto"
          letterSpacing=".04em"
          opacity={0.751}
        >
          <tspan x={0} y={17}>
            Pedido de troca
          </tspan>
        </text>
        <g opacity={0.714} data-name="Grupo 21328">
          <g data-name="Grupo 20664" transform="translate(1007.094 -2070.516)">
            <rect
              data-name="Ret\xE2ngulo 2419"
              width={130}
              height={34}
              rx={17}
              transform="translate(-.094)"
              fill="url(#prefix_HIW_d)"
            />
            <text
              transform="translate(61.793 20.235)"
              fill="#fff"
              fontSize={12}
              fontFamily="Roboto-Regular, Roboto"
            >
              <tspan x={-26.66} y={0}>
                APROVAR
              </tspan>
            </text>
          </g>
          <g data-name="Grupo 20663" transform="translate(868.359 -2070.516)">
            <rect
              data-name="Ret\xE2ngulo 2420"
              width={123}
              height={34}
              rx={17}
              transform="translate(-.359)"
              fill="url(#prefix_HIW_e)"
            />
            <text
              transform="translate(57.129 20.344)"
              fill="#fff"
              fontSize={12}
              fontFamily="Roboto-Regular, Roboto"
            >
              <tspan x={-26.068} y={0}>
                RECUSAR
              </tspan>
            </text>
          </g>
        </g>
        <g
          opacity={0.759}
          fill="none"
          stroke="#009b8d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path data-name="Caminho 2729" d="M907.652-2150.511v7.458h7.458" />
          <path data-name="Caminho 2730" d="M934.999-2130.622v-7.458h-7.458" />
          <path
            data-name="Caminho 2731"
            d="M931.88-2144.295a11.188 11.188 0 00-18.46-4.177l-5.768 5.418m27.348 4.972l-5.768 5.42a11.188 11.188 0 01-18.46-4.175"
          />
        </g>
      </g>
    </svg>
  );
}

export default BoxHowItWorksIcon1;
