import React, { memo } from 'react';

// Componentes
import Layout from 'components/layout/Layout';
import BoxIntroduction from 'modules/privacyPolicy/privacyPolicyComponents/BoxIntroduction';
import BoxText from 'modules/privacyPolicy/privacyPolicyComponents/BoxText';

function PrivacyPolicyPage() {
  window.scrollTo(0, 0);

  return (
    <Layout>
      <BoxIntroduction />
      <BoxText />
    </Layout>
  );
}

export default memo(PrivacyPolicyPage);

// ==================================================
// Estilos
// ==================================================
