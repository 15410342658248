import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';

// ==================================================
// Inicia o analytics
// ==================================================
export const initAnalytics = () => {
  mixpanel.init('e3fc971e8f32028955d1ea5d358adb50');
  ReactGA.initialize('UA-128335743-1');

  return null;
};

// ==================================================
// Envia eventos
// ==================================================
export const sendEventAnalytics = (event) => {
  mixpanel.track(event);

  ReactGA.event({
    category: 'EVENT',
    action: event,
  });

  return null;
};

// ==================================================
// Eventos teste AB
// ==================================================
// SITE/NEW/ACCESS
// SITE/NEW/FORM/OPEN
// SITE/NEW/FORM/SEND

// ==================================================
// Eventos abrir modal solicita demo
// ==================================================
// HEADER/MENU/DEMO
// FLOAT/BUTTON/DEMO
// HOME/BUTTON/DEMO
// INTEGRATION/BUTTON/DEMO
// SWAP-REQUEST/BUTTON/DEMO
// WORKSCHEDULE/BUTTON/DEMO

// ==================================================
// Eventos solicitou suporte/contato
// ==================================================
// EVENT/SUPPPORT/OPEN
// EVENT/SUPPPORT/SEND
// EVENT/CONTACT/OPEN
// EVENT/CONTACT/SEND

// ==================================================
// Eventos menu superior
// ==================================================
// HEADER/MENU/HOME

// HEADER/MENU/SWAP-REQUEST
// HEADER/MENU/WORKSCHEDULE
// HEADER/MENU/INTEGRATION

// HEADER/MENU/BLOG
// HEADER/MENU/TRAINING
// HEADER/MENU/VIDEO

// HEADER/MENU/ACCOUNT

// ==================================================
// Eventos rodapé
// ==================================================
// FOOTER/MENU/HOME

// FOOTER/MENU/SWAP-REQUEST
// FOOTER/MENU/WORKSCHEDULE
// FOOTER/MENU/INTEGRATION

// FOOTER/MENU/BLOG
// FOOTER/MENU/TRAINING
// FOOTER/MENU/VIDEO

// FOOTER/MENU/PRIVACY-POLICE
// FOOTER/MENU/SUPPORT
// FOOTER/MENU/CONTACT

// FOOTER/SOCIAL/INSTAGRAM
// FOOTER/SOCIAL/FACEBOOK
// FOOTER/SOCIAL/LINKEDIN
