import React, { memo } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

// Actions
import { getOptions } from 'components/button/ButtonAction';

function ButtonCircleComponent(props) {
  const options = getOptions('default', props.colors, props.transparent, props.line, props.lineLight, props.disabled, props.pin, props.favorite, props.padding, props.margins, props.marginsIcon);

  return (
    <div>
      <ButtonSG
        id={props.id || 'button_circle'}
        textcolor={options.textColor}
        backgrondcolor={options.backgrondColor}
        bordercolor={options.borderColor}
        margins={options.margins}
        linetype={options.lineType}
        disabled={props.disabled || false}
        fullWidth={props.fullWidth || false}
        type={props.type || 'button'}
        size="small"
        variant="contained"
        onClick={props.onClick}
      >
        {props.progress
          ? <CircularProgress size={20} />
          : (props.iconChildren ? props.iconChildren
            : (!options.pin ? <Icon icon={props.icon} textcolor={options.textColor} marginsicon={options.marginsIcon} />
              : ''
            )
          )}
      </ButtonSG>
    </div>
  );
}

export default memo(ButtonCircleComponent);

// ==================================================
// Estilos
// ==================================================
const ButtonSG = styled(Button)`
  display: flex !important;
  min-height: 30px !important;
  max-height: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important;
  border-radius: 15px !important;
  font: normal 12px Roboto !important;
  letter-spacing: 0px !important;

  // OPCIONAIS
  color: ${props => (props.textcolor)} !important;
  background: ${props => (props.backgrondcolor)} !important;
  border: ${props => props.linetype} ${props => (props.bordercolor)} !important;
  margin: ${props => (props.margins)} !important;

  padding: 0px !important;
  text-transform: none !important;
  box-shadow: none !important;
  transition: all 100ms;
  &:hover {
    transition: all 100ms;
    box-shadow: 
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px !important;
  color: ${props => props.textcolor};
  margin: ${props => (props.marginsicon)} !important;
`;
