const INITIAL_STATE = {
  statusModalDemo: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_DEMO_FETCHED':
      return { ...state, statusModalDemo: INITIAL_STATE.statusModalDemo };

    case 'OPEN_MODAL_DEMO_FETCHED':
      return { ...state, statusModalDemo: action.payload };

    default:
      return state;
  }
};
