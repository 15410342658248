const INITIAL_STATE = {
  statusModalContact: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CONTACT_FETCHED':
      return { ...state, statusModalContact: INITIAL_STATE.statusModalContact };

    case 'OPEN_MODAL_CONTACT_FETCHED':
      return { ...state, statusModalContact: action.payload };

    default:
      return state;
  }
};
