import React, { useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import BoxAvoidBreakingRulesIcon from 'modules/home/homeComponents/BoxAvoidBreakingRulesIcon';

// Tradução
import { translate } from 'translates/TranslatesAction';

function BoxAvoidBreakingRulesComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxAvoidBreakingRulesContent>
      <BoxAvoidBreakingRules>
        <GridContainer alignItems="center" minheight="615px">
          <GridItem xs={12} sm={5} md={5} lg={5} xl={5} direction="column" alignItems="flex-start">
            <BoxOverflow>
              <Title data-aos="fade-down-right" data-aos-duration="600">{translate('Evite_quebrar_regras')}</Title>
              <Subtitle data-aos="fade-right" data-aos-duration="600">{translate('Nunca_mais_quebre_regras_trabalhistas_e_saiba_exatamente_quantos_colaboradores_voce_tem_trabalhando_por_dia')}</Subtitle>
            </BoxOverflow>
          </GridItem>
          <GridItem xs={12} sm={7} md={7} lg={7} xl={7} alignItems="center" justify="flex-end">
            <BoxOverflow>
              <BoxIcon data-aos="fade-down" data-aos-easing="linear" data-aos-duration="600">
                <BoxAvoidBreakingRulesIconSG />
              </BoxIcon>
            </BoxOverflow>
          </GridItem>
        </GridContainer>
      </BoxAvoidBreakingRules>
    </BoxAvoidBreakingRulesContent>
  );
}

export default memo(BoxAvoidBreakingRulesComponent);

// ==================================================
// Estilos
// ==================================================
const BoxAvoidBreakingRulesContent = styled.div`
  width: 100%;
  background: #F7F9FC;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const BoxAvoidBreakingRules = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #9751EB;

  @media (max-width: 599px) {
    padding: 0px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 25px 0px 25px;
    font: bold 30px Noto Sans;
    margin: 0px 0px 10px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 0px 0px 25px;
    font: bold 42px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: bold 42px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
  @media (min-width: 1920px) {
    font: bold 42px Noto Sans;
    margin: 0px 0px 20px 0px;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #677EA0;

  @media (max-width: 599px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal 14px Noto Sans;
    padding: 0px 45px 0px 25px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal 18px Noto Sans;
    padding: 0px 0px 0px 25px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 18px Noto Sans;
  }
  @media (min-width: 1920px) {
    font: normal 18px Noto Sans;
  }
`;

const BoxAvoidBreakingRulesIconSG = styled(BoxAvoidBreakingRulesIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;

const BoxIcon = styled.div``;

const BoxOverflow = styled.div`
  overflow: none;
`;
