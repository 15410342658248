import * as React from 'react';

function BoxReduceYourCostsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={733}
      height={475}
      viewBox="0 0 733 475"
      {...props}
    >
      <defs>
        <filter
          id="prefix_EEE_a"
          x={79}
          y={0}
          width={386}
          height={342}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="b" />
          <feFlood floodOpacity={0.192} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_EEE_d"
          x={0}
          y={243}
          width={373}
          height={195}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={20} result="e" />
          <feFlood floodOpacity={0.114} />
          <feComposite operator="in" in2="e" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix_EEE_g"
          x={296}
          y={73}
          width={409}
          height={402}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} result="h" />
          <feFlood floodOpacity={0.173} />
          <feComposite operator="in" in2="h" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix_EEE_c"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#835bdb" />
          <stop offset={1} stopColor="#c282fd" />
        </linearGradient>
        <linearGradient
          id="prefix_EEE_f"
          x1={0.736}
          y1={0.081}
          x2={0.376}
          y2={0.844}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#c39ef3" stopOpacity={0.373} />
          <stop offset={1} stopColor="#b996e7" stopOpacity={0} />
        </linearGradient>
        <style>
          {
            '.prefix_EEE_a,.prefix_EEE_i{fill:#fff}.prefix_EEE_a{stroke:#dee6f2}.prefix_EEE_b{fill:#9751eb;font-size:16px;opacity:.762;font-family:Roboto-Regular,Roboto;letter-spacing:.04em}.prefix_EEE_c{fill:url(#prefix_EEE_c)}.prefix_EEE_e,.prefix_EEE_w{fill:none}.prefix_EEE_e{stroke:#5b5e6e;opacity:.117}.prefix_EEE_g{fill:#a9b7cc}.prefix_EEE_h{fill:#83d3cb}.prefix_EEE_l{fill:#bfbfbf;opacity:.297}.prefix_EEE_p{fill:#243e64}.prefix_EEE_v{stroke:none}'
          }
        </style>
      </defs>
      <g transform="translate(60 40)">
        <g transform="translate(-59.996 -40)" filter="url(#prefix_EEE_a)">
          <g className="prefix_EEE_a" transform="translate(139 40)">
            <rect className="prefix_EEE_v" width={266} height={222} rx={20} />
            <rect
              className="prefix_EEE_w"
              x={0.5}
              y={0.5}
              width={265}
              height={221}
              rx={19.5}
            />
          </g>
        </g>
        <text className="prefix_EEE_b" transform="translate(95 19)">
          <tspan x={0} y={15}>
            Banco de horas
          </tspan>
        </text>
        <g transform="translate(252.878 186.498)">
          <rect
            className="prefix_EEE_c"
            width={78}
            height={24}
            rx={12}
            transform="translate(.122 -.497)"
          />
          <text
            transform="translate(36.122 12.503)"
            fontSize={7}
            letterSpacing=".04em"
            fontFamily="Roboto-Regular,Roboto"
            fill="#fff"
          >
            <tspan x={-14.165} y={0}>
              Exportar
            </tspan>
          </text>
        </g>
        <g>
          <path
            className="prefix_EEE_e"
            d="M114.905 160.689h212.141M112.745 135.118h212.141M112.745 109.729h212.141M112.745 88.381h212.141M112.745 62.992h212.141"
          />
          <text
            transform="translate(102.914 70.82)"
            fill="#868e96"
            fontSize={6}
            fontFamily="Inter-Regular,Inter"
          >
            <tspan x={-11.114} y={0}>
              30h
            </tspan>
            <tspan x={-10.926} y={18}>
              20h
            </tspan>
            <tspan x={-9.98} y={36}>
              15h
            </tspan>
            <tspan x={-10.082} y={54}>
              10h
            </tspan>
            <tspan x={-7.295} y={72}>
              0h
            </tspan>
          </text>
          <path
            className="prefix_EEE_g"
            d="M127.216 62.104h10.862v99.571h-10.862z"
          />
          <path
            className="prefix_EEE_h"
            d="M217.735 62.104h10.862v99.571h-10.862z"
          />
          <path
            className="prefix_EEE_g"
            d="M186.959 103.743h10.862v57.932h-10.862zM246.701 103.743h10.862v57.932h-10.862z"
          />
          <path
            className="prefix_EEE_h"
            d="M158.898 139.95h10.862v20.819h-10.862zM277.478 139.95h10.862v20.819h-10.862z"
          />
          <path
            className="prefix_EEE_g"
            d="M307.349 120.941h10.862v39.828h-10.862z"
          />
        </g>
        <g>
          <g transform="translate(-60 -40.002)" filter="url(#prefix_EEE_d)">
            <rect
              className="prefix_EEE_i"
              width={253}
              height={75}
              rx={10}
              transform="translate(60 293)"
            />
          </g>
          <path
            d="M189.137 277.967h51.322v16.154h-51.322l-6.711-8.04z"
            fill="#f4b300"
            opacity={0.692}
          />
          <text
            transform="translate(212.98 289.249)"
            fontSize={10}
            fontFamily="Roboto-Bold,Roboto"
            fontWeight={700}
            fill="#fff"
          >
            <tspan x={-16.682} y={0}>
              {'F\xC9RIAS'}
            </tspan>
          </text>
          <g transform="translate(14.751 271.844)">
            <rect
              className="prefix_EEE_l"
              width={104}
              height={8}
              rx={4}
              transform="translate(50.249 7.156)"
            />
            <rect
              className="prefix_EEE_l"
              width={55}
              height={7}
              rx={3.5}
              transform="translate(50.249 20.156)"
            />
            <circle
              cx={17.5}
              cy={17.5}
              r={17.5}
              transform="translate(.249 .156)"
              fill="rgba(151,81,235,.45)"
            />
            <path
              className="prefix_EEE_i"
              d="M16.943 18.07a3.551 3.551 0 10-3.552-3.549 3.552 3.552 0 003.552 3.549zm3.157.789h-1.36a4.293 4.293 0 01-3.6 0h-1.354a3.157 3.157 0 00-3.157 3.16v.395a1.184 1.184 0 001.184 1.184h10.259a1.184 1.184 0 001.184-1.184v-.395a3.157 3.157 0 00-3.157-3.157z"
            />
          </g>
        </g>
        <circle
          cx={128}
          cy={128}
          r={128}
          transform="translate(417 -3)"
          opacity={0.701}
          fill="url(#prefix_EEE_f)"
        />
      </g>
      <g transform="translate(-.002 .002)" filter="url(#prefix_EEE_g)">
        <g className="prefix_EEE_a" transform="translate(356 113)">
          <rect className="prefix_EEE_v" width={289} height={282} rx={20} />
          <rect
            className="prefix_EEE_w"
            x={0.5}
            y={0.5}
            width={288}
            height={281}
            rx={19.5}
          />
        </g>
      </g>
      <text className="prefix_EEE_b" transform="translate(385 134)">
        <tspan x={0} y={15}>
          Folgas extras
        </tspan>
      </text>
      <g opacity={0.782}>
        <path
          className="prefix_EEE_h"
          d="M496.855 307.537a62.283 62.283 0 1160.064-64.425 62.283 62.283 0 01-60.064 64.425zm-4.049-115.556a53.344 53.344 0 1055.18 51.444 53.344 53.344 0 00-55.18-51.444z"
        />
        <path
          className="prefix_EEE_p"
          d="M509.034 305.896a62.283 62.283 0 11-2.694-121.784l-1.674 8.78a53.344 53.344 0 102.307 104.308z"
        />
        <text
          transform="translate(411.251 206.529)"
          fontSize={18}
          fill="#313131"
          fontFamily="Roboto-Regular,Roboto"
        >
          <tspan x={-16.699} y={0}>
            60%
          </tspan>
        </text>
        <text
          transform="translate(587.998 279.815)"
          fill="#009b8d"
          fontSize={18}
          fontFamily="Roboto-Regular,Roboto"
        >
          <tspan x={-16.699} y={0}>
            40%
          </tspan>
        </text>
      </g>
      <g transform="translate(384.527 355.043)">
        <text
          transform="translate(31.473 7.956)"
          fontSize={9}
          fill="#243e64"
          fontFamily="Roboto-Regular,Roboto"
        >
          <tspan x={-20.316} y={0}>
            {'J\xE1 tiraram'}
          </tspan>
        </text>
        <text
          transform="translate(104.473 7.956)"
          fill="#5eb5ad"
          fontSize={9}
          fontFamily="Roboto-Regular,Roboto"
        >
          <tspan x={-23.607} y={0}>
            {'N\xE3o tiraram'}
          </tspan>
        </text>
        <ellipse
          className="prefix_EEE_p"
          cx={3.5}
          cy={4}
          rx={3.5}
          ry={4}
          transform="translate(.473 1.956)"
        />
        <ellipse
          className="prefix_EEE_h"
          cx={3.5}
          cy={4}
          rx={3.5}
          ry={4}
          transform="translate(68.473 1.956)"
        />
      </g>
      <g transform="translate(536.254 346.614)">
        <rect
          className="prefix_EEE_c"
          width={91}
          height={27}
          rx={13.5}
          transform="translate(-.254 .385)"
        />
        <text
          transform="translate(44.746 15.385)"
          fontSize={9}
          letterSpacing=".04em"
          fontFamily="Roboto-Regular,Roboto"
          fill="#fff"
        >
          <tspan x={-18.212} y={0}>
            Exportar
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default BoxReduceYourCostsIcon;
