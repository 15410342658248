import { initialize } from 'redux-form';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Serviços
import ApiAuth from 'services/ApiAuth';

// Actions
import { toMessage } from 'components/message/MessageToasterAction';

// ==================================================
// FECHAR MODAL
// ==================================================
export function closeModalAccount() {
  return {
    type: 'CLOSE_MODAL_ACCOUNT_FETCHED',
    payload: false,
  };
}

// ==================================================
// ABRIR MODAL
// ==================================================
function openModal() {
  return {
    type: 'OPEN_MODAL_ACCOUNT_FETCHED',
    payload: true,
  };
}
export function openModalAccount() {
  const body = {
    installation: {
      name: '',
      type: 'TRIAL',
    },
    user: {
      name: '',
      contact: '',
      password: '',
    },
    indication: '',
  };

  return [
    cleanRequestAccount(),
    initialize('modalAccountForm', body),
    openModal(),
  ];
}

// ==================================================
// Solicita login
// ==================================================
function cleanRequestAccount() {
  return {
    type: 'CLEAN_STATUS_ACCOUNT_FETCHED',
    payload: '',
  };
}
function setRequestAccount(value) {
  return {
    type: 'STATUS_ACCOUNT_FETCHED',
    payload: value,
  };
}
export function sendRequestAccount(payload) {
  return (dispatch) => {
    ApiAuth().post('/v4/installations/trial/new', payload)
      .then(() => {
        dispatch(setRequestAccount(true));
      })
      .catch((e) => {
        toMessage(e.response, translate('Problema_ao_enviar_email'));
        dispatch(setRequestAccount(false));
      })
      .finally(() => {
      });
  };
}
