import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

function Captcha(props) {
  const onChange = (value) => {
    props.onChange(value);
  };

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      onChange={e => onChange(!!e)}
    />
  );
}

export default Captcha;

// ==================================================
// Estilos
// ==================================================
