import React, { useEffect, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';
import Aos from 'aos';
import 'aos/dist/aos.css';

// Componentes
import GridContainer from 'components/grids/GridContainer';
import GridItem from 'components/grids/GridItem';
import BoxIntroductionIcon from 'modules/home/homeComponents/BoxIntroductionIcon';

// Tradução
import { translate } from 'translates/TranslatesAction';

const Sirio = `${process.env.PUBLIC_URL}/images/logo_hospital_sirio_libanes.png`;
const SantaCasa = `${process.env.PUBLIC_URL}/images/logo_santa_casa_de_misericordia.svg`;
const CeA = `${process.env.PUBLIC_URL}/images/logo_cea.png`;
const Sodexo = `${process.env.PUBLIC_URL}/images/logo_sodexo.png`;

function BoxIntroductionComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <BoxIntroductionContent>
      <BoxIntroduction>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={5} md={5} lg={5} xl={5} direction="column" alignItems="flex-start" justify="space-between">
            <Title>{translate('Simplifique_a_forma_como_voca_faz_escalas_de_trabalho')}</Title>
            <Subtitle>{translate('Economize_tempo_e_custos_trabalhistas_otimizando_suas_escalas_de_trabalho_com_inteligencia_artificial')}</Subtitle>
            <Subtitle>{translate('Aplicativo_que_gera_escalas_de_trabalho_com_Inteligencia_Artificial')}</Subtitle>
            <IconDown icon={faArrowDown} />
          </GridItem>
          <GridItem xs={12} sm={7} md={7} lg={7} xl={7} alignItems="center">
            <BoxOverflow>
              <BoxIntroductionIconSG data-aos="fade-left" data-aos-duration="600" />
            </BoxOverflow>
          </GridItem>
        </GridContainer>
        <BoxClients>
          <Label>{translate('Empresas_que_confiam')}</Label>
          <GridContainer alignItems="center">
            <GridItem xs={6} sm={4} md={4} lg={4} xl={4} alignItems="center" justify="center">
              <LogoSirio src={Sirio} alt="Sírio Libanês" data-aos="flip-up" data-aos-anchor-placement="top-bottom" data-aos-duration="600" />
            </GridItem>
            <GridItem xs={6} sm={3} md={3} lg={3} xl={3} alignItems="center" justify="center">
              <LogoSantaCasa src={SantaCasa} alt="Santa Casa de Misericórdia de Porto Alegre" data-aos="flip-down" data-aos-anchor-placement="top-bottom" data-aos-duration="600" />
            </GridItem>
            <GridItem xs={6} sm={2} md={2} lg={2} xl={2} alignItems="center" justify="center">
              <LogoCeA src={CeA} alt="C&A" data-aos="flip-up" data-aos-anchor-placement="top-bottom" data-aos-duration="600" />
            </GridItem>
            <GridItem xs={6} sm={3} md={3} lg={3} xl={3} alignItems="center" justify="center">
              <LogoSodexo src={Sodexo} alt="Sodexo" data-aos="flip-down" data-aos-anchor-placement="top-bottom" data-aos-duration="600" />
            </GridItem>
          </GridContainer>
        </BoxClients>
      </BoxIntroduction>
    </BoxIntroductionContent>
  );
}

export default memo(BoxIntroductionComponent);

// ==================================================
// Estilos
// ==================================================
const BoxIntroductionContent = styled.div`
  width: 100%;
  background: #9751EB;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BoxIntroduction = styled.div`
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
  }
`;

const BoxClients = styled.div`
  position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 40px #00000014;
  border-radius: 20px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  bottom: 0;

  @media (max-width: 599px) {
    width: 100%;
    margin-bottom: -85px;
    min-height: 110px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
    margin-bottom: -60px;
    min-height: 70px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 100%;
    margin-bottom: -85px;
    min-height: 110px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    min-width: 1220px;
    max-width: 1220px;
    margin-bottom: -55px;
    min-height: 110px;
  }
  @media (min-width: 1920px) {
    min-width: 1220px;
    max-width: 1220px;
    margin-bottom: -55px;
    min-height: 110px;
  }
`;

const Label = styled.div`
  letter-spacing: 0.48px;
  color: #677EA0;
  white-space: nowrap;

  @media (max-width: 599px) {
    font: normal 12px Noto Sans;
    margin: 0px 10px 0px 10px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal 14px Noto Sans;
    margin: 0px 10px 0px 40px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal 16px Noto Sans;
    margin: 0px 20px 0px 40px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal 16px Noto Sans;
    margin: 0px 25px 0px 25px;
  }
  @media (min-width: 1920px) {
    font: normal 16px Noto Sans;
    margin: 0px 25px 0px 25px;
  }
`;

const Title = styled.div`
  letter-spacing: 0px;
  line-height: 50px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    padding: 0px 0px 0px 20px;
    margin: 20px 0px 15px 0px;
    font: bold 25px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin: 0px 0px 10px 0px;
    padding: 0px 0px 0px 20px;
    font: bold 25px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin: 0px 0px 20px 0px;
    padding: 0px 0px 0px 25px;
    font: bold 35px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 0px 0px 20px 0px;
    font: bold 46px Noto Sans;
  }
  @media (min-width: 1920px) {
    margin: 0px 0px 20px 0px;
    font: bold 46px Noto Sans;
  }
`;

const Subtitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    padding: 0px 0px 0px 20px;
    margin: 0px 0px 20px 0px;
    font: normal 12px Noto Sans;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 0px 0px 0px 20px;
    margin: 0px 0px 20px 0px;
    font: normal 12px Noto Sans;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    padding: 0px 0px 0px 25px;
    margin: 0px 0px 20px 0px;
    font: normal 16px Noto Sans;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 0px 0px 20px 0px;
    font: normal 16px Noto Sans;
  }
  @media (min-width: 1920px) {
    margin: 0px 0px 20px 0px;
    font: normal 16px Noto Sans;
  }
`;

const IconDown = styled(FontAwesomeIcon)`
  color: #FFF;

  @media (max-width: 599px) {
    margin: 0px 0px 0px 20px;
    font-size: 18px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin: 0px 0px 0px 20px;
    font-size: 18px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin: 0px 0px 0px 25px;
    font-size: 18px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin: 0px 0px 0px 0px;
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    font-size: 18px;
    margin: 0px 0px 0px 0px;
  }
`;

const LogoSirio = styled.img`
  @media (max-width: 599px) {
    width: 100px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100px;
    margin-top: 5px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 220px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 220px;
  }
  @media (min-width: 1920px) {
    width: 220px;
  }
`;

const LogoSodexo = styled.img`
  @media (max-width: 599px) {
    width: 100px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100px;
    margin-top: 5px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin-top: 10px;
    width: 160px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin-top: 10px;
    width: 160px;
  }
  @media (min-width: 1920px) {
    margin-top: 10px;
    width: 160px;
  }
`;

const LogoCeA = styled.img`
  @media (max-width: 599px) {
    width: 80px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 80px;
    margin-top: 5px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    margin-top: 10px;
    width: 120px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    margin-top: 10px;
    width: 120px;
  }
  @media (min-width: 1920px) {
    margin-top: 10px;
    width: 120px;
  }
`;

const LogoSantaCasa = styled.img`
  @media (max-width: 599px) {
    width: 80px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 80px;
    margin-top: 5px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 170px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 170px;
  }
  @media (min-width: 1920px) {
    width: 170px;
  }
`;

const BoxIntroductionIconSG = styled(BoxIntroductionIcon)`
  width: 100%;
  @media (max-width: 599px) {
    height: 250px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    height: 260px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    height: 410px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    height: 480px;
  }
  @media (min-width: 1920px) {
    height: 480px;
  }
`;


const BoxOverflow = styled.div`
  overflow: none;
`;
