import React, { memo } from 'react';

// Componentes
import Layout from 'components/layout/Layout';
import BoxIntroduction from 'modules/integration/integrationComponents/BoxIntroduction';
import BoxViews from 'modules/integration/integrationComponents/BoxViews';
import BoxApi from 'modules/integration/integrationComponents/BoxApi';
import BoxCsv from 'modules/integration/integrationComponents/BoxCsv';

function IntegrationPage() {
  window.scrollTo(0, 0);

  return (
    <Layout>
      <BoxIntroduction />
      <BoxViews />
      <BoxApi />
      <BoxCsv />
    </Layout>
  );
}

export default memo(IntegrationPage);

// ==================================================
// Estilos
// ==================================================
