import React, { useEffect, useState, useRef } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch, connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useHistory } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import ReactInterval from 'react-interval';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components/macro';

// Componentes
import Layout from 'components/layout/Layout';
import LogoStarGridWhite from 'components/layout/layoutComponents/LogoStarGridWhite';
import ButtonBig from 'components/button/ButtonBig';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import GridContainer from 'components/grids/GridContainer';

// Rotas
import Paths from 'routes/Paths';

// Tradução
import { translate } from 'translates/TranslatesAction';

// Estilos
import { purpleColor } from 'styles/Colors';

// Actions
import { setStatusLoader } from 'components/loader/loaderGeneral/LoaderGeneralAction';
import { sendRequestAccountValidate, sendRequestAccount } from 'modules/signUp/SignUpAction';
import { toMessage } from 'components/message/MessageToasterAction';

const selector = formValueSelector('signUpAccountForm');

function SignUpPage(props) {
  window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const history = useHistory();

  const status = useSelector(state => state.signUpReducer.status);
  const statusAccount = useSelector(state => state.signUpReducer.statusAccount);

  const [showPassword, setShowPassword] = useState(false);
  const [count, setCount] = useState(7);

  const isFirstRender = useRef(true);

  const Emoji = `${process.env.PUBLIC_URL}/images/emoji.png`;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      const token = props.location.search.replace('?token=', '');

      if (token) {
        dispatch(setStatusLoader(true));
        dispatch(sendRequestAccountValidate(token));
      } else {
        toMessage('', translate('Token_invalido'));
        history.push(Paths.home);
      }
      Aos.init({ duration: 2000 });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRender]);

  useEffect(() => {
    if (status === false) {
      toMessage('', translate('Token_invalido'));
      history.push(Paths.home);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (count === 2) onRedirect();
  }, [count]);

  const onRedirect = () => {
    window.location.href = process.env.REACT_APP_API_URL_APP;
  };

  const onInterval = () => {
    // setTimeout(1000);
    setCount(count - 1);
  };

  const onClose = () => {
    history.push(Paths.home);
  };

  const onSubmit = (data) => {
    if (!props.name || !props.email || !props.phoneNumber || !props.companyName || !props.password || !props.passwordConfirm || !props.segment || !props.job) {
      toastr.warning('', translate('Preencha_todos_os_campos'));
      return;
    }

    if (props.password !== props.passwordConfirm) {
      toastr.warning('', translate('As_senhas_devem_ser_iguais'));
      return;
    }

    const regexName = /^[a-zA-Z\s]+$/;
    if (!!props.name && !regexName.test(props?.name?.replace(' ', ''))) {
      toastr.warning('', translate('Nome_deve_conter_apenas_letras'));
      return;
    }

    dispatch(setStatusLoader(true));
    dispatch(sendRequestAccount(data));
  };

  if (statusAccount) {
    return (
      <Layout>
        <BoxForm>
          <BoxC>
            <ReactInterval timeout={1000} enabled callback={() => onInterval()} />
            <TitleOk>{translate('Cadastro_realizado_com_sucesso')}</TitleOk>
            <SubtitleOk>{translate('Voce_sera_redirecionado_para_o_app_em')} {count} {translate('segundos')}</SubtitleOk>
            <IconOk icon={faCheckCircle} />
          </BoxC>
        </BoxForm>
      </Layout>
    );
  }

  const arraySegment = [
    { value: 'LOGISTICS', label: 'Logística' },
    { value: 'EDUCATION', label: 'Educação' },
    { value: 'FOOD', label: 'Restaurantes' },
    { value: 'COMMERCE', label: 'Comércio' },
    { value: 'FINANCIAL SERVICES', label: 'Serviços Financeiros' },
    { value: 'SUPERMARKET', label: 'Supermercados' },
    { value: 'CONSTRUCTION', label: 'Construção' },
    { value: 'HEALTH', label: 'Hospital/Saúde' },
    { value: 'SECURITY', label: 'Segurança' },
    { value: 'CALL-CENTER', label: 'Call Centers' },
    { value: 'HOTEL', label: 'Hotéis' },
    { value: 'RETAIL', label: 'Varejo' },
    { value: 'INDUSTRY', label: 'Indústria' },
    { value: 'TECHNOLOGY', label: 'Tecnologia' },
    { value: 'OTHER', label: 'Outro' },
  ];

  return (
    <Layout>
      <form onSubmit={props.handleSubmit(onSubmit)} autoComplete="off" style={{ width: '100%', marginBottom: 0 }} id="new_form">
        <BoxForm>
          <BoxA>
            <LogoStarGridWhite data-aos="fade-left" data-aos-duration="600" />
            <BoxATitle data-aos="fade-up" data-aos-duration="600">
              {translate('Bem_vindo_a')}
              <Emogi src={Emoji} alt={translate('Ola')} />
            </BoxATitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 50 }}>{translate('Entre_com_suas_credenciais_ou_faca_seu_cadastro_para_comecar_a_usar_a_StarGrid')}</BoxASubtitle>
            <BoxASubtitle data-aos="fade-right" data-aos-duration="600" style={{ marginTop: 50 }}>{translate('Experimente_de_graca_por_15_dias')}</BoxASubtitle>
          </BoxA>

          <BoxB>
            <CircleClose onClick={() => onClose()}>X</CircleClose>

            <BoxBTitle>{translate('Cadastro')}</BoxBTitle>
            <GridContainer>
              <Field
                name="user.name"
                id="user.name"
                col={8}
                label={translate('Nome_e_sobrenome')}
                placeholder={translate('Digite_aqui___')}
                type="text"
                component={Input}
                fullWidth
                paddingRight
                shrink
              />

              <Field
                name="user.cpf"
                id="user.cpf"
                col={4}
                label={translate('CPF')}
                placeholder={translate('Digite_aqui___')}
                type="text"
                component={Input}
                fullWidth
                paddingRight={false}
                shrink
              />

              <Field
                name="installation.name"
                id="installation.name"
                col={12}
                label={translate('Nome_da_empresa')}
                placeholder={translate('Digite_aqui___')}
                type="text"
                component={Input}
                fullWidth
                paddingRight={false}
                shrink
              />

              <Field
                name="user.email"
                id="user.email"
                col={12}
                label={translate('Email')}
                type="text"
                component={Input}
                fullWidth
                paddingRight={false}
                shrink
                disabled
              />

              <Field
                name="user.phone_number"
                id="user.phone_number"
                col={12}
                label={translate('Telefone')}
                placeholder="(00) 0000-00000"
                mask="phone"
                validMask="PHONE"
                type="text"
                component={Input}
                fullWidth
                paddingRight={false}
                shrink
              />

              <Field
                name="user.job"
                id="user.job"
                col={12}
                label={translate('Cargo')}
                placeholder={translate('Digite_aqui___')}
                type="text"
                component={Input}
                fullWidth
                paddingRight={false}
                shrink
              />

              <Field
                name="installation.segment"
                id="installation.segment"
                col={12}
                label={translate('Segmento')}
                placeholder={translate('Selecione___')}
                component={Select}
                options={arraySegment}
                fullWidth
                paddingRight={false}
                shrink
              />

              <Field
                name="installation.indication"
                id="installation.indication_b"
                col={12}
                label={translate('Como_voce_conheceu_a_StarGrid__')}
                placeholder={translate('Um_amigo_me_indicou_e_quero_experimentar')}
                type="text"
                component={Input}
                fullWidth
                paddingRight={false}
                shrink
              />

              <Field
                name="user.password"
                id="user.password"
                col={6}
                label={translate('Senha')}
                placeholder="********"
                type={showPassword ? 'text' : 'password'}
                component={Input}
                fullWidth
                paddingRight
                shrink
                cursorPointer
                icon={showPassword ? faEye : faEyeSlash}
                onClickIcon={() => setShowPassword(!showPassword)}
              />
              <Field
                name="user.password_confirm"
                id="user.password_confirm"
                col={6}
                label={translate('Confirme_a_senha')}
                placeholder="********"
                type={showPassword ? 'text' : 'password'}
                component={Input}
                fullWidth
                paddingRight={false}
                cursorPointer
                icon={showPassword ? faEye : faEyeSlash}
                onClickIcon={() => setShowPassword(!showPassword)}
                shrink
              />
            </GridContainer>

            <ButtonBig
              id="button_modal_account_send"
              alignItems="center"
              label={translate('Finalizar_cadastro')}
              type="submit"
              colors="purple"
              margins="30px 0px 0px 0px"
              padding="0px 150px"
            />

            <ButtonBig
              id="button_modal_account_login"
              alignItems="center"
              label={translate('Ja_tenho_conta__Login')}
              type="button"
              colors="purple"
              transparent
              margins="0px 0px 20px 0px"
              padding="0px 107px"
              onClick={() => onRedirect()}
            />
          </BoxB>
        </BoxForm>
      </form>
    </Layout>
  );
}

const mapStateToProps = state => ({
  name: selector(state, 'user.name'),
  email: selector(state, 'user.email'),
  phoneNumber: selector(state, 'user.phone_number'),
  companyName: selector(state, 'installation.name'),
  segment: selector(state, 'installation.segment'),
  job: selector(state, 'user.job'),
  companyType: selector(state, 'installation.type'),
  password: selector(state, 'user.password'),
  passwordConfirm: selector(state, 'user.password_confirm'),
});

export default compose(
  reduxForm({ form: 'signUpAccountForm' }),
  connect(mapStateToProps),
)(SignUpPage);

// ==================================================
// Estilos
// ==================================================
const BoxForm = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 30px;
  margin: 12px 0px 12px 0px;
`;

const BoxA = styled.div`
  background: #9751EB;
  min-width: 600px;
  max-width: 600px;
  min-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 0px 0px 50px;
  border-radius: 10px 0px 0px 10px;

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;

const BoxB = styled.div`
  position: relative;
  background: #FFFFFF;
  flex: 1;
  min-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px 10px 60px;
  border-radius: 0px 10px 10px 0px;
`;

const BoxC = styled.div`
  position: relative;
  background: #FFFFFF;
  flex: 1;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 60px 10px 60px;
  border-radius: 10px;
`;

const BoxATitle = styled.div`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    font: normal normal normal 20px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 30px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 115px 0px 0px 0px;
  }
`;

const BoxASubtitle = styled.p`
  letter-spacing: 0px;
  color: #FFFFFF;

  @media (max-width: 599px) {
    text-align: center;
    font: normal normal normal 12px Noto Sans;
    margin: 5px 0px 0px 0px;
    max-width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 30px 0px 20px 0px;
    max-width: 486px;
    text-align: center;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 18px/28px Noto Sans;
    margin: 60px 0px 0px 0px;
    max-width: 486px;
  }
`;

const Emogi = styled.img`
  @media (max-width: 599px) {
    width: 20px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
  @media (min-width: 1920px) {
    width: 35px;
    margin: 0px 0px 0px 10px;
  }
`;

const CircleClose = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &:hover {
    box-shadow:
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }

  @media (max-width: 599px) {
    color: #FFF;
    background: transparent;
    border: solid 1px #FFF;
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;
    border-radius: 15px;
    top: 10px;
    right: 10px;
    font-size: 9px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    color: #FFF;
    background: transparent;
    border: solid 1px #FFF;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    color: #707070;
    background: #9751EB10;
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 34px;
    top: 25px;
    right: 25px;
    font-size: 18px;
  }
`;

const BoxBTitle = styled.div`
  width: 100%;
  letter-spacing: 0px;
  color: #000;

  @media (max-width: 599px) {
    font: normal normal normal 20px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
  @media (min-width: 1920px) {
    font: normal normal normal 36px/52px Noto Sans;
    margin: 5px 0px 0px 0px;
  }
`;

const IconOk = styled(FontAwesomeIcon)`
  font-size: 150px;
  color: #00FF00;
`;

const TitleOk = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: ${purpleColor[9]};
`;

const SubtitleOk = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  margin: 10px 0px 15px 0px;
`;
